import * as TEXTS from "../i18nText"

export default {
    [TEXTS.DISCLAIMER_TITLE_TEXT]: "Datos MLS proporcionados bajo licencia de la",
    [TEXTS.DISCLAIMER_TITLE_LINK_TEXT]: "Red Nacional de Corretaje",
    [TEXTS.PAYMENT_BREAKDOWN_TITLE_TEXT]: "Este no es un anuncio de un préstamo hipotecario ni una oferta para otorgar crédito.",
    [TEXTS.PAYMENT_BREAKDOWN_FIRST_TEXT]: "El pago estimado se basa en supuestos de seguro, impuestos y una tasa de interés de muestra del 7,250 % que utilizamos en el momento en que lo calificamos. Estos factores pueden ser diferentes al momento del cierre.",
    [TEXTS.PAYMENT_BREAKDOWN_SECOND_TEXT]: "El pago estimado se basa en supuestos de seguros, impuestos y el ",
    [TEXTS.PAYMENT_BREAKDOWN_SECOND_STRONG_TEXT]: "programa de préstamos para el que califica. Estos factores pueden ser diferentes al momento del cierre.",
    [TEXTS.IMPORT_PROP_ONE_TEXT]: "Usted puede importar una propiedad desde",
    [TEXTS.IMPORT_PROP_TWO_TEXT]: "proveyendo la URL de la propiedad",
    [TEXTS.IMPORT_PROP_O_TEXT]: "o",
    [TEXTS.THERE_IS_NO_LOAN_O_TEXT]: "No hay un originador de préstamo, por favor agregue uno",
    [TEXTS.PLEASE_AUTHENTICATE_TEXT]: "Para marcar propiedades como favoritas y agregar originadores de préstamos, autentíquese",
    [TEXTS.CURRENTLY_AS_GUEST_TEXT]: "Actualmente estás navegando como invitado",
    [TEXTS.AMOUNTS_BELOW_TEXT]: "Los montos a continuación provienen de la aprobación de su préstamo",
    [TEXTS.REMOVE_LOAN_ORIGINATOR_TEXT]: "¿Estás seguro de que quieres eliminar el originador de préstamos asociado?",
    [TEXTS.DO_NOT_HAVE_LOAN_ORIGINATOR_TEXT]: "No tienes un originador de préstamos vinculado a tu perfil",
    [TEXTS.LOAN_OFFICER_CONNECTED_TEXT]: "Su oficial de préstamos está conectado con Padzilly",
    [TEXTS.WE_ARE_CONTACTING_TEXT]: "Nos estamos comunicando con ellos para cargar su perfil de aprobación de préstamo de inmediato",
    [TEXTS.GETTING_PROPS_TEXT]: "Obteniendo propiedades, por favor espere",
    [TEXTS.GETTING_DETAILS_TEXT]: "Obteniendo la propiedad, por favor espere",
    [TEXTS.FORGOT_PASS_OTP_TEXT]: "Le enviaremos un correo electrónico con una contraseña de un solo uso (OTP) para restablecer su contraseña.",
    [TEXTS.VERIFICATION_TEXT]: "Por favor ingrese el código de verificación que le enviamos a su dirección de correo electrónico",
    [TEXTS.CHECK_SPAM_TEXT]: "Revisa tu carpeta de spam y basura si no está en tu bandeja de entrada. Esto puede tardar entre 2 y 3 minutos",
    [TEXTS.LETS_CREATE_PADZILLY_SWIFTMATCH_TEXT]: "¡Creemos su perfil de búsqueda de casas en Padzilly SearchPro!",
    [TEXTS.CHOOSE_PRE_APPROVED_TEXT]: "Elija esto si tiene una calificación o aprobación actual de un prestamista",
    [TEXTS.CHOOSE_NOT_APPROVED_TEXT]: "Elija esto si no tiene una aprobación, pero desea iniciar la búsqueda inteligente ahora",
    [TEXTS.CREATE_SWIFTMATCH_PROFILE_TEXT]: "¡Ningún problema! Creemos tu perfil de SearchPro",
    [TEXTS.FOR_BEST_RESULTS_TEXT]: "Para obtener los mejores resultados, se recomienda una precalificación de prestamista relacionada con Padzilly",
    [TEXTS.IN_THE_MEANTIME_TEXT]: "Mientras tanto, ¡probemos SearchPro!",
    [TEXTS.WE_WILL_REPLACE_TEXT]: "Reemplazaremos esto con los datos de su préstamo una vez que se cargue",
    [TEXTS.WE_WILL_CHECK_LOAN_OFFICER_TEXT]: "Verificaremos si su oficial de préstamos ya está conectado con Padzilly",
    [TEXTS.GREAT_NEWS_TEXT]: "¡Gran noticia! Su oficial de préstamos está conectado con Padzilly",
    [TEXTS.LOAN_OFFICER_NOT_CONNECTED_TEXT]: "Parece que su oficial de préstamos aún no está conectado con Padzilly",
    [TEXTS.WE_ARE_REACHING_OUT_TEXT]: "Nos estamos comunicando con ellos para que puedan registrarse y hacer que su viaje de compra de casas sea más fácil y rápido",
    [TEXTS.GREAT_NEWS_TWO_TEXT]: "¡Gran noticia! Su prestamista está conectado con Padzilly y encontramos la aprobación de su préstamo",
    [TEXTS.CONTINUE_SEARCH_TEXT]: "Puedes ir a Casas y continuar tu búsqueda",
    [TEXTS.ENTER_INFO_FOR_SEARCH_TEXT]: "Por favor, ingrese una ciudad, código postal o dirección para buscar",
    [TEXTS.NO_MATCHING_PROPERTIES_TEXT]: "No pudimos encontrar ninguna propiedad que coincida con sus criterios de búsqueda",
    [TEXTS.CHANGE_PROPERTY_TYPE_TEXT]: "Cambie el tipo de propiedad, las habitaciones y los filtros anteriores",
    [TEXTS.FIND_IN_ZILLOW_OR_REALTOR_TEXT]: "Si no puede encontrar una propiedad específica, puede buscar en Zillow o Realtor.com y compartirla con",
    [TEXTS.CHECK_MLS_TEXT]: "Consulta la cobertura de la MLS en tu área de búsqueda",
    [TEXTS.PROBLEM_LOADING_PROPERTIES_TEXT]: "Hubo un problema al cargar las propiedades",
    [TEXTS.CHECK_YOUR_CONNECTION_TEXT]: "Por favor, compruebe la conexión y vuelva a intentarlo",
    [TEXTS.WE_COULDNT_FIND_PROP_TEXT]: "Lo sentimos, no pudimos encontrar la propiedad",
    [TEXTS.FAVORITES_GUEST_TEXT]: "Se requiere una cuenta para acceder a su listado de propiedades favorito. Regístrese para crear una cuenta o inicie sesión si tiene una",
    [TEXTS.OFFER_PRO_BACKDROP_TEXT]: "Utilice OfferPro para calificar o asegurar la mejor oferta ajustando el precio y otros factores",
    [TEXTS.VERIFY_MONTHLY_ASSOCIATION_TEXT]: "Verifique la tarifa mensual de la asociación y el monto del impuesto anual a la propiedad para garantizar una aprobación precisa",
    [TEXTS.NOT_SAVED_PROPERTIES_TEXT]: "No has guardado ninguna propiedad como favorita",
    [TEXTS.EXPLORE_AND_SAVE_PROPERTIES_TEXT]: "Explora y guarda tus propiedades preferidas",
    [TEXTS.YOU_APPEAR_QUALIFIED_TEXT]: "¡Pareces calificado para esta casa!",
    [TEXTS.CASH_NEEDED_TEXT]: "Puede calificar si se reduce el efectivo necesario. Intente ajustar a continuación",
    [TEXTS.PAYMENT_NEEDED_TEXT]: "Puede calificar si se reduce el pago. Intente ajustar a continuación",
    [TEXTS.PAYMENT_AND_CASH_NEEDED_TEXT]: "Puede calificar si se reducen el pago y el efectivo necesarios. Intente ajustar a continuación",
    [TEXTS.MUST_BE_GREATER_THAN_TEXT]: "debe ser mayor que 0",
    [TEXTS.MORTGAGE_PAYMENT_ERROR_TEXT]: "debe ser menor que los Fondos disponibles",
    [TEXTS.VALID_URL_TO_IMPORT_ERROR_TEXT]: "debe ser una URL válida de Realtor o Zillow",
    [TEXTS.INVALID_EMAIL_ERROR_TEXT]: "Correo electronico inválido, ej: joe@padzilly.com",
    [TEXTS.ONLY_LETTERS_ERROR_TEXT]: "debe tener solo letras",
    [TEXTS.NO_MLO_FOUND_ERROR_TEXT]: "No se encontró ningún MLO con el correo electrónico proporcionado",
    [TEXTS.MLO_SAME_EMAIL_ERROR_TEXT]: "no debe ser el mismo que el que está asociado al MLO actual",
    [TEXTS.PASSWORDS_MUST_MATCH_ERROR_TEXT]: "Las contraseñas deben coincidir",
    [TEXTS.FIRST_NAME_ERROR_TEXT]: "Nombre Inválido, ej: Joe",
    [TEXTS.LAST_NAME_ERROR_TEXT]: "Apellido Inválido, ej: Smith",
    [TEXTS.FULL_NAME_ERROR_TEXT]: "Nombre completo Inválido, ej: Joe Smith",
    [TEXTS.MLO_WAS_REMOVED_ERROR_TEXT]: "El MLO fue eliminado",
    [TEXTS.NO_INVESTMENT_PROFILE_TEXT]: "No existe un perfil de inversión, si tienes una cuenta puedes iniciar sesión aquí",
    [TEXTS.CONTINUE_AS_GUEST_TEXT]: "Si deseas continuar como invitado puedes crear un perfil de inversión aquí",
    //LANDING PAGE
    [TEXTS.WE_DIDNT_FIND_PROPS_TEXT]: "No encontramos ninguna propiedad cerca de usted, puede iniciar una nueva búsqueda",
    [TEXTS.INFO_TEXT_ONE_TEXT]: "Solo Padzilly se concentra rápidamente en su casa utilizando el pago mensual deseado y el efectivo disponible para filtrar todas las casas y encontrar aquellas que se ajusten a su presupuesto.",
    [TEXTS.INFO_ONE_POINT_ONE_TEXT]: "Establezca su propio límite de pago e inversión, o cargue su calificación de préstamo de prestamista",
    [TEXTS.INFO_ONE_POINT_TWO_TEXT]: "Vea solo casas que se ajusten a sus requisitos o aprobación previa del prestamista",
    [TEXTS.INFO_ONE_POINT_THREE_TEXT]: "Cifras de pago y efectivo de alta precisión",
    [TEXTS.OFFER_LIKE_A_PRO_TEXT]: "Padzilly es la única plataforma que le ayuda a estructurar su oferta para obtener la mejor oferta y adaptar una propiedad a la aprobación de su préstamo.",
    [TEXTS.INFO_TWO_POINT_ONE_TEXT]: "Adjust price, seller concession and down payment in secondsAjuste el precio, la concesión del vendedor y el pago inicial en segundos",
    [TEXTS.INFO_TWO_POINT_TWO_TEXT]: "Pruebe infinitos escenarios financieros con facilidad",
    [TEXTS.INFO_TWO_POINT_THREE_TEXT]: "Estructura tu mejor trato y haz ofertas con confianza, tal como lo hacen los profesionales",
    [TEXTS.LOAN_APPROVAL_TEXT]: "Pídale a su prestamista que cargue la aprobación de su préstamo y Padzilly se asegurará de que cada listado coincida con su préstamo preaprobado",
    [TEXTS.INFO_THREE_POINT_ONE_TEXT]: "Elimina las conjeturas",
    [TEXTS.INFO_THREE_POINT_TWO_TEXT]: "Vea solo las casas para las que realmente califica",
    [TEXTS.INFO_THREE_POINT_THREE_TEXT]: "Pagos a nivel de prestamista y cifras de efectivo",
    [TEXTS.INFO_THREE_POINT_FOUR_TEXT]: "Obtenga cartas de aprobación de propiedad instantáneas y términos de oferta",
    [TEXTS.FOOTER_TEXT]: "Para una mejor experiencia puedes descargar la aplicación desde Google Play o AppStore",
    //SCHOOLS
    [TEXTS.SCHOOLS_DISCLAIMER_TEXT]: "La calificación GreatSchools ayuda a los padres a comparar escuelas dentro de un estado basándose en una variedad de indicadores de calidad escolar y proporciona una imagen útil de la eficacia con la que cada escuela atiende a todos sus estudiantes. Las calificaciones están en una escala de 1 (por debajo del promedio) a 10 (por encima del promedio) y puede incluir puntajes de exámenes, preparación para la universidad, progreso académico, cursos avanzados, equidad, disciplina y datos de asistencia. También recomendamos a los padres que visiten las escuelas, consideren otra información sobre el desempeño y los programas escolares y consideren las necesidades familiares como parte del proceso de selección",
    //SEARCH PRO
    [TEXTS.SELLER_REBATE_ONLY_LAP_TEXT]: "El reembolso al vendedor solo está disponible para usuarios con préstamos aprobados asociados a su cuenta",
    //LOADING PROPERTIES
    [TEXTS.LOADING_PROPERTIES_ONE_TEXT]: "Sacando cuentas como un chef picando verduras... ¡Tu receta para la casa perfecta está casi lista! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_TWO_TEXT]: "Sacando cuentas como un DJ mezclando ritmos... ¡La pista de la casa perfecta está a punto de sonar! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_THREE_TEXT]: "Sacando cuentas como una ardilla guardando bellotas... ¡Recopilando las casas adecuadas para ti! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_FOUR_TEXT]: " Sacando cuentas como un barista espumando leche... ¡Tu casa personalizada está casi lista! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_FIVE_TEXT]: "Sacando cuentas como un maestro de rompecabezas... ¡Encajando las piezas de tu hogar perfecto! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_SIX_TEXT]: " Sacando cuentas como un escritor buscando la palabra perfecta... ¡Tu historia de hogar está casi escrita! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_SEVEN_TEXT]: "Sacando cuentas como un escultor tallando mármol... ¡Tu obra maestra de hogar está tomando forma! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_EIGHT_TEXT]: " Sacando cuentas como un panadero amasando masa... ¡La casa perfecta está a punto de levantarse! Por favor, espere",
    [TEXTS.LOADING_PROPERTIES_NINE_TEXT]: "Sacando cuentas como un gamer subiendo de nivel... ¡Tu victoria de hogar está en el horizonte! Por favor, espere",
}