import BaseDialog from "../BaseDialog/BaseDialog";
import BaseDialogBody from "../BaseDialog/BaseDialogBody";
import BaseDialogFooter from "../BaseDialog/BaseDialogFooter";
import BaseDialogHeader from "../BaseDialog/BaseDialogHeader";
import BaseCurrency from "../BaseComponents/BaseCurrency";
import BaseBtn from "../BaseComponents/BaseBtn";
import BaseCheckbox from "../BaseComponents/BaseCheckbox";
import BaseSelect from "../BaseComponents/BaseSelect";
import { Box } from "@mui/material";
import {
    YEAR_BUILT_MAX,
    YEAR_BUILT_MIN,
    BATH_BED_ROOMS_MAX,
    BATH_BED_ROOMS_MIN,
    HOME_SIZE_MIN,
    HOME_SIZE_MAX,
    LOT_SIZE_MIN,
    LOT_SIZE_MAX
} from "../../constants/selectOptions";
import { useState, useEffect, useRef } from "react";
import {
    CONDOMINIUM,
    MOBILE_HOME,
    SINGLE_FAMILY,
    TOWNHOUSE
} from "../../constants/constants";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useStore } from "../../hooks/store/store";
import { GET_LAST_SEARCH } from "../../constants/store/getters";
import { useTranslation } from 'react-i18next';
import {
    BATHROOMS_LABEL,
    BEDROOMS_LABEL,
    CONDOMINIUM_LABEL,
    FILTERS_LABEL,
    HOME_SIZE_LABEL,
    HOME_TYPE_LABEL,
    LOT_SIZE_LABEL,
    MAX_LABEL,
    MIN_LABEL,
    MOBILE_HOME_LABEL,
    PRICE_LABEL,
    RESET_LABEL,
    SEARCH_HOMES_LABEL,
    SINGLE_FAMILY_LABEL,
    TOWNHOUSE_LABEL,
    YEAR_BUILT_LABEL
} from "../../i18n/i18nLabel";

export default function FiltersDialog(props) {

    const { t } = useTranslation();

    const theme = useTheme();

    const { state } = useStore();

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

    const [search, setSearch] = useState(props.search);

    useEffect(() => { setSearch(props.search) }, [props.search]);

    const [homeType, setHomeType] = useState(() => {
        let type = {
            townhouse: true,
            condominium: true,
            single_house: true,
            mobile_home: true,
        };

        const lastSearch = state[GET_LAST_SEARCH] !== null && state[GET_LAST_SEARCH] !== undefined
            ? Object.keys(state[GET_LAST_SEARCH]).length > 0
                ? state[GET_LAST_SEARCH]
                : null
            : null;

        if (lastSearch) {
            const typesString = lastSearch.propertyType;
            const typesArray = typesString.split(",");
            if (typesArray.indexOf(TOWNHOUSE) !== -1) {
                type.townhouse = true;
            } else {
                type.townhouse = false;
            }
            if (typesArray.indexOf(CONDOMINIUM) !== -1) {
                type.condominium = true;
            } else {
                type.condominium = false;
            }
            if (typesArray.indexOf(SINGLE_FAMILY) !== -1) {
                type.single_house = true;
            } else {
                type.single_house = false;
            }
            if (typesArray.indexOf(MOBILE_HOME) !== -1) {
                type.mobile_home = true;
            } else {
                type.mobile_home = false;
            }
        }

        return type;
    });

    const types = useRef([]);

    useEffect(() => {
        types.current = [CONDOMINIUM, TOWNHOUSE, SINGLE_FAMILY, MOBILE_HOME];
        if (homeType.townhouse === false) {
            const index = types.current.indexOf(TOWNHOUSE);
            if (index !== -1) {
                types.current.splice(index, 1);
            }
        } else {
            if (!types.current.includes(TOWNHOUSE)) {
                types.current.push(TOWNHOUSE);
            }
        }
        if (homeType.condominium === false) {
            const index = types.current.indexOf(CONDOMINIUM);
            if (index !== -1) {
                types.current.splice(index, 1);
            }
        } else {
            if (!types.current.includes(CONDOMINIUM)) {
                types.current.push(CONDOMINIUM);
            }
        }
        if (homeType.single_house === false) {
            const index = types.current.indexOf(SINGLE_FAMILY);
            if (index !== -1) {
                types.current.splice(index, 1);
            }
        } else {
            if (!types.current.includes(SINGLE_FAMILY)) {
                types.current.push(SINGLE_FAMILY);
            }
        }
        if (homeType.mobile_home === false) {
            const index = types.current.indexOf(MOBILE_HOME);
            if (index !== -1) {
                types.current.splice(index, 1);
            }
        } else {
            if (!types.current.includes(MOBILE_HOME)) {
                types.current.push(MOBILE_HOME);
            }
        }
        const typesString = types.current.join(",");
        setSearch(prevState => ({
            ...prevState,
            propertyType: typesString
        }));

    }, [homeType]);

    const handleDialogBeforeHide = () => {
        setHomeType({
            townhouse: true,
            condominium: true,
            single_house: true,
            mobile_home: true,
        });
        types.current = [];
    }

    return (
        <BaseDialog {...props}>
            <BaseDialogHeader title={t(FILTERS_LABEL)} close={() => props.onClose()} />
            <BaseDialogBody>
                <Box sx={{
                    display: "flex",
                    justifyContent: "end"
                }}>
                    <BaseBtn
                        className="resetBtn"
                        label={t(RESET_LABEL)}
                        variant="outlined"
                        onClick={() => {
                            setSearch((prevState) => {
                                return {
                                    ...prevState,
                                    minPrice: null,
                                    maxPrice: null,
                                    minBedrooms: null,
                                    maxBedrooms: null,
                                    minBathrooms: null,
                                    maxBathrooms: null,
                                    minHomeSize: null,
                                    maxHomeSize: null,
                                    minLotSize: null,
                                    maxLotSize: null,
                                    minHomeYear: null,
                                    maxHomeYear: null,
                                    propertyType: "Condominium,Townhouse,Single Family,Mobile Home",
                                }
                            });

                            setHomeType({
                                townhouse: true,
                                condominium: true,
                                single_house: true,
                                mobile_home: true,
                            });

                        }}
                    />
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: !isMdOrSmaller ? "row" : "column"
                }}>
                    <Box>
                        <p style={{ marginTop: "0px", padding: "4px", textAlign: "center" }}>{t(PRICE_LABEL)}</p>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                        }}>
                            <BaseCurrency
                                nullable
                                className="inputFilters"
                                label={t(MIN_LABEL)}
                                variant="outlined"
                                value={search.minPrice}
                                onInputChange={(value) => {
                                    if (value) {
                                        value = value.toString();
                                    }
                                    setSearch(prevState => ({
                                        ...prevState,
                                        minPrice: (value) ? parseFloat(value.replace(/,/g, '')) : null,
                                    }));
                                }}
                                onBlur={() => {
                                    if (search.maxPrice < search.minPrice && search.maxPrice !== null && search.minPrice !== null) {
                                        const minP = search.minPrice;
                                        const maxP = search.maxPrice;
                                        setSearch(prevState => ({
                                            ...prevState,
                                            minPrice: maxP,
                                            maxPrice: minP,
                                        }));
                                    }
                                }}
                            />
                            <BaseCurrency
                                nullable
                                className="inputFilters"
                                label={t(MAX_LABEL)}
                                variant="outlined"
                                value={search.maxPrice}
                                onInputChange={(value) => {
                                    if (value) {
                                        value = value.toString();
                                    }
                                    setSearch(prevState => ({
                                        ...prevState,
                                        maxPrice: (value) ? parseFloat(value.replace(/,/g, '')) : null,
                                    }));
                                }}
                                onBlur={() => {
                                    if (search.maxPrice < search.minPrice && search.maxPrice !== null && search.minPrice !== null) {
                                        const minP = search.minPrice;
                                        const maxP = search.maxPrice;
                                        setSearch(prevState => ({
                                            ...prevState,
                                            minPrice: maxP,
                                            maxPrice: minP,
                                        }));
                                    }
                                }}
                            />
                        </Box>
                        <p style={{ marginTop: "0px", padding: "4px", textAlign: "center" }}>{t(BEDROOMS_LABEL)}</p>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: "8px"
                        }}>
                            <BaseSelect
                                className="inputFilters"
                                optionValue="value"
                                optionLabel="label"
                                optionKey="value"
                                label={t(MIN_LABEL)}
                                variant="outlined"
                                options={BATH_BED_ROOMS_MIN}
                                value={search.minBedrooms}
                                onInputChange={(value) => {
                                    const i = BATH_BED_ROOMS_MAX.findIndex(item => item.value === value);
                                    setSearch(prevState => ({
                                        ...prevState,
                                        minBedrooms: value !== "" ? value : null,
                                        maxBedrooms: prevState.maxBedrooms < value && prevState.maxBedrooms !== null && value !== null
                                            ? BATH_BED_ROOMS_MAX[i + 1].value === "Any"
                                                ? null
                                                : BATH_BED_ROOMS_MAX[i + 1].value
                                            : prevState.maxBedrooms
                                    }));
                                }}
                            />
                            <BaseSelect
                                className="inputFilters"
                                optionValue="value"
                                optionLabel="label"
                                optionKey="value"
                                label={t(MAX_LABEL)}
                                variant="outlined"
                                options={BATH_BED_ROOMS_MAX}
                                value={search.maxBedrooms}
                                onInputChange={(value) => {
                                    const i = BATH_BED_ROOMS_MIN.findIndex(item => item.value === value);
                                    setSearch(prevState => ({
                                        ...prevState,
                                        maxBedrooms: value,
                                        minBedrooms: prevState.minBedrooms > value && prevState.minBedrooms !== null && value !== null
                                            ? BATH_BED_ROOMS_MIN[i - 1].value === "Any"
                                                ? null
                                                : BATH_BED_ROOMS_MIN[i - 1].value
                                            : prevState.minBedrooms
                                    }));
                                }}
                            />
                        </Box>
                        <p style={{ marginTop: "0px", padding: "4px", textAlign: "center" }}>{t(BATHROOMS_LABEL)}</p>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: "8px"
                        }}>
                            <BaseSelect
                                className="inputFilters"
                                optionValue="value"
                                optionLabel="label"
                                optionKey="value"
                                label={t(MIN_LABEL)}
                                variant="outlined"
                                options={BATH_BED_ROOMS_MIN}
                                value={search.minBathrooms}
                                onInputChange={(value) => {
                                    const i = BATH_BED_ROOMS_MAX.findIndex(item => item.value === value);
                                    setSearch(prevState => ({
                                        ...prevState,
                                        minBathrooms: value !== "" ? value : null,
                                        maxBathrooms: prevState.maxBathrooms < value && prevState.maxBathrooms !== null && value !== null
                                            ? BATH_BED_ROOMS_MAX[i + 1].value === "Any"
                                                ? null
                                                : BATH_BED_ROOMS_MAX[i + 1].value
                                            : prevState.maxBathrooms
                                    }));
                                }}
                            />
                            <BaseSelect
                                className="inputFilters"
                                optionValue="value"
                                optionLabel="label"
                                optionKey="value"
                                label={t(MAX_LABEL)}
                                variant="outlined"
                                options={BATH_BED_ROOMS_MAX}
                                value={search.maxBathrooms}
                                onInputChange={(value) => {
                                    const i = BATH_BED_ROOMS_MIN.findIndex(item => item.value === value);
                                    setSearch(prevState => ({
                                        ...prevState,
                                        maxBathrooms: value !== "" ? value : null,
                                        minBathrooms: prevState.minBathrooms > value && prevState.minBathrooms !== null && value !== null
                                            ? BATH_BED_ROOMS_MIN[i - 1].value === "Any"
                                                ? null
                                                : BATH_BED_ROOMS_MIN[i - 1].value
                                            : prevState.minBathrooms
                                    }));
                                }}
                            />
                        </Box>
                        <p style={{ marginTop: "0px", padding: "4px", textAlign: "center" }}>{t(HOME_SIZE_LABEL)}</p>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: "8px"
                        }}>
                            <BaseSelect
                                className="inputFilters"
                                optionValue="value"
                                optionLabel="label"
                                optionKey="value"
                                label={t(MIN_LABEL)}
                                variant="outlined"
                                options={HOME_SIZE_MIN}
                                value={search.minHomeSize}
                                onInputChange={(value) => {
                                    const i = HOME_SIZE_MAX.findIndex(item => item.value === value);
                                    setSearch(prevState => ({
                                        ...prevState,
                                        minHomeSize: value !== "" ? value : null,
                                        maxHomeSize: prevState.maxHomeSize < value && prevState.maxHomeSize !== null && value !== null
                                            ? HOME_SIZE_MAX[i + 1].value === "Any"
                                                ? null
                                                : HOME_SIZE_MAX[i + 1].value
                                            : prevState.maxHomeSize
                                    }));
                                }}
                            />
                            <BaseSelect
                                className="inputFilters"
                                optionValue="value"
                                optionLabel="label"
                                optionKey="value"
                                label={t(MAX_LABEL)}
                                variant="outlined"
                                options={HOME_SIZE_MAX}
                                value={search.maxHomeSize}
                                onInputChange={(value) => {
                                    const i = HOME_SIZE_MIN.findIndex(item => item.value === value);
                                    setSearch(prevState => ({
                                        ...prevState,
                                        maxHomeSize: value !== "" ? value : null,
                                        minHomeSize: prevState.minHomeSize > value && prevState.minHomeSize !== null && value !== null
                                            ? HOME_SIZE_MIN[i - 1].value === "Any"
                                                ? null
                                                : HOME_SIZE_MIN[i - 1].value
                                            : prevState.minHomeSize
                                    }));
                                }}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <p style={{ marginTop: "0px", padding: "4px", textAlign: "center" }}>{t(LOT_SIZE_LABEL)}</p>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: "8px"
                        }}>
                            <BaseSelect
                                className="inputFilters"
                                optionValue="value"
                                optionLabel="label"
                                optionKey="value"
                                label={t(MIN_LABEL)}
                                variant="outlined"
                                options={LOT_SIZE_MIN}
                                value={search.minLotSize}
                                onInputChange={(value) => {
                                    const i = LOT_SIZE_MAX.findIndex(item => item.value === value);
                                    setSearch(prevState => ({
                                        ...prevState,
                                        minLotSize: value !== "" ? value : null,
                                        maxLotSize: prevState.maxLotSize < value && prevState.maxLotSize !== null && value !== null
                                            ? LOT_SIZE_MAX[i + 1].value === "Any"
                                                ? null
                                                : LOT_SIZE_MAX[i + 1].value
                                            : prevState.maxLotSize
                                    }));
                                }}
                            />
                            <BaseSelect
                                className="inputFilters"
                                optionValue="value"
                                optionLabel="label"
                                optionKey="value"
                                label={t(MAX_LABEL)}
                                variant="outlined"
                                options={LOT_SIZE_MAX}
                                value={search.maxLotSize}
                                onInputChange={(value) => {
                                    const i = LOT_SIZE_MIN.findIndex(item => item.value === value);
                                    setSearch(prevState => ({
                                        ...prevState,
                                        maxLotSize: value !== "" ? value : null,
                                        minLotSize: prevState.minLotSize > value && prevState.minLotSize !== null && value !== null
                                            ? LOT_SIZE_MIN[i - 1].value === "Any"
                                                ? null
                                                : LOT_SIZE_MIN[i - 1].value
                                            : prevState.minLotSize
                                    }));
                                }}
                            />
                        </Box>
                        <p style={{ marginTop: "0px", padding: "4px", textAlign: "center" }}>{t(YEAR_BUILT_LABEL)}</p>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: "8px"
                        }}>
                            <BaseSelect
                                className="inputFilters"
                                optionValue="value"
                                optionLabel="label"
                                optionKey="value"
                                label={t(MIN_LABEL)}
                                variant="outlined"
                                options={YEAR_BUILT_MIN()}
                                value={search.minHomeYear}
                                onInputChange={(value) => {
                                    const i = YEAR_BUILT_MAX().findIndex(item => item.value === value);
                                    setSearch(prevState => ({
                                        ...prevState,
                                        minHomeYear: value !== "" ? value : null,
                                        maxHomeYear: prevState.maxHomeYear < value && prevState.maxHomeYear !== null && value !== null
                                            ? YEAR_BUILT_MAX()[i - 1].value === "Any"
                                                ? null
                                                : YEAR_BUILT_MAX()[i - 1].value
                                            : prevState.maxHomeYear
                                    }));
                                }}
                            />
                            <BaseSelect
                                className="inputFilters"
                                optionValue="value"
                                optionLabel="label"
                                optionKey="value"
                                label={t(MAX_LABEL)}
                                variant="outlined"
                                options={YEAR_BUILT_MAX()}
                                value={search.maxHomeYear}
                                onInputChange={(value) => {
                                    const i = YEAR_BUILT_MIN().findIndex(item => item.value === value);
                                    setSearch(prevState => ({
                                        ...prevState,
                                        maxHomeYear: value !== "" ? value : null,
                                        minHomeYear: prevState.minHomeYear > value && prevState.minHomeYear !== null && value !== null
                                            ? YEAR_BUILT_MIN()[i + 1].value === "Any"
                                                ? null
                                                : YEAR_BUILT_MIN()[i + 1].value
                                            : prevState.minHomeYear
                                    }));
                                }}
                            />
                        </Box>
                        <p style={{ marginTop: "0px", padding: "4px", textAlign: "center" }}>{t(HOME_TYPE_LABEL)}</p>
                        <Box sx={{
                            display: "flex",
                            flexDirection: !isMdOrSmaller ? "row" : "column",
                            alignItems: !isMdOrSmaller ? "center" : "start",
                            marginLeft: "5px",
                            marginRight: "5px"
                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <BaseCheckbox
                                    label={t(CONDOMINIUM_LABEL)}
                                    value={homeType.condominium}
                                    onInputChange={(value) => {
                                        setHomeType(prevState => ({
                                            ...prevState,
                                            condominium: value
                                        }));
                                    }}
                                />
                                <BaseCheckbox
                                    label={t(TOWNHOUSE_LABEL)}
                                    value={homeType.townhouse}
                                    onInputChange={(value) => {
                                        setHomeType(prevState => ({
                                            ...prevState,
                                            townhouse: value
                                        }));
                                    }}
                                />
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <BaseCheckbox
                                    label={t(SINGLE_FAMILY_LABEL)}
                                    value={homeType.single_house}
                                    onInputChange={(value) => {
                                        setHomeType(prevState => ({
                                            ...prevState,
                                            single_house: value
                                        }));
                                    }}
                                />
                                <BaseCheckbox
                                    label={t(MOBILE_HOME_LABEL)}
                                    value={homeType.mobile_home}
                                    onInputChange={(value) => {
                                        setHomeType(prevState => ({
                                            ...prevState,
                                            mobile_home: value
                                        }));
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </BaseDialogBody>
            <BaseDialogFooter className="setProperties">
                <BaseBtn
                    label={t(SEARCH_HOMES_LABEL)}
                    onClick={() => {
                        props.changeSearch(search);
                        props.onClose();
                        handleDialogBeforeHide();
                    }}
                />
            </BaseDialogFooter>
        </BaseDialog>
    );
}