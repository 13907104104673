import * as LABELS from "../i18nLabel"

export default {
    //TOP BAR
    [LABELS.HOMES_LABEL]: "Homes",
    [LABELS.FAVORITES_LABEL]: "Favorites",
    [LABELS.PROFILE_LABEL]: "Profile",
    [LABELS.SETTINGS_LABEL]: "Settings",
    [LABELS.SIGN_OUT_LABEL]: "Sign Out",
    //HOMES
    [LABELS.CURRENT_LOCATION_LABEL]: "Current Location",
    [LABELS.PRICE_LABEL]: "Price",
    [LABELS.BEDROOMS_LABEL]: "Beds",
    [LABELS.SIZE_LABEL]: "Size",
    [LABELS.FULL_BATH_LABEL]: "Full Baths",
    [LABELS.HOME_SIZE_LABEL]: "Home Size",
    [LABELS.LOT_SIZE_LABEL]: "Lot Size",
    [LABELS.YEAR_BUILT_LABEL]: "Year Built",
    [LABELS.ORDER_BY_LABEL]: "Order By",
    [LABELS.PAYMENT_LABEL]: "Payment",
    [LABELS.CASH_LABEL]: "Cash",
    [LABELS.FILTERS_LABEL]: "Filters",
    [LABELS.BATHROOMS_LABEL]: "Baths",
    [LABELS.HOME_TYPE_LABEL]: "Home Type",
    [LABELS.CONDOMINIUM_LABEL]: "Condiminium",
    [LABELS.SINGLE_FAMILY_LABEL]: "Single Family",
    [LABELS.MOBILE_HOME_LABEL]: "Mobile Home",
    [LABELS.TOWNHOUSE_LABEL]: "Townhouse",
    [LABELS.SEARCH_HOMES_LABEL]: "Search Homes",
    [LABELS.RESET_LABEL]: "Reset",
    [LABELS.SEARCH_LABEL]: "Search",
    [LABELS.MAX_LABEL]: "Max",
    [LABELS.MIN_LABEL]: "Min",
    [LABELS.LIVE_SEARCH_LABEL]: "City, Zip or Address",
    [LABELS.NO_LOCATIONS_LABEL]: "No Locations",
    [LABELS.ANY_LABEL]: "Any",
    [LABELS.NEXT_LABEL]: "More Properties",
    [LABELS.SEARCH_RESULT_LABEL]: "Houses for sale in",
    //PROPERTY_CARD
    [LABELS.NEW_CONSTRUCTION_LABEL]: "New Construction",
    [LABELS.PENDING_LABEL]: "Pending",
    [LABELS.UNDER_CONTRACT_LABEL]: "Under Contract",
    [LABELS.COMING_SOON_LABEL]: "Coming Soon",
    [LABELS.FOR_SALE_LABEL]: "For Sale",
    [LABELS.NO_INFO_LABEL]: "No Info",
    [LABELS.UNKNOWN_ADDRESS_LABEL]: "Unknown Address",
    [LABELS.UNKNOWN_CITY_LABEL]: "Unknown City",
    [LABELS.UNKNOWN_STATE_LABEL]: "Unknown State",
    [LABELS.UNKNOWN_ZIP_CODE_LABEL]: "Unknown Zip Code",
    [LABELS.NOTE_LABEL]: "NOTE",
    [LABELS.HOA_FEE_LABEL]: "HOA Fee",
    [LABELS.TAXES_LABEL]: "Taxes",
    [LABELS.MONTHLY_PAYMENT_LABEL]: "Est. Monthly Payment",
    [LABELS.CASH_NEEDED_LABEL]: "Cash Needed",
    //PAYMENT BREAKDOWN
    [LABELS.LEARN_MORE_LABEL]: "Learn More",
    [LABELS.PAYMENT_NOT_INCLUDE_LABEL]: "Payment does not include",
    [LABELS.ASSOCIATION_FEE_LABEL]: "Association Fee",
    [LABELS.INSURANCE_LABEL]: "Insurance",
    [LABELS.PROPERTY_TAX_LABEL]: "Property Tax",
    [LABELS.MORTGAGE_LABEL]: "Mortgage Insurance",
    //FAV CARD
    [LABELS.VIEW_MORE_FAV_CARD_LABEL]: "View More",
    [LABELS.COMPARE_LABEL]: "Compare",
    [LABELS.CANCEL_LABEL]: "Cancel",
    [LABELS.SAVED_HOMES_LABEL]: "Saved Homes",
    [LABELS.TAP_HOMES_LABEL]: "Tap Homes to Select",
    [LABELS.SELECTED_LABEL]: "Selected",
    [LABELS.SELECTED_TO_COMPARE_LABEL]: "Select 2-5 homes to compare",
    //PROP COMPARISON
    [LABELS.STREET_ADDRESS_LABEL]: "Street Address",
    [LABELS.STATUS_LABEL]: "Status",
    [LABELS.CITY_LABEL]: "City",
    [LABELS.STATE_LABEL]: "State",
    [LABELS.ZIPCODE_LABEL]: "Zip Code",
    [LABELS.LISTING_PRICE_LABEL]: "Listing Price",
    [LABELS.SAVED_OFFER_PRICE_LABEL]: "Saved Offer Price",
    [LABELS.SELLER_REBATE_LABEL]: "Seller Rebate",
    [LABELS.DOWN_PAYMENT_LABEL]: "Down Payment",
    [LABELS.ANNUAL_TAX_AMOUNT_LABEL]: "Annual Tax Amount",
    [LABELS.INVESTMENT_LABEL]: "Investment",
    [LABELS.HALF_BATHROOMS_LABEL]: "Half Bathrooms",
    [LABELS.SQUARE_FEET_LABEL]: "Square Feet",
    [LABELS.MONTHLY_LABEL]: "Monthly",
    [LABELS.CLOSE_LABEL]: "Close",
    [LABELS.HOME_COMPARISON_LABEL]: "Home Comparison",
    //IMPORT PROP
    [LABELS.IMPORT_PROPERTY_LABEL]: "Import Property",
    [LABELS.SEARCH_AND_IMPORT_PROP_LABEL]: "Search and Import Property",
    //SETTINGS
    [LABELS.TERMS_OF_USE_LABEL]: "Terms of Use",
    [LABELS.PRIVACY_POLICY_LABEL]: "Privacy and Security",
    [LABELS.LOG_IN_LABEL]: "Log In",
    //Profile
    [LABELS.SIGN_UP_LABEL]: "Sign Up",
    [LABELS.PERSONAL_INFO_LABEL]: "Personal Info",
    [LABELS.NAME_LABEL]: "Name",
    [LABELS.EMAIL_LABEL]: "Email",
    [LABELS.COMPANY_LABEL]: "Company",
    [LABELS.PHONE_LABEL]: "Phone",
    [LABELS.LOAN_ORIGINATOR_PERSONAL_INFO_LABEL]: "LOAN ORIGINATOR PERSONAL INFO",
    [LABELS.ADD_LOAN_ORIGINATOR_LABEL]: "Add Loan Originator",
    [LABELS.CHANGE_MLO_LABEL]: "Change MLO",
    [LABELS.REMOVE_MLO_LABEL]: "Remove MLO",
    [LABELS.INVESTMENT_PROFILE_LABEL]: "INVESTMENT PROFILE",
    [LABELS.PAYMENT_LIMIT_LABEL]: "Payment Limit",
    [LABELS.FUND_LIMIT_LABEL]: "Funds Limit",
    [LABELS.SAVE_CHANGES_LABEL]: "Save Changes",
    [LABELS.GO_BACK_TO_SEARCH_LABEL]: "Go Back To Search",
    [LABELS.MISSING_FIRST_NAME_LABEL]: "Missing First Name",
    [LABELS.MISSING_LAST_NAME_LABEL]: "Missing Last Name",
    [LABELS.MISSING_EMAIL_LABEL]: "Missing Email",
    [LABELS.LOAN_ORIGINATOR_LABEL]: "Loan Originator",
    [LABELS.CHANGE_LOAN_ORIGINATOR_LABEL]: "Change Loan Originator",
    [LABELS.CONFIRM_LOAN_ORIGINATOR_LABEL]: "Confirm Loan Originator",
    [LABELS.LOAN_OFFICER_NAME_LABEL]: "Loan Officer's First Name",
    [LABELS.LOAN_OFFICER_EMAIL_LABEL]: "Loan Officer's Email",
    [LABELS.LOAN_OFFICER_LABEL]: "Your Loan Officer",
    [LABELS.CONFIRM_LABEL]: "Confirm",
    [LABELS.CONFIRMATION_LABEL]: "Confirmation",
    [LABELS.LOADING_LABEL]: "Thanks for your waiting",
    [LABELS.CREATE_INVESTMENT_PROFILE_LABEL]: "Create Investment Profile",
    [LABELS.LOAN_INFORMATION_LABEL]: "Loan Information",
    [LABELS.LOAN_TYPE_LABEL]: "Loan Type",
    [LABELS.TERM_LABEL]: "Term",
    [LABELS.YEARS_LABEL]: "years",
    //PROP DETAILS
    [LABELS.SHARE_LABEL]: "Share",
    [LABELS.SAVE_LABEL]: "Save as Favorite",
    [LABELS.QUALIFICATION_LABEL]: "Qualification Letter",
    [LABELS.OFFER_PRO_LABEL]: "Offer Pro",
    [LABELS.REMOVE_LABEL]: "Remove from Favorites",
    [LABELS.VIEW_IMAGE_LABEL]: "View Image",
    [LABELS.VIEW_ALL_IMAGES_LABEL]: "See All",
    [LABELS.PHOTOS_LABEL]: "Photos",
    [LABELS.NO_PHOTOS_LABEL]: "No Photos Available",
    [LABELS.BUILT_IN_LABEL]: "Built in",
    [LABELS.MORTGAGE_INS_LABEL]: "Mortgage Ins",
    [LABELS.ABOUT_THIS_HOME_LABEL]: "About this home",
    [LABELS.LISTED_BY_LABEL]: "Listed By",
    //LOGIN
    [LABELS.WELCOME_BACK_LABEL]: "Welcome Back",
    [LABELS.PASSWORD_LABEL]: "Password",
    [LABELS.FORGOT_YOUR_PASSWORD_LABEL]: "Forgot your password?",
    [LABELS.DO_NOT_HAVE_ACCOUNT_LABEL]: "Do not have an account?",
    [LABELS.GO_BACK_LABEL]: "Go Back",
    //FORGOT PASS
    [LABELS.FORGOT_PASSWORD_LABEL]: "Forgot Password",
    [LABELS.SEND_EMAIL_LABEL]: "Send Email",
    [LABELS.BACK_TO_LABEL]: "Back to",
    //CREATE ACCOUNT
    [LABELS.CREATE_ACCOUNT_LABEL]: "Create Account",
    [LABELS.FIRST_NAME_LABEL]: "First Name",
    [LABELS.LAST_NAME_LABEL]: "Last Name",
    [LABELS.FULL_NAME_LABEL]: "Full Name",
    [LABELS.NO_WHITE_SPACES_LABEL]: "White spaces are not allowed",
    [LABELS.MIX_PASS_CHARACTERS_LABEL]: "Mix of uppercase and lowercase letters, numbers and symbols",
    [LABELS.EIGHT_CHARACTERS_LABEL]: "At least 8 characters",
    [LABELS.ALREADY_HAVE_AN_ACCOUNT_LABEL]: "Already have an account?",
    //VERIFY
    [LABELS.VERIFY_LABEL]: "Verify",
    [LABELS.RESEND_LABEL]: "Resend",
    [LABELS.EXPIRES_IN_LABEL]: "Expires in",
    //SELECT LOAN
    [LABELS.PRE_APPROVED_LABEL]: "I've Been Pre-Approved",
    [LABELS.NOT_APPROVED_LABEL]: "No Loan Approval Yet",
    //INV PROFILE
    [LABELS.CHOOSE_LABEL]: "Choose",
    [LABELS.PADZILLY_CONNECTED_LENDER_LABEL]: "Padzilly connected lender here",
    [LABELS.TELL_US_HOME_SEARCH_GOALS_LABEL]: "Tell us your home search goals",
    [LABELS.DESIRED_MORTGAGE_LABEL]: "Enter desired mortgage payment limit",
    [LABELS.AVAILABLE_FUNDS_LABEL]: "Enter your available funds limit",
    [LABELS.CONTINUE_LABEL]: "Continue",
    //SWIFTMATCH
    [LABELS.SWIFTMATCH_READY_LABEL]: "Your SearchPro Profile is Ready!",
    [LABELS.MAXIMUM_PAYMENT_LIMIT_LABEL]: "Maximum Desired Payment Limit",
    [LABELS.SAVINGS_AND_FUNDS_LIMIT_LABEL]: "Savings and Funds Limit",
    [LABELS.PRE_APPROVED_LOAN_PROFILE_LABEL]: "Pre-Approved Loan Profile",
    [LABELS.TEMPORARY_LOAN_PROFILE_LABEL]: "Temporary Loan Profile",
    [LABELS.CONVENTIONAL_LABEL]: "Conventional",
    [LABELS.ALTERNATE_LOAN_PROFILE]: "Alternate Loan Profile",
    [LABELS.INCREASE_DOWN_PAYMENT_LABEL]: "You can increase down payment for your search",
    [LABELS.LETS_GO_LABEL]: "Let's Go!",
    [LABELS.START_HOME_MATCHING_LABEL]: "Start Home Matching!",
    //LOAN OFFICER
    [LABELS.ENTER_LOAN_OFFICER_INFO_LABEL]: "Enter loan officer's contact info",
    [LABELS.LOAN_OFFICER_PHONE_LABEL]: "Loan Officer's mobile",
    [LABELS.CONFIRM_YOUR_LENDER_LABEL]: "Please confirm your Lender",
    [LABELS.IS_MY_LENDER_LABEL]: "Yes! This is correct",
    [LABELS.IS_NOT_MY_LENDER_LABEL]: "This is not my Lender",
    //CHANGE PASS
    [LABELS.CHANGE_PASSWORD_LABEL]: "Change Password",
    [LABELS.ENTER_NEW_PASSWORD_LABEL]: "Enter your new password",
    [LABELS.CONFIRM_PASSWORD_LABEL]: "Confirm Password",
    [LABELS.SUBMIT_LABEL]: "Submit",
    //404
    [LABELS.PAGE_NOT_FOUND_LABEL]: "Page not found",
    //NO PROP FOUND
    [LABELS.NO_PROPERTIES_FOUND_LABEL]: "No properties found",
    [LABELS.TRY_THE_FOLOWING_LABEL]: "Try the following",
    [LABELS.HERE_LABEL]: "here",
    //HOME SEARCH GOALS
    [LABELS.HOME_SEARCH_GOALS_LABEL]: "Home Search Goals",
    [LABELS.START_SEARCHING_LABEL]: "Start Searching",
    //FAV
    [LABELS.ACCOUNT_REQUIRED_LABEL]: "Account Required",
    [LABELS.NO_FAVORITE_PROPERTIES_YET_LABEL]: "No favorite properties yet",
    //SWIFTMATCH PROFILE
    [LABELS.SWIFTMATCH_PROFILE_LABEL]: "SearchPro Profile",
    //OFFER PRO
    [LABELS.RESET_ALL_LABEL]: "Reset All",
    [LABELS.OFFER_PRICE_LABEL]: "Offer Price",
    [LABELS.PRINT_OFFER_LETTERS_LABEL]: "Print Offer Letters",
    [LABELS.WITH_THE_ABOVE_TERMS_LABEL]: "With the above terms",
    [LABELS.PADZILLY_OFFER_PRO_LABEL]: "Padzilly Offer Pro",
    [LABELS.BACK_TO_PROP_DETAILS_LABEL]: "Back to Property Details",
    //SNACK BAR FAV
    [LABELS.VIEW_LABEL]: "View",
    [LABELS.ADDED_TO_FAV_LABEL]: "Added to Favorite Properties",
    //ERROR LABELS
    [LABELS.FUNDS_ERROR_LABEL]: "Funds",
    [LABELS.MORTGAGE_ERROR_LABEL]: "Mortgage",
    [LABELS.IS_REQUIRED_ERROR_LABEL]: "is required",
    [LABELS.URL_LABEL]: "URL",
    [LABELS.NAME_ERROR_LABEL]: "Name",
    [LABELS.EMAIL_ERROR_LABEL]: "Email",
    [LABELS.PASSWORD_ERROR_LABEL]: "Password",
    [LABELS.CONFIRM_PASSWORD_ERROR_LABEL]: "Confirm Password",
    [LABELS.LAST_ERROR_LABEL]: "Last Name",
    [LABELS.PHONE_NUMBER_ERROR_LABEL]: "Phone number",
    //LANDING PAGE
    [LABELS.LENDERS_LABEL]: "LENDERS",
    [LABELS.HOMEBUYING_EVOLVED_LABEL]: "Home Buying Evolved!",
    [LABELS.LOOKING_FOR_PROP_LABEL]: "Looking for properties near you",
    [LABELS.HOMES_FOR_YOU_LABEL]: "Homes for you in",
    [LABELS.NO_PROPERTIES_FOR_YOU_LABEL]: "No properties for you in",
    [LABELS.START_NEW_SEARCH_LABEL]: "Start a new search",
    [LABELS.VIEW_MORE_PROP_IN_LABEL]: "View More Properties in",
    [LABELS.VIEW_FAVORITES_LABEL]: "View Favorites",
    [LABELS.UNKNOW_PROPERTY_TYPE_LABEL]: "Unknow Property Type",
    [LABELS.YOU_CAN_CONTINUE_SEARCHING_LABEL]: "You can continue searching in:",
    [LABELS.INFO_TITLE_ONE_LABEL]: "No More Endless Scrolling",
    [LABELS.OFFER_LIKE_A_PRO_LABEL]: "Tailor Your Offer Like a Pro",
    [LABELS.LENDER_APPROVAL_LABEL]: "Link Your Lender Loan Approval",
    [LABELS.ALL_RIGHTS_RESERVED_LABEL]: "© Padzilly 2024. All Rights Reserved",
    [LABELS.GAIN_THE_UPPER_HAND_LABEL]: "Gain the Upper Hand with Padzilly's",
    [LABELS.SEARCH_PRO_LANDING_LABEL]: "Search",
    [LABELS.OFFER_PRO_LANDING_LABEL]: "Offer",
    [LABELS.LOAN_LINK_LABEL]: "Loan",
    [LABELS.PRO_LABEL]: "Pro",
    [LABELS.LINK_LABEL]: "Link",
    [LABELS.AND_LABEL]: "and",
    [LABELS.FOLLOW_US_LABEL]: "Follow us:",
    [LABELS.IMPORTANT_LINKS_LABEL]: "Important Links",
    [LABELS.ABOUT_LABEL]: "About",
    [LABELS.INVESTORS_LABEL]: "Investors",
    [LABELS.CONTACT_LABEL]: "Contact",
    //END LANDING PAGE
    //SEARCH PRO
    [LABELS.PADZILLY_SEARCH_PRO_LABEL]: "Padzilly Search Pro",
    [LABELS.MIN_DOWN_PAYMENT_CONV_LABEL]: "Conv Minimum Down Payment:",
    [LABELS.MIN_DOWN_PAYMENT_FHA_LABEL]: "FHA Minimum Down Payment:",
    [LABELS.CONV_DOWN_PAYMENT_MESSAGE_LABEL]: "Conventional Minimum Down Payment is 3%",
    [LABELS.FHA_DOWN_PAYMENT_MESSAGE_LABEL]: "FHA Minimum Down Payment is 3.5%",
    [LABELS.CONV_DOWN_PAYMENT_MAX_MESSAGE_LABEL]: "Conventional Maximum Down Payment is 80%",
    [LABELS.FHA_DOWN_PAYMENT_MAX_MESSAGE_LABEL]: "FHA Maximum Down Payment is 80%",
    [LABELS.DOWN_PAYMENT_VALUES_SAVED_LABEL]: "Down payment values ​​were saved",
    [LABELS.SEARCH_PRO_LABEL]: "Search Pro",
    [LABELS.DONT_SHOW_VIDEO_LABEL]: "Don't show video again",
    //MAP
    [LABELS.MAP_LABEL]: "Map",
    [LABELS.LIST_LABEL]: "List",
    //SCHOOLS
    [LABELS.NEARBY_SCHOOLS_LABEL]: "Nearby Schools",
    [LABELS.PRIVATE_LABEL]: "Private",
    [LABELS.ELEMENTARY_LABEL]: "Elementary",
    [LABELS.MIDDLE_LABEL]: "Middle",
    [LABELS.HIGH_LABEL]: "High",
    [LABELS.DISTANCE_LABEL]: "Distance",
    [LABELS.MORE_DETAILS_LABEL]: "More Details",
    [LABELS.HIDE_LABEL]: "Hide",
    [LABELS.SCHOOL_WEBSITE_LABEL]: "School Website",
    [LABELS.AWAY_LABEL]: "away",
}