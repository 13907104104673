import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Box } from "@mui/material";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Avatar from '@mui/material/Avatar';
import { green, yellow, red } from '@mui/material/colors';
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FaxIcon from '@mui/icons-material/Fax';
import BaseBtn from '../../BaseComponents/BaseBtn';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import {
    AWAY_LABEL,
    DISTANCE_LABEL,
    ELEMENTARY_LABEL,
    HIDE_LABEL,
    HIGH_LABEL,
    MIDDLE_LABEL,
    MORE_DETAILS_LABEL,
    NEARBY_SCHOOLS_LABEL,
    PRIVATE_LABEL,
    SCHOOL_WEBSITE_LABEL
} from '../../../i18n/i18nLabel';
import { SCHOOLS_DISCLAIMER_TEXT } from '../../../i18n/i18nText';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

export default function SchoolsList(props) {

    const { t } = useTranslation();

    const { schools } = JSON.parse(props.schools);

    const highSchools = schools ? schools.filter(item => {
        const levels = item['level-codes'].split(',');
        return levels.includes('h');
    }) : [];

    const middleSchools = schools ? schools.filter(item => {
        const levels = item['level-codes'].split(',');
        return levels.includes('m');
    }) : [];

    const elementarySchools = schools ? schools.filter(item => {
        const levels = item['level-codes'].split(',');
        return levels.includes('e');
    }) : [];

    const privateSchools = schools ? schools.filter((item) => item["type"] === "private") : [];

    const [expanded, setExpanded] = useState(false);

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [value, setValue] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    return (schools?.length
        ? (
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
                <h3>{t(NEARBY_SCHOOLS_LABEL)}</h3>
                <Box>
                    <p>
                        {t(SCHOOLS_DISCLAIMER_TEXT)}
                    </p>
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChangeTab}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label={t(NEARBY_SCHOOLS_LABEL)} disabled={!schools?.length} />
                        <Tab label={t(PRIVATE_LABEL)} disabled={!privateSchools?.length} />
                        <Tab label={t(ELEMENTARY_LABEL)} disabled={!elementarySchools?.length} />
                        <Tab label={t(MIDDLE_LABEL)} disabled={!middleSchools?.length} />
                        <Tab label={t(HIGH_LABEL)} disabled={!highSchools?.length} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    {
                        schools.map((item, index) => (
                            < Accordion
                                key={index}
                                expanded={expanded === index}
                                onChange={handleChangeAccordion(index)}
                                sx={{ boxShadow: "none", border: "none", position: "inherit" }}
                            >
                                <AccordionSummary
                                    expandIcon={expanded !== index ? <KeyboardArrowRightIcon sx={{ marginLeft: "15px" }} /> : null}
                                    sx={{ width: "fit-content" }}

                                >
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Avatar
                                            sx={{
                                                width: "2.3rem",
                                                height: "2.3rem",
                                                bgcolor: item["rating"] !== null
                                                    ? Number(item["rating"]) < 6
                                                        ? red[800]
                                                        : Number(item["rating"]) < 8
                                                            ? yellow[800]
                                                            : Number(item["rating"]) >= 8
                                                                ? green[800]
                                                                : null
                                                    : null
                                            }}
                                        >
                                            <p style={{ fontSize: ".9rem" }}>{item["rating"] !== null ? item["rating"] : "-"}/10</p>
                                        </Avatar>
                                        <Box
                                            sx={{ marginLeft: "8px", display: "flex", flexDirection: "column" }}
                                        >
                                            <p style={{ margin: "0px", fontWeight: "bold", fontSize: ".9rem" }}>
                                                {item.name}
                                            </p>
                                            <p style={{ margin: "0px", fontSize: ".8rem" }}>
                                                {item.distance ? (t(DISTANCE_LABEL) + ": " + item.distance.toFixed(2) + " mi " + t(AWAY_LABEL)) : ""}
                                            </p>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {item["street"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                            <FmdGoodOutlinedIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["street"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["phone"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                            <LocalPhoneIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["phone"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["fax"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                            <FaxIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["fax"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["school-summary"]
                                        ? <Box
                                            sx={{ marginBottom: "15px" }}
                                        >
                                            <p style={{ margin: "0px" }}>
                                                {item["school-summary"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    <Box sx={{ display: "flex" }}>
                                        {item["web-site"]
                                            ? <BaseBtn
                                                label={t(SCHOOL_WEBSITE_LABEL)}
                                                variant="outlined"
                                                onClick={() => window.open(item["web-site"], "_blank")}
                                            />
                                            : null
                                        }
                                        {item["overview-url"]
                                            ? <BaseBtn
                                                style={{ marginLeft: item["web-site"] ? "10px" : "0px" }}
                                                label={t(MORE_DETAILS_LABEL)}
                                                onClick={() => window.open(item["overview-url"], "_blank")}
                                            />
                                            : null
                                        }
                                        <BaseBtn
                                            label={t(HIDE_LABEL)}
                                            variant="standard"
                                            style={{ marginLeft: "10px" }}
                                            onClick={() => setExpanded(false)}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    {
                        privateSchools.map((item, index) => (
                            < Accordion
                                key={index}
                                expanded={expanded === index}
                                onChange={handleChangeAccordion(index)}
                                sx={{ boxShadow: "none", border: "none", position: "inherit" }}
                            >
                                <AccordionSummary
                                    expandIcon={expanded !== index ? <KeyboardArrowRightIcon sx={{ marginLeft: "15px" }} /> : null}
                                    sx={{ width: "fit-content" }}

                                >
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Avatar
                                            sx={{
                                                width: "2.3rem",
                                                height: "2.3rem",
                                                bgcolor: item["rating"] !== null
                                                    ? Number(item["rating"]) < 6
                                                        ? red[800]
                                                        : Number(item["rating"]) < 8
                                                            ? yellow[800]
                                                            : Number(item["rating"]) >= 8
                                                                ? green[800]
                                                                : null
                                                    : null
                                            }}
                                        >
                                            <p style={{ fontSize: ".9rem" }}>{item["rating"] !== null ? item["rating"] : "-"}/10</p>
                                        </Avatar>
                                        <Box
                                            sx={{ marginLeft: "8px", display: "flex", flexDirection: "column" }}
                                        >
                                            <p style={{ margin: "0px", fontWeight: "bold", fontSize: ".9rem" }}>
                                                {item.name}
                                            </p>
                                            <p style={{ margin: "0px", fontSize: ".8rem" }}>
                                                {item.distance ? (t(DISTANCE_LABEL) + ": " + item.distance.toFixed(2) + " mi " + t(AWAY_LABEL)) : ""}
                                            </p>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {item["street"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                            <FmdGoodOutlinedIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["street"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["phone"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                            <LocalPhoneIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["phone"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["fax"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                            <FaxIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["fax"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["school-summary"]
                                        ? <Box
                                            sx={{ marginBottom: "15px" }}
                                        >
                                            <p style={{ margin: "0px" }}>
                                                {item["school-summary"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    <Box sx={{ display: "flex" }}>
                                        {item["web-site"]
                                            ? <BaseBtn
                                                label={t(SCHOOL_WEBSITE_LABEL)}
                                                variant="outlined"
                                                onClick={() => window.open(item["web-site"], "_blank")}
                                            />
                                            : null
                                        }
                                        <BaseBtn
                                            style={{ marginLeft: item["web-site"] ? "10px" : "0px" }}
                                            label={t(MORE_DETAILS_LABEL)}
                                            onClick={() => window.open(item["overview-url"], "_blank")}
                                        />
                                        <BaseBtn
                                            label={t(HIDE_LABEL)}
                                            variant="standard"
                                            style={{ marginLeft: "10px" }}
                                            onClick={() => setExpanded(false)}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    {
                        elementarySchools.map((item, index) => (
                            < Accordion
                                key={index}
                                expanded={expanded === index}
                                onChange={handleChangeAccordion(index)}
                                sx={{ boxShadow: "none", border: "none", position: "inherit" }}
                            >
                                <AccordionSummary
                                    expandIcon={expanded !== index ? <KeyboardArrowRightIcon sx={{ marginLeft: "15px" }} /> : null}
                                    sx={{ width: "fit-content" }}

                                >
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Avatar
                                            sx={{
                                                width: "2.3rem",
                                                height: "2.3rem",
                                                bgcolor: item["rating"] !== null
                                                    ? Number(item["rating"]) < 6
                                                        ? red[800]
                                                        : Number(item["rating"]) < 8
                                                            ? yellow[800]
                                                            : Number(item["rating"]) >= 8
                                                                ? green[800]
                                                                : null
                                                    : null
                                            }}
                                        >
                                            <p style={{ fontSize: ".9rem" }}>{item["rating"] !== null ? item["rating"] : "-"}/10</p>
                                        </Avatar>
                                        <Box
                                            sx={{ marginLeft: "8px", display: "flex", flexDirection: "column" }}
                                        >
                                            <p style={{ margin: "0px", fontWeight: "bold", fontSize: ".9rem" }}>
                                                {item.name}
                                            </p>
                                            <p style={{ margin: "0px", fontSize: ".8rem" }}>
                                                {item.distance ? (t(DISTANCE_LABEL) + ": " + item.distance.toFixed(2) + " mi " + t(AWAY_LABEL)) : ""}
                                            </p>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {item["street"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                            <FmdGoodOutlinedIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["street"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["phone"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                            <LocalPhoneIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["phone"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["fax"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                            <FaxIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["fax"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["school-summary"]
                                        ? <Box
                                            sx={{ marginBottom: "15px" }}
                                        >
                                            <p style={{ margin: "0px" }}>
                                                {item["school-summary"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    <Box sx={{ display: "flex" }}>
                                        {item["web-site"]
                                            ? <BaseBtn
                                                label={t(SCHOOL_WEBSITE_LABEL)}
                                                variant="outlined"
                                                onClick={() => window.open(item["web-site"], "_blank")}
                                            />
                                            : null
                                        }
                                        <BaseBtn
                                            style={{ marginLeft: item["web-site"] ? "10px" : "0px" }}
                                            label={t(MORE_DETAILS_LABEL)}
                                            onClick={() => window.open(item["overview-url"], "_blank")}
                                        />
                                        <BaseBtn
                                            label={t(HIDE_LABEL)}
                                            variant="standard"
                                            style={{ marginLeft: "10px" }}
                                            onClick={() => setExpanded(false)}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    {
                        middleSchools.map((item, index) => (
                            < Accordion
                                key={index}
                                expanded={expanded === index}
                                onChange={handleChangeAccordion(index)}
                                sx={{ boxShadow: "none", border: "none", position: "inherit" }}
                            >
                                <AccordionSummary
                                    expandIcon={expanded !== index ? <KeyboardArrowRightIcon sx={{ marginLeft: "15px" }} /> : null}
                                    sx={{ width: "fit-content" }}

                                >
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Avatar
                                            sx={{
                                                width: "2.3rem",
                                                height: "2.3rem",
                                                bgcolor: item["rating"] !== null
                                                    ? Number(item["rating"]) < 6
                                                        ? red[800]
                                                        : Number(item["rating"]) < 8
                                                            ? yellow[800]
                                                            : Number(item["rating"]) >= 8
                                                                ? green[800]
                                                                : null
                                                    : null
                                            }}
                                        >
                                            <p style={{ fontSize: ".9rem" }}>{item["rating"] !== null ? item["rating"] : "-"}/10</p>
                                        </Avatar>
                                        <Box
                                            sx={{ marginLeft: "8px", display: "flex", flexDirection: "column" }}
                                        >
                                            <p style={{ margin: "0px", fontWeight: "bold", fontSize: ".9rem" }}>
                                                {item.name}
                                            </p>
                                            <p style={{ margin: "0px", fontSize: ".8rem" }}>
                                                {item.distance ? (t(DISTANCE_LABEL) + ": " + item.distance.toFixed(2) + " mi " + t(AWAY_LABEL)) : ""}
                                            </p>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {item["street"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                            <FmdGoodOutlinedIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["street"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["phone"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                            <LocalPhoneIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["phone"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["fax"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                            <FaxIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["fax"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["school-summary"]
                                        ? <Box
                                            sx={{ marginBottom: "15px" }}
                                        >
                                            <p style={{ margin: "0px" }}>
                                                {item["school-summary"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    <Box sx={{ display: "flex" }}>
                                        {item["web-site"]
                                            ? <BaseBtn
                                                label={t(SCHOOL_WEBSITE_LABEL)}
                                                variant="outlined"
                                                onClick={() => window.open(item["web-site"], "_blank")}
                                            />
                                            : null
                                        }
                                        <BaseBtn
                                            style={{ marginLeft: item["web-site"] ? "10px" : "0px" }}
                                            label={t(MORE_DETAILS_LABEL)}
                                            onClick={() => window.open(item["overview-url"], "_blank")}
                                        />
                                        <BaseBtn
                                            label={t(HIDE_LABEL)}
                                            variant="standard"
                                            style={{ marginLeft: "10px" }}
                                            onClick={() => setExpanded(false)}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                    {
                        highSchools.map((item, index) => (
                            < Accordion
                                key={index}
                                expanded={expanded === index}
                                onChange={handleChangeAccordion(index)}
                                sx={{ boxShadow: "none", border: "none", position: "inherit" }}
                            >
                                <AccordionSummary
                                    expandIcon={expanded !== index ? <KeyboardArrowRightIcon sx={{ marginLeft: "15px" }} /> : null}
                                    sx={{ width: "fit-content" }}

                                >
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Avatar
                                            sx={{
                                                width: "2.3rem",
                                                height: "2.3rem",
                                                bgcolor: item["rating"] !== null
                                                    ? Number(item["rating"]) < 6
                                                        ? red[800]
                                                        : Number(item["rating"]) < 8
                                                            ? yellow[800]
                                                            : Number(item["rating"]) >= 8
                                                                ? green[800]
                                                                : null
                                                    : null
                                            }}
                                        >
                                            <p style={{ fontSize: ".9rem" }}>{item["rating"] !== null ? item["rating"] : "-"}/10</p>
                                        </Avatar>
                                        <Box
                                            sx={{ marginLeft: "8px", display: "flex", flexDirection: "column" }}
                                        >
                                            <p style={{ margin: "0px", fontWeight: "bold", fontSize: ".9rem" }}>
                                                {item.name}
                                            </p>
                                            <p style={{ margin: "0px", fontSize: ".8rem" }}>
                                                {item.distance ? (t(DISTANCE_LABEL) + ": " + item.distance.toFixed(2) + " mi " + t(AWAY_LABEL)) : ""}
                                            </p>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {item["street"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                            <FmdGoodOutlinedIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["street"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["phone"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                            <LocalPhoneIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["phone"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["fax"]
                                        ? <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                            <FaxIcon fontSize="small" />
                                            <p style={{ fontSize: ".9rem", margin: "0px" }}>
                                                {item["fax"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    {item["school-summary"]
                                        ? <Box
                                            sx={{ marginBottom: "15px" }}
                                        >
                                            <p style={{ margin: "0px" }}>
                                                {item["school-summary"]}
                                            </p>
                                        </Box>
                                        : null
                                    }
                                    <Box sx={{ display: "flex" }}>
                                        {item["web-site"]
                                            ? <BaseBtn
                                                label={t(SCHOOL_WEBSITE_LABEL)}
                                                variant="outlined"
                                                onClick={() => window.open(item["web-site"], "_blank")}
                                            />
                                            : null
                                        }
                                        <BaseBtn
                                            style={{ marginLeft: item["web-site"] ? "10px" : "0px" }}
                                            label={t(MORE_DETAILS_LABEL)}
                                            onClick={() => window.open(item["overview-url"], "_blank")}
                                        />
                                        <BaseBtn
                                            label={t(HIDE_LABEL)}
                                            variant="standard"
                                            style={{ marginLeft: "10px" }}
                                            onClick={() => setExpanded(false)}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </CustomTabPanel>
            </Box >
        )
        : null
    );
}