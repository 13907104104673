import { Box } from "@mui/material";
import BaseDialog from "../BaseDialog/BaseDialog";
import BaseDialogHeader from "../BaseDialog/BaseDialogHeader";
import BaseDialogBody from "../BaseDialog/BaseDialogBody";
import Link from "@mui/material/Link";
import {
    GET_INVESTMENT_PROFILE,
} from "../../constants/store/getters";
import { useStore } from "../../hooks/store/store";
import { PAYMENT_EXPLAIN } from "../../constants/urls";
import { useTranslation } from 'react-i18next';
import {
    PAYMENT_BREAKDOWN_FIRST_TEXT,
    PAYMENT_BREAKDOWN_SECOND_STRONG_TEXT,
    PAYMENT_BREAKDOWN_SECOND_TEXT,
    PAYMENT_BREAKDOWN_TITLE_TEXT
} from "../../i18n/i18nText";
import {
    ASSOCIATION_FEE_LABEL,
    INSURANCE_LABEL,
    LEARN_MORE_LABEL,
    MORTGAGE_LABEL,
    PAYMENT_NOT_INCLUDE_LABEL,
    PROPERTY_TAX_LABEL
} from "../../i18n/i18nLabel";

export default function PaymentBreakdown(props) {

    const { state } = useStore();

    const { t } = useTranslation();

    const investment_profile = state[GET_INVESTMENT_PROFILE];

    const pAndI = Math.ceil(props.property.principalAndInt * 100) / 100;
    const insurance = Math.ceil(props.property.homeIns * 100) / 100;
    const taxes = Math.ceil(props.property.taxes * 100) / 100;
    const mortageIns = Math.ceil(props.property.monthlyPMI * 100) / 100;
    const total = Math.ceil((pAndI + insurance + taxes + mortageIns) * 100) / 100;


    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    return (
        <BaseDialog
            {...props}
        >
            <BaseDialogHeader
                close={() => { props.onClose(); }}
            >
                {t(PAYMENT_BREAKDOWN_TITLE_TEXT)}{" "}
                <Link
                    className="cursor"
                    color="secondary"
                    onClick={(e) => {
                        handleClick(e);
                        window.open(PAYMENT_EXPLAIN, '_blank');
                    }}
                >
                    {t(LEARN_MORE_LABEL)}
                </Link>
            </BaseDialogHeader>
            <BaseDialogBody>
                <div style={{ padding: "8px" }}>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>P&I</td>
                                    <td style={{ textAlign: "right" }}>{pAndI}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t(INSURANCE_LABEL)}</td>
                                    <td style={{ textAlign: "right" }}>{insurance}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t(PROPERTY_TAX_LABEL)}</td>
                                    <td style={{ textAlign: "right" }}>{taxes}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t(MORTGAGE_LABEL)}</td>
                                    <td
                                        style={{ borderBottom: "solid 1px grey", textAlign: "right" }}
                                    >{mortageIns}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td style={{ fontWeight: "bold", textAlign: "right" }}>${total}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        {props.property.associationFee ? <p style={{ fontWeight: "bold" }}>{t(PAYMENT_NOT_INCLUDE_LABEL)} ${props.property.associationFee} {t(ASSOCIATION_FEE_LABEL)}</p> : null}
                    </Box>
                    {
                        investment_profile?.shouldShowDisclosureRate ?
                            <p>
                                {t(PAYMENT_BREAKDOWN_FIRST_TEXT)}
                            </p>
                            : <p>
                                {t(PAYMENT_BREAKDOWN_SECOND_TEXT)}<strong>{t(PAYMENT_BREAKDOWN_SECOND_STRONG_TEXT)}</strong>
                            </p>
                    }
                </div>
            </BaseDialogBody>
        </BaseDialog>
    );
}