import { CALCULATION_OFFER_PRO_VALUES, CONDOMINIUM, CONVENTIONAL, FHA } from "../constants/constants"

export const propertyCalculations = (property, loan, downP, offerPrice, sellerRebate, floodZ, hoaMo, taxYearly, defaultValues) => {

    if (!defaultValues) {
        defaultValues = CALCULATION_OFFER_PRO_VALUES;
    }

    const {
        oneTimePMI = 0,
        loanAmount: loanAmountFromLoan = 0,
        floodInsMo = 0,
        term = 360,
        purchasePrice = 0,
        originationFeePerc = 0,
        originationFeeAmt = 0,
        discountPointsPerc = 0,
        prepaidInterestDays = 0,
        lenderTitleIns = 0,
        mtgstamps = 0,
        deedstamps = 0,
        homeownerInsMo = 0,
        downPaymentPerc = 0,
        hoiReserveMths = 0,
        floodInsReserveMths = 0,
        otherInsMo = 0,
        otherInsReserveMths = 0,
        propTaxReserveMths = 0,
        prepaidFinanceCharges = 0,
        totalAMTPaidToOthers = 0,
        brokerFeePerc = 0,
        brokerFeeAmt = 0,
        lenderCreditPerc = 0,
        lenderCreditAmt = 0,
        otherCreditsPerc = 0,
        otherCreditsAmt = 0,
        propTaxMo = 0,
        totalHousingExpenses = 0,
        totalCashInBank = 0,
        loanType = ''
    } = loan

    const downPayment = downP / 100
    let diffPMIFactor = 0
    const ufMipFactor = defaultValues.ufmipFactor ?? 0.0175
    const FLOOD_FACTOR = defaultValues.floodFactor ?? 0.042

    const { interestRate = 0, mipFactor = 0, propertyType } = property

    const price = offerPrice ?? property.price
    const taxAnnualAmount = taxYearly * 12 ?? property.taxAnnualAmount
    const floodZone = floodZ ?? property.floodZone

    const homeInsFactor = (homeownerInsMo === null || homeownerInsMo === 0 || purchasePrice === null || purchasePrice === 0)
        ? 0
        : homeownerInsMo / purchasePrice

    let pmiFactor = (loan.mgtInsMonthly === null || loanAmountFromLoan === 0 || loan.mgtInsMonthly === 0 || loan.loanAmount === 0)
        ? 0
        : (loan?.mgtInsMonthly ?? 0) / loanAmountFromLoan

    const downPaymentFromLoan = (downPaymentPerc / 100)

    const floodInsVarFee = floodInsMo

    const variableFee = (((purchasePrice * (1 - downPaymentFromLoan)) * (originationFeePerc / 100)) + originationFeeAmt) +
        (((purchasePrice * (1 - downPaymentFromLoan)) * (brokerFeePerc / 100)) + brokerFeeAmt) +
        (loanAmountFromLoan * (discountPointsPerc / 100)) +
        (((loanAmountFromLoan * interestRate) / 365) * prepaidInterestDays) +
        lenderTitleIns + mtgstamps + deedstamps + (homeownerInsMo * 12) +
        (homeownerInsMo * hoiReserveMths) +
        (floodInsVarFee * 12) +
        (floodInsVarFee * floodInsReserveMths) +
        (otherInsMo * 12) +
        (otherInsMo * otherInsReserveMths) +
        (propTaxMo * propTaxReserveMths) +
        (loanType === FHA ? (purchasePrice * (1 - downPaymentFromLoan) * ufMipFactor) : 0)

    const fixedFees = (prepaidFinanceCharges + totalAMTPaidToOthers) - variableFee

    const fvif = Math.pow(1 + (interestRate / 12), term)
    const fvr = fvif / (fvif - 1)
    const pAndIFromLoan = interestRate / 12 * loanAmountFromLoan * fvr
    let avgBalance = loanAmountFromLoan
    let currentBalance = loanAmountFromLoan

    let counter = 1

    do {
        currentBalance = currentBalance - (pAndIFromLoan - (currentBalance * interestRate / 12))
        avgBalance = avgBalance + currentBalance
        counter++
    } while (counter < 12)

    avgBalance = avgBalance / 12
    const avgBalanceFactor = avgBalance / loanAmountFromLoan

    if (loanType === CONVENTIONAL && (downPayment >= 0.2 || oneTimePMI > 0)) {
        pmiFactor = 0
    } else {
        diffPMIFactor = pmiFactor - (pmiFactor * (((downPayment * 100) / 5 - (downPaymentFromLoan * 100) / 5) * 0.15))
        pmiFactor = diffPMIFactor > pmiFactor ? pmiFactor : diffPMIFactor
    }

    const ltv = 1 - downPayment
    const loanAmount = loanType === FHA ? (ltv * price * ufMipFactor) + ltv * price : ltv * price
    const taxes = taxAnnualAmount / 12
    const pAndAmountAndInt = -interestRate / 12 * -loanAmount * fvr
    const homeIns = price * homeInsFactor
    const floodIns = (!!floodZone || floodZone === 'X') || propertyType === CONDOMINIUM
        ? 0
        : floodInsMo === 0 ? price * 0.75 * FLOOD_FACTOR / 12 : price * floodInsMo / purchasePrice
    const mtgIns = loanType === CONVENTIONAL
        ? loanAmount * pmiFactor
        : loanType === FHA ? mipFactor * (avgBalanceFactor * loanAmount) / 100 / (1 + ufMipFactor) / 12 : 0

    const hoa = hoaMo ?? 0

    const value1 = floodZone !== 'X' && propertyType !== CONDOMINIUM ? floodInsMo === 0 ? price * 0.75 * FLOOD_FACTOR / 12 : price * floodInsMo / purchasePrice : 0
    const investmentTotal = ((price * downPayment) +
        fixedFees +
        (((price * ltv) * (originationFeePerc / 100)) + originationFeeAmt) +
        (((price * ltv) * (brokerFeePerc / 100)) + brokerFeeAmt) +
        (loanAmount * (discountPointsPerc / 100)) +
        (((loanAmount * interestRate) / 365) * prepaidInterestDays) +
        (loanAmount * lenderTitleIns / loanAmountFromLoan) +
        (loanAmount * mtgstamps / loanAmountFromLoan) +
        (price * deedstamps / purchasePrice) +
        (price * homeownerInsMo * 12 / purchasePrice) +
        (price * homeownerInsMo / purchasePrice) * hoiReserveMths +
        (value1) +
        ((value1) * floodInsReserveMths) +
        (otherInsMo * 12 / purchasePrice * price) +
        ((otherInsMo / purchasePrice) * price * otherInsReserveMths) +
        ((taxAnnualAmount / 12) * propTaxReserveMths) -
        (((loanAmount * (lenderCreditPerc / 100)) + lenderCreditAmt) + ((otherCreditsPerc / 100) * price) + otherCreditsAmt)) - sellerRebate

    let payment = pAndAmountAndInt + mtgIns + taxes + homeIns + floodIns + hoa
    payment = Math.ceil(payment);
    const isPaymentQualified = payment <= totalHousingExpenses
    const isInvestmentQualified = investmentTotal <= totalCashInBank

    return {
        payment,
        investmentTotal,
        isPaymentQualified,
        isInvestmentQualified
    }
}