import { Box } from "@mui/material";
import BaseBtn from "../BaseComponents/BaseBtn";
import BaseInput from "../BaseComponents/BaseInput";
import { Link } from 'react-router-dom';
import BaseAlert from "../BaseComponents/BaseAlert";
import { handleError } from "../../utils/functions";
import { useError } from "../../hooks/handleError";
import { useStore } from "../../hooks/store/store";
import { EMAIL_ERROR } from "../../constants/messages";
import { validations } from "../../utils/validations";
import { useState, useRef, useMemo } from "react";
import {
    CREATE_ACCOUNT_ROUTE,
    FORGOT_PASSWORD,
    HOME_ROUTE,
    LANDING_PAGE,
} from "../../constants/routes";
import { logIn } from "../../services/authServices";
import {
    GET_EXISTENT_LOAN,
    GET_FUNDS_LIMIT,
    GET_LOAN,
    GET_MLO_ID,
    GET_MLO_PROFILE,
    GET_PAYMENT_LIMIT,
    GET_PROFILE,
    GET_PROFILE_EMAIL,
    GET_PROFILE_FINISHED,
    GET_PROFILE_ID,
    GET_PROFILE_ROLE,
    GET_PROFILE_TOKEN,
    GET_PROFILE_VERIFIED,
    GET_INVESTMENT_PROFILE,
    GET_STORE_COMPLETE,
    GET_INVESTMENT_PROFILE_LOAN,
    GET_FAVORITES,
    GET_NEAR_PROPERTIES,
    GET_LOCATION,
    GET_SEARCH_PREFERENCE,
    GET_FHA_DOWN_PAYMENT,
    GET_CONVENTIONAL_DOWN_PAYMENT,
    GET_OFFER_PRICE_FHA,
    GET_SELLER_REBATE_FHA,
    GET_OFFER_PRICE_CONVENTIONAL,
    GET_SELLER_REBATE_CONVENTIONAL
} from "../../constants/store/getters";
import {
    BORROWER_ROLE,
    DEFAULT_CONVENTIONAL,
    DEFAULT_FHA,
    GUEST_ROLE,
    LIST,
    PRIMARY_COLOR,
} from "../../constants/constants";
import {
    getLoanByUserEmail,
    getMloById
} from "../../services/loanServices";
import { getInvestmentProfile } from "../../services/investmentProfileServices";
import { useHistory } from "react-router-dom";
import { getFavorites } from "../../services/propertiesServices";
import Grow from '@mui/material/Grow';
import { GETTERS } from "../../hooks/store/getters";
import { useTranslation } from 'react-i18next';
import {
    DO_NOT_HAVE_ACCOUNT_LABEL,
    EMAIL_ERROR_LABEL,
    EMAIL_LABEL,
    FORGOT_YOUR_PASSWORD_LABEL,
    GO_BACK_LABEL,
    LOG_IN_LABEL,
    PASSWORD_ERROR_LABEL,
    PASSWORD_LABEL,
    SIGN_UP_LABEL,
    WELCOME_BACK_LABEL
} from "../../i18n/i18nLabel";

export default function LogIn() {

    const { t } = useTranslation();

    const history = useHistory()

    const { state, dispatch } = useStore();

    const EMAIL = "email";

    const PASSWORD = "password";

    const [errors, setErrors] = useState({});

    const { error, setErrorMessage, clearError } = useError();

    const activeErrors = useRef(false);

    const [email, setEmail] = useState(null);

    const [password, setPassword] = useState(null);

    const [saving, setSaving] = useState(false);

    const validationValues = [
        { validation: ["required", "isEmail"], value: email, key: EMAIL },
        { validation: ["required"], value: password, key: PASSWORD },
    ];

    const showError = (errorValue, field) => {
        if (field === EMAIL && Array.isArray(errorValue) && errorValue.length > 0) {
            return errorValue[0] === EMAIL_ERROR ? t(errorValue[0]) : t(EMAIL_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        if (field === PASSWORD && Array.isArray(errorValue) && errorValue.length > 0) {
            return t(PASSWORD_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        return "";
    };

    const handleLogIn = async () => {
        try {
            setSaving(true);
            const user = await logIn(email, password);
            if (state[GET_PROFILE_ROLE] === GUEST_ROLE) {
                localStorage.clear();
                for (const key in GETTERS) {
                    if (key !== GET_NEAR_PROPERTIES && key != GET_LOCATION && key !== GET_STORE_COMPLETE) {
                        dispatch({ action: key, value: null });
                    }
                }
            }
            if (user) {

                localStorage.setItem("search_preference", LIST);
                dispatch({ action: GET_SEARCH_PREFERENCE, LIST });

                if (user?.hasConfirmedOTP) {

                    let mlo = null;
                    let invProfile = null;

                    const loan = await getLoanByUserEmail(user.token);

                    if (loan) {
                        mlo = await getMloById(loan.loanOriginatorId, user.token);
                    }

                    if (user.hasInvestmentProfile) {
                        invProfile = await getInvestmentProfile(user.token);
                        if (!mlo && invProfile.loanOriginatorId) {
                            mlo = await getMloById(invProfile.loanOriginatorId, user.token);
                        }
                    }

                    const favorites = await getFavorites(0, 20, user.id, user.token);

                    dispatch({ action: GET_FAVORITES, value: favorites });

                    if (invProfile) {
                        localStorage.setItem('profile_id', user.id);
                        localStorage.setItem("profile_role", user.role);
                        dispatch({ action: GET_PROFILE_ID, value: user.id });
                        dispatch({ action: GET_PROFILE, value: user });
                        dispatch({ action: GET_PROFILE_TOKEN, value: user.token });
                        dispatch({ action: GET_PROFILE_VERIFIED, value: true });
                        dispatch({ action: GET_PROFILE_EMAIL, value: user.email });
                        dispatch({ action: GET_PROFILE_ROLE, value: BORROWER_ROLE });
                        dispatch({ action: GET_INVESTMENT_PROFILE, value: invProfile });
                        dispatch({ action: GET_INVESTMENT_PROFILE_LOAN, value: invProfile?.loan ?? null });

                        localStorage.setItem("down_payment_conventional", state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc ? (state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc / 100) : DEFAULT_CONVENTIONAL);
                        localStorage.setItem("down_payment_fha", state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc ? (state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc / 100) : DEFAULT_FHA);
                        dispatch({ action: GET_CONVENTIONAL_DOWN_PAYMENT, value: state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc ? (state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc / 100) : DEFAULT_CONVENTIONAL });
                        dispatch({ action: GET_FHA_DOWN_PAYMENT, value: state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc ? (state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc / 100) : DEFAULT_FHA });

                        localStorage.setItem("offer_price_fha", 0);
                        localStorage.setItem("seller_rebate_fha", 0);
                        localStorage.setItem("offer_price_conventional", 0);
                        localStorage.setItem("seller_rebate_conventional", 0);
                        dispatch({ action: GET_OFFER_PRICE_FHA, value: 0 });
                        dispatch({ action: GET_SELLER_REBATE_FHA, value: 0 });
                        dispatch({ action: GET_OFFER_PRICE_CONVENTIONAL, value: 0 });
                        dispatch({ action: GET_SELLER_REBATE_CONVENTIONAL, value: 0 });

                        localStorage.setItem("loan_info", invProfile?.loan ? true : false);
                        dispatch({ action: GET_PAYMENT_LIMIT, value: invProfile.paymentLimit });
                        dispatch({ action: GET_FUNDS_LIMIT, value: invProfile.investmentAmount });
                        if (mlo) {
                            dispatch({ action: GET_MLO_ID, value: mlo?.id });
                            dispatch({ action: GET_MLO_PROFILE, value: mlo });
                        }
                        localStorage.setItem("profile_finished", true);
                        dispatch({ action: GET_STORE_COMPLETE, value: true });
                        dispatch({ action: GET_PROFILE_FINISHED, value: true });
                        if (localStorage.getItem("property_details")) {
                            history.push(localStorage.getItem("property_details"));
                        } else {
                            history.push(HOME_ROUTE);
                        }
                    } else {
                        dispatch({ action: GET_PROFILE_ID, value: user.id });
                        dispatch({ action: GET_PROFILE, value: user });
                        dispatch({ action: GET_PROFILE_TOKEN, value: user.token });
                        dispatch({ action: GET_PROFILE_VERIFIED, value: true });
                        dispatch({ action: GET_PROFILE_EMAIL, value: user.email });
                        dispatch({ action: GET_PROFILE_ROLE, value: BORROWER_ROLE });

                        if (loan?.loanId) {
                            localStorage.setItem("loan_exist", JSON.stringify(loan));
                            dispatch({ action: GET_EXISTENT_LOAN, value: loan });
                            dispatch({ action: GET_LOAN, value: loan });
                        }
                        localStorage.setItem('profile_id', user.id);
                        localStorage.setItem("profile_role", user.role);
                        dispatch({ action: GET_PROFILE_ID, value: user.id });
                    }
                } else {
                    localStorage.setItem('profile_id', user.userId);
                    localStorage.setItem("profile_role", user.role);
                    dispatch({ action: GET_PROFILE_ID, value: user.userId });
                    dispatch({ action: GET_PROFILE, value: user });
                }
            }
        } catch (e) {
            setErrorMessage(handleError(e));
        } finally {
            setSaving(false);
        }
    }

    const login = useMemo(() => {
        return (
            <Grow
                in={true}
                {...({ timeout: 500 })}
            >
                <Box sx={{
                    width: "100% !important",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: 'center',
                }}>
                    <BaseAlert alert={error} onClose={() => { clearError(); }} />
                    <p
                        style={{
                            fontWeight: "bold",
                            fontSize: "1.8rem"
                        }}
                    >
                        {t(WELCOME_BACK_LABEL)}
                    </p>
                    <BaseInput
                        label={t(EMAIL_LABEL)}
                        required
                        placeholder="joe@padzilly.com"
                        style={{
                            width: "100%"
                        }}
                        value={email}
                        error={showError(errors.email, EMAIL)}
                        onInputChange={(value) => {
                            validationValues[0].value = value;
                            setEmail(value);
                            setErrors(validations(validationValues));
                        }}
                    />
                    <BaseInput style={{
                        width: "100%"
                    }}
                        label={t(PASSWORD_LABEL)}
                        required
                        type="password"
                        value={password}
                        error={showError(errors.password, PASSWORD)}
                        onInputChange={(value) => {
                            validationValues[1].value = value;
                            setPassword(value);
                            setErrors(validations(validationValues));
                        }}
                    />
                    <Link
                        className="cursor"
                        style={{
                            color: PRIMARY_COLOR,
                            textDecorationColor: PRIMARY_COLOR
                        }}
                        to={FORGOT_PASSWORD}
                    >
                        {t(FORGOT_YOUR_PASSWORD_LABEL)}
                    </Link>
                    <BaseBtn
                        label={t(LOG_IN_LABEL)}
                        onClick={() => {
                            const eList = validations(validationValues);
                            setErrors(eList);
                            (Object.keys(eList).length === 0)
                                ? activeErrors.current = false
                                : activeErrors.current = true
                            handleLogIn();
                        }}
                        style={{ marginTop: "20px", width: "300px" }}
                        loading={saving}
                    />
                    <p>
                        {t(DO_NOT_HAVE_ACCOUNT_LABEL)}
                        &nbsp;
                        <Link
                            to={CREATE_ACCOUNT_ROUTE}
                            className="cursor"
                            style={{
                                color: PRIMARY_COLOR,
                                textDecorationColor: PRIMARY_COLOR
                            }}
                        >
                            {t(SIGN_UP_LABEL)}
                        </Link>
                    </p>
                    <Link
                        className="cursor"
                        style={{
                            color: PRIMARY_COLOR,
                            textDecorationColor: PRIMARY_COLOR,
                            fontSize: ".9rem",
                            marginTop: "20px",
                            textDecoration: "none"
                        }}
                        to={LANDING_PAGE}
                    >
                        {t(GO_BACK_LABEL)}
                    </Link>
                </Box>
            </Grow>
        );
    }, [email, password, error, saving])

    return login;
}