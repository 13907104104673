import { Box } from "@mui/material";
import Background from "../Images/Background";
import { useStore } from "../../hooks/store/store";
import { GET_LANDING_PAGE_ELEMENTS } from "../../constants/store/getters";
import { LANDING_MAIN_SECTION } from "../../constants/constants";
import { getLandingPageStyles } from "../../utils/functions";

export default function LandingMainSection() {

    const { state } = useStore();

    const mainSeactionImage = state[GET_LANDING_PAGE_ELEMENTS]?.length >= 0
        ? getLandingPageStyles(state[GET_LANDING_PAGE_ELEMENTS].filter(element => element.section === LANDING_MAIN_SECTION), LANDING_MAIN_SECTION)
        : {};

    return (
        mainSeactionImage?.image
            ? <Box
                sx={{
                    height: '100vh',
                    width: '100%',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: -1,
                    backgroundImage: `url(${mainSeactionImage?.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            >
            </Box>
            : <Box
                sx={{
                    height: '100vh',
                    width: '100%',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: -1,
                }}
            >{
                    <Background />
                }
            </Box>
    );
}