import { Box } from "@mui/material";
import { useTranslation } from 'react-i18next';
import {
    ABOUT_THIS_HOME_LABEL,
    LISTED_BY_LABEL,
} from "../../../i18n/i18nLabel";
import DisclaimerBtnDialog from "../../properties/DisclaimerBtnDialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function AboutThisHome({ property }) {

    const { t } = useTranslation();

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.only('xs'));

    const getFormattedEmails = (emails) => {
        if (emails) {
            return emails.split(";");
        } else {
            return [""];
        }
    }

    return (
        <Box
            sx={{
                borderBottom: "solid 1px grey",
                paddingBottom: "20px"
            }}
        >
            <Box sx={{ padding: "4px" }}>
                <h3>{t(ABOUT_THIS_HOME_LABEL)}</h3>
                <p>{property?.remarks}</p>
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: isSmOrSmaller ? "center" : "space-between",
                alignItems: "start",
                padding: "4px",
                flexDirection: isSmOrSmaller ? "column" : "row"
            }}>
                {property?.listAgentFullName || property?.listAgentEmail || property?.brokerageAtribution
                    ? <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ color: "dimgray" }}>{t(LISTED_BY_LABEL)}</span>
                        <span style={{ fontWeight: "bold", color: "dimgray" }}>{property?.listAgentFullName}</span>
                        {
                            getFormattedEmails(property?.listAgentEmail).map((item) => (
                                <span key={item} style={{ fontWeight: "bold", color: "dimgray" }}>{item}</span>
                            ))
                        }
                        <span style={{ fontWeight: "bold", color: "dimgray" }}>{property?.brokerageAtribution}</span>
                    </Box>
                    : null}
                <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <img
                        alt="MLS Logo"
                        style={{ maxHeight: "2.5rem", maxWidth: "5rem" }}
                        src={property?.mlsLogoURl}
                        loading="lazy"
                    />
                    {property?.disclaimer ?
                        <DisclaimerBtnDialog property={property} />
                        : null
                    }
                </Box>
            </Box>
        </Box>
    );
}