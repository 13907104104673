import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';

export default function ViewImage(props) {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [draggingStartPos, setDraggingStartPos] = useState({ x: 0, y: 0 });
    const [draggingOffset, setDraggingOffset] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);

    const handleDialogBeforeHide = () => {
        setCurrentImageIndex(0);
        setZoom(1);
        setDraggingStartPos({ x: 0, y: 0 });
        setDraggingOffset({ x: 0, y: 0 });
        setIsDragging(false);
        props.onClose();
    }

    const nextImage = () => {
        setCurrentImageIndex((prev) => (prev + 1) % props.images.length);
        setZoom(1);
        setDraggingOffset({ x: 0, y: 0 });
    };

    const prevImage = () => {
        setCurrentImageIndex((prev) => (prev - 1 + props.images.length) % props.images.length);
        setZoom(1);
        setDraggingOffset({ x: 0, y: 0 });
    };

    const handleScroll = (e) => {
        if (e.deltaY > 0) {
            setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1));
        } else {
            setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3));
        }
    };

    const handleMouseDown = (e) => {
        setDraggingStartPos({ x: e.clientX, y: e.clientY });
        setIsDragging(true);
        e.preventDefault();
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            setDraggingOffset({
                x: e.clientX - draggingStartPos.x,
                y: e.clientY - draggingStartPos.y
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        setDraggingStartPos({ x: 0, y: 0 });
    };

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    useEffect(() => {
        window.addEventListener('wheel', handleScroll);
        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, []);

    return (
        <Modal
            open={props.open}
            onClose={() => { handleDialogBeforeHide(); }}
        >
            <Box sx={{
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'unset',
                backdropFilter: 'blur(10px)',
                outline: 'none',
                height: "100vh",
                position: 'relative'
            }}>
                <IconButton
                    onClick={() => { handleDialogBeforeHide(); }}
                    sx={{ position: 'fixed', top: 10, right: 10, color: "white", zIndex: "1000" }}
                >
                    <CloseIcon />
                </IconButton>
                <IconButton
                    onClick={prevImage}
                    sx={{
                        position: 'fixed',
                        top: '50%',
                        left: 10,
                        zIndex: "1000",
                        transform: 'translateY(-50%)',
                        color: "black",
                        display: props.images.length > 1 ? "" : "none",
                        background: "white",
                        '&:hover': {
                            background: 'white',
                        },
                        '&:active': {
                            background: 'white',
                        },
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: 'relative'
                    }}
                    onMouseDown={handleMouseDown}
                >
                    <img
                        alt='Property Image'
                        src={props.images[currentImageIndex]?.mediaUrl}
                        style={{
                            maxHeight: '90vh',
                            maxWidth: '90vw',
                            transform: `scale(${zoom}) translate(${draggingOffset.x}px, ${draggingOffset.y}px)`,
                            transition: isDragging ? 'none' : 'transform 0.2s'
                        }}
                    />
                    <span
                        style={{
                            color: "white",
                            textAlign: "center",
                            position: "fixed",
                            bottom: 0,
                            zIndex: "1000"
                        }}>
                        {props.info}</span>
                </Box>
                <IconButton
                    onClick={nextImage}
                    sx={{
                        position: 'fixed',
                        top: '50%',
                        right: 10,
                        zIndex: "1000",
                        transform: 'translateY(-50%)',
                        color: "black",
                        display: props.images.length > 1 ? "" : "none",
                        background: "white",
                        '&:hover': {
                            background: 'white',
                        },
                        '&:active': {
                            background: 'white',
                        },
                    }}
                >
                    <ArrowForwardIcon />
                </IconButton>
            </Box>
        </Modal>
    );
}