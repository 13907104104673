import BaseDialog from "../BaseDialog/BaseDialog";
import BaseDialogBody from "../BaseDialog/BaseDialogBody";
import BaseDialogFooter from "../BaseDialog/BaseDialogFooter";
import BaseDialogHeader from "../BaseDialog/BaseDialogHeader";
import BaseAlert from "../BaseComponents/BaseAlert";
import BaseBtn from "../BaseComponents/BaseBtn";
import { useStore } from "../../hooks/store/store";
import { useState, useRef } from "react";
import { useError } from "../../hooks/handleError";
import { getDownPayment, handleError } from "../../utils/functions";
import { Box } from "@mui/material";
import Link from "@mui/material/Link";
import {
    REALTOR,
    ZILLOW
} from "../../constants/urls";
import BaseInput from "../BaseComponents/BaseInput";
import { validations } from "../../utils/validations";
import {
    getProperty,
    getSharedProperty,
    saveFavoriteProperty,
    saveExternalProperty
} from "../../services/propertiesServices";
import {
    GET_FAVORITES,
    GET_INVESTMENT_PROFILE_LOAN,
    GET_LAST_SEARCH,
    GET_PROFILE_ID,
    GET_PROFILE_TOKEN
} from "../../constants/store/getters";
import { setSharedProperty } from "../../utils/scrappingProperty";
import { PRIMARY_COLOR, REALTOR_URL_TO_IMPORT } from "../../constants/constants";
import { useHistory } from "react-router-dom";
import { HOME_DETAILS } from "../../constants/routes";
import { useTranslation } from 'react-i18next';
import {
    IMPORT_PROPERTY_LABEL,
    SEARCH_AND_IMPORT_PROP_LABEL,
    URL_LABEL
} from "../../i18n/i18nLabel";
import {
    IMPORT_PROP_O_TEXT,
    IMPORT_PROP_ONE_TEXT,
    IMPORT_PROP_TWO_TEXT
} from "../../i18n/i18nText";

export default function ImportProperty(props) {

    const history = useHistory();

    const { t } = useTranslation();

    const [url, setUrl] = useState(null);

    const [errors, setErrors] = useState({});

    const activeErrors = useRef(false);

    const [saving, setSaving] = useState(false);

    const { state, dispatch } = useStore();

    const URL = "url";

    const { error, setErrorMessage, clearError } = useError();

    const validationValues = [
        { validation: ["required", "isValidRealtorZillowURL"], value: url, key: URL },
    ];

    const showError = (errorValue, field) => {
        if (field === URL && Array.isArray(errorValue) && errorValue.length > 0) {
            return t(URL_LABEL) + " " + t(errorValue[0]);
        }
        return "";
    };

    const handleDialogBeforeHide = () => {
        setErrors({});
        clearError();
        activeErrors.current = false;
        setSaving(false);
        setUrl(null);
        props.onClose();
    }

    const formatingProperty = async () => {
        if (!activeErrors.current) {
            try {
                setSaving(true);
                const result = await getSharedProperty(url, state[GET_PROFILE_TOKEN]);
                const prop = await setSharedProperty(result, url.includes(REALTOR_URL_TO_IMPORT) ? "realtor" : "zillow", url);
                if (props) {
                    const savedProperty = await saveExternalProperty(prop, state[GET_PROFILE_TOKEN]);
                    if (savedProperty) {
                        const newProp = await getProperty(String(savedProperty), state[GET_PROFILE_TOKEN]);
                        if (!newProp.isFavoriteProperty) {

                            await saveFavoriteProperty(
                                state[GET_PROFILE_ID],
                                newProp.propertyId,
                                getDownPayment(state[GET_LAST_SEARCH], state[GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc),
                                newProp.price,
                                newProp.sellerRebate,
                                newProp.taxes,
                                newProp.hoa,
                                state[GET_PROFILE_TOKEN]
                            );

                            const favortes = state[GET_FAVORITES];
                            favortes.push(newProp);
                            dispatch({ action: GET_FAVORITES, value: favortes });
                        }
                        history.push(HOME_DETAILS + savedProperty);
                    }
                } else {
                    setErrorMessage(handleError());
                }
            } catch (e) {
                setErrorMessage(handleError(e));
            } finally {
                setSaving(false);
            }
        }
    }

    return (
        <BaseDialog {...props}>
            <BaseDialogHeader title={t(IMPORT_PROPERTY_LABEL)} close={() => handleDialogBeforeHide()} />
            <BaseDialogBody>
                <BaseAlert alert={error} onClose={() => { clearError(); }} />
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <p style={{ marginTop: "0px" }}>
                        {t(IMPORT_PROP_ONE_TEXT)}
                        &nbsp;
                        <Link
                            onClick={() => window.open(REALTOR, "_blank")}
                            className="cursor"
                            style={{ color: PRIMARY_COLOR, textDecorationColor: PRIMARY_COLOR }}
                        >Realtor</Link>
                        &nbsp;
                        {t(IMPORT_PROP_O_TEXT)}
                        &nbsp;
                        <Link
                            onClick={() => window.open(ZILLOW, "_blank")}
                            className="cursor"
                            style={{ color: PRIMARY_COLOR, textDecorationColor: PRIMARY_COLOR }}
                        >Zillow</Link>
                        &nbsp;
                        {t(IMPORT_PROP_TWO_TEXT)}
                    </p>
                    <BaseInput
                        label="URL"
                        value={url}
                        style={{ width: "100%" }}
                        error={showError(errors.url, URL)}
                        onInputChange={(value) => {
                            validationValues[0].value = value;
                            setUrl(value);
                            setErrors(validations(validationValues));
                        }}
                    />
                    <BaseBtn
                        label={t(SEARCH_AND_IMPORT_PROP_LABEL)}
                        disabled={!url}
                        style={{
                            width: "220px"
                        }}
                        onClick={() => {
                            const eList = validations(validationValues);
                            setErrors(eList);
                            (Object.keys(eList).length === 0)
                                ? activeErrors.current = false
                                : activeErrors.current = true
                            formatingProperty();
                        }}
                        loading={saving}
                    />
                </Box>
            </BaseDialogBody>
            <BaseDialogFooter>
                <BaseBtn
                    close
                    onClick={() => handleDialogBeforeHide()}
                />
            </BaseDialogFooter>
        </BaseDialog>
    );
}