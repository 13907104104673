import React, {
    createContext,
    useReducer,
    useContext,
    useEffect
} from 'react';
import { GETTERS } from './getters';
import * as GETTER_NAMES from '../../constants/store/getters';
import * as functions from './functions';
import {
    BORROWER_ROLE,
    CALCULATION_OFFER_PRO_VALUES,
    CREATE_ACCOUNT,
    DEFAULT_CONVENTIONAL,
    DEFAULT_FHA,
    DESC,
    GUEST_ROLE,
    LANDING_PAGE,
    LIST,
    LOGIN_ACCOUNT,
} from '../../constants/constants';
import BaseAlert from '../../components/BaseComponents/BaseAlert';
import { useError } from '../handleError';
import {
    getBoolean,
    handleError
} from '../../utils/functions';
import { useTranslation } from 'react-i18next';

const reducer = (state, action) => {
    switch (action.action) {
        case GETTER_NAMES.GET_INITIAL_STATE:
            if (action.value === null) {
                return {};
            }
            return { ...state, ...action.value };
        default:
            return { ...state, [action.action]: action.value };
    }
};

const StoreContext = createContext(undefined);

export const StoreProvider = ({ children }) => {

    const { error, setErrorMessage, clearError } = useError();

    const [state, dispatch] = useReducer(reducer, {});

    const { i18n } = useTranslation();

    useEffect(() => {
        const fetchInitialState = async () => {
            const initialState = {};
            for (const key in GETTERS) {
                if (Object.prototype.hasOwnProperty.call(GETTERS, key)) {
                    if (key !== GETTER_NAMES.GET_NEAR_PROPERTIES) {
                        try {
                            switch (key) {
                                case GETTER_NAMES.GET_LANGUAGE:
                                    initialState[key] = i18n.language;
                                    break;
                                case GETTER_NAMES.GET_PAGE:
                                    initialState[key] = localStorage.getItem("page") ?? null;
                                    break;
                                case GETTER_NAMES.GET_LANDING_PAGE_ELEMENTS:
                                    //NO LANDING PAGE ELEMENTS UNTIL HAVE THE FINAL DESIGN
                                    /*if (initialState[GETTER_NAMES.GET_PAGE] === LANDING_PAGE) {
                                        initialState[key] = await functions.getLandingElements();
                                    } else {
                                        initialState[key] = ["NO ELEMENTS"];
                                    }*/
                                    initialState[key] = ["NO ELEMENTS"];
                                    break;
                                case GETTER_NAMES.GET_LOCATION:
                                    initialState[key] = await functions.getLocation() ?? null;
                                    break;
                                case GETTER_NAMES.GET_NEAR_PROPERTIES:
                                    break;
                                case GETTER_NAMES.GET_LAST_SEARCHED_PROPERTIES:
                                    break;
                                case GETTER_NAMES.GET_PROFILE_ID:
                                    initialState[key] = localStorage.getItem("profile_id") ?? null;
                                    break;
                                case GETTER_NAMES.GET_PROFILE:
                                    if (initialState[GETTER_NAMES.GET_PROFILE_ID]) {
                                        const user = await functions.getProfile(initialState[GETTER_NAMES.GET_PROFILE_ID]);
                                        if (user) {
                                            initialState[key] = user;
                                        } else {
                                            initialState[key] = null;
                                        }
                                    } else {
                                        initialState[key] = null;
                                    }
                                    break;
                                case GETTER_NAMES.GET_PROFILE_TOKEN:
                                    initialState[key] = initialState[GETTER_NAMES.GET_PROFILE]?.token ?? null;
                                    break;
                                case GETTER_NAMES.GET_PROFILE_ROLE:
                                    initialState[key] = initialState[GETTER_NAMES.GET_PROFILE]?.role ?? localStorage.getItem("profile_role");
                                    initialState[key] ? localStorage.setItem("profile_role", initialState[key]) : null;
                                    break;
                                case GETTER_NAMES.GET_PROFILE_EMAIL:
                                    initialState[key] = initialState[GETTER_NAMES.GET_PROFILE]?.email ?? null;
                                    break;
                                case GETTER_NAMES.GET_PROFILE_VERIFIED:
                                    initialState[key] = (initialState[GETTER_NAMES.GET_PROFILE]?.hasConfirmedOTP && initialState[GETTER_NAMES.GET_PROFILE]?.role !== GUEST_ROLE) ? true : false;
                                    break;
                                case GETTER_NAMES.GET_PROFILE_FINISHED:
                                    initialState[key] = getBoolean(localStorage.getItem("profile_finished")) ? true : false;
                                    break;
                                case GETTER_NAMES.GET_BORROWER_INVESTMENT_PROFILE:
                                    initialState[key] = getBoolean(localStorage.getItem("borrower_investment_profile")) ? true : false;
                                    break;
                                case GETTER_NAMES.GET_EXISTENT_LOAN:
                                    initialState[key] = localStorage.getItem("loan_exist") ? JSON.parse(localStorage.getItem("loan_exist")) : null;
                                    break;
                                case GETTER_NAMES.GET_LOAN:
                                    if (initialState[GETTER_NAMES.GET_PROFILE_TOKEN] && initialState[GETTER_NAMES.GET_PROFILE_ROLE] === BORROWER_ROLE) {
                                        initialState[key] = await functions.getLoanByEmail(initialState[GETTER_NAMES.GET_PROFILE_TOKEN]) ?? null;
                                    } else {
                                        initialState[key] = null;
                                    }
                                    break;
                                case GETTER_NAMES.GET_INVESTMENT_PROFILE:
                                    if ((initialState[GETTER_NAMES.GET_PROFILE_TOKEN] && initialState[GETTER_NAMES.GET_BORROWER_INVESTMENT_PROFILE])
                                        || (initialState[GETTER_NAMES.GET_PROFILE_TOKEN] && initialState[GETTER_NAMES.GET_PROFILE_FINISHED])
                                    ) {
                                        initialState[key] = await functions.getInvestmentProfile(initialState[GETTER_NAMES.GET_PROFILE_TOKEN]) ?? null;
                                    } else {
                                        initialState[key] = null;
                                    }
                                    break;
                                case GETTER_NAMES.GET_INVESTMENT_PROFILE_LOAN:
                                    initialState[key] = initialState[GETTER_NAMES.GET_INVESTMENT_PROFILE]?.loan ?? null;
                                    break;
                                case GETTER_NAMES.GET_LOAN:
                                    if (initialState[GETTER_NAMES.GET_PROFILE_TOKEN]) {
                                        initialState[key] = await functions.getLoanByEmail(initialState[GETTER_NAMES.GET_PROFILE_TOKEN]) ?? null;
                                    } else {
                                        initialState[key] = null;
                                    }
                                    break;
                                case GETTER_NAMES.GET_SEARCH_PRO_DOWN_PAYMENT:
                                    if (!getBoolean(localStorage.getItem("loan_info")) && initialState[GETTER_NAMES.GET_INVESTMENT_PROFILE_LOAN]) {
                                        localStorage.setItem("search_pro_down_payment", false);
                                        localStorage.setItem("loan_info", true);
                                    }
                                    if (!getBoolean(localStorage.getItem("search_pro_down_payment")) && initialState[GETTER_NAMES.GET_INVESTMENT_PROFILE_LOAN]) {
                                        initialState[key] = false;
                                        localStorage.setItem("search_pro_down_payment", false);
                                    } else {
                                        localStorage.setItem("search_pro_down_payment", true);
                                        initialState[key] = true;
                                    }
                                    break;
                                case GETTER_NAMES.GET_PAYMENT_LIMIT:
                                    initialState[key] = initialState[GETTER_NAMES.GET_INVESTMENT_PROFILE]?.paymentLimit ?? null;
                                    break;
                                case GETTER_NAMES.GET_FUNDS_LIMIT:
                                    initialState[key] = initialState[GETTER_NAMES.GET_INVESTMENT_PROFILE]?.investmentAmount ?? null;
                                    break;
                                case GETTER_NAMES.GET_CONVENTIONAL_DOWN_PAYMENT:
                                    if (!localStorage.getItem("down_payment_conventional")) {
                                        localStorage.setItem("down_payment_conventional", DEFAULT_CONVENTIONAL);
                                    }
                                    localStorage.setItem("down_payment_conventional", !initialState[GETTER_NAMES.GET_SEARCH_PRO_DOWN_PAYMENT] ? initialState[GETTER_NAMES.GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc / 100 : localStorage.getItem("down_payment_conventional"));
                                    initialState[key] = localStorage.getItem("down_payment_conventional");
                                    break;
                                case GETTER_NAMES.GET_FHA_DOWN_PAYMENT:
                                    if (!localStorage.getItem("down_payment_fha")) {
                                        localStorage.setItem("down_payment_fha", DEFAULT_FHA);
                                    }
                                    localStorage.setItem("down_payment_fha", !initialState[GETTER_NAMES.GET_SEARCH_PRO_DOWN_PAYMENT] ? initialState[GETTER_NAMES.GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc / 100 : localStorage.getItem("down_payment_fha"));
                                    initialState[key] = localStorage.getItem("down_payment_fha");
                                    break;
                                case GETTER_NAMES.GET_OFFER_PRICE_CONVENTIONAL:
                                    if (!localStorage.getItem("offer_price_conventional")) {
                                        localStorage.setItem("offer_price_conventional", 0);
                                    }
                                    initialState[key] = Number(localStorage.getItem("offer_price_conventional"));
                                    break;
                                case GETTER_NAMES.GET_OFFER_PRICE_FHA:
                                    if (!localStorage.getItem("offer_price_fha")) {
                                        localStorage.setItem("offer_price_fha", 0);
                                    }
                                    initialState[key] = Number(localStorage.getItem("offer_price_fha"));
                                    break;
                                case GETTER_NAMES.GET_SELLER_REBATE_CONVENTIONAL:
                                    if (!localStorage.getItem("seller_rebate_conventional")) {
                                        localStorage.setItem("seller_rebate_conventional", 0);
                                    }
                                    initialState[key] = Number(localStorage.getItem("seller_rebate_conventional"));
                                    break;
                                case GETTER_NAMES.GET_SELLER_REBATE_FHA:
                                    if (!localStorage.getItem("seller_rebate_fha")) {
                                        localStorage.setItem("seller_rebate_fha", 0);
                                    }
                                    initialState[key] = Number(localStorage.getItem("seller_rebate_fha"));
                                    break;
                                case GETTER_NAMES.GET_LAST_INPUT_SEARCH:
                                    initialState[key] = localStorage.getItem("last_input_search")
                                        ? JSON.parse(localStorage.getItem("last_input_search"))
                                        : "";
                                    break;
                                case GETTER_NAMES.GET_LAST_SEARCH:
                                    initialState[key] = localStorage.getItem("last_search")
                                        ? JSON.parse(localStorage.getItem("last_search"))
                                        : {};
                                    break;
                                case GETTER_NAMES.GET_LOAN_TYPE:
                                    initialState[key] = localStorage.getItem("loan_type") ?? null;
                                    break;
                                case GETTER_NAMES.GET_LAST_SEARCH_RESULTS:
                                    initialState[key] = localStorage.getItem("last_search_results")
                                        ? await functions.setLastResults(JSON.parse(localStorage.getItem("last_search_results")), initialState[GETTER_NAMES.GET_FAVORITES])
                                        : [];
                                    break;
                                case GETTER_NAMES.GET_NO_MLO:
                                    initialState[key] = localStorage.getItem("no_mlo") ?? null;
                                    break;
                                case GETTER_NAMES.GET_MLO_ID:
                                    if (initialState[GETTER_NAMES.GET_INVESTMENT_PROFILE]) {
                                        initialState[key] = initialState[GETTER_NAMES.GET_INVESTMENT_PROFILE]?.loanOriginatorId ?? null;
                                    }
                                    break;
                                case GETTER_NAMES.GET_MLO_PROFILE:
                                    if (initialState[GETTER_NAMES.GET_MLO_ID] && initialState[GETTER_NAMES.GET_PROFILE_TOKEN]) {
                                        initialState[key] = await functions.getMloProfile(initialState[GETTER_NAMES.GET_MLO_ID], initialState[GETTER_NAMES.GET_PROFILE_TOKEN]) ?? null;
                                    } else {
                                        initialState[key] = null;
                                    }
                                    break;
                                case GETTER_NAMES.GET_MLO_PASSED:
                                    initialState[key] = localStorage.getItem("mlo_passed") ?? null;
                                    break;
                                case GETTER_NAMES.GET_PAGE_FAVORITES:
                                    initialState[key] = 0;
                                    break;
                                case GETTER_NAMES.GET_FAVORITES:
                                    if (initialState[GETTER_NAMES.GET_PROFILE_ID]
                                        && initialState[GETTER_NAMES.GET_PROFILE_TOKEN]
                                        && initialState[GETTER_NAMES.GET_PROFILE_ROLE] === BORROWER_ROLE) {
                                        initialState[key] = await functions.getFavorites(initialState[GETTER_NAMES.GET_PAGE_FAVORITES], 20, initialState[GETTER_NAMES.GET_PROFILE_ID], initialState[GETTER_NAMES.GET_PROFILE_TOKEN]) ?? [];
                                    } else {
                                        initialState[key] = [];
                                    }
                                    break;
                                case GETTER_NAMES.GET_LAST_FAVORITE_RESULT:
                                    initialState[key] = initialState[GETTER_NAMES.GET_FAVORITES]?.length < 20 ? true : false;
                                    break;
                                case GETTER_NAMES.GET_ORDER_BY:
                                    initialState[key] = { orderBy: "", direction: DESC };
                                    break;
                                case GETTER_NAMES.GET_OFFER_PRO_VALUES:
                                    initialState[key] = CALCULATION_OFFER_PRO_VALUES;
                                    break;
                                case GETTER_NAMES.GET_USER_ID_OTP:
                                    initialState[key] = localStorage.getItem("user_id_otp") ?? null;
                                    break;
                                case GETTER_NAMES.GET_SEARCH_PREFERENCE:
                                    if (!localStorage.getItem("search_preference")) {
                                        localStorage.setItem("search_preference", LIST);
                                        initialState[key] = LIST;
                                    } else {
                                        initialState[key] = localStorage.getItem("search_preference");
                                    }
                                    break;
                                case GETTER_NAMES.GET_STORE_COMPLETE:
                                    initialState[key] = true;
                                    break;
                                default:
                                    break;
                            }
                        } catch (e) {
                            if (handleError(e) === "Account not found") {
                                localStorage.clear();
                                window.location.reload();
                            }
                            setErrorMessage(handleError(e));
                        }
                    }
                }
            }
            dispatch({ action: GETTER_NAMES.GET_INITIAL_STATE, value: initialState });
        };

        if (localStorage.getItem("profile_finished")
            || localStorage.getItem("profile_id")
            || localStorage.getItem("user_id_otp")
            || localStorage.getItem("page") === LANDING_PAGE
            || localStorage.getItem("page") === LOGIN_ACCOUNT
            || localStorage.getItem("page") === CREATE_ACCOUNT) {
            fetchInitialState();
        }
    }, []);

    return (
        <StoreContext.Provider value={{ state, dispatch }}>
            <BaseAlert alert={error} onClose={() => { clearError(); }} />
            {children}
        </StoreContext.Provider>
    );
};

export const useStore = () => {
    const context = useContext(StoreContext);
    if (!context) {
        throw new Error('useStore must be used inside a StoreProvider');
    }
    return context;
};