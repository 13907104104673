import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState, useEffect } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from 'react-i18next';
import { ANY_LABEL } from '../../i18n/i18nLabel';

export default function BaseSelect(props) {

    const { t } = useTranslation();

    const DEFAULT_OPTION = ANY_LABEL;

    const [value, setValue] = useState(props.value !== null ? props.value : DEFAULT_OPTION);

    useEffect(() => { setValue(props.value !== null ? props.value : DEFAULT_OPTION) }, [props.value])

    const setSelectValue = (value) => {
        return (value === DEFAULT_OPTION ? null : value);
    }

    const handleChange = (event) => {
        const value = setSelectValue(event.target.value);
        setValue(value === DEFAULT_OPTION ? null : value);
        if (props.onInputChange) {
            props.onInputChange(value === DEFAULT_OPTION ? null : value);
        }
    };

    const label = props.required ? props.label + "*" : props.label;

    return (
        <FormControl
            className={props.className ?? ""}
            sx={{
                width: props.width ? props.width : 150
            }}
        >
            <InputLabel
                color='secondary'
                id={"select-label-" + props.label}>
                {label}
            </InputLabel>
            <Select
                labelId={"select-label-" + props.label}
                value={value === null ? DEFAULT_OPTION : value}
                color='secondary'
                defaultValue={DEFAULT_OPTION}
                label={label}
                onChange={handleChange}
                size='small'
                error={props.error ? true : false}
                renderValue={(value) => (
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                    >
                        <Box>{
                            (props.optionValue != undefined && props.optionLabel != undefined) ?
                                t(props.options.find((opt) =>
                                    opt[props.optionValue] === value)[props.optionLabel])
                                : t(value)
                        }</Box>
                    </Box>
                )}
            >
                {props.options.map((opt) => (
                    <MenuItem
                        key={props.optionKey ? opt[props.optionKey] : opt}
                        value={props.optionValue ? opt[props.optionValue] : opt}
                    >
                        {props.optionLabel ? t(opt[props.optionLabel]) : t(opt)}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText style={{ minHeight: "5px" }}>{props.error ?? ""}</FormHelperText>
        </FormControl>
    );
}