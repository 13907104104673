import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { secondaryTheme } from '../themes/themes';
import { useTranslation } from 'react-i18next';
import { CANCEL_LABEL } from '../../i18n/i18nLabel';

export default function BaseBtn(props) {

    const { t } = useTranslation();

    const {
        close,
        label,
        loading,
        disabled
    } = props;

    return (
        (!close) ?
            <Button
                className={props.className ?? ""}
                variant={props.variant ?? "contained"}
                onClick={() => props.onClick()}
                disabled={disabled}
                color={props.color ?? 'secondary'}
                style={{
                    borderRadius: props?.style?.borderRadius ?? "3px",
                    fontSize: ".7rem",
                    fontWeight: "bold",
                    ...props?.style
                }}
            >
                {
                    <Box sx={{ display: "flex", justifyContent: "center", textAlign: 'center' }}>
                        <div style={{ display: loading ? "none" : "", color: "inherit" }}>
                            {label ?? props.children}
                        </div>
                        <CircularProgress style={{
                            display: !loading ? "none" : "",
                            color: "inherit"
                        }}
                            size={20}
                        />
                    </Box>
                }
            </Button>

            : <ThemeProvider theme={secondaryTheme}>
                <Button
                    disabled={disabled}
                    variant="outlined"
                    color='secondary'
                    style={{ borderRadius: "3px", fontSize: ".7rem", fontWeight: "bold" }}
                    onClick={() => props.onClick()}
                >
                    {props.label ? props.label : t(CANCEL_LABEL)}
                </Button>
            </ThemeProvider>
    );
}