import axios from "axios";
import {
    GET_LOAN_BY_ID,
    GET_LOAN_BY_USER_EMAIL,
    GET_MLO_BY_EMAIL,
    GET_MLO_BY_ID
} from "../endpoints/endpoints";

export async function getMloByEmail(name, phone, email, profile_token) {
    try {
        const headers = profile_token != undefined && profile_token != null
            ? { Authorization: `Bearer ${profile_token}` }
            : {}
        const mlo = await axios.get(GET_MLO_BY_EMAIL + "?email=" + email, {
            headers
        });
        return mlo.data;
    } catch (error) {
        throw error;
    }
}

export async function getMloById(id, profile_token) {
    try {
        const headers = profile_token != undefined && profile_token != null
            ? { Authorization: `Bearer ${profile_token}` }
            : {}
        const mlo = await axios.get(GET_MLO_BY_ID + "?id=" + id, {
            headers
        });
        return mlo.data;
    } catch (error) {
        throw error;
    }
}

export async function getLoanByUserEmail(profile_token) {
    try {
        const headers = profile_token != undefined && profile_token != null
            ? { Authorization: `Bearer ${profile_token}` }
            : {}
        const loan = await axios.get(GET_LOAN_BY_USER_EMAIL, {
            headers
        });
        return loan.data;
    } catch (error) {
        throw error;
    }
}

export async function getLoanById(id, profile_token) {
    try {
        const headers = profile_token != undefined && profile_token != null
            ? { Authorization: `Bearer ${profile_token}` }
            : {}
        const loan = await axios.get(GET_LOAN_BY_ID + "?id=" + id, {
            headers
        });
        return loan.data;
    } catch (error) {
        throw error;
    }
}