import { setCurrencyValue } from "../../../utils/functions";
import { Card, CardContent, CardMedia } from "@mui/material";
import { Box } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useHistory } from "react-router-dom";
import { HOME_DETAILS } from "../../../constants/routes";
import { GET_PROFILE } from "../../../constants/store/getters";
import { useState } from "react";
import HomeSearchGoalsDialog from "../../Dialogs/HomeSearchGoalsDialog";
import { useStore } from "../../../hooks/store/store";
import {
    CONDOMINIUM,
    MOBILE_HOME,
    PROPERTY_SRC,
    SINGLE_FAMILY,
    TOWNHOUSE
} from "../../../constants/constants";
import { useTranslation } from 'react-i18next';
import {
    BATHROOMS_LABEL,
    BEDROOMS_LABEL,
    CONDOMINIUM_LABEL,
    MOBILE_HOME_LABEL,
    SINGLE_FAMILY_LABEL,
    TOWNHOUSE_LABEL,
    UNKNOW_PROPERTY_TYPE_LABEL
} from "../../../i18n/i18nLabel";

export default function PropertyCard(props) {

    const { t } = useTranslation();

    const { state } = useStore();

    const [showDialog, setShowDialog] = useState(false);

    const history = useHistory();

    const { property } = props;

    const valuePercent = () => {
        const value = 100 - ((property.price * 100) / property.previousPrice);
        return Math.ceil(value * 100) / 100;
    }

    const verifyProfile = () => {
        if (!state[GET_PROFILE]) {
            setShowDialog(true);
        } else {
            history.push(HOME_DETAILS + property.propertyId)
        }
    }

    return (
        <>
            <Card
                className="cardLink"
                sx={{ minWidth: "310px", cursor: "pointer" }}
                onClick={() => verifyProfile()}
            >
                <CardMedia
                    component="img"
                    height="170"
                    image={property?.media ? property.media[0]?.mediaUrl : PROPERTY_SRC}
                />
                <CardContent>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "start", fontWeight: "bold", alignItems: "center" }}>
                            <p
                                style={{
                                    fontSize: "1.3rem",
                                    margin: "0px"
                                }}
                            >
                                ${setCurrencyValue(property?.price)}
                            </p>
                            {(property?.previousPrice && property?.price && property?.previousPrice > 10000) ? valuePercent() > 0
                                ? <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <ArrowDownwardIcon sx={{ fontSize: "medium", color: "red" }} />
                                    <span style={{ fontSize: ".7rem" }}>{valuePercent()}%</span>
                                </Box>

                                : <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <ArrowUpwardIcon sx={{ fontSize: "medium", color: "green" }} />
                                    <span style={{ fontSize: ".7rem" }}>{Math.abs(valuePercent())}%</span>
                                </Box>
                                : null
                            }
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <img
                                alt="MLS logo"
                                style={{ maxHeight: "1.5rem", maxWidth: "5rem" }}
                                src={property?.mlsLogoURl}
                            />
                        </Box>
                    </Box>
                    <p style={{ margin: 0, fontWeight: "bold" }}>
                        {property.propertyType === CONDOMINIUM
                            ? t(CONDOMINIUM_LABEL)
                            : property.propertyType === SINGLE_FAMILY
                                ? t(SINGLE_FAMILY_LABEL)
                                : property.propertyType === MOBILE_HOME
                                    ? t(MOBILE_HOME_LABEL)
                                    : property.propertyType === TOWNHOUSE
                                        ? t(TOWNHOUSE_LABEL)
                                        : t(UNKNOW_PROPERTY_TYPE_LABEL)
                        }
                    </p>
                    <Box sx={{ fontSize: ".9rem", color: "grey", display: "flex", justifyContent: "start" }}>
                        <p style={{ margin: 0 }}>{t(BEDROOMS_LABEL)}: {property?.bedrooms}</p>
                        <p style={{ margin: 0 }}>&nbsp;|&nbsp;</p>
                        <p style={{ margin: 0 }}>{t(BATHROOMS_LABEL)}: {property?.bathroomsFull}</p>
                        <p style={{ margin: 0 }}>&nbsp;|&nbsp;</p>
                        <p style={{ margin: 0 }}>SQFT: {property?.livingArea}</p>
                    </Box >
                    <Box sx={{
                        fontSize: ".8rem",
                        color: "grey",
                        display: "flex",
                        justifyContent: "start",
                        height: "39px"
                    }}>
                        <p style={{ margin: 0, wordBreak: "break-all" }}>
                            {property?.fullAddress
                                ? property?.fullAddress + ", " : ""
                            }
                            {property?.city
                                ? property?.city + "," : ""
                            }
                            {property?.state
                                ? property?.state + " " : ""
                            }
                            {property?.zipCode
                                ? property?.zipCode : ""
                            }
                        </p>
                    </Box>
                </CardContent>
            </Card>
            <HomeSearchGoalsDialog
                open={showDialog}
                onClose={() => {
                    history.push(HOME_DETAILS + property.propertyId);
                    setShowDialog(false);
                }}
            />
        </>
    );
}