import { Box } from "@mui/material";
import { useTranslation } from 'react-i18next';
import {
    BEDROOMS_LABEL,
    FULL_BATH_LABEL,
    HALF_BATHROOMS_LABEL,
} from "../../../i18n/i18nLabel";
import PropertyStatus from "../../properties/PropertyStatus";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import WcOutlinedIcon from '@mui/icons-material/WcOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { setCurrencyValue } from "../../../utils/functions";

export default function DetailsHome({ property }) {

    const { t } = useTranslation();

    const theme = useTheme();

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

    const isLgOrSmaller = useMediaQuery(theme.breakpoints.down('lg'));

    const valuePercent = () => {
        const value = 100 - ((property?.price * 100) / property?.previousPrice);
        return Math.ceil(value * 100) / 100;
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box>
                <Box sx={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <PropertyStatus status={property?.status} />
                </Box>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: isLgOrSmaller ? "column" : "row",
                justifyContent: isLgOrSmaller ? "" : "space-between",
                alignItems: isLgOrSmaller ? "start" : "center",
                paddingBottom: "20px",
                borderBottom: "solid 1px grey"
            }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    padding: "4px"
                }}>
                    ${setCurrencyValue(String(property?.price))}
                    <div style={{ marginLeft: "1rem" }}>
                        {(property?.previousPrice && property?.price && property?.previousPrice > 10000) ? valuePercent() > 0
                            ? <Box sx={{ display: "flex", alignItems: "center" }}>
                                <ArrowDownwardIcon sx={{ fontSize: "large", color: "red" }} />
                                <span style={{ fontSize: "1rem" }}>{valuePercent()}%</span>
                            </Box>

                            : <Box sx={{ display: "flex", alignItems: "center" }}>
                                <ArrowUpwardIcon sx={{ fontSize: "large", color: "green" }} />
                                <span style={{ fontSize: "1rem" }}>{Math.abs(valuePercent())}%</span>
                            </Box>
                            : null
                        }
                    </div>
                </Box>
                <Box sx={{ display: "flex", padding: "4px", flexWrap: "wrap" }}>
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                        <HotelOutlinedIcon sx={{ marginRight: "3px", fontSize: isMdOrSmaller ? 20 : 40 }} />
                        <span>{property?.bedrooms !== null ? property?.bedrooms : "-"} {t(BEDROOMS_LABEL)}</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                        <BathtubOutlinedIcon sx={{ marginRight: "3px", fontSize: isMdOrSmaller ? 20 : 40 }} />
                        <span>{property?.bathroomsFull !== null ? property?.bathroomsFull : "-"} {t(FULL_BATH_LABEL)}</span>
                    </div>
                    {
                        property?.bathroomsHalf > 0
                            ? <div style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                                <WcOutlinedIcon sx={{ marginRight: "3px", fontSize: isMdOrSmaller ? 20 : 40 }} />
                                <span>{property?.bathroomsHalf} {t(HALF_BATHROOMS_LABEL)}</span>
                            </div>
                            : null
                    }
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                        <HomeOutlinedIcon sx={{ marginRight: "3px", fontSize: isMdOrSmaller ? 20 : 40 }} />
                        <span>{property?.livingArea !== null ? property?.livingArea : "-"} sqft</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                        <StraightenOutlinedIcon sx={{ marginRight: "3px", fontSize: isMdOrSmaller ? 20 : 40 }} />
                        <span>{property?.lotSize !== null ? property?.lotSize : "-"} sqft</span>
                    </div>
                </Box>
            </Box>
        </Box>
    );
}