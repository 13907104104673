import BaseDialog from "../BaseDialog/BaseDialog";
import BaseDialogBody from "../BaseDialog/BaseDialogBody";
import BaseDialogFooter from "../BaseDialog/BaseDialogFooter";
import BaseDialogHeader from "../BaseDialog/BaseDialogHeader";
import BaseBtn from "../BaseComponents/BaseBtn";
import { setCurrencyValue } from "../../utils/functions";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    TableContainer,
    Paper
} from '@mui/material';
import { useState, useEffect } from "react";
import {
    CONDOMINIUM,
    MOBILE_HOME,
    SINGLE_FAMILY,
    STATUS_ACTIVE,
    STATUS_COMING_SOON,
    STATUS_PENDING,
    STATUS_UNDER_CONTRACT,
    TABLE_FIELDS,
    TOWNHOUSE
} from "../../constants/constants";
import { PROPERTY_SRC } from "../../constants/constants";
import ViewImage from "./ViewImage";
import { GET_INVESTMENT_PROFILE_LOAN } from "../../constants/store/getters";
import { useStore } from "../../hooks/store/store";
import { useTranslation } from 'react-i18next';
import {
    CLOSE_LABEL,
    COMING_SOON_LABEL,
    CONDOMINIUM_LABEL,
    FOR_SALE_LABEL,
    HOME_COMPARISON_LABEL,
    INVESTMENT_LABEL,
    MOBILE_HOME_LABEL,
    MONTHLY_LABEL,
    PAYMENT_LABEL,
    PENDING_LABEL,
    SINGLE_FAMILY_LABEL,
    TOWNHOUSE_LABEL,
    UNDER_CONTRACT_LABEL
} from "../../i18n/i18nLabel";

export default function PropertyComparison(props) {

    const { t } = useTranslation();

    const { state } = useStore();

    let indexMinPayment = null;

    let indexMinInvestment = null;

    const setTableValues = (properties) => {
        const propArray = [];

        let minPayment = Infinity;
        let minInvestment = Infinity;

        properties.forEach((property, index) => {

            propArray.push({
                [TABLE_FIELDS[0]]: property.streetAddress ?? "-",
                [TABLE_FIELDS[1]]: property.status === STATUS_ACTIVE
                    ? t(FOR_SALE_LABEL) : property.status === STATUS_PENDING
                        ? t(PENDING_LABEL) : property.status === STATUS_COMING_SOON
                            ? t(COMING_SOON_LABEL) : property.status === STATUS_UNDER_CONTRACT
                                ? t(UNDER_CONTRACT_LABEL) : "-",
                [TABLE_FIELDS[2]]: property.city ?? "-",
                [TABLE_FIELDS[3]]: property.state ?? "-",
                [TABLE_FIELDS[4]]: property.zipCode ?? "-",
                [TABLE_FIELDS[5]]: property.price ? "$" + setCurrencyValue(property.price) : "-",
                [TABLE_FIELDS[6]]: property.offerPrice ? "$" + setCurrencyValue(property.offerPrice) : property.price ? "$" + setCurrencyValue(property.price) : "-",
                [TABLE_FIELDS[7]]: property.sellerRebate ? "$" + setCurrencyValue(property.sellerRebate) : "-",
                [TABLE_FIELDS[8]]: (property.downPayment ?? state[GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc) + "%",
                [TABLE_FIELDS[9]]: property.taxAnnualAmount ? "$" + setCurrencyValue(Math.ceil(property.taxAnnualAmount)) : "-",
                [TABLE_FIELDS[10]]: property.hoa ? "$" + setCurrencyValue(Math.ceil(property.hoa)) + " " + t(MONTHLY_LABEL) : "$0 " + t(MONTHLY_LABEL),
                [TABLE_FIELDS[11]]: property.calculationOfPayment ? "$" + setCurrencyValue(Math.ceil(property.calculationOfPayment)) : "-",
                [TABLE_FIELDS[12]]: property.investmentTotal ? "$" + setCurrencyValue(Math.ceil(property.investmentTotal)) : "-",
                [TABLE_FIELDS[13]]: property.bedrooms && property.bedrooms != 0 ? property.bedrooms : "-",
                [TABLE_FIELDS[14]]: property.bathroomsFull && property.bathroomsFull != 0 ? property.bathroomsFull : "-",
                [TABLE_FIELDS[15]]: property.bathroomsHalf && property.bathroomsHalf != 0 ? property.bathroomsHalf : "-",
                [TABLE_FIELDS[16]]: property.livingArea ? property.livingArea + " SQFT" : "-",
                [TABLE_FIELDS[17]]: property.propertyType === CONDOMINIUM
                    ? t(CONDOMINIUM_LABEL) : property.propertyType === MOBILE_HOME
                        ? t(MOBILE_HOME_LABEL) : property.propertyType === SINGLE_FAMILY
                            ? t(SINGLE_FAMILY_LABEL) : property.propertyType === TOWNHOUSE
                                ? t(TOWNHOUSE_LABEL) : "-",
                [TABLE_FIELDS[18]]: property.builtYear ?? "-",
                [TABLE_FIELDS[19]]: property.lotSize ? property.lotSize + " SQFT" : "-",
            });

            if (property?.calculationOfPayment < minPayment) {
                minPayment = property?.calculationOfPayment;
                indexMinPayment = index;
            }
            if (property?.investmentTotal < minInvestment) {
                minInvestment = property?.investmentTotal;
                indexMinInvestment = index;
            }

        });
        return propArray;
    }

    const setTableImages = (properties) => {
        const propArray = [];
        properties.forEach(property => {
            if (property.media?.length > 0) {
                propArray.push(property.media);
            } else {
                propArray.push([{ mediaUrl: PROPERTY_SRC }]);
            }
        });
        return propArray;
    }

    const [table, setTable] = useState(setTableValues(props.properties));

    const [images, setImages] = useState(setTableImages(props.properties));

    const [imagesViewer, setImagesViewer] = useState([]);

    const [showViewImage, setShowViewImage] = useState(false);

    useEffect(() => {
        setTable(setTableValues(props.properties));
        setImages(setTableImages(props.properties));
    }, [props.properties]);

    const keys = table.length > 0 ? Object.keys(table[0]) : [];

    return (
        <BaseDialog {...props}>
            <BaseDialogHeader title={t(HOME_COMPARISON_LABEL)} close={() => { props.onClose(); }} />
            <BaseDialogBody>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                {table.map((_, index) => (
                                    <TableCell key={index}>
                                        <img
                                            alt="Property Image"
                                            onClick={() => {
                                                if (images[index][0]?.mediaUrl !== PROPERTY_SRC) {
                                                    setImagesViewer(images[index]);
                                                    setShowViewImage(true);
                                                }
                                            }}
                                            className={images[index][0]?.mediaUrl !== PROPERTY_SRC ? "cursor" : ""}
                                            style={{
                                                borderRadius: "5px",
                                                objectFit: 'cover'
                                            }}
                                            height="60px"
                                            width="60px"
                                            src={images[index][0]?.mediaUrl}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {keys.map((key, keyIndex) => (
                                <TableRow key={keyIndex}>
                                    <TableCell >{t(key)}</TableCell>
                                    {table.map((row, rowIndex) => (
                                        <TableCell key={rowIndex}
                                            sx={{
                                                background: (rowIndex === indexMinInvestment
                                                    && key === INVESTMENT_LABEL && row[key] !== "-")
                                                    || (rowIndex === indexMinPayment
                                                        && key === PAYMENT_LABEL && row[key] !== "-")
                                                    ? "#428442"
                                                    : "",
                                                color: (rowIndex === indexMinInvestment
                                                    && key === INVESTMENT_LABEL && row[key] !== "-")
                                                    || (rowIndex === indexMinPayment
                                                        && key === PAYMENT_LABEL && row[key] !== "-")
                                                    ? "white"
                                                    : ""
                                            }}>{row[key]}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </BaseDialogBody>
            <BaseDialogFooter>
                <BaseBtn
                    label={t(CLOSE_LABEL)}
                    close
                    onClick={() => { props.onClose(); }}
                />
            </BaseDialogFooter>
            <ViewImage
                open={showViewImage}
                onClose={() => { setShowViewImage(false); }}
                images={imagesViewer}
            />
        </BaseDialog>
    );
}