import { Box, Card, CardContent } from "@mui/material";
import BaseBtn from "../BaseComponents/BaseBtn";
import { setCurrencyValue } from "../../utils/functions";
import HelpIcon from '@mui/icons-material/Help';
import { useStore } from "../../hooks/store/store";
import {
    GET_CONVENTIONAL_DOWN_PAYMENT,
    GET_FHA_DOWN_PAYMENT,
    GET_FUNDS_LIMIT,
    GET_INVESTMENT_PROFILE,
    GET_INVESTMENT_PROFILE_LOAN,
    GET_OFFER_PRICE_CONVENTIONAL,
    GET_OFFER_PRICE_FHA,
    GET_PAYMENT_LIMIT,
    GET_PROFILE_FINISHED,
    GET_PROFILE_ROLE,
    GET_PROFILE_TOKEN,
    GET_SEARCH_PREFERENCE,
    GET_SELLER_REBATE_CONVENTIONAL,
    GET_SELLER_REBATE_FHA,
    GET_STORE_COMPLETE
} from "../../constants/store/getters";
import { useHistory } from "react-router-dom";
import { updateInvestmentProfile } from "../../services/investmentProfileServices";
import { useError } from "../../hooks/handleError";
import { handleError } from "../../utils/functions";
import BaseAlert from "../BaseComponents/BaseAlert";
import { HOME_ROUTE } from "../../constants/routes";
import { HELP_URL } from "../../constants/urls";
import {
    BORROWER_ROLE,
    DEFAULT_CONVENTIONAL,
    DEFAULT_FHA,
    LIST,
    PRIMARY_COLOR
} from "../../constants/constants";
import Grow from '@mui/material/Grow';
import { useTranslation } from 'react-i18next';
import {
    ALTERNATE_LOAN_PROFILE,
    CONVENTIONAL_LABEL,
    DOWN_PAYMENT_LABEL,
    INCREASE_DOWN_PAYMENT_LABEL,
    LETS_GO_LABEL,
    MAXIMUM_PAYMENT_LIMIT_LABEL,
    PRE_APPROVED_LOAN_PROFILE_LABEL,
    SAVINGS_AND_FUNDS_LIMIT_LABEL,
    START_HOME_MATCHING_LABEL,
    SWIFTMATCH_READY_LABEL,
    TEMPORARY_LOAN_PROFILE_LABEL
} from "../../i18n/i18nLabel";

export default function CompleteSwiftProfile() {

    const { t } = useTranslation();

    const { state, dispatch } = useStore();

    const history = useHistory();

    const { error, setErrorMessage, clearError } = useError();

    const handleContinue = async () => {
        try {
            const invPro = state[GET_INVESTMENT_PROFILE];

            invPro.loanOriginatorId = localStorage.getItem("loan_originator_id");
            if (invPro.loan) {
                invPro.paymentLimit = state[GET_INVESTMENT_PROFILE]?.loan?.totalHousingExpenses;
                invPro.investmentAmount = state[GET_INVESTMENT_PROFILE]?.loan?.totalCashInBank;
                invPro.loanOriginatorId = state[GET_INVESTMENT_PROFILE]?.loan?.loanOriginatorId;
            }
            const invProfile = await updateInvestmentProfile(invPro, state[GET_PROFILE_TOKEN]);

            if (invProfile) {
                localStorage.setItem("profile_finished", true);
                dispatch({ action: GET_PROFILE_FINISHED, value: true });
                dispatch({ action: GET_INVESTMENT_PROFILE, value: invProfile });
                dispatch({ action: GET_PAYMENT_LIMIT, value: invProfile.paymentLimit });
                dispatch({ action: GET_FUNDS_LIMIT, value: invProfile.investmentAmount });
                dispatch({ action: GET_STORE_COMPLETE, value: true });
                dispatch({ action: GET_PROFILE_ROLE, value: BORROWER_ROLE });

                dispatch({ action: GET_SEARCH_PREFERENCE, value: LIST });
                localStorage.setItem("search_preference", LIST);

                localStorage.setItem("down_payment_conventional", state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc ? (state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc / 100) : DEFAULT_CONVENTIONAL);
                localStorage.setItem("down_payment_fha", state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc ? (state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc / 100) : DEFAULT_FHA);
                dispatch({ action: GET_CONVENTIONAL_DOWN_PAYMENT, value: state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc ? (state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc / 100) : DEFAULT_CONVENTIONAL });
                dispatch({ action: GET_FHA_DOWN_PAYMENT, value: state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc ? (state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc / 100) : DEFAULT_FHA });

                localStorage.setItem("offer_price_fha", 0);
                localStorage.setItem("seller_rebate_fha", 0);
                localStorage.setItem("offer_price_conventional", 0);
                localStorage.setItem("seller_rebate_conventional", 0);
                dispatch({ action: GET_OFFER_PRICE_FHA, value: 0 });
                dispatch({ action: GET_SELLER_REBATE_FHA, value: 0 });
                dispatch({ action: GET_OFFER_PRICE_CONVENTIONAL, value: 0 });
                dispatch({ action: GET_SELLER_REBATE_CONVENTIONAL, value: 0 });

                localStorage.removeItem("loan_originator_id");
                if (state[GET_INVESTMENT_PROFILE_LOAN]) {
                    localStorage.setItem("loan_info", true);
                    localStorage.setItem("open_offer_pro", true);
                }
                localStorage.removeItem("mlo_passed");
                localStorage.removeItem("borrower_investment_profile");
                localStorage.removeItem('loan_type');

                if (localStorage.getItem("property_details")) {
                    history.push(localStorage.getItem("property_details"));
                } else {
                    history.push(HOME_ROUTE);
                }
            } else {
                setErrorMessage(handleError());
            }
        } catch (e) {
            setErrorMessage(handleError(e));
        }
    }

    return (
        <Grow
            in={true}
            {...({ timeout: 500 })}
        >
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <BaseAlert alert={error} onClose={() => { clearError(); }} />
                <p
                    style={{ fontWeight: "bold", color: PRIMARY_COLOR, textAlign: "center" }}
                >
                    {t(SWIFTMATCH_READY_LABEL)}
                </p>
                <Card sx={{ marginBottom: "10px", width: "100%" }}>
                    <CardContent>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <strong style={{ textAlign: "center" }}>{t(MAXIMUM_PAYMENT_LIMIT_LABEL)}</strong>
                            <strong style={{ color: PRIMARY_COLOR }}>${setCurrencyValue(state[GET_PAYMENT_LIMIT] ?? Math.ceil(state[GET_INVESTMENT_PROFILE]?.loan?.totalHousingExpenses))}</strong>
                            <strong style={{ textAlign: "center" }}>{t(SAVINGS_AND_FUNDS_LIMIT_LABEL)}</strong>
                            <strong style={{ color: PRIMARY_COLOR }}>${setCurrencyValue(state[GET_FUNDS_LIMIT] ?? Math.ceil(state[GET_INVESTMENT_PROFILE]?.loan?.totalCashInBank))}</strong>
                            {
                                !state[GET_INVESTMENT_PROFILE]?.loan
                                    ? null
                                    : <>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <strong style={{ textAlign: "center" }}>{t(PRE_APPROVED_LOAN_PROFILE_LABEL)}</strong>
                                            &nbsp;
                                            <HelpIcon fontSize="small" className="cursor" onClick={() => { window.open(HELP_URL, '_blank'); }} />
                                        </Box>
                                        <strong style={{ color: PRIMARY_COLOR }}>{state[GET_INVESTMENT_PROFILE]?.loan?.loanType}, {parseFloat(state[GET_INVESTMENT_PROFILE]?.loan?.downPaymentPerc).toFixed(2)}% {t(DOWN_PAYMENT_LABEL)}</strong>
                                    </>
                            }

                        </Box>
                    </CardContent>
                </Card>
                {!state[GET_INVESTMENT_PROFILE]?.loan
                    ? <Card sx={{ marginBottom: "10px", width: "100%" }}>
                        <CardContent>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                                    <strong>{t(TEMPORARY_LOAN_PROFILE_LABEL)}</strong>
                                    &nbsp;
                                    <HelpIcon fontSize="small" className="cursor" onClick={() => { window.open(HELP_URL, '_blank'); }} />
                                </Box>
                                <strong style={{ color: PRIMARY_COLOR, textAlign: "center" }}>
                                    {t(CONVENTIONAL_LABEL)}, {t(DOWN_PAYMENT_LABEL)}
                                </strong>
                                <p style={{ marginBottom: "0px" }}>{t(ALTERNATE_LOAN_PROFILE)}</p>
                                <strong style={{ color: PRIMARY_COLOR }}>
                                    FHA 3.5% {t(DOWN_PAYMENT_LABEL)}
                                </strong>
                                <p style={{ textAlign: "center" }}>
                                    {t(INCREASE_DOWN_PAYMENT_LABEL)}
                                </p>
                            </Box>
                        </CardContent>
                    </Card>
                    : null
                }
                <BaseBtn
                    style={{ width: "300px" }}
                    label={!state[GET_INVESTMENT_PROFILE]?.loan ? t(LETS_GO_LABEL) : t(START_HOME_MATCHING_LABEL)}
                    onClick={() => { handleContinue(); }}
                />
            </Box>
        </Grow>
    )
}