import { Box } from "@mui/material";
import PaymentsIcon from '@mui/icons-material/Payments';
import HandHolding from "../../icons/HandHolding";
import { setCurrencyValue } from "../../utils/functions";
import { useStore } from "../../hooks/store/store";
import {
    GET_FUNDS_LIMIT,
    GET_PAYMENT_LIMIT
} from "../../constants/store/getters";
import { useState } from "react";
import PaymentBreakdown from "../Dialogs/PaymentBreakdown";
import { useTranslation } from 'react-i18next';
import {
    CASH_NEEDED_LABEL,
    MONTHLY_PAYMENT_LABEL
} from "../../i18n/i18nLabel";

export default function PaymentAndFoundValues({ property, onClick, noBreakdown }) {

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const { state } = useStore();

    const investment = Math.ceil(property?.investmentTotal);

    const monthlyPayment = Math.ceil(property?.calculationOfPayment);

    const paymentLimit = state[GET_PAYMENT_LIMIT];

    const fundsLimit = state[GET_FUNDS_LIMIT];

    const handleDialog = (event = null) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        setOpen(!open);
    };

    return (
        property?.calculationOfPayment && property?.investmentTotal
            ? <Box sx={{ padding: "4px" }} onClick={onClick}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        border: "solid 1px #D9D9D9",
                        width: "100%",
                        padding: "4px",
                        borderRadius: "4px",
                        alignItems: "center",
                        background: monthlyPayment > paymentLimit ? "#FFE1E4" : "#C1FBE0"
                    }}
                    className={!noBreakdown ? "cursor borderShadow" : ""}
                    onClick={(e) => { !noBreakdown ? handleDialog(e) : null }}
                >
                    <PaymentsIcon style={{ width: "20px", paddingRight: "4px" }} />
                    <span>${setCurrencyValue(String(monthlyPayment))}</span>

                </Box>
                <p style={{ color: "grey", margin: "0px", fontSize: "0.70rem" }}>{t(MONTHLY_PAYMENT_LABEL)}</p>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    border: "solid 1px #D9D9D9",
                    width: "100%",
                    padding: "4px",
                    borderRadius: "4px",
                    alignItems: "center",
                    background: investment > fundsLimit ? "#FFE1E4" : "#C1FBE0"
                }}>
                    <HandHolding style={{ width: "20px", paddingRight: "4px" }} />
                    <span>${setCurrencyValue(String(investment))}</span>

                </Box>
                <p style={{ color: "grey", margin: "0px", fontSize: "0.70rem" }}>{t(CASH_NEEDED_LABEL)}</p>
                <PaymentBreakdown
                    open={open}
                    onClose={() => { handleDialog(); }}
                    maxWidth="sm"
                    property={property}
                />
            </Box>
            : <Box sx={{ padding: "4px" }}></Box>
    );
}