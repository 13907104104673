import axios from "axios";
import {
  LISTED_PROPERTIES,
  SEARCH_LOCATION,
  GET_PROPERTY,
  GET_FAVORITES,
  SAVE_FAVORITE,
  PROXY_URL,
  SAVE_EXTERNAL_PROPERTY
} from "../endpoints/endpoints";
import { CONVENTIONAL, FHA, GET_NEW_VALUES_FOR_SEARCH } from "../constants/constants";

export const getProperties = async (search, profile_token, downPayment, convDownPayment, fhaDownPayment) => {
  try {
    const result = await axios.post(LISTED_PROPERTIES, {
      downpayment: downPayment ? downPayment : search.loanType === CONVENTIONAL ? convDownPayment : fhaDownPayment,
      loanType: search.loanType,
      filterQualified: search.filterQualified,
      pageSize: search.pageSize,
      search: search.search,
      searchBy: search.searchBy,
      searchState: search.searchState,
      propertyType: search.propertyType,
      minPrice: search.minPrice,
      maxPrice: search.maxPrice,
      minBedrooms: search.minBedrooms,
      maxBedrooms: search.maxBedrooms,
      minBathrooms: search.minBathrooms,
      maxBathrooms: search.maxBathrooms,
      minLotSize: search.minLotSize,
      maxLotSize: search.maxLotSize,
      minHomeYear: search.minHomeYear,
      maxHomeYear: search.maxHomeYear,
      minHomeSize: search.minHomeSize,
      maxHomeSize: search.maxHomeSize,
      propertyId: search.propertyId,
      lastPropertyId: search.lastPropertyId,
      lastModificationTimestamp: search.lastModificationTimestamp,
      sellerRebate: search.loanType === CONVENTIONAL
        ? localStorage.getItem("seller_rebate_conventional") ?? 0
        : search.loanType === FHA
          ? localStorage.getItem("seller_rebate_fha") ?? 0
          : 0,
      offerPrice: search.loanType === CONVENTIONAL
        ? localStorage.getItem("offer_price_conventional") ?? 0
        : search.loanType === FHA
          ? localStorage.getItem("offer_price_fha") ?? 0
          : 0,
    }, {
      headers: profile_token ? {
        Authorization: `Bearer ${profile_token}`
      } : {}
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getLocations = async (search) => {
  try {
    if (search.length < 3) {
      return await Promise.resolve([]);
    }
    const result = await axios.get(SEARCH_LOCATION, {
      params: { search: search.trim() },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getProperty = async (id, profile_token, loan) => {

  const last_search = localStorage.getItem("last_search")
    ? JSON.parse(localStorage.getItem("last_search"))
    : GET_NEW_VALUES_FOR_SEARCH(loan);

  try {
    const GET_PROPERTY_URL = `${GET_PROPERTY}${id}`;

    const headers = profile_token
      ? { Authorization: `Bearer ${profile_token}` }
      : {};

    const downPayment = loan?.loanType === FHA
      ? localStorage.getItem("down_payment_fha")
      : loan?.loanType === CONVENTIONAL
        ? localStorage.getItem("down_payment_conventional")
        : null

    const params = {
      loanType: loan?.loanType ?? last_search?.loanType ?? CONVENTIONAL,
      downpayment: downPayment
        ? downPayment
        : last_search.loanType === CONVENTIONAL
          ? localStorage.getItem("down_payment_conventional")
          : localStorage.getItem("down_payment_fha"),
      sellerRebate: (loan?.loanType === CONVENTIONAL || last_search?.loanType === CONVENTIONAL)
        ? Number(localStorage.getItem("seller_rebate_conventional"))
        : Number(localStorage.getItem("seller_rebate_fha")),
      offerPrice: (loan?.loanType === CONVENTIONAL || last_search?.loanType === CONVENTIONAL)
        ? Number(localStorage.getItem("offer_price_conventional"))
        : Number(localStorage.getItem("offer_price_fha")),
    };

    const result = await axios.get(GET_PROPERTY_URL, {
      headers,
      params,
    });

    return result.data;
  } catch (error) {

    throw error;
  }
};

export async function getFavorites(page, pageSize, userId, profile_token) {
  try {
    const headers = profile_token
      ? { Authorization: `Bearer ${profile_token}` }
      : {};
    const result = await axios.get(GET_FAVORITES + "?page=" + page + "&pageSize=" + pageSize + "&userId=" + userId, {
      headers
    });
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function saveFavoriteProperty(profileId, propertyId, downPayment, offerPrice, sellerRebate, taxes, hoa, profile_token) {
  try {
    const headers = profile_token
      ? { Authorization: `Bearer ${profile_token}` }
      : {};
    const result = await axios.post(SAVE_FAVORITE, {
      borrowerUserId: profileId,
      listedPropertyId: propertyId,
      downPayment: downPayment,
      offerPrice: offerPrice,
      sellerRebate: sellerRebate,
      taxes: taxes,
      hoa: hoa
    }, {
      headers
    });
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getSharedProperty(url, profile_token) {
  try {
    const headers = profile_token
      ? { Authorization: `Bearer ${profile_token}` }
      : {};
    const result = await axios.get(PROXY_URL + "?url=" + url, { headers });
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function saveExternalProperty(property, profile_token) {
  try {
    const headers = profile_token ? { Authorization: `Bearer ${profile_token}` } : {};

    const config = {
      headers,
      transformResponse: [function (response) {
        return response;
      }],
    };

    const result = await axios.post(SAVE_EXTERNAL_PROPERTY, property, config);
    return result.data;
  } catch (error) {
    throw error;
  }
}
