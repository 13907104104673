import {
    EmailIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TelegramShareButton,
    TelegramIcon,
} from 'react-share';
import {
    Menu,
    MenuItem
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { useEffect, useState } from 'react';
import BaseAlert from '../BaseComponents/BaseAlert';
import { useError } from '../../hooks/handleError';
import { SUBJECT, SUCCESS, VISITING } from '../../constants/constants';
import SmsIcon from '@mui/icons-material/Sms';

export default function ShareProperty({ url, description, anchorEl, handleClose }) {

    const [urlValid, setUrlValid] = useState(url);

    useEffect(() => { setUrlValid(url); }, [url]);

    const [anchor, setAnchor] = useState(anchorEl);

    const { error, setErrorMessage, clearError } = useError();

    useEffect(() => {
        setAnchor(anchorEl);
    }, [anchorEl]);

    const copyLink = { message: "Link copied to clipboard", type: SUCCESS }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(urlValid);
        handleClose();
        setErrorMessage(copyLink.message, copyLink.type);
    };

    const messageContent = description + "\n" + "\n" + VISITING + "\n" + urlValid;

    const messageContentWhatsapp = description + "\n" + "\n" + VISITING;

    const messageContentTelegram = "\n" + description;

    const handleSendEmail = () => {
        const mailtoLink = `mailto:?subject=${encodeURIComponent(SUBJECT)}&body=${encodeURIComponent(messageContent)}`;
        window.location.href = mailtoLink;
    };

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <Menu
            anchorEl={anchor}
            open={Boolean(anchor)}
            onClose={() => handleClose()}
            keepMounted
        >
            <BaseAlert alert={error} onClose={() => { clearError(); }} />
            <MenuItem onClick={() => handleClose()}>
                <div onClick={handleSendEmail} style={{ display: "flex", alignItems: "center" }}>
                    <EmailIcon size={32} round />
                    <span style={{ marginLeft: '0.5rem' }}>Email</span>
                </div>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                {!isMobile
                    ? <WhatsappShareButton
                        url={urlValid}
                        title={messageContentWhatsapp}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <WhatsappIcon size={32} round />
                        <span style={{ marginLeft: '0.5rem' }}>WhatsApp</span>
                    </WhatsappShareButton>
                    : <div
                        onClick={() => { window.location.href = `whatsapp://send?text=${encodeURIComponent(messageContentWhatsapp + "\n" + urlValid)}`; }}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <WhatsappIcon size={32} round />
                        <span style={{ marginLeft: '0.5rem' }}>WhatsApp</span>
                    </div>
                }
            </MenuItem>
            <MenuItem onClick={handleClose}>
                {!isMobile
                    ? <TelegramShareButton
                        url={urlValid}
                        title={messageContentTelegram}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <TelegramIcon size={32} round />
                        <span style={{ marginLeft: '0.5rem' }}>Telegram</span>
                    </TelegramShareButton>
                    : <div
                        onClick={() => { window.location.href = window.location.href = `tg://share?text=${encodeURIComponent(urlValid + "\n" + messageContentTelegram)}`; }}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <TelegramIcon size={32} round />
                        <span style={{ marginLeft: '0.5rem' }}>Telegram</span>
                    </div>
                }
            </MenuItem>
            {isMobile
                ? <MenuItem onClick={handleClose}>
                    <div
                        onClick={() => {
                            const message = "Check this property!" + "\n" + urlValid;
                            window.location.href = window.location.href = `sms:?&body=${encodeURIComponent(message)}`;
                        }}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <SmsIcon style={{ color: 'black', width: "32px", height: "32px", padding: "4px" }} />
                        <span style={{ marginLeft: '0.5rem' }}>SMS</span>
                    </div>
                </MenuItem>
                : null
            }
            <MenuItem onClick={handleCopyLink} style={{ display: "flex", alignItems: "center" }}>
                <ShareIcon style={{ color: 'black', width: "32px", height: "32px", padding: "4px" }} />
                <span style={{ marginLeft: '0.5rem' }}>Copy Link</span>
            </MenuItem>
        </Menu>
    );
}