import { Box, IconButton } from "@mui/material";
import { BORROWER_ROLE, PRIMARY_COLOR, UNKNOWN_ERROR } from "../../../constants/constants";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as LinkTo } from "react-router-dom";
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {
    GET_INVESTMENT_PROFILE_LOAN,
    GET_PROFILE_ROLE,
    GET_FAVORITES,
    GET_LAST_SEARCH,
    GET_LAST_SEARCH_RESULTS,
    GET_PROFILE_ID,
    GET_PROFILE_TOKEN,
} from "../../../constants/store/getters";
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { HOME_ROUTE } from "../../../constants/routes";
import {
    GO_BACK_TO_SEARCH_LABEL,
    OFFER_PRO_LABEL,
    REMOVE_LABEL,
    SAVE_LABEL,
    SHARE_LABEL,
} from "../../../i18n/i18nLabel";
import { useState, useMemo } from 'react';
import ShareProperty from "../../properties/ShareProperty";
import BaseAlert from "../../BaseComponents/BaseAlert";
import { useError } from "../../../hooks/handleError";
import { saveFavoriteProperty } from "../../../services/propertiesServices";
import { SAVE_AS_FAVORITE_MESSAGE } from "../../../constants/messages";
import { SUCCESS } from "../../../constants/constants";
import SnackBarSaveProperty from "../SavedProperties/SnackbarSaveProperty";
import getSharedPropertyUrl,
{
    getDownPayment,
    setCurrencyValue,
    handleError
} from "../../../utils/functions";
import { useStore } from "../../../hooks/store/store";
import OfferProDialog from "../../Dialogs/OfferProDialog";
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import BaseBtn from "../../BaseComponents/BaseBtn";
import Fab from '@mui/material/Fab';

export default function DetailsTopBar({ property }) {

    const { t } = useTranslation();

    const [showShare, setShowShare] = useState(null);

    const [showOfferPro, setShowOfferPro] = useState(false);

    const { error, setErrorMessage, clearError } = useError();

    const { state, dispatch } = useStore();

    const [favorite, setFavorite] = useState(property?.isFavoriteProperty ? true : false);

    let favoriteArray = state[GET_FAVORITES];

    const descriptionShare = () => {
        const price = "Price: $" + setCurrencyValue(property?.price);
        const address = property?.streetAddress ? `${property?.streetAddress},` : "";
        const description = "Address: " + address + " " + property?.city + ", " + property?.state + " " + property?.zipCode ?? "";
        const info = "Bedrooms: " + property?.bedrooms + ", "
            + "Full Bathrooms: " + property?.bathroomsFull + ", "
            + "Home Size: " + property?.livingArea + " SQFT";

        return price + "\n" + "\n" + description + "\n" + "\n" + info;
    }

    const handleClick = async () => {
        try {
            await saveFavoriteProperty(
                state[GET_PROFILE_ID],
                property?.propertyId,
                getDownPayment(state[GET_LAST_SEARCH], state[GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc),
                property?.price,
                0,
                property?.taxes ?? 0,
                property?.hoa ?? 0,
                state[GET_PROFILE_TOKEN]
            );

            favoriteArray = state[GET_FAVORITES];

            if (!favorite) {
                favoriteArray.push(property);
                dispatch({ action: GET_FAVORITES, value: favoriteArray });
                setErrorMessage(SAVE_AS_FAVORITE_MESSAGE, SUCCESS);

                const propsArray = JSON.parse(localStorage.getItem("last_search_results"));
                const index = propsArray.findIndex(item => item.propertyId === property?.propertyId);

                if (index !== -1) {
                    propsArray[index].isFavoriteProperty = true;
                }

                localStorage.setItem("last_search_results", JSON.stringify(propsArray));
                dispatch({ action: GET_LAST_SEARCH_RESULTS, value: propsArray });

            } else {
                let indexP = favoriteArray.findIndex(item => item.propertyId === property?.propertyId);
                let arr = indexP !== -1 ? favoriteArray.slice(0, indexP).concat(favoriteArray.slice(indexP + 1)) : favoriteArray;
                dispatch({ action: GET_FAVORITES, value: arr });

                const propsArray = JSON.parse(localStorage.getItem("last_search_results"));
                const index = propsArray.findIndex(item => item.propertyId === property?.propertyId);

                if (index !== -1) {
                    propsArray[index].isFavoriteProperty = false;
                }

                localStorage.setItem("last_search_results", JSON.stringify(propsArray));
                dispatch({ action: GET_LAST_SEARCH_RESULTS, value: propsArray });

            }

        } catch (e) {
            setErrorMessage(handleError(e));
        }
    };

    const favoriteIcon = useMemo(() => {
        return (
            state[GET_PROFILE_ROLE] === BORROWER_ROLE
                ? <Tooltip
                    title={favorite ? t(REMOVE_LABEL) : t(SAVE_LABEL)}
                    onClick={() => {
                        handleClick();
                        setFavorite(!favorite);
                        property.isFavoriteProperty = !property?.isFavoriteProperty;
                    }}
                    style={{ marginRight: ".5rem" }}
                    className="cursor"
                >
                    <IconButton>
                        <FavoriteIcon style={{ color: favorite ? "red" : "grey" }} fontSize="small" />
                    </IconButton>
                </Tooltip>
                : <span></span>
        );
    }, [favorite]);

    const snackbar = useMemo(() => {
        return (
            error?.message !== UNKNOWN_ERROR
                ?
                (<SnackBarSaveProperty
                    alert={error}
                    onClose={() => { clearError(); }}
                />)
                : error?.message !== SAVE_AS_FAVORITE_MESSAGE
                    ? <BaseAlert alert={error} onClose={() => { clearError(); }} />
                    : null
        );
    }, [favorite, error]);

    const handleShare = (event) => {
        setShowShare(event.currentTarget);
    }

    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "fixed",
            width: "100%",
            top: "54px",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "10px",
            left: "0px",
            background: "#fff",
            zIndex: "1000",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.03)",
            paddingBottom: "10px",
        }}>
            {snackbar}
            <Box>
                <LinkTo to={HOME_ROUTE} style={{ textDecoration: "none" }}>
                    <Box
                        style={{ display: "flex", alignItems: "center", color: "black" }}
                        className="cursor"
                    >
                        <ArrowBackIcon fontSize="small" />
                        <span
                            className="optionEmphasis"
                            style={{ margin: "0px" }}
                        >
                            {t(GO_BACK_TO_SEARCH_LABEL)}
                        </span>
                    </Box>
                </LinkTo>
            </Box>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around"
            }}>
                <Tooltip
                    title={!showShare ? t(SHARE_LABEL) : ""}
                    onClick={handleShare}
                    style={{ marginRight: ".5rem" }}
                    className="cursor"
                >
                    <IconButton>
                        <ShareIcon style={{ color: "black" }} fontSize="small" />
                    </IconButton>
                </Tooltip>
                {favoriteIcon}
                {state[GET_INVESTMENT_PROFILE_LOAN]
                    ? <BaseBtn
                        onClick={() => setShowOfferPro(true)}
                        className="cursor"
                        style={{
                            position: "fixed",
                            top: "120px"
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CalculateOutlinedIcon sx={{ marginRight: "5px" }} />
                            <span>{t(OFFER_PRO_LABEL)}</span>
                        </Box>
                    </BaseBtn>
                    : null
                }
            </Box>
            <ShareProperty
                description={descriptionShare()}
                url={getSharedPropertyUrl(property?.propertyId)}
                handleClose={() => { setShowShare(null); }}
                anchorEl={showShare}
            />
            {
                state[GET_INVESTMENT_PROFILE_LOAN]
                    ? <OfferProDialog
                        open={showOfferPro}
                        onClose={() => setShowOfferPro(false)}
                        property={property}
                        setFavorite={() => setFavorite(true)}
                        favorite={favorite}
                    />
                    : null
            }
        </Box>
    )
}