import axios from "axios";
import {
    CHANGE_RESET_PASSWORD,
    GET_USER_ACCOUNT,
    LOGIN_AS_GUEST,
    LOG_IN,
    LOG_OUT,
    RESEND_VERIFICATION_CODE,
    SEND_PASSWORD_OTP,
    SING_UP,
    UPDATE_GUEST_USER,
    VERIFICATION_USER
} from "../endpoints/endpoints";
import { BORROWER_ROLE, GUEST_ROLE } from "../constants/constants";

async function splitName(fullName) {
    let words = fullName.split(" ");
    let name = "";
    let last = "";

    words = words.filter(item => item !== '');

    if (words.length === 2) {
        name = words[0];
        last = words[1];
    } else {
        const lastNameNumber = 2;
        const allWords = words.length;
        last = words.slice(allWords - lastNameNumber).join(" ");
        name = words.slice(0, allWords - lastNameNumber).join(" ");
    }
    return {
        name: name,
        last: last
    };
}

export const loginAsAGuest = async (paymentLimit, fundsLimit) => {
    try {
        const profile = await axios.post(LOGIN_AS_GUEST,
            {
                paymentLimit: paymentLimit,
                investmentAmount: fundsLimit
            }
        );
        return profile.data;
    } catch (error) {
        throw error;
    }
};

export async function signUpMobile(fullName, email, password, role, id, profile_token) {
    try {

        const nameAndLast = await splitName(fullName);

        const firstName = nameAndLast?.name;

        const lastName = nameAndLast?.last;

        if (role === GUEST_ROLE) {

            const headers = profile_token
                ? { Authorization: `Bearer ${profile_token}` }
                : {};

            const user = await axios.post(UPDATE_GUEST_USER, {
                firstName: firstName,
                lastname: lastName,
                email: email,
                password: password,
                confirmPassword: password,
                role: 'Borrower',
                guestUserId: id,
            }, {
                headers,
            });

            return user.data;
        } else {
            const user = await axios.post(SING_UP, {
                firstName: firstName,
                lastname: lastName,
                email: email,
                password: password,
                confirmPassword: password,
                role: 'Borrower'
            });

            return user.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function signUpDesktop(first, last, email, password, role, id, profile_token) {

    let name = first.trim();
    name = name.replace(/\s+/g, ' ');
    let lastN = last.trim();
    lastN = lastN.replace(/\s+/g, ' ');

    try {

        if (role === GUEST_ROLE) {

            const headers = profile_token
                ? { Authorization: `Bearer ${profile_token}` }
                : {};

            const user = await axios.post(UPDATE_GUEST_USER, {
                firstName: name,
                lastname: lastN,
                email: email,
                password: password,
                confirmPassword: password,
                role: 'Borrower',
                guestUserId: id
            }, {
                headers,
            });

            return user.data;

        } else {
            const user = await axios.post(SING_UP, {
                firstName: name,
                lastname: lastN,
                email: email,
                password: password,
                confirmPassword: password,
                role: 'Borrower'
            });

            return user.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function verifyUser(id, code) {
    try {
        const verify = await axios.post(VERIFICATION_USER, {
            userId: id,
            otp: code,
        });

        return verify.data;

    } catch (error) {
        throw error;
    }
}

export async function resendOtp(id) {
    try {
        const resend = await axios.post(RESEND_VERIFICATION_CODE, {
            userId: id,
        });

        return resend.data;

    } catch (error) {
        throw error;
    }
}

export async function getUserAccount(id) {
    try {
        const user = await axios.get(`${GET_USER_ACCOUNT}/${id}`);
        return user.data;
    } catch (error) {
        throw error;
    }
}

export async function signOut(profile_token) {
    try {
        const headers = profile_token != undefined && profile_token != null
            ? { Authorization: `Bearer ${profile_token}` }
            : {}

        const out = await axios.put(LOG_OUT, {}, {
            headers,
        });
        return out.data;
    } catch (error) {
        throw error;
    }
}

export async function logIn(email, password) {
    try {
        const result = await axios.post(LOG_IN, {
            email: email,
            password: password,
            role: BORROWER_ROLE
        });
        return result.data;
    } catch (error) {
        throw error;
    }
}

export async function sendPasswordOTP(email) {
    try {
        const result = await axios.post(SEND_PASSWORD_OTP, {
            email: email
        });
        return result.data;
    } catch (error) {
        throw error;
    }
}

export async function changeResetPassword(email, password) {
    try {
        const result = await axios.post(CHANGE_RESET_PASSWORD, {
            email: email,
            password: password
        });
        return result.data;
    } catch (error) {
        throw error;
    }
}