import { Box } from "@mui/material";
import {
    PieChart,
    Pie,
    Cell,
    Tooltip,
    Legend
} from 'recharts';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
    INSURANCE_LABEL,
    MORTGAGE_INS_LABEL,
    TAXES_LABEL
} from "../../../i18n/i18nLabel";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../../constants/constants";

export default function PaymentChart(props) {

    const { t } = useTranslation();

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const pAndI = Math.ceil(props.property.principalAndInt * 100) / 100;
    const insurance = Math.ceil(props.property.homeIns * 100) / 100;
    const taxes = Math.ceil(props.property.taxes * 100) / 100;
    const mortgageIns = Math.ceil(props.property.monthlyPMI * 100) / 100;

    const data = [
        { name: 'P&I', value: pAndI },
        { name: t(INSURANCE_LABEL), value: insurance },
        { name: t(TAXES_LABEL), value: taxes },
        { name: t(MORTGAGE_INS_LABEL), value: mortgageIns },
    ];

    const colors = [SECONDARY_COLOR, PRIMARY_COLOR, '#407a8d', '#6aa3b4'];

    return (
        <Box>
            <PieChart
                width={isSmOrSmaller ? 300 : props?.width ?? 400}
                height={props?.height ?? 200}
            >
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={({ name, percent }) => percent ? `${(percent * 100).toFixed(2)}%` : null}
                    outerRadius={50}
                    innerRadius={25}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </Box>
    )
}