import { ANY_LABEL } from "../i18n/i18nLabel";

export const BATH_BED_ROOMS_MIN = [
    { label: ANY_LABEL, value: ANY_LABEL },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
];

export const BATH_BED_ROOMS_MAX = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: ANY_LABEL, value: ANY_LABEL },
];

export const HOME_SIZE_MIN = [
    { label: ANY_LABEL, value: ANY_LABEL },
    { label: "500 sqft", value: 500 },
    { label: "750 sqft", value: 750 },
    { label: "1,000 sqft", value: 1000 },
    { label: "1,250 sqft", value: 1250 },
    { label: "1,500 sqft", value: 1500 },
    { label: "1,750 sqft", value: 1750 },
    { label: "2,000 sqft", value: 2000 },
    { label: "2,250 sqft", value: 2250 },
    { label: "2,500 sqft", value: 2500 },
    { label: "2,750 sqft", value: 2750 },
    { label: "3,000 sqft", value: 3000 },
    { label: "3,500 sqft", value: 3500 },
    { label: "4,000 sqft", value: 4000 },
    { label: "5,000 sqft", value: 5000 },
    { label: "7,500 sqft", value: 7500 },
    { label: "10,000 sqft", value: 10000 },
];

export const HOME_SIZE_MAX = [
    { label: "500 sqft", value: 500 },
    { label: "750 sqft", value: 750 },
    { label: "1,000 sqft", value: 1000 },
    { label: "1,250 sqft", value: 1250 },
    { label: "1,500 sqft", value: 1500 },
    { label: "1,750 sqft", value: 1750 },
    { label: "2,000 sqft", value: 2000 },
    { label: "2,250 sqft", value: 2250 },
    { label: "2,500 sqft", value: 2500 },
    { label: "2,750 sqft", value: 2750 },
    { label: "3,000 sqft", value: 3000 },
    { label: "3,500 sqft", value: 3500 },
    { label: "4,000 sqft", value: 4000 },
    { label: "5,000 sqft", value: 5000 },
    { label: "7,500 sqft", value: 7500 },
    { label: "10,000 sqft", value: 10000 },
    { label: ANY_LABEL, value: ANY_LABEL },
];

export const LOT_SIZE_MIN = [
    { label: ANY_LABEL, value: ANY_LABEL },
    { label: "1,000 sqft", value: 1000 },
    { label: "2,000 sqft", value: 2000 },
    { label: "3,000 sqft", value: 3000 },
    { label: "4,000 sqft", value: 4000 },
    { label: "5,000 sqft", value: 5000 },
    { label: "6,000 sqft", value: 6000 },
    { label: "7,500 sqft", value: 7500 },
    { label: "1/4 acre", value: 10890 },
    { label: "1/2 acre", value: 21780 },
    { label: "1 acre", value: 43560 },
    { label: "2 acres", value: 87120 },
    { label: "5 acres", value: 217800 },
    { label: "10 acres", value: 435600 },
    { label: "20 acres", value: 871200 },
    { label: "50 acres", value: 2177999 },
    { label: "100 acres", value: 4355998 },
];

export const LOT_SIZE_MAX = [
    { label: "1,000 sqft", value: 1000 },
    { label: "2,000 sqft", value: 2000 },
    { label: "3,000 sqft", value: 3000 },
    { label: "4,000 sqft", value: 4000 },
    { label: "5,000 sqft", value: 5000 },
    { label: "6,000 sqft", value: 6000 },
    { label: "7,500 sqft", value: 7500 },
    { label: "1/4 acre", value: 10890 },
    { label: "1/2 acre", value: 21780 },
    { label: "1 acre", value: 43560 },
    { label: "2 acres", value: 87120 },
    { label: "5 acres", value: 217800 },
    { label: "10 acres", value: 435600 },
    { label: "20 acres", value: 871200 },
    { label: "50 acres", value: 2177999 },
    { label: "100 acres", value: 4355998 },
    { label: ANY_LABEL, value: ANY_LABEL },
];

export const YEAR_BUILT_MIN = () => {
    let actualYear = new Date().getFullYear();
    let years = [];
    for (let year = actualYear; year >= 1900; year--) {
        years.push({ label: String(year), value: year });
    }
    years.push({ label: ANY_LABEL, value: ANY_LABEL });
    return years;
}

export const YEAR_BUILT_MAX = () => {
    let actualYear = new Date().getFullYear();
    let years = [];
    years.push({ label: ANY_LABEL, value: ANY_LABEL });
    for (let year = actualYear; year >= 1900; year--) {
        years.push({ label: String(year), value: year });
    }
    return years;
}