import { Box } from "@mui/material";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import BaseRadio from "../../BaseComponents/BaseRadio";
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import {
    DESC,
    ASC
} from "../../../constants/constants";
import CloseIcon from '@mui/icons-material/Close';
import {
    useState,
    useRef,
    useEffect
} from "react";
import BaseBtn from "../../BaseComponents/BaseBtn";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ImportProperty from "../../Dialogs/ImportProperty";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
    BEDROOMS_LABEL,
    CANCEL_LABEL,
    COMPARE_LABEL,
    FULL_BATH_LABEL,
    HOME_SIZE_LABEL,
    LOT_SIZE_LABEL,
    ORDER_BY_LABEL,
    PRICE_LABEL,
    SAVED_HOMES_LABEL,
    TAP_HOMES_LABEL,
    YEAR_BUILT_LABEL
} from "../../../i18n/i18nLabel";
import SortIcon from '@mui/icons-material/Sort';
import { IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';

export default function SavedTopBar(props) {

    const { t } = useTranslation();

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const [isFilterOptionOpen, setIsFilterOptionOpen] = useState(false);

    const [showImportProperty, setShowImportProperty] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const popperRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popperRef.current && !popperRef.current.contains(event.target) && !anchorEl.contains(event.target)) {
            setIsFilterOptionOpen(false);
        }
    };

    useEffect(() => {
        if (isFilterOptionOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isFilterOptionOpen]);

    return (
        <Box sx={{
            position: "fixed",
            width: "100%",
            top: "54px",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "5px",
            left: "0px",
            background: "#fff",
            zIndex: "1000",
            paddingBottom: "5px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.03)"
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
            }}
            >
                <Box>
                    {!isSmOrSmaller
                        ? <p style={{
                            fontWeight: "bold",
                            margin: "0px"
                        }}>{!props.comparison ? t(SAVED_HOMES_LABEL) : t(TAP_HOMES_LABEL)}</p>
                        : null
                    }
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {!props.comparison
                        ? <IconButton
                            size="small"
                            onClick={() => { setShowImportProperty(true) }}
                        >
                            <SystemUpdateAltIcon
                                fontSize="small"
                                sx={{ color: "black" }}
                            />
                        </IconButton>
                        : null
                    }
                    {!props.comparison ?
                        <Box>
                            <Popper
                                sx={{ zIndex: 9999 }}
                                open={isFilterOptionOpen}
                                anchorEl={anchorEl}
                                placement={"bottom"}
                                transition
                                ref={popperRef}
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "8px"
                                        }}>
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: "start"
                                            }}>

                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "start"
                                                }}>
                                                    <BaseRadio
                                                        label={t(PRICE_LABEL)}
                                                        selectedValue={props.orderBy}
                                                        value="price"
                                                        onInputChange={(value) => {
                                                            props.setOrderBy(value);
                                                        }}
                                                    />
                                                    <BaseRadio
                                                        label={t(BEDROOMS_LABEL)}
                                                        selectedValue={props.orderBy}
                                                        value="bedrooms"
                                                        onInputChange={(value) => {
                                                            props.setOrderBy(value);
                                                        }}
                                                    />
                                                    <BaseRadio
                                                        label={t(FULL_BATH_LABEL)}
                                                        selectedValue={props.orderBy}
                                                        value="bathroomsFull"
                                                        onInputChange={(value) => {
                                                            props.setOrderBy(value);
                                                        }}
                                                    />
                                                    <BaseRadio
                                                        label={t(HOME_SIZE_LABEL)}
                                                        selectedValue={props.orderBy}
                                                        value="livingArea"
                                                        onInputChange={(value) => {
                                                            props.setOrderBy(value);
                                                        }}
                                                    />
                                                    <BaseRadio
                                                        label={t(LOT_SIZE_LABEL)}
                                                        selectedValue={props.orderBy}
                                                        value="lotSize"
                                                        onInputChange={(value) => {
                                                            props.setOrderBy(value);
                                                        }}
                                                    />
                                                    <BaseRadio
                                                        label={t(YEAR_BUILT_LABEL)}
                                                        selectedValue={props.orderBy}
                                                        value="builtYear"
                                                        onInputChange={(value) => {
                                                            props.setOrderBy(value);
                                                        }}
                                                    />
                                                </Box>
                                                <Box>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            if (props.orderDirection === ASC) {
                                                                props.setOrderDirection(DESC);
                                                            } else {
                                                                props.setOrderDirection(ASC);
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            props.orderDirection === DESC
                                                                ? <VerticalAlignBottomIcon fontSize="small" sx={{ margin: "8px", color: "black" }} />
                                                                : <VerticalAlignTopIcon fontSize="small" sx={{ margin: "8px", color: "black" }} />
                                                        }
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: "end"
                                            }}>
                                                <BaseBtn
                                                    label={t(ORDER_BY_LABEL)}
                                                    variant="outlined"
                                                    onClick={() => { setIsFilterOptionOpen(false); props.handleOrder(); }}
                                                />
                                            </Box>

                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                            <IconButton
                                size="small"
                                onClick={(event) => {
                                    setIsFilterOptionOpen(true);
                                    setAnchorEl(event.currentTarget);
                                }}>
                                <SortIcon
                                    fontSize="small"
                                    sx={{ color: "black" }}
                                />
                            </IconButton>
                        </Box>
                        : null
                    }
                    <BaseBtn
                        variant="outlined"
                        style={{ marginLeft: "10px", width: "118px" }}
                        onClick={() => { props.handleCompare(); }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {!props.comparison
                                ? <><HolidayVillageIcon sx={{ mr: 1 }} />
                                    {t(COMPARE_LABEL)}</>
                                : <><CloseIcon sx={{ mr: 1 }} />
                                    {t(CANCEL_LABEL)}</>}
                        </Box>
                    </BaseBtn>
                </Box>
            </Box>
            <ImportProperty
                open={showImportProperty}
                onClose={() => setShowImportProperty(false)}
            />
        </Box >
    );
}