import { useState, useEffect } from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { ERROR } from '../../constants/constants';
import { useTranslation } from 'react-i18next';

export default function BaseAlert({ alert, onClose }) {

    const { t } = useTranslation();

    const [openAlert, setOpenAlert] = useState(!!alert?.message);

    useEffect(() => {
        setOpenAlert(!!alert?.message);
    }, [alert]);

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        if (onClose) {
            setOpenAlert(false);
            onClose();
        }
    };

    return (
        <Snackbar
            autoHideDuration={3000}
            open={openAlert}
            onClose={handleAlertClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{ width: "auto", marginTop: "54px", zIndex: 9999 }}
        >
            <Alert
                severity={alert?.type ?? ERROR}
                variant="standard"
                onClose={handleAlertClose}
                sx={{ width: "100%" }}
            >
                {t(alert?.message)}
            </Alert>
        </Snackbar>

    );
}


