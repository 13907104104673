import axios from "axios";
import {
    GET_LETTER,
    OFFER_PRO_LETTER
} from "../endpoints/endpoints";

export async function getLetter(propertyId, downPayment, profile_token) {
    try {
        const headers = profile_token
            ? { Authorization: `Bearer ${profile_token}` }
            : {};

        const result = await axios.get(GET_LETTER + "?propertyId=" + propertyId + "&downPayment=" + downPayment, {
            headers,
            responseType: 'blob'
        });

        const fileReaderInstance = new FileReader();

        return await new Promise((resolve, reject) => {
            fileReaderInstance.onload = () => {
                resolve(fileReaderInstance.result)
            }
            fileReaderInstance.onerror = () => {
                reject(fileReaderInstance.error)
            }
            fileReaderInstance.readAsDataURL(result.data)
        });

    } catch (error) {
        throw error;
    }
}

export async function offerProLetter(profileId, propertyId, downPayment, offerPrice, sellerRebate, taxes, hoa, profile_token) {
    try {
        const headers = profile_token
            ? { Authorization: `Bearer ${profile_token}` }
            : {};

        const result = await axios.post(OFFER_PRO_LETTER, {
            borrowerUserId: profileId,
            listedPropertyId: propertyId,
            downPayment: downPayment,
            offerPrice: offerPrice,
            sellerRebate: sellerRebate,
            taxes: taxes,
            hoa: hoa
        }, {
            headers,
            responseType: 'blob'
        });

        const fileReaderInstance = new FileReader();

        return await new Promise((resolve, reject) => {
            fileReaderInstance.onload = () => {
                resolve(fileReaderInstance.result)
            }
            fileReaderInstance.onerror = () => {
                reject(fileReaderInstance.error)
            }
            fileReaderInstance.readAsDataURL(result.data)
        });

    } catch (error) {
        throw error;
    }
}