import {
    HOMES_LABEL,
    FAVORITES_LABEL,
    SETTINGS_LABEL,
    PROFILE_LABEL
} from "../i18n/i18nLabel";
import {
    HOME_ROUTE,
    FAVORITES_ROUTE,
    PROFILE_ROUTE,
    SETTINGS_ROUTE
} from "./routes";

export const NAVIGATION = [
    {
        section: HOMES_LABEL,
        path: HOME_ROUTE,
    },
    {
        section: FAVORITES_LABEL,
        path: FAVORITES_ROUTE,
    },
    {
        section: PROFILE_LABEL,
        path: PROFILE_ROUTE,
    },
    /*{
        section: SETTINGS_LABEL,
        path: SETTINGS_ROUTE,
    },*/
];