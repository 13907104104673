import { Box } from "@mui/material";
import SearchLocation from "../Search/SearchLocation";
import { useStore } from "../../../hooks/store/store";
import { GET_LANDING_PAGE_ELEMENTS } from "../../../constants/store/getters";
import { getLandingPageStyles } from "../../../utils/functions";
import { LANDING_SEARCH_BAR } from "../../../constants/constants";
import SearchPro from "../../Dialogs/SearchPro";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function SearchTopBar() {

    const { state } = useStore();

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const barStyles = state[GET_LANDING_PAGE_ELEMENTS]?.length >= 0
        ? getLandingPageStyles(state[GET_LANDING_PAGE_ELEMENTS].filter(element => element.section === LANDING_SEARCH_BAR), LANDING_SEARCH_BAR)
        : {};

    return (
        <Box sx={{
            width: "100%",
            padding: "10px",
            background: barStyles?.background ?? "white",
            display: "flex",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1,
            borderBottom: "solid 1px #8080807d",
        }}>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
            }}>
                <SearchLocation />
                &nbsp;
                <SearchPro onClose={() => { }} />
            </Box>
        </Box>
    );
}