import CreateAccount from "./CreateAccount";
import Verification from "./Verification";
import SelectLoan from "./SelectLoan";
import InvProfileValues from "./InvProfileValues";
import { useEffect, useState } from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { NOT_APPROVED, APPROVED, GUEST_ROLE } from "../../constants/constants";
import CompleteSwiftProfile from "./CompleteSwiftProfile";
import { useStore } from "../../hooks/store/store";
import {
    GET_EXISTENT_LOAN,
    GET_INVESTMENT_PROFILE,
    GET_LOAN_TYPE,
    GET_MLO_PASSED,
    GET_PROFILE,
    GET_PROFILE_ID,
    GET_PROFILE_ROLE,
    GET_PROFILE_VERIFIED
} from "../../constants/store/getters";
import Padzilly from "../Images/Padzilly";
import LoanOfficer from "./LoanOfficer";
import LoanApproved from "./LoanApproved";
import BaseChangeLanguage from "../BaseComponents/BaseChangeLanguage";

export default function CreateAccountStepper() {

    const { state, dispatch } = useStore();

    const theme = useTheme();

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const steps = ['1', '2', '3', '4', '5'];

    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        const checkStore = () => {
            //Verification Step
            if (state[GET_PROFILE_ID] && state[GET_PROFILE_ROLE] !== GUEST_ROLE && !state[GET_PROFILE_VERIFIED]) {
                setActiveStep(1);
                return;
            }
            //Loan
            if (state[GET_PROFILE] && state[GET_EXISTENT_LOAN] && state[GET_PROFILE_VERIFIED] && state[GET_PROFILE_ROLE] !== GUEST_ROLE) {
                setActiveStep(1);
                return;
            }
            //Select Loan
            if (state[GET_PROFILE] && !state[GET_LOAN_TYPE] && state[GET_PROFILE_VERIFIED] && state[GET_PROFILE_ROLE] !== GUEST_ROLE) {
                setActiveStep(2);
                return;
            }
            //Loan Approved
            if (state[GET_PROFILE] && state[GET_LOAN_TYPE] === APPROVED && state[GET_PROFILE_VERIFIED] && state[GET_PROFILE_ROLE] !== GUEST_ROLE && !state[GET_MLO_PASSED] && !state[GET_INVESTMENT_PROFILE]) {
                setActiveStep(2);
                return;
            }
            //Inv Profile
            if ((state[GET_PROFILE] && state[GET_LOAN_TYPE] === NOT_APPROVED && state[GET_PROFILE_VERIFIED] && !state[GET_INVESTMENT_PROFILE] && state[GET_PROFILE_ROLE] !== GUEST_ROLE)
                || (state[GET_MLO_PASSED] && state[GET_PROFILE] && state[GET_LOAN_TYPE] === APPROVED && state[GET_PROFILE_VERIFIED] && !state[GET_INVESTMENT_PROFILE] && state[GET_PROFILE_ROLE] !== GUEST_ROLE)) {
                setActiveStep(3);
                return;
            }
            //Final Step
            if (state[GET_PROFILE] && state[GET_INVESTMENT_PROFILE] && state[GET_PROFILE_ROLE] !== GUEST_ROLE) {
                setActiveStep(4);
                return;
            }
        };
        checkStore();
    }, [state]);

    const handleOption = (value) => {
        localStorage.setItem('loan_type', value);
        dispatch({ action: GET_LOAN_TYPE, value: value });
    }

    const StepContent = ({ activeStep, state, handleOption }) => {
        switch (activeStep) {
            case 0:
                return <CreateAccount />;
            case 1:
                if (state[GET_EXISTENT_LOAN]) {
                    return <LoanApproved />
                } else {
                    return <Verification />;
                }
            case 2:
                if (!state[GET_LOAN_TYPE]) {
                    return <SelectLoan option={(e) => handleOption(e)} />;
                }
                if (state[GET_LOAN_TYPE] === APPROVED) {
                    return <LoanOfficer />;
                }
                return null;
            case 3:
                if (state[GET_LOAN_TYPE] === NOT_APPROVED) {
                    return <InvProfileValues />;
                }
                if (state[GET_LOAN_TYPE] === APPROVED && state[GET_MLO_PASSED]) {
                    return <InvProfileValues />;
                }
                return null;
            case 4:
                return <CompleteSwiftProfile />;
            default:
                return null;
        }
    };

    return (
        <Box sx={{
            height: '100vh', display: 'flex'
        }}>
            <Padzilly
                style={{
                    flex: isMdOrSmaller ? 0 : 1,
                }}
            />
            <Box sx={{ width: isMdOrSmaller ? "100%" : "60%" }}>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: "100%",
                    alignItems: "center",
                    justifyContent: 'space-between',
                    padding: "2rem",
                    borderLeft: !isMdOrSmaller ? "solid #EB5A2D 5px" : "none",
                    height: "100%"
                }}>
                    {!isMdOrSmaller
                        ? <Stepper activeStep={activeStep} sx={{ width: "100%" }}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={index} completed={activeStep > index}>
                                        <StepLabel></StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        : null
                    }
                    <Box sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <StepContent
                            activeStep={activeStep}
                            loanType={state[GET_LOAN_TYPE]}
                            state={state}
                            handleOption={handleOption}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
                        <BaseChangeLanguage />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}