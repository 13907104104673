import { Box } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { SELLER_REBATE_ONLY_LAP_TEXT } from "../../../i18n/i18nText";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useState } from "react";

export default function SellerInfo() {

    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const { t } = useTranslation();

    return (
        <Box sx={{ cursor: "pointer", display: "flex" }}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    title={t(SELLER_REBATE_ONLY_LAP_TEXT)}
                    placement="top"
                    arrow
                >
                    <HelpIcon fontSize="small" onClick={handleTooltipOpen} />
                </Tooltip>
            </ClickAwayListener>
        </Box>
    );
}