import { Box } from "@mui/material";
import BaseBtn from "../BaseComponents/BaseBtn";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { useStore } from "../../hooks/store/store";
import {
    GET_MLO_PASSED,
    GET_MLO_PROFILE
} from "../../constants/store/getters";
import Grow from '@mui/material/Grow';
import { useTranslation } from 'react-i18next';
import {
    GREAT_NEWS_TEXT,
    LOAN_OFFICER_NOT_CONNECTED_TEXT,
    WE_ARE_CONTACTING_TEXT,
    WE_ARE_REACHING_OUT_TEXT
} from "../../i18n/i18nText";
import {
    CONTINUE_LABEL,
    EMAIL_LABEL,
    LOAN_OFFICER_LABEL
} from "../../i18n/i18nLabel";

export default function LoanOfficerInfo(props) {

    const { state, dispatch } = useStore();

    const { t } = useTranslation();

    return (
        <Grow
            in={true}
            {...({ timeout: 500 })}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <PersonSearchIcon fontSize="large" color="secondary" />
                <p
                    style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: "1.2rem",
                        marginTop: "0px"
                    }}
                >
                    {state[GET_MLO_PROFILE]
                        ? t(GREAT_NEWS_TEXT)
                        : t(LOAN_OFFICER_NOT_CONNECTED_TEXT)}
                </p>
                <p style={{ textAlign: "center" }}>
                    {state[GET_MLO_PROFILE]
                        ? t(WE_ARE_CONTACTING_TEXT)
                        : t(WE_ARE_REACHING_OUT_TEXT)
                    }
                </p>
                <Box
                    sx={{
                        width: "100%"
                    }}
                >
                    <p
                        style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            fontSize: "1.2rem",
                        }}
                    >
                        {t(LOAN_OFFICER_LABEL)}
                    </p>
                    {state[GET_MLO_PROFILE]
                        ? <>
                            <p>{state[GET_MLO_PROFILE]?.name}</p>
                            <p>{state[GET_MLO_PROFILE]?.companyName}</p>
                            <p
                                style={{
                                    whiteSpace: 'normal',
                                    wordBreak: 'break-word'
                                }}
                            ><strong>{t(EMAIL_LABEL)}: </strong><a style={{ color: "black" }} href={`mailto:${state[GET_MLO_PROFILE]?.email}`}>{state[GET_MLO_PROFILE]?.email}</a></p>
                        </>
                        : <>
                            <p>
                                {props.name ?? localStorage.getItem("no_mlo_name")}
                            </p>
                            <p
                                style={{
                                    whiteSpace: 'normal',
                                    wordBreak: 'break-word'
                                }}
                            ><strong>{t(EMAIL_LABEL)}: </strong><a style={{ color: "black" }} href={`mailto:${localStorage.getItem("no_mlo_email")}`}>{props.email ?? localStorage.getItem("no_mlo_email")}</a></p>
                        </>
                    }
                </Box>
                <BaseBtn
                    label={t(CONTINUE_LABEL)}
                    style={{
                        width: "300px"
                    }}
                    onClick={() => {
                        localStorage.removeItem("no_mlo");
                        localStorage.removeItem("no_mlo_name");
                        localStorage.removeItem("no_mlo_phone");
                        localStorage.removeItem("no_mlo_email");
                        localStorage.setItem("mlo_passed", true);
                        dispatch({ action: GET_MLO_PASSED, value: true });
                    }}
                />
            </Box>
        </Grow>
    )
}