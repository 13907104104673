import { Box, Container, Link, Skeleton } from "@mui/material"
import React, { useMemo, useRef, useEffect } from 'react';
import { useStore } from "../../hooks/store/store";
import {
    GET_CONVENTIONAL_DOWN_PAYMENT,
    GET_FHA_DOWN_PAYMENT,
    GET_INVESTMENT_PROFILE_LOAN,
    GET_LANDING_PAGE_ELEMENTS,
    GET_LANGUAGE,
    GET_LAST_SEARCH,
    GET_LAST_SEARCH_RESULTS,
    GET_LAST_SEARCHED_PROPERTIES,
    GET_PROFILE_FINISHED,
    GET_PROFILE_TOKEN,
} from "../../constants/store/getters";
import PropertyCard from "./PropertyCard/PropertyCard";
import IconButton from '@mui/material/IconButton';
import PropertyCardSkeleton from "./PropertyCard/PropertyCardSkeleton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useHistory } from "react-router-dom";
import { HOME_ROUTE } from "../../constants/routes";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { getLandingPageStyles } from "../../utils/functions";
import {
    CONVENTIONAL,
    EN_US,
    FHA,
    LANDING_LAST_SEARCH_PROPERTIES,
    PRIMARY_COLOR,
    TITLES_COLOR
} from "../../constants/constants";
import { useTranslation } from 'react-i18next';
import {
    VIEW_MORE_PROP_IN_LABEL,
    YOU_CAN_CONTINUE_SEARCHING_LABEL
} from "../../i18n/i18nLabel";
import { useQuery } from "@tanstack/react-query";
import { getProperties } from "../../services/propertiesServices";

export default function LandingPageSearchedProperties() {

    const { t } = useTranslation();

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

    const history = useHistory();

    const { state, dispatch } = useStore();

    const scrollContainerRef = useRef(null);

    const { data, isLoading, isError } = useQuery({
        queryKey: [
            "properties",
            state[GET_LAST_SEARCH]
        ],
        queryFn: async () => {
            try {
                if ((!state[GET_LAST_SEARCHED_PROPERTIES] || state[GET_LAST_SEARCHED_PROPERTIES]?.length === 0 || state[GET_LAST_SEARCHED_PROPERTIES] !== state[GET_LAST_SEARCH_RESULTS])
                    && state[GET_LAST_SEARCH]
                    && Object.keys(state[GET_LAST_SEARCH]).length > 0) {
                    return await getProperties(
                        state[GET_LAST_SEARCH],
                        state[GET_PROFILE_TOKEN],
                        state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === FHA
                            ? state[GET_FHA_DOWN_PAYMENT]
                            : state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === CONVENTIONAL
                                ? state[GET_CONVENTIONAL_DOWN_PAYMENT]
                                : null,
                        state[GET_CONVENTIONAL_DOWN_PAYMENT],
                        state[GET_FHA_DOWN_PAYMENT]
                    );
                } else {
                    return [];
                }
            } catch (e) {
                dispatch({ action: GET_LAST_SEARCHED_PROPERTIES, value: [] });
            }
        },
        enabled: (!state[GET_LAST_SEARCHED_PROPERTIES] || state[GET_LAST_SEARCHED_PROPERTIES] !== state[GET_LAST_SEARCH_RESULTS]),
    });

    useEffect(() => {
        if (!isLoading && data !== undefined && state[GET_LAST_SEARCH]) {
            dispatch({ action: GET_LAST_SEARCHED_PROPERTIES, value: data });
        }
    }, [data]);

    const handleScroll = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = isSmOrSmaller ? 310 : 620;
            if (direction === 'left') {
                scrollContainerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
            } else {
                scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            }
        }
    };

    const goToLocationSearch = () => {
        localStorage.setItem("profile_finished", true);
        dispatch({ action: GET_PROFILE_FINISHED, value: true });
        history.push(HOME_ROUTE);
    }

    const lastPropertiesStyles = state[GET_LANDING_PAGE_ELEMENTS]?.length >= 0
        ? getLandingPageStyles(state[GET_LANDING_PAGE_ELEMENTS].filter(element => element.section === LANDING_LAST_SEARCH_PROPERTIES), LANDING_LAST_SEARCH_PROPERTIES)
        : {};

    const lastSearch = localStorage.getItem("last_search")
        ? JSON.parse(localStorage.getItem("last_search"))
        : {};

    const landingProperties = useMemo(() => {
        if (!Array.isArray(state[GET_LAST_SEARCHED_PROPERTIES])) {
            return (
                <Box sx={{
                    width: "100%",
                    background: lastPropertiesStyles?.background ?? "white",
                    display: "flex"
                }}>
                    <Container>
                        <Box
                            sx={{
                                width: "100%",
                                padding: "20px",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingLeft: "10px",
                                }}
                            >
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "1.4rem",
                                        margin: 0,
                                        color: lastPropertiesStyles?.title?.color ?? TITLES_COLOR
                                    }}
                                >
                                    {
                                        (lastPropertiesStyles?.title?.text
                                            ? state[GET_LANGUAGE] === EN_US
                                                ? lastPropertiesStyles?.title?.text
                                                : lastPropertiesStyles?.title?.transition
                                            : t(YOU_CAN_CONTINUE_SEARCHING_LABEL)) + " " + lastSearch?.search + ", " + lastSearch?.searchState
                                    }
                                </p>
                            </Box>
                            <Box sx={{ position: 'relative', width: '100%' }}>
                                <Box
                                    ref={scrollContainerRef}
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        alignItems: "center",
                                        overflowX: 'auto',
                                        gap: 2,
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                        scrollBehavior: 'smooth',
                                        '&::-webkit-scrollbar': {
                                            display: 'none',
                                        },
                                    }}
                                >
                                    {
                                        Array.from({ length: 10 }).map((_, index) => (
                                            <PropertyCardSkeleton key={index} />
                                        ))
                                    }
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%"
                                }}>
                                    <Skeleton width={"220px"} />
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            );
        } else {
            return (
                <Box sx={{
                    width: "100%",
                    background: lastPropertiesStyles?.background ?? "white",
                    display: "flex"
                }}>
                    <Container>
                        <Box
                            sx={{
                                padding: "20px",
                                width: "100%",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingLeft: "10px",
                                }}
                            >
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "1.4rem",
                                        margin: 0,
                                        color: lastPropertiesStyles?.title?.color ?? TITLES_COLOR
                                    }}
                                >
                                    {!state[GET_LAST_SEARCHED_PROPERTIES]
                                        ? ""
                                        : (lastPropertiesStyles?.title?.text
                                            ? state[GET_LANGUAGE] === EN_US
                                                ? lastPropertiesStyles?.title?.text
                                                : lastPropertiesStyles?.title?.transition
                                            : t(YOU_CAN_CONTINUE_SEARCHING_LABEL)) + " " + lastSearch?.search + ", " + lastSearch?.searchState
                                    }
                                </p>
                                {state[GET_LAST_SEARCHED_PROPERTIES]?.length > 0
                                    ? <Box sx={{ display: "flex" }}>
                                        <IconButton
                                            onClick={() => handleScroll('left')}
                                            style={{ borderRadius: "20px", marginRight: "5px" }}
                                        >
                                            <ArrowBackIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleScroll('right')}
                                            style={{ borderRadius: "20px", marginLeft: "5px" }}
                                        >
                                            <ArrowForwardIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                    : null
                                }
                            </Box>
                            <Box sx={{ position: 'relative', width: '100%' }}>
                                <Box
                                    ref={scrollContainerRef}
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        alignItems: "center",
                                        overflowX: 'auto',
                                        gap: 2,
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                        paddingLeft: "5px",
                                        paddingRight: "5px",
                                        scrollBehavior: 'smooth',
                                        '&::-webkit-scrollbar': {
                                            display: 'none',
                                        },
                                    }}
                                >
                                    {
                                        !state[GET_LAST_SEARCHED_PROPERTIES]
                                            ? Array.from({ length: 10 }).map((_, index) => (
                                                <PropertyCardSkeleton key={index} />
                                            ))
                                            : state[GET_LAST_SEARCHED_PROPERTIES]?.length > 0
                                                ? state[GET_LAST_SEARCHED_PROPERTIES].map((property, index) => (
                                                    <React.Fragment key={index}>
                                                        <PropertyCard property={property} />
                                                    </React.Fragment>
                                                ))
                                                : null
                                    }
                                </Box>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <Link
                                        onClick={() => goToLocationSearch()}
                                        sx={{
                                            color: lastPropertiesStyles?.btn?.color ?? PRIMARY_COLOR,
                                            textDecoration: "none",
                                            fontSize: ".9rem",
                                            cursor: "pointer",
                                            transition: "opacity 0.3s",
                                            '&:hover': {
                                                opacity: 0.8,
                                            },
                                        }}
                                    >
                                        {(lastPropertiesStyles?.btn?.text
                                            ? state[GET_LANGUAGE] === EN_US
                                                ? lastPropertiesStyles?.btn?.text
                                                : lastPropertiesStyles?.btn?.transition
                                            : t(VIEW_MORE_PROP_IN_LABEL)) + " " + state[GET_LAST_SEARCH]?.search + ", " + state[GET_LAST_SEARCH]?.searchState}
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            );
        }
    }, [
        state[GET_LAST_SEARCHED_PROPERTIES],
        isSmOrSmaller,
        isMdOrSmaller
    ]);

    return landingProperties;
}