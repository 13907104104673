import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export default function BaseChip(props) {
    const handleDelete = () => {
        if (props.closeChip) {
            props.closeChip(props.value);
        }
    };

    return (
        <Stack direction="row" spacing={1}>
            <Chip label={props.label ?? ""} variant="outlined" onDelete={handleDelete} />
        </Stack>
    );
}