import BaseDialog from "../BaseDialog/BaseDialog";
import BaseDialogBody from "../BaseDialog/BaseDialogBody";
import BaseDialogFooter from "../BaseDialog/BaseDialogFooter";
import BaseDialogHeader from "../BaseDialog/BaseDialogHeader";
import BaseCurrency from "../BaseComponents/BaseCurrency";
import Box from "@mui/material/Box";
import HelpIcon from '@mui/icons-material/Help';
import BaseBtn from "../BaseComponents/BaseBtn";
import { useState, useRef } from "react";
import { validations } from "../../utils/validations";
import { HELP_URL } from "../../constants/urls";
import { loginAsAGuest } from "../../services/authServices";
import {
    getInvestmentProfile
} from "../../services/investmentProfileServices";
import BaseAlert from "../BaseComponents/BaseAlert";
import { handleError } from "../../utils/functions";
import { useError } from "../../hooks/handleError";
import { useStore } from "../../hooks/store/store";
import {
    GET_PROFILE_TOKEN,
    GET_PROFILE,
    GET_PAYMENT_LIMIT,
    GET_FUNDS_LIMIT,
    GET_PROFILE_ID,
    GET_INVESTMENT_PROFILE,
    GET_PROFILE_ROLE,
    GET_CONVENTIONAL_DOWN_PAYMENT,
    GET_FHA_DOWN_PAYMENT,
    GET_SEARCH_PREFERENCE,
} from "../../constants/store/getters";
import { useTranslation } from 'react-i18next';
import {
    AVAILABLE_FUNDS_LABEL,
    DESIRED_MORTGAGE_LABEL,
    FUNDS_ERROR_LABEL,
    HOME_SEARCH_GOALS_LABEL,
    MORTGAGE_ERROR_LABEL,
    START_SEARCHING_LABEL
} from "../../i18n/i18nLabel";
import {
    DEFAULT_CONVENTIONAL,
    DEFAULT_FHA,
    LIST
} from "../../constants/constants";

export default function HomeSearchGoalsDialog(props) {

    const { t } = useTranslation();

    const PAYMENT_LIMIT = "payment_limit";

    const FUNDS_LIMIT = "funds_limit";

    const [paymentLimit, setPaymentLimit] = useState(null);

    const [fundsLimit, setFundsLimit] = useState(null);

    const [errors, setErrors] = useState({});

    const activeErrors = useRef(false);

    const [saving, setSaving] = useState(false);

    const { dispatch } = useStore();

    const { error, setErrorMessage, clearError } = useError();

    const validationValues = [
        { validation: ["required", "isGreaterThanCero"], value: paymentLimit, key: "payment_limit" },
        { validation: ["required", "isGreaterThanCero"], value: fundsLimit, key: "funds_limit" },
        { validation: ["paymentLimitAndFundLimit"], value: [paymentLimit, fundsLimit], key: "payment_limit" },
    ];

    const openHelp = () => {
        window.open(HELP_URL, '_blank');
    };

    const setProperties = async () => {
        if (!activeErrors.current) {
            try {
                setSaving(true);
                const payLimit = parseFloat(paymentLimit.replace(/,/g, ''));
                const fundLimit = parseFloat(fundsLimit.replace(/,/g, ''));

                const profile = await loginAsAGuest(
                    payLimit,
                    fundLimit
                );
                localStorage.setItem('profile_id', profile.id);
                localStorage.setItem("profile_role", profile.role);
                dispatch({ action: GET_PROFILE_TOKEN, value: profile.token });
                dispatch({ action: GET_PROFILE_ID, value: profile.id });
                dispatch({ action: GET_PROFILE, value: profile });
                dispatch({ action: GET_PROFILE_ROLE, value: profile.role });
                dispatch({ action: GET_PAYMENT_LIMIT, value: payLimit });
                dispatch({ action: GET_FUNDS_LIMIT, value: fundLimit });
                localStorage.setItem("down_payment_conventional", DEFAULT_CONVENTIONAL);
                localStorage.setItem("down_payment_fha", DEFAULT_FHA);
                dispatch({ action: GET_CONVENTIONAL_DOWN_PAYMENT, value: DEFAULT_CONVENTIONAL });
                dispatch({ action: GET_FHA_DOWN_PAYMENT, value: DEFAULT_FHA });
                localStorage.setItem("search_preference", LIST);
                dispatch({ action: GET_SEARCH_PREFERENCE, LIST });
                const invProfile = await getInvestmentProfile(profile.token);
                if (invProfile) {
                    dispatch({ action: GET_INVESTMENT_PROFILE, value: invProfile });
                } else {
                    setErrorMessage(handleError());
                }
                handleDialogBeforeHide();
            } catch (e) {
                setErrorMessage(handleError(e));
            } finally {
                setErrors({});
                setSaving(false);
            }
        }
    };

    const showError = (errorValue, field) => {
        if (field === FUNDS_LIMIT && Array.isArray(errorValue) && errorValue.length > 0) {
            return t(FUNDS_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        if (field === PAYMENT_LIMIT && Array.isArray(errorValue) && errorValue.length > 0) {
            return t(MORTGAGE_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        return "";
    };

    const handleDialogBeforeHide = () => {
        props.onClose();
        setFundsLimit(null);
        setPaymentLimit(null);
        setErrors({});
        setSaving(false);
    };

    return (
        <BaseDialog
            {...props}
        >
            <BaseDialogHeader
                title={t(HOME_SEARCH_GOALS_LABEL)}
            />
            <BaseDialogBody>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}>
                    <BaseAlert alert={error} onClose={() => { clearError(); }} />
                    <label>{t(DESIRED_MORTGAGE_LABEL)}</label>
                    <BaseCurrency
                        value={paymentLimit}
                        error={showError(errors.payment_limit, PAYMENT_LIMIT)}
                        onInputChange={(value) => {
                            validationValues[0].value = value;
                            validationValues[2].value = [value, fundsLimit];
                            setPaymentLimit(value);
                            setErrors(validations(validationValues));
                        }}
                    />
                    <div className="displayFlexRow">
                        <label>{t(AVAILABLE_FUNDS_LABEL)}</label>
                        <HelpIcon
                            style={{ marginLeft: "14px" }}
                            className="cursor"
                            fontSize="small"
                            onClick={openHelp}
                        />
                    </div>
                    <BaseCurrency
                        value={fundsLimit}
                        error={showError(errors.funds_limit, FUNDS_LIMIT)}
                        onInputChange={(value) => {
                            validationValues[1].value = value;
                            validationValues[2].value = [paymentLimit, value];
                            setFundsLimit(value);
                            setErrors(validations(validationValues));
                        }}
                    />
                </Box>
            </BaseDialogBody>
            <BaseDialogFooter className="setProperties">
                <BaseBtn
                    label={t(START_SEARCHING_LABEL)}
                    style={{ minWidth: "145px" }}
                    onClick={() => {
                        const eList = validations(validationValues);
                        setErrors(eList);
                        (Object.keys(eList).length === 0)
                            ? activeErrors.current = false
                            : activeErrors.current = true
                        setProperties();
                    }}
                    loading={saving}
                />
            </BaseDialogFooter>
        </BaseDialog>
    );
}