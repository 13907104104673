import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)({
    width: '40px',
    height: '40px',
    textAlign: 'center',
    fontSize: '1.5rem'
});

export default function BaseComplete({ length = 6, onComplete }) {

    const [code, setCode] = useState(Array(length).fill(''));
    const inputRefs = useRef([]);
    const lastAction = useRef("onChange");

    function areAllPositionsFilled(array) {
        return array.every((item) => item.trim() !== '');
    }

    useEffect(() => {
        if (areAllPositionsFilled(code)) {
            onComplete && onComplete(code.join(''));
        } else {
            onComplete && onComplete(null);
        }
    }, [code]);

    const handleChange = (index, event) => {
        const newCode = [...code];
        const value = event.target.value;

        if ((areAllPositionsFilled(newCode) && lastAction.current === 'Backspace') || (!areAllPositionsFilled(newCode))) {

            if (value.length > 1) {
                const pastedValue = value.slice(0, length);
                for (let i = 0; i < length; i++) {
                    newCode[i] = pastedValue[i] || '';
                }
                setCode(newCode);
                return;
            }

            newCode[index] = value;
            setCode(newCode);

            if (value && index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
            lastAction.current = "onChange";
        }
    };

    const handleBackspace = (index, event) => {
        if (event.key === 'Backspace' && index > 0) {
            if (inputRefs.current[5].value !== "") {
                inputRefs.current[5].focus();
            } else {
                inputRefs.current[index - 1].focus();
            }
            lastAction.current = "Backspace";
        }
    };

    return (
        <Grid container spacing={1} justifyContent="center">
            {code.map((value, index) => (
                <Grid item key={index}>
                    <StyledTextField
                        autoComplete='off'
                        type="text"
                        inputProps={
                            {
                                maxLength: 6,
                                style: { textAlign: "center", fontWeight: "bold" }
                            }
                        }
                        value={value}
                        onChange={(e) => handleChange(index, e)}
                        onKeyDown={(e) => handleBackspace(index, e)}
                        inputRef={(el) => (inputRefs.current[index] = el)}
                        variant="standard"
                        color='secondary'
                    />
                </Grid>
            ))}
        </Grid>
    );
}