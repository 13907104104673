import { Box } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import Link from "@mui/material/Link";
import { COVERAGE } from "../../constants/urls";
import { useTranslation } from 'react-i18next';
import {
    CHANGE_PROPERTY_TYPE_TEXT,
    CHECK_MLS_TEXT,
    ENTER_INFO_FOR_SEARCH_TEXT,
    FIND_IN_ZILLOW_OR_REALTOR_TEXT,
    NO_MATCHING_PROPERTIES_TEXT
} from "../../i18n/i18nText";
import {
    HERE_LABEL,
    NO_PROPERTIES_FOUND_LABEL,
    TRY_THE_FOLOWING_LABEL
} from "../../i18n/i18nLabel";
import LandingPageFooter from "../LandingPage/LandingPageFooter";
import { PRIMARY_COLOR } from "../../constants/constants";

export default function NoDataProperties(props) {

    const { t } = useTranslation();

    return (
        <Box
            sx={{ height: "100vh" }}
        >
            <Box
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}
            >
                {props.noResults
                    ? <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                marginTop: '164px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '1rem',
                            }}
                        >

                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}>
                                <HomeIcon
                                    fontSize="large"
                                />
                                <p>
                                    {t(NO_PROPERTIES_FOUND_LABEL)}
                                </p>
                                <p style={{ fontSize: ".9rem" }}>{t(NO_MATCHING_PROPERTIES_TEXT)}</p>
                                <Box>
                                    <p style={{ color: "#263238", fontWeight: "bold" }}>{t(TRY_THE_FOLOWING_LABEL)}:</p>
                                    <p style={{ color: "#263238" }}>{t(CHANGE_PROPERTY_TYPE_TEXT)}</p>
                                    <p style={{ color: "#263238" }}>{t(FIND_IN_ZILLOW_OR_REALTOR_TEXT)} <span style={{ color: "#263238", fontWeight: "bold" }}>Padzilly</span>
                                    </p>
                                    <p style={{ color: "#263238" }}>
                                        {t(CHECK_MLS_TEXT)}&nbsp;
                                        <Link
                                            className="cursor"
                                            style={{ color: PRIMARY_COLOR, textDecorationColor: PRIMARY_COLOR }}
                                            onClick={() => window.open(COVERAGE, "_blank")}
                                        >
                                            {t(HERE_LABEL)}
                                        </Link>
                                    </p>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    :
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                marginTop: '164px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '1rem',
                            }}
                        >

                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}>
                                <HomeIcon
                                    fontSize="large"
                                />
                                <p style={{ textAlign: "center" }}>
                                    {t(ENTER_INFO_FOR_SEARCH_TEXT)}
                                </p>
                            </Box>
                        </Box>
                    </Box>
                }
                <LandingPageFooter />
            </Box>
        </Box>
    );
}