import { Box } from "@mui/material";
import BaseBtn from "../BaseComponents/BaseBtn";
import {
    useHistory
} from "react-router-dom";
import { HOME_ROUTE } from "../../constants/routes";
import { GO_BACK_TO_SEARCH_LABEL } from "../../i18n/i18nLabel";
import { useTranslation } from 'react-i18next';
import {
    CONTINUE_SEARCH_TEXT,
    WE_COULDNT_FIND_PROP_TEXT
} from "../../i18n/i18nText";
import LandingPageFooter from "../LandingPage/LandingPageFooter";

export default function NoPropertyDetails() {

    const { t } = useTranslation();

    const history = useHistory();

    const handleGoBack = () => {
        history.push(HOME_ROUTE);
    }

    return (
        <Box sx={{ height: "100vh" }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "space-between"
                }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "104px"
                }}>
                    <p
                        style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: "1.1rem",
                            marginTop: "0px"
                        }}
                    >
                        {t(WE_COULDNT_FIND_PROP_TEXT)}
                    </p>
                    <p
                        style={{
                            marginTop: "0px",
                            textAlign: "center"
                        }}>
                        {t(CONTINUE_SEARCH_TEXT)}
                    </p>
                    <BaseBtn
                        label={t(GO_BACK_TO_SEARCH_LABEL)}
                        variant="outlined"
                        onClick={() => { handleGoBack(); }}
                    />
                </Box>
                <LandingPageFooter />
            </Box>
        </Box>
    );
}