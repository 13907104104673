import React from "react";
import { createRoot } from "react-dom/client";
import "@fontsource/roboto";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { StoreProvider } from "./hooks/store/store";
import StoreContainer from "./StoreContainer";
import MetaTags from "./MetaTags";
import { LOGO } from "./constants/constants";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
const metas = [
  {
    type: "url",
    content: window.location
  },
  {
    type: "title",
    content: "::Padzilly::"
  },
  {
    type: "image",
    content: LOGO
  },
  {
    type: "description",
    content: "Padzilly is the only platform that helps you structure your offer to get the best deal and fit a property to your loan approval"
  }
];
root.render(
  <StoreProvider>
    <MetaTags metas={metas} />
    <StoreContainer />
  </StoreProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
