import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import BaseLoading from "./components/BaseComponents/BaseLoading";
import { useStore } from "./hooks/store/store";
import { GET_STORE_COMPLETE } from "./constants/store/getters";
import { LANDING_PAGE } from "./constants/constants";

export default function StoreContainer() {

    const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

    const { state } = useStore();

    if (localStorage.getItem("profile_finished") && localStorage.getItem("page") !== LANDING_PAGE) {
        if (!state[GET_STORE_COMPLETE]) {
            return <BaseLoading loading={true} />;
        }
    }

    return (
        <Suspense fallback={localStorage.getItem("page") !== LANDING_PAGE ? <BaseLoading loading={true} /> : null}>
            <BrowserRouter basename={baseUrl}>
                <App />
            </BrowserRouter>
        </Suspense>
    );
}

