import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function BaseDialog(props) {

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            return false;
        }
        if (reason === "escapeKeyDown") {
            return false;
        }
        if (typeof onClose === "function") {
            props.onClose();
        }
        return event;
    }

    return (
        <Dialog
            fullScreen={isSmOrSmaller}
            open={props.open}
            onClose={handleClose}
            maxWidth={props.maxWidth ?? "xl"}
            className={props.className}
        >
            {props.children}
        </Dialog>
    );
}