import * as React from 'react';
import Box from '@mui/material/Box';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchPro from '../../Dialogs/SearchPro';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FAVORITES_LABEL, HOMES_LABEL, PROFILE_LABEL } from '../../../i18n/i18nLabel';
import { useHistory } from "react-router-dom";
import { FAVORITES_ROUTE, HOME_ROUTE, PROFILE_ROUTE } from '../../../constants/routes';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../constants/constants';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';

export default function BottomNavBar(props) {

    const { t } = useTranslation();

    const history = useHistory();

    return (
        <Box sx={{
            position: "fixed",
            bottom: "0px",
            zIndex: 999,
            width: "100%",
            background: "white",
        }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    padding: "15px",
                    alignItems: "center"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginLeft: "10px",
                        marginRight: "10px",
                        cursor: "pointer"
                    }}
                    onClick={() => { history.push(HOME_ROUTE); }}
                >
                    <SearchIcon
                        fontSize='medium'
                        sx={{ color: location.pathname === HOME_ROUTE ? PRIMARY_COLOR : SECONDARY_COLOR }}
                    />
                    <Link
                        sx={{
                            textDecoration: "none",
                            color: location.pathname === HOME_ROUTE ? PRIMARY_COLOR : SECONDARY_COLOR
                        }}
                    >
                        {t(HOMES_LABEL)}
                    </Link>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginLeft: "10px",
                        marginRight: "10px",
                        cursor: "pointer"
                    }}
                    onClick={() => { history.push(FAVORITES_ROUTE); }}
                >
                    <FavoriteIcon
                        fontSize='medium'
                        sx={{ color: location.pathname === FAVORITES_ROUTE ? PRIMARY_COLOR : SECONDARY_COLOR }}
                    />
                    <Link
                        sx={{
                            textDecoration: "none",
                            color: location.pathname === FAVORITES_ROUTE ? PRIMARY_COLOR : SECONDARY_COLOR
                        }}
                    >
                        {t(FAVORITES_LABEL)}
                    </Link>
                </Box>
                {location.pathname === HOME_ROUTE
                    ? <SearchPro
                        bottomBar={true}
                        onClose={() => { props.searchPro(); }}
                    />
                    : null
                }
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginLeft: "10px",
                        marginRight: "10px",
                        cursor: "pointer"
                    }}
                    onClick={() => { history.push(PROFILE_ROUTE); }}
                >
                    <PersonIcon
                        fontSize='medium'
                        sx={{ color: location.pathname === PROFILE_ROUTE ? PRIMARY_COLOR : SECONDARY_COLOR }}
                    />
                    <Link
                        sx={{
                            textDecoration: "none",
                            color: location.pathname === PROFILE_ROUTE ? PRIMARY_COLOR : SECONDARY_COLOR
                        }}
                    >
                        {t(PROFILE_LABEL)}
                    </Link>
                </Box>
            </Box>
        </Box>
    );
}