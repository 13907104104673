import { Box, Container } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import Link from "@mui/material/Link";
import {
    getLandingPageStyles,
} from "../../../utils/functions";
import { CREATE_ACCOUNT_ROUTE } from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../hooks/store/store";
import {
    GET_LANDING_PAGE_ELEMENTS,
} from "../../../constants/store/getters";
import {
    BORROWER_ROLE,
    GUEST_ROLE,
    LANDING_TOP_BAR,
    PRIMARY_COLOR,
    SECONDARY_COLOR,
} from "../../../constants/constants";
import SessionActions from "../../Login/SessionActions";
import LogoWhite from "../../Images/LogoWhite";
import BaseChangeLanguage from "../../BaseComponents/BaseChangeLanguage";
import { useTranslation } from 'react-i18next';
import {
    LENDERS_LABEL,
    SIGN_UP_LABEL
} from "../../../i18n/i18nLabel";
import { LENDERS } from "../../../constants/urls";

export default function TopBar() {

    const { t } = useTranslation();

    const history = useHistory();

    const { state } = useStore();

    const topBarStyles = state[GET_LANDING_PAGE_ELEMENTS]?.length >= 0
        ? getLandingPageStyles(state[GET_LANDING_PAGE_ELEMENTS].filter(element => element.section === LANDING_TOP_BAR), LANDING_TOP_BAR)
        : {};

    return (
        <Container sx={{
            background: `${topBarStyles?.banner?.background ?? "transparent"} !important`,
        }}>
            <Box
                sx={{
                    background: `${topBarStyles?.banner?.background ?? "transparent"} !important`,
                    boxShadow: "none",
                    borderBottom: `solid 4px ${topBarStyles?.banner?.border ?? "transparent"}`,
                    height: "54px"
                }}
            >
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "54px !important",
                    alignItems: "center",
                }}>
                    <Box sx={{ marginLeft: ".5rem" }}>
                        <ButtonBase component={Link} to="/">
                            {topBarStyles?.logo ? <img alt="Padzilly Logo" src={topBarStyles?.logo} width="135px" height="30px" /> : <LogoWhite width="135px" height="30px" />}
                        </ButtonBase>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        paddingRight: "10px",
                        paddingLeft: "10px",
                        fontSize: ".8rem"
                    }}>
                        <Link
                            style={{
                                textDecoration: "none",
                                marginRight: "15px",
                                cursor: "pointer",
                            }}
                            onClick={() => window.open(LENDERS, "_blank")}
                        >
                            <Box
                                sx={{
                                    height: '54px',
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    color: `${topBarStyles?.lender?.color ?? "white"}`,
                                    transition: 'color 0.2s ease-out',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        height: '2px',
                                        width: '0%',
                                        backgroundColor: `${topBarStyles?.lender?.hover ?? PRIMARY_COLOR}`,
                                        transition: 'width 0.2s ease-out',
                                    },
                                    '&:hover::after': {
                                        width: '100%',
                                    },
                                    '&:hover': {
                                        fontWeight: "bold",
                                    },
                                    '&:active': {
                                        '&::after': {
                                            width: '100%',
                                        },
                                    },
                                }}
                            >
                                {t(LENDERS_LABEL)}
                            </Box>
                        </Link>
                        {localStorage.getItem("profile_role") === GUEST_ROLE || !localStorage.getItem("profile_role")
                            ? <Link
                                style={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                    color: SECONDARY_COLOR,
                                }}
                                onClick={() => history.push(CREATE_ACCOUNT_ROUTE)}
                            >
                                <Box sx={{
                                    height: '54px',
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: "center",
                                    color: `${topBarStyles?.signUp?.color ?? "white"}`,
                                    transition: 'color 0.2s ease-out',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        height: '2px',
                                        width: '0%',
                                        backgroundColor: `${topBarStyles?.signUp?.hover ?? PRIMARY_COLOR}`,
                                        transition: 'width 0.2s ease-out',
                                    },
                                    '&:hover::after': {
                                        width: '100%',
                                    },
                                    '&:hover': {
                                        fontWeight: "bold",
                                    },
                                    '&:active': {
                                        '&::after': {
                                            width: '100%',
                                        },
                                    },
                                }}>
                                    {t(SIGN_UP_LABEL)}
                                </Box>
                            </Link>
                            : null
                        }
                        <BaseChangeLanguage />
                        {
                            localStorage.getItem("profile_role") === BORROWER_ROLE
                                ? <SessionActions />
                                : null
                        }
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}