import {
    Box,
    CardContent,
    Card
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import {
    CONVENTIONAL_LABEL,
    PADZILLY_SEARCH_PRO_LABEL,
} from "../../../i18n/i18nLabel";
import { useStore } from "../../../hooks/store/store";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from "react";
import {
    CONVENTIONAL,
    FHA,
} from "../../../constants/constants";
import HelpIcon from '@mui/icons-material/Help';
import { PADZILLY_SWIFTMATCH } from "../../../constants/urls";
import {
    GET_INVESTMENT_PROFILE_LOAN,
} from "../../../constants/store/getters";
import Conventional from "../../Dialogs/SearchPro/Conventional";
import Fha from "../../Dialogs/SearchPro/Fha";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

export default function EditDownPaymentProfile() {

    const { state } = useStore();

    const { t } = useTranslation();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const openHelp = () => {
        window.open(PADZILLY_SWIFTMATCH, '_blank');
    };

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "space-between",
            }}
        >
            <CardContent>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <p
                        style={{
                            fontWeight: "bold",
                            marginTop: "0px",
                            textTransform: "uppercase",
                            margin: "0px"
                        }}
                    >
                        {t(PADZILLY_SEARCH_PRO_LABEL)}
                    </p>
                    <HelpIcon
                        style={{ marginLeft: "14px" }}
                        className="cursor"
                        fontSize="small"
                        onClick={openHelp}
                    />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="secondary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {!state[GET_INVESTMENT_PROFILE_LOAN] || state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === CONVENTIONAL
                                ? < Tab label={t(CONVENTIONAL_LABEL)} />
                                : null
                            }
                            {!state[GET_INVESTMENT_PROFILE_LOAN] || state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === FHA
                                ? <Tab label={t(FHA)} />
                                : null
                            }
                        </Tabs>
                    </Box>
                    {!state[GET_INVESTMENT_PROFILE_LOAN] || state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === CONVENTIONAL
                        ? <CustomTabPanel value={value} index={0}>
                            <Conventional />
                        </CustomTabPanel>
                        : null
                    }
                    {!state[GET_INVESTMENT_PROFILE_LOAN] || state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === FHA
                        ? <CustomTabPanel value={value} index={state[GET_INVESTMENT_PROFILE_LOAN] ? 0 : 1}>
                            <Fha />
                        </CustomTabPanel>
                        : null
                    }
                </Box>
            </CardContent>
        </Card>
    );
}