import Box from "@mui/material/Box";
import PropertyTile from "./PropertyTile";
import {
    useMemo,
    useState,
    useEffect,
    useRef
} from "react";
import GoogleMaps from "../Pages/OtherComponents/GoogleMaps";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
    CONDOMINIUM,
    MAP,
    MOBILE_HOME,
    PROPERTY_SRC,
    SINGLE_FAMILY,
    TOWNHOUSE
} from "../../constants/constants";
import {
    CONDOMINIUM_LABEL,
    MOBILE_HOME_LABEL,
    NEXT_LABEL,
    NO_INFO_LABEL,
    SINGLE_FAMILY_LABEL,
    TOWNHOUSE_LABEL
} from "../../i18n/i18nLabel";
import { useTranslation } from 'react-i18next';
import { useStore } from "../../hooks/store/store";
import { GET_SEARCH_PREFERENCE } from "../../constants/store/getters";
import LandingPageFooter from "../LandingPage/LandingPageFooter";
import BaseBtn from "../BaseComponents/BaseBtn";

export default function PropertiesList(props) {

    const { t } = useTranslation();

    const theme = useTheme();

    const { state } = useStore();

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down("sm"));

    const [mapHeight, setMapHeight] = useState(window.innerHeight - (145));

    const [locations, setLocations] = useState([]);

    const scrollContainerRef = useRef(null);

    const propertyList = useMemo(() => {
        const locationsArray = [];
        let properties = [];
        if (props.list?.length > 0) {
            properties = props.list.map((property) => {
                if (property?.latitude && property?.longitude) {
                    locationsArray.push({
                        lat: property?.latitude,
                        lng: property?.longitude,
                        price: property?.price,
                        img: property?.media ? property?.media[0]?.mediaUrl : PROPERTY_SRC,
                        address: property?.fullAddress,
                        type: property?.propertyType === CONDOMINIUM
                            ? t(CONDOMINIUM_LABEL)
                            : property?.propertyType === SINGLE_FAMILY
                                ? t(SINGLE_FAMILY_LABEL)
                                : property?.propertyType === MOBILE_HOME
                                    ? t(MOBILE_HOME_LABEL)
                                    : property?.propertyType === TOWNHOUSE
                                        ? t(TOWNHOUSE_LABEL)
                                        : t(NO_INFO_LABEL),
                        beds: property?.bedrooms,
                        bath: property?.bathroomsFull,
                        hBath: property?.bathroomsHalf,
                        size: property?.livingArea,
                        id: property?.propertyId,
                        payment: property?.calculationOfPayment,
                        cash: property?.investmentTotal,
                    });
                }
                return (
                    <Box
                        key={property?.propertyId}
                        sx={{ margin: ".5rem" }}
                        className="cardLink"
                    >
                        <PropertyTile
                            property={property}
                        />
                    </Box>
                )
            });
        }
        setLocations(locationsArray);
        return properties;
    }, [props.list]);

    useEffect(() => {
        const handleResize = () => {
            setMapHeight(window.innerHeight - (145));
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [props.filters]);

    return (
        <Box>
            <Box sx={{ height: "145px" }} />
            <Box sx={{
                width: "100%",
                display: "flex",
                flexDirection: isMdOrSmaller ? "column" : "row"
            }}>
                {/*state[GET_SEARCH_PREFERENCE] === MAP
                    ? <Box sx={{
                        width: "45%",
                        display: "none",
                        height: mapHeight + "px",
                        display: isMdOrSmaller ? "none" : ""
                    }}>
                        <GoogleMaps
                            locations={locations}
                            height={mapHeight}
                            zoom={10}
                        />
                    </Box>
                    : null
                */}
                <Box sx={{
                    paddingTop: "3px",
                    width: "100%",
                    //width: isMdOrSmaller ? "100%" : state[GET_SEARCH_PREFERENCE] === MAP ? "55%" : "100%",
                    height: mapHeight + "px",
                    overflowY: "auto",
                    zIndex: 1,
                }}
                    ref={scrollContainerRef}
                >
                    <Box sx={(theme) => (state[GET_SEARCH_PREFERENCE] === MAP
                        ? {
                            /*display: "grid",
                            gridAutoRows: "1fr",
                            [theme.breakpoints.between("1", "950")]: {
                                gridTemplateColumns: "repeat(1, 1fr)",
                            },
                            "@media (min-width: 1115px)": {
                                gridTemplateColumns: "repeat(2, 1fr)",
                            },
                            "@media (min-width: 1600px)": {
                                gridTemplateColumns: "repeat(3, 1fr)",
                            },*/
                            display: "grid",
                            gridAutoRows: "1fr",
                            [theme.breakpoints.between("1", "570")]: {
                                gridTemplateColumns: "repeat(1, 1fr)",
                            },
                            [theme.breakpoints.between("570", "950")]: {
                                gridTemplateColumns: "repeat(2, 1fr)",
                            },
                            [theme.breakpoints.between("950", "1220")]: {
                                gridTemplateColumns: "repeat(3, 1fr)",
                            },
                            [theme.breakpoints.between("1220", "1520")]: {
                                gridTemplateColumns: "repeat(4, 1fr)",
                            },
                            "@media (min-width: 1520px)": {
                                gridTemplateColumns: "repeat(5, 1fr)",
                            },
                        }
                        : {
                            display: "grid",
                            gridAutoRows: "1fr",
                            [theme.breakpoints.between("1", "570")]: {
                                gridTemplateColumns: "repeat(1, 1fr)",
                            },
                            [theme.breakpoints.between("570", "950")]: {
                                gridTemplateColumns: "repeat(2, 1fr)",
                            },
                            [theme.breakpoints.between("950", "1220")]: {
                                gridTemplateColumns: "repeat(3, 1fr)",
                            },
                            [theme.breakpoints.between("1220", "1520")]: {
                                gridTemplateColumns: "repeat(4, 1fr)",
                            },
                            "@media (min-width: 1520px)": {
                                gridTemplateColumns: "repeat(5, 1fr)",
                            },
                        }
                    )}>
                        {propertyList}
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "20px",
                            marginTop: "20px"
                        }}
                    >
                        {!props.noResults
                            ? <BaseBtn
                                onClick={() => props.reachedBottom()}
                                variant="outlined"
                                label={t(NEXT_LABEL)}
                                loading={props.loading}
                            />
                            : null
                        }
                    </Box>
                    <LandingPageFooter />
                </Box>
            </Box>
        </Box>
    );
}