import validator from "validator";
import {
    IS_REQUIRED,
    IS_GREATER_THAN_CERO,
    FULL_NAME_ERROR,
    EMAIL_ERROR,
    MORTAGE_PAYMENT_ERROR,
    ONLY_LETTERS,
    MLO_SAME_EMAIL_ERROR,
    VALID_URL_TO_IMPORT,
    PASSWORDS_MUST_MATCH_MESSAGE
} from "../constants/messages";
import { REALTOR_URL_TO_IMPORT, ZILLOW_URL_TO_IMPORT } from "../constants/constants";

const functionsMap = {
    required: (value) => {
        if (Array.isArray(value)) {
            if (typeof value[0] !== "undefined" && value[0] !== null) {
                if (typeof value[0] !== "string") {
                    if (!value[0]) {
                        return IS_REQUIRED;
                    }
                    return null;
                }
                if (validator.isEmpty(value[0])) {
                    return IS_REQUIRED;
                }
                return null;
            } else {
                return IS_REQUIRED;
            }
        } else {
            if (typeof value !== "undefined" && value !== null) {
                if (typeof value !== "string") {
                    if (!value) {
                        return IS_REQUIRED;
                    }
                    return null;
                }
                if (validator.isEmpty(value)) {
                    return IS_REQUIRED;
                }
                return null;
            } else {
                return IS_REQUIRED;
            }
        }
    },
    isGreaterThanCero: (value) => {
        if (typeof value !== "undefined" && value !== "" && value !== null) {
            let numericValue;
            if (typeof value === "string") {
                numericValue = parseFloat(value.replace(/,/g, ''));
                numericValue = numericValue.toString();
            }
            if (typeof value === "number") {
                numericValue = value.toString();
            }
            if (parseFloat(numericValue) <= 0) {
                return IS_GREATER_THAN_CERO;
            }
        }
        return null;
    },
    isFullName: (value) => {
        if ((typeof value !== "undefined" && typeof value === "string")
            && value !== "") {
            if (FULL_NAME.test(value)) {
                return null;
            } else {
                return FULL_NAME_ERROR;
            }
        } else {
            return FULL_NAME_ERROR;
        }
    },
    isEmail: (value) => {
        if (Array.isArray(value)) {
            if (typeof value[0] !== "undefined" && value[0] !== null) {
                if (!validator.isEmail(value[0])) {
                    return EMAIL_ERROR;
                }
            }
            return null;
        } else {
            if (typeof value !== "undefined" && value !== null) {
                if (!validator.isEmail(value)) {
                    return EMAIL_ERROR;
                }
            }
            return null;
        }
    },
    paymentLimitAndFundLimit: (values) => {
        let payment = values[0];
        let funds = values[1];

        if (payment && funds) {
            payment = payment.toString();
            payment = payment.replace(/[^\d]/g, '');
            payment = payment.replace(/^0+/, '');

            funds = funds.toString();
            funds = funds.replace(/[^\d]/g, '');
            funds = funds.replace(/^0+/, '');

            if (parseFloat(payment) > parseFloat(funds)) {
                return MORTAGE_PAYMENT_ERROR;
            }
        }
        return null;
    },
    onlyLetters: (value) => {
        if (value) {
            if (!FIRST_AND_LAST_NAME.test(value)) {
                return ONLY_LETTERS;
            }
        }
        return null;
    },
    isSameEmail: (value) => {
        if (value[0] === value[1]) {
            return MLO_SAME_EMAIL_ERROR;
        }
        return null;
    },
    isValidRealtorZillowURL: (value) => {
        if (value) {
            if (validator.isURL(value)
                && (value.includes(REALTOR_URL_TO_IMPORT)
                    || value.includes(ZILLOW_URL_TO_IMPORT))) {
                return null;
            } else {
                return VALID_URL_TO_IMPORT;
            }
        }
    },
    isSamePassword: (value) => {
        if (value[0] !== value[1]) {
            return PASSWORDS_MUST_MATCH_MESSAGE;
        }
        return null;
    }
};

export const validations = (validations) => {
    let error = {};
    validations.forEach((val) => {
        if (Array.isArray(val.validation)) {
            let validatorLoop = [];
            val.validation.forEach((v) => {
                if (functionsMap[v] && typeof functionsMap[v] === "function") {
                    const value = functionsMap[v](val.value);
                    value ? validatorLoop.push(value) : null;
                }
            })
            validatorLoop.length ? error[val.key] = validatorLoop : null;
        } else {
            if (functionsMap[val.validation] && typeof functionsMap[val.validation] === "function") {
                const value = functionsMap[val.validation](val.value);
                value ? error[val.key] = [value] : null;
            }

        }
    });

    return error;
}

export const MORE_THAN_8_CHARACTERS = /^.{8,}$/;
export const NO_SPACES = /^[^\s]+$/;
export const MIX_STRING_FOR_PASS = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).+$/;
export const FULL_NAME = /^\s*[a-zA-Z]+(\s+[a-zA-Z]+)+\s*$/;
export const FIRST_AND_LAST_NAME = /^[a-zA-Z\s]+$/;