import {
    CONVENTIONAL,
    DEFAULT_CONVENTIONAL,
    DEFAULT_FHA,
    FARMERS_HOME_ADMINISTRATION,
    FHA,
    GEOLOCATION_NOT_SUPPORTED,
    GEO_PERMISSION_DENIED,
    GEO_POSITION_UNAVAILABLE,
    GEO_TIMEOUT,
    IMAGE,
    LANDING_CARDS,
    LANDING_FAVORITE_PROPERTIES,
    LANDING_FOOTER,
    LANDING_INFO,
    LANDING_LAST_SEARCH_PROPERTIES,
    LANDING_MAIN_SECTION,
    LANDING_NEAR_PROPERTIES,
    LANDING_READY_GET_STARTED,
    LANDING_SEARCH_BAR,
    LANDING_SEARCH_SECTION,
    LANDING_SUBSECTION_FAVORITES_BACKGROUND,
    LANDING_SUB_SECTION_BANNER,
    LANDING_SUB_SECTION_BANNER_LOGO,
    LANDING_SUB_SECTION_BORDER_BANNER,
    LANDING_SUB_SECTION_FAVORITES_BTN,
    LANDING_SUB_SECTION_FAVORITES_TITLE,
    LANDING_SUB_SECTION_FOOTER_APP_STORE,
    LANDING_SUB_SECTION_FOOTER_BACKGROUND,
    LANDING_SUB_SECTION_FOOTER_LOGO,
    LANDING_SUB_SECTION_FOOTER_PADZILLY_DISCLAIMER,
    LANDING_SUB_SECTION_FOOTER_PLAY_STORE,
    LANDING_SUB_SECTION_FOOTER_PRIVACY_POLICY,
    LANDING_SUB_SECTION_FOOTER_TERMS_OF_USE,
    LANDING_SUB_SECTION_FOOTER_TEXT,
    LANDING_SUB_SECTION_INFO_BACKGROUND,
    LANDING_SUB_SECTION_INFO_CARD_BACKGROUND,
    LANDING_SUB_SECTION_INFO_CARD_ICON,
    LANDING_SUB_SECTION_INFO_CARD_TEXT,
    LANDING_SUB_SECTION_INFO_CARD_TITLE,
    LANDING_SUB_SECTION_INFO_IMAGE,
    LANDING_SUB_SECTION_INFO_TEXT,
    LANDING_SUB_SECTION_INFO_TITLE,
    LANDING_SUB_SECTION_LAST_SEARCHED_PROPERTIES_BACKGROUND,
    LANDING_SUB_SECTION_LAST_SEARCHED_PROPERTIES_BTN,
    LANDING_SUB_SECTION_LAST_SEARCHED_PROPERTIES_TITLE,
    LANDING_SUB_SECTION_LENDERS,
    LANDING_SUB_SECTION_NEAR_NO_PROP_BTN,
    LANDING_SUB_SECTION_NEAR_PROP_BACKGROUND,
    LANDING_SUB_SECTION_NEAR_PROP_NO_TEXT,
    LANDING_SUB_SECTION_NEAR_PROP_PRIMARY_TITLE,
    LANDING_SUB_SECTION_NEAR_PROP_SECONDARY_TITLE,
    LANDING_SUB_SECTION_NEAR_PROP_THIRD_TITLE,
    LANDING_SUB_SECTION_NEAR_PROP_VIEW_MORE,
    LANDING_SUB_SECTION_READY_GET_STARTED_BACKGROUND,
    LANDING_SUB_SECTION_READY_GET_STARTED_LINK_CREATE_ACCOUNT,
    LANDING_SUB_SECTION_READY_GET_STARTED_LINK_SEARCH_HOMES,
    LANDING_SUB_SECTION_READY_GET_STARTED_TITLE,
    LANDING_SUB_SECTION_SEARCH_BAR,
    LANDING_SUB_SECTION_SEARCH_LOGIN,
    LANDING_SUB_SECTION_SEARCH_SIGN_UP,
    LANDING_SUB_SECTION_SEARCH_TITLE,
    LANDING_SUB_SECTION_SIGN_UP,
    LANDING_TOP_BAR,
    PRIMARY_RESIDENCE,
    TEXT,
    UNKNOWN_ERROR,
    VA
} from "../constants/constants";
import { LANDING_PAGE } from "../constants/routes";

export function setCurrencyValue(value, nullable) {
    if (value) {
        value = value.toString();
        value = value.replace(/[^\d]/g, '');
        if (value === '') {
            return nullable ? null : '0';
        }
        value = value.replace(/^0+/, '');
        if (value === '') {
            return nullable ? null : '0';
        }
        let formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return formattedValue;
    } else {
        if (nullable) {
            return null;
        } else {
            return "0";
        }
    }
}

export function handleError(error) {
    if (error) {
        if (error?.request?.response) {
            if (error?.request?.response[0] === '"' || error?.request?.response[0] === '{') {
                if (JSON.parse(error?.request?.response)?.title === "Bad Request") {
                    return UNKNOWN_ERROR;
                }
            }
            return error.request.response;
        } else {
            return UNKNOWN_ERROR;
        }
    }
};

export function destroySession() {
    localStorage.clear();
}

export const getSellerRebateMax = (loan, downPayment) => {
    const { loanType, occupancyType } = loan || {};

    if (!loanType) {
        return 0;
    }

    switch (loanType) {
        case CONVENTIONAL:
            if (downPayment < 10) {
                return 3;
            }
            if (downPayment < 25) {
                return 6;
            }
            return 9;
        case FHA:
            return 6;
        case VA:
            if (occupancyType === PRIMARY_RESIDENCE) {
                return 4
            }
            break;
        case FARMERS_HOME_ADMINISTRATION:
            if (occupancyType === PRIMARY_RESIDENCE) {
                return 6;
            }
            break;
        default:
            break;
    }

    return 0;
}

export default function getSharedPropertyUrl(id) {
    if (id) {
        if (window.location.origin.startsWith("https://qa")) {
            return `https://qa.padzilly.app/homes/details/${id}`;
        }
        if (
            window.location.origin.startsWith("https://www.") ||
            window.location.origin.startsWith("https://padzilly.com")
        ) {
            return `https://padzilly.com/homes/details/${id}`;
        }
        if (window.location.origin.startsWith("https://localhost")) {
            return `https://qa.padzilly.app/homes/details/${id}`;
        }
        if (window.location.origin.startsWith("https://1")) {
            return `https://qa.padzilly.app/homes/details/${id}`;
        }
    }
    return "";
}

export async function getCurrentLocation() {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    resolve(showPosition(position));
                },
                error => {
                    reject(showError(error));
                }
            );
        } else {
            reject(GEOLOCATION_NOT_SUPPORTED);
        }
    });
}

function showPosition(position) {
    const lat = position.coords.latitude;
    const lon = position.coords.longitude;
    return { lat: lat, lng: lon };
}

function showError(error) {
    switch (error.code) {
        case error.PERMISSION_DENIED:
            return GEO_PERMISSION_DENIED;
        case error.POSITION_UNAVAILABLE:
            return GEO_POSITION_UNAVAILABLE;
        case error.TIMEOUT:
            return GEO_TIMEOUT;
        case error.UNKNOWN_ERROR:
            return UNKNOWN_ERROR;
    }
}

export function getDownPayment(lastSearch, dowpayment) {
    if (dowpayment) {
        return (dowpayment);
    }
    //this implementation is momentary
    if (lastSearch?.loanType === CONVENTIONAL) {
        return DEFAULT_CONVENTIONAL * 100;
    } else {
        return DEFAULT_FHA * 100;
    }
}

export function getLenderURL() {
    if (window.location.origin.startsWith("https://qa")) {
        return `https://qa.padzilly.app/lenders`;
    }
    if (
        window.location.origin.startsWith("https://www.") ||
        window.location.origin.startsWith("https://padzilly.com")
    ) {
        return `https://padzilly.com/lenders`;
    }
    if (window.location.origin.startsWith("https://localhost")) {
        return `https://qa.padzilly.app/lenders`;
    }
    if (window.location.origin.startsWith("https://1")) {
        return `https://qa.padzilly.app/lenders`;
    }
    return LANDING_PAGE;
}

export function getLandingPageStyles(elements, section) {
    if (section === LANDING_TOP_BAR) {
        const topBar = {
            banner: { background: null, border: null, },
            lender: { color: null, hover: null },
            signUp: { color: null, hover: null },
            logo: null
        };

        elements.forEach(e => {
            if (e.subSection === LANDING_SUB_SECTION_BANNER_LOGO) {
                topBar.logo = e.image;
            }
            if (e.subSection === LANDING_SUB_SECTION_BANNER) {
                topBar.banner.background = e.color;
            }
            if (e.subSection === LANDING_SUB_SECTION_LENDERS) {
                topBar.lender.color = e.color;
                topBar.lender.hover = e.hover;
            }
            if (e.subSection === LANDING_SUB_SECTION_SIGN_UP) {
                topBar.signUp.color = e.color;
                topBar.signUp.hover = e.hover;
            }
            if (e.subSection === LANDING_SUB_SECTION_BORDER_BANNER) {
                topBar.banner.border = e.color
            }
        });

        return topBar;
    }
    if (section === LANDING_MAIN_SECTION) {
        const mainSection = {
            image: null
        }
        elements.forEach(e => {
            if (e.type === IMAGE && e.image) {
                mainSection.image = e.image;
            }
        });
        return mainSection;
    }
    if (section === LANDING_SEARCH_SECTION) {
        const searchSection = {
            title: { text: null, color: null, translation: null },
            signUp: { color: null, text: null, translation: null },
            login: { color: null, text: null, translation: null }
        };
        elements.forEach(e => {
            if (e.type === TEXT && e.text && e.subSection === LANDING_SUB_SECTION_SEARCH_TITLE) {
                searchSection.title.text = e.text;
                searchSection.title.color = e.color;
                searchSection.title.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_SEARCH_SIGN_UP && e.color) {
                searchSection.signUp.color = e.color;
                searchSection.signUp.text = e.text;
                searchSection.signUp.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_SEARCH_LOGIN && e.color) {
                searchSection.login.color = e.color;
                searchSection.login.text = e.text;
                searchSection.login.translation = e.translation;
            }
        });
        return searchSection;
    }
    if (section === LANDING_NEAR_PROPERTIES) {
        const nearPropSection = {
            primaryTitle: { text: null, color: null, translation: null },
            secondaryTitle: { text: null, color: null, translation: null },
            thirdTitle: { text: null, color: null, translation: null },
            noPropText: { text: null, color: null, translation: null },
            noPropBtn: { text: null, color: null, translation: null },
            viewMoreBtn: { text: null, color: null, translation: null },
            background: null,
        }
        elements.forEach(e => {
            if (e.subSection === LANDING_SUB_SECTION_NEAR_PROP_PRIMARY_TITLE) {
                nearPropSection.primaryTitle.color = e.color;
                nearPropSection.primaryTitle.text = e.text;
                nearPropSection.primaryTitle.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_NEAR_PROP_SECONDARY_TITLE) {
                nearPropSection.secondaryTitle.color = e.color;
                nearPropSection.secondaryTitle.text = e.text;
                nearPropSection.secondaryTitle.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_NEAR_PROP_THIRD_TITLE) {
                nearPropSection.thirdTitle.color = e.color;
                nearPropSection.thirdTitle.text = e.text;
                nearPropSection.thirdTitle.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_NEAR_PROP_NO_TEXT) {
                nearPropSection.noPropText.color = e.color;
                nearPropSection.noPropText.text = e.text;
                nearPropSection.noPropText.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_NEAR_NO_PROP_BTN) {
                nearPropSection.noPropBtn.color = e.color;
                nearPropSection.noPropBtn.text = e.text;
                nearPropSection.noPropBtn.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_NEAR_PROP_VIEW_MORE) {
                nearPropSection.viewMoreBtn.color = e.color;
                nearPropSection.viewMoreBtn.text = e.text;
                nearPropSection.viewMoreBtn.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_NEAR_PROP_BACKGROUND) {
                nearPropSection.background = e.color;
            }
        });
        return nearPropSection;
    }
    if (section === LANDING_FAVORITE_PROPERTIES
        || section === LANDING_LAST_SEARCH_PROPERTIES) {
        const favAndLastSection = {
            title: { text: null, color: null, translation: null },
            btn: { text: null, color: null, translation: null },
            background: null
        };
        elements.forEach(e => {
            if (e.subSection === LANDING_SUB_SECTION_FAVORITES_TITLE
                || e.subSection === LANDING_SUB_SECTION_LAST_SEARCHED_PROPERTIES_TITLE
            ) {
                favAndLastSection.title.text = e.text;
                favAndLastSection.title.color = e.color;
                favAndLastSection.title.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_FAVORITES_BTN
                || e.subSection === LANDING_SUB_SECTION_LAST_SEARCHED_PROPERTIES_BTN
            ) {
                favAndLastSection.btn.color = e.color;
                favAndLastSection.btn.text = e.text;
                favAndLastSection.btn.translation = e.translation;
            }
            if (e.subSection === LANDING_SUBSECTION_FAVORITES_BACKGROUND
                || e.subSection === LANDING_SUB_SECTION_LAST_SEARCHED_PROPERTIES_BACKGROUND
            ) {
                favAndLastSection.background = e.color;
            }
        });
        return favAndLastSection;
    }
    if (section === LANDING_CARDS || section === LANDING_INFO) {
        const infoCards = {
            firstCard: { icon: null, title: { text: null, color: null, translation: null }, text: { text: null, color: null, translation: null } },
            secondCard: { icon: null, title: { text: null, color: null, translation: null }, text: { text: null, color: null, translation: null } },
            thirdCard: { icon: null, title: { text: null, color: null, translation: null }, text: { text: null, color: null, translation: null } },
            background: null,
        }
        elements.forEach(e => {
            if (e.subSection === LANDING_SUB_SECTION_INFO_CARD_ICON
                || e.subSection === LANDING_SUB_SECTION_INFO_IMAGE
            ) {
                if (e.order === 1) {
                    infoCards.firstCard.icon = e.image;
                }
                if (e.order === 2) {
                    infoCards.secondCard.icon = e.image;
                }
                if (e.order === 3) {
                    infoCards.thirdCard.icon = e.image;
                }
            }
            if (e.subSection === LANDING_SUB_SECTION_INFO_CARD_TITLE
                || e.subSection === LANDING_SUB_SECTION_INFO_TITLE
            ) {
                if (e.order === 1) {
                    infoCards.firstCard.title.color = e.color;
                    infoCards.firstCard.title.text = e.text;
                    infoCards.firstCard.title.translation = e.translation;
                }
                if (e.order === 2) {
                    infoCards.secondCard.title.color = e.color;
                    infoCards.secondCard.title.text = e.text;
                    infoCards.secondCard.title.translation = e.translation;
                }
                if (e.order === 3) {
                    infoCards.thirdCard.title.color = e.color;
                    infoCards.thirdCard.title.text = e.text;
                    infoCards.thirdCard.title.translation = e.translation;
                }
            }
            if (e.subSection === LANDING_SUB_SECTION_INFO_CARD_TEXT
                || e.subSection === LANDING_SUB_SECTION_INFO_TEXT
            ) {
                if (e.order === 1) {
                    infoCards.firstCard.text.color = e.color;
                    infoCards.firstCard.text.text = e.text;
                    infoCards.firstCard.text.translation = e.translation;
                }
                if (e.order === 2) {
                    infoCards.secondCard.text.color = e.color;
                    infoCards.secondCard.text.text = e.text;
                    infoCards.secondCard.text.translation = e.translation;
                }
                if (e.order === 3) {
                    infoCards.thirdCard.text.color = e.color;
                    infoCards.thirdCard.text.text = e.text;
                    infoCards.thirdCard.text.translation = e.translation;
                }
            }
            if (e.subSection === LANDING_SUB_SECTION_INFO_CARD_BACKGROUND
                || e.subSection === LANDING_SUB_SECTION_INFO_BACKGROUND
            ) {
                infoCards.background = e.color;
            }
        });
        return infoCards;
    }
    if (section === LANDING_READY_GET_STARTED) {
        const titles = {
            title: { text: null, color: null, translation: null },
            linkCreateAccount: { text: null, color: null, translation: null },
            linkSearchHomes: { text: null, color: null, translation: null },
            background: null,
        }
        elements.forEach(e => {
            if (e.subSection === LANDING_SUB_SECTION_READY_GET_STARTED_TITLE) {
                titles.title.text = e.text;
                titles.title.color = e.color;
                titles.title.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_READY_GET_STARTED_LINK_CREATE_ACCOUNT) {
                titles.linkCreateAccount.text = e.text;
                titles.linkCreateAccount.color = e.color;
                titles.linkCreateAccount.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_READY_GET_STARTED_LINK_SEARCH_HOMES) {
                titles.linkSearchHomes.text = e.text;
                titles.linkSearchHomes.color = e.color;
                titles.linkSearchHomes.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_READY_GET_STARTED_BACKGROUND) {
                titles.background = e.color;
            }
        });
        return titles;
    }
    if (section === LANDING_FOOTER) {
        const footer = {
            logo: null,
            text: { text: null, color: null, translation: null },
            appStore: null,
            playStore: null,
            terms: { text: null, color: null, translation: null },
            privacy: { text: null, color: null, translation: null },
            disclaimer: { text: null, color: null, translation: null },
            background: null
        };
        elements.forEach(e => {
            if (e.subSection === LANDING_SUB_SECTION_FOOTER_LOGO) {
                footer.logo = e.image;
            }
            if (e.subSection === LANDING_SUB_SECTION_FOOTER_TEXT) {
                footer.text.text = e.text;
                footer.text.color = e.color;
                footer.text.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_FOOTER_APP_STORE) {
                footer.appStore = e.image;
            }
            if (e.subSection === LANDING_SUB_SECTION_FOOTER_PLAY_STORE) {
                footer.playStore = e.image;
            }
            if (e.subSection === LANDING_SUB_SECTION_FOOTER_TERMS_OF_USE) {
                footer.terms.text = e.text;
                footer.terms.color = e.color;
                footer.terms.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_FOOTER_PRIVACY_POLICY) {
                footer.privacy.text = e.text;
                footer.privacy.color = e.color;
                footer.privacy.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_FOOTER_PADZILLY_DISCLAIMER) {
                footer.disclaimer.text = e.text;
                footer.disclaimer.color = e.color;
                footer.disclaimer.translation = e.translation;
            }
            if (e.subSection === LANDING_SUB_SECTION_FOOTER_BACKGROUND) {
                footer.background = e.color;
            }
        });
        return footer;
    }
    if (section === LANDING_SEARCH_BAR) {
        const searchBar = { background: null };
        elements.forEach(e => {
            if (e.subSection === LANDING_SUB_SECTION_SEARCH_BAR) {
                searchBar.background = e.color;
            }
        });
        return searchBar;
    }
}

export function getBoolean(value) {
    if (value === "true") {
        return true;
    }
    if (value === "false") {
        return false;
    }
    if (value) {
        return true;
    } else {
        return false;
    }
}

export function getStateCode(stateName) {
    const states = {
        "Alabama": "AL",
        "Alaska": "AK",
        "Arizona": "AZ",
        "Arkansas": "AR",
        "California": "CA",
        "Colorado": "CO",
        "Connecticut": "CT",
        "Delaware": "DE",
        "Florida": "FL",
        "Georgia": "GA",
        "Hawaii": "HI",
        "Idaho": "ID",
        "Illinois": "IL",
        "Indiana": "IN",
        "Iowa": "IA",
        "Kansas": "KS",
        "Kentucky": "KY",
        "Louisiana": "LA",
        "Maine": "ME",
        "Maryland": "MD",
        "Massachusetts": "MA",
        "Michigan": "MI",
        "Minnesota": "MN",
        "Mississippi": "MS",
        "Missouri": "MO",
        "Montana": "MT",
        "Nebraska": "NE",
        "Nevada": "NV",
        "New Hampshire": "NH",
        "New Jersey": "NJ",
        "New Mexico": "NM",
        "New York": "NY",
        "North Carolina": "NC",
        "North Dakota": "ND",
        "Ohio": "OH",
        "Oklahoma": "OK",
        "Oregon": "OR",
        "Pennsylvania": "PA",
        "Rhode Island": "RI",
        "South Carolina": "SC",
        "South Dakota": "SD",
        "Tennessee": "TN",
        "Texas": "TX",
        "Utah": "UT",
        "Vermont": "VT",
        "Virginia": "VA",
        "Washington": "WA",
        "West Virginia": "WV",
        "Wisconsin": "WI",
        "Wyoming": "WY"
    };

    const stateCode = states[stateName];
    if (stateCode) {
        return stateCode;
    } else {
        return stateName;
    }
}