import { Box } from "@mui/material";
import {
    NO_ACTIVE_SESSION,
    NO_PROPERTY,
} from "../../constants/constants";
import NoPropertyDetails from "./NoPropertyDetails";
import PropertyDetailSkeleton from "./PropertyDetailSkeleton";
import {
    useState,
    useEffect,
} from 'react';
import getSharedPropertyUrl,
{
    setCurrencyValue
} from "../../utils/functions";
import { useStore } from "../../hooks/store/store";
import {
    GET_PROFILE,
} from "../../constants/store/getters";
import OfferProBackdrop from "../Dialogs/OfferProBackdrop";
import MetaTags from "../../MetaTags";
import Container from "@mui/material/Container";
import SchoolsList from "./OtherComponents/SchoolsList";
import LandingPageFooter from "../LandingPage/LandingPageFooter";
import DetailsTopBar from "./PropertyDetails/DetailsTopBar";
import AboutThisHome from "./PropertyDetails/AboutThisHome";
import DetailsInfo from "./PropertyDetails/DetailsInfo";
import DetailsPhotos from "./PropertyDetails/DetailsPhotos";
import DetailsHome from "./PropertyDetails/DetailsHome";

export default function ViewDetails(props) {

    const [property, setProperty] = useState(props.property);

    useEffect(() => { setProperty(props.property) }, [props.property]);

    const { state } = useStore();

    useEffect(() => {
        if (localStorage.getItem("property_details")) {
            localStorage.removeItem("property_details");
        }
        window.scrollTo(0, 0);
    }, []);

    const [images, setImages] = useState(property?.media ?? []);

    useEffect(() => {
        setImages(property?.media ?? []);
    }, [props.property]);

    const metaDescription = () => {
        const price = "Price: $" + setCurrencyValue(property?.price);
        const address = property?.fullAddress ? `${property?.fullAddress}` : "";
        return price + " " + address;
    }

    return (
        <Box >
            {
                property === NO_PROPERTY ?
                    <NoPropertyDetails />
                    : property === null ?
                        <PropertyDetailSkeleton />
                        : property === NO_ACTIVE_SESSION ?
                            null
                            :
                            <Box>
                                <Container>
                                    {
                                        state[GET_PROFILE]?.isFirstTimeLogin && localStorage.getItem("open_offer_pro")
                                            ? <OfferProBackdrop />
                                            : null
                                    }
                                    <DetailsTopBar property={property} />
                                    <Box sx={{
                                        marginTop: "130px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between"
                                    }}>
                                        <DetailsPhotos images={images} property={property} />
                                        <DetailsHome property={property} />
                                        <DetailsInfo property={property} />
                                        <AboutThisHome property={property} />
                                        <SchoolsList schools={property?.schools} />
                                    </Box>
                                    <MetaTags metas={[
                                        {
                                            type: "url",
                                            content: getSharedPropertyUrl(property?.propertyId)
                                        },
                                        {
                                            type: "title",
                                            content: "Padzilly Property"
                                        },
                                        {
                                            type: "image",
                                            content: images[0]?.mediaUrl ?? ""
                                        },
                                        {
                                            type: "description",
                                            content: metaDescription()
                                        },
                                        {
                                            title: (property?.fullAddress ? property?.fullAddress.trim() + ", " : "") + property?.city + ", " + property?.state + " | ::Padzilly::"
                                        }
                                    ]} />
                                </Container>
                                <LandingPageFooter />
                            </Box>

            }
        </Box >
    );
}