import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { saveFavoriteProperty } from '../../services/propertiesServices';
import {
    GET_FAVORITES,
    GET_INVESTMENT_PROFILE_LOAN,
    GET_LAST_SEARCH,
    GET_LAST_SEARCH_RESULTS,
    GET_PROFILE_ID,
    GET_PROFILE_ROLE,
    GET_PROFILE_TOKEN,
} from "../../constants/store/getters";
import { useStore } from "../../hooks/store/store";
import { Box } from '@mui/material';
import {
    BORROWER_ROLE,
    PRIMARY_COLOR,
    SUCCESS
} from '../../constants/constants';
import { useError } from "../../hooks/handleError";
import SnackBarSaveProperty from "../Pages/SavedProperties/SnackbarSaveProperty";
import { getDownPayment, handleError } from '../../utils/functions';
import PropertyStatus from './PropertyStatus';
import { useState, useMemo } from "react";
import { SAVE_AS_FAVORITE_MESSAGE } from '../../constants/messages';
import { useTranslation } from 'react-i18next';
import { NEW_CONSTRUCTION_LABEL } from '../../i18n/i18nLabel';

export default function MediaCard({ isFavorite, property, status, select, selected, full }) {

    const [favorite, setFavorite] = useState(isFavorite)

    const { state, dispatch } = useStore();

    const { t } = useTranslation();

    const { error, setErrorMessage, clearError } = useError();

    let favoriteArray = state[GET_FAVORITES];

    const handleClickFavorite = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        try {
            await saveFavoriteProperty(
                state[GET_PROFILE_ID],
                property.propertyId,
                getDownPayment(state[GET_LAST_SEARCH], state[GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc),
                property.price,
                0,
                property.taxes ?? 0,
                property.hoa ?? 0,
                state[GET_PROFILE_TOKEN]
            );

            favoriteArray = state[GET_FAVORITES] ?? [];

            if (!favorite) {
                favoriteArray.push(property);
                await dispatch({ action: GET_FAVORITES, value: favoriteArray });
                setErrorMessage(SAVE_AS_FAVORITE_MESSAGE, SUCCESS);

                const propsArray = JSON.parse(localStorage.getItem("last_search_results"));
                const index = propsArray.findIndex(item => item.propertyId === property.propertyId);
                if (index !== -1) {
                    propsArray[index].isFavoriteProperty = true;
                }

                localStorage.setItem("last_search_results", JSON.stringify(propsArray));
                await dispatch({ action: GET_LAST_SEARCH_RESULTS, value: propsArray });

            } else {

                let indexP = favoriteArray.findIndex(item => item.propertyId === property.propertyId);
                let arr = indexP !== -1 ? favoriteArray.slice(0, indexP).concat(favoriteArray.slice(indexP + 1)) : favoriteArray;
                await dispatch({ action: GET_FAVORITES, value: arr });

                const propsArray = JSON.parse(localStorage.getItem("last_search_results"));
                const index = propsArray.findIndex(item => item.propertyId === property.propertyId);
                if (index !== -1) {
                    propsArray[index].isFavoriteProperty = false;
                }

                localStorage.setItem("last_search_results", JSON.stringify(propsArray));
                await dispatch({ action: GET_LAST_SEARCH_RESULTS, value: propsArray });

            }
            setFavorite(!favorite);
        } catch (e) {
            setErrorMessage(handleError(e));
        }
    };

    const fav = useMemo(() => {
        return (
            <FavoriteIcon style={{
                color: favorite ? "red" : "grey",
                padding: "6px",
                fontSize: "2.3rem",
                borderRadius: "50%",
                background: "white",
                marginRight: "5px",
                marginBottom: "5px"
            }}
                onClick={handleClickFavorite}
                className="cursor"
            />
        );
    }, [favorite]);

    const getDaysOnPadzilly = () => {
        if (property?.builtYear === new Date().getFullYear()) {
            return t(NEW_CONSTRUCTION_LABEL);
        }
        // add creation timestamp ???
        /*if (property?.modificationTimestamp) {
            const currentDate = new Date();
            const providedDate = new Date(property?.modificationTimestamp);
            const timeDifference = currentDate - providedDate;
            const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            return (daysDifference !== -1 && daysDifference !== 0
                ? daysDifference === 1
                    ? daysDifference + " day on Padzilly"
                    : daysDifference + " days on Padzilly"
                : "Recently added");
        }*/
        return null;
    }
    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
        }}
        >
            <SnackBarSaveProperty
                alert={error}
                onClose={() => { clearError(); }}
            />
            <Box sx={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                justifyContent: "space-between"
            }}>
                {
                    getDaysOnPadzilly() !== null
                        ? <Box
                            sx={{
                                padding: "4px",
                                background: "#808080c9",
                                borderRadius: "20px",
                                fontSize: ".8rem",
                                marginTop: "4px",
                                marginLeft: "4px",
                                color: "white",
                                fontWeight: "bold",
                                cursor: "default"
                            }}>
                            {getDaysOnPadzilly()}
                        </Box>
                        : <span />
                }
                {status
                    ? <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        background: "white",
                        padding: "7px",
                        fontSize: ".8rem",
                        fontWeight: "bold",
                        borderTopRightRadius: "10px"
                    }}>
                        <PropertyStatus
                            status={status}
                        />
                    </Box>
                    : null
                }
            </Box>
            <Box sx={{
                display: "flex",
                height: "100%",
                paddingTop: "5px",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-between"
            }}>
                {(select && !full) || (selected && full && select)
                    ? <CheckCircleIcon
                        style={{
                            color: !selected ? "white" : PRIMARY_COLOR
                        }} />
                    : <span />
                }
                {!select && state[GET_PROFILE_ROLE] === BORROWER_ROLE
                    ? fav
                    : <span></span>
                }
            </Box>
        </div>
    );
}