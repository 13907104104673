//TOP BAR//
export const HOMES_LABEL = "HOMES_LABEL";
export const FAVORITES_LABEL = "FAVORITES_LABEL";
export const PROFILE_LABEL = "PROFILE_LABEL";
export const SETTINGS_LABEL = "SETTINGS_LABEL";
export const SIGN_OUT_LABEL = "SIGN_OUT_LABEL";
//HOMES//
export const CURRENT_LOCATION_LABEL = "CURRENT_LOCATION_LABEL";
export const PRICE_LABEL = "PRICE_LABEL";
export const BEDROOMS_LABEL = "BEDROOMS_LABEL";
export const SIZE_LABEL = "SIZE_LABEL";
export const FULL_BATH_LABEL = "FULL_BATH_LABEL";
export const HOME_SIZE_LABEL = "HOME_SIZE_LABEL";
export const LOT_SIZE_LABEL = "LOT_SIZE_LABEL";
export const YEAR_BUILT_LABEL = "YEAR_BUILT_LABEL";
export const ORDER_BY_LABEL = "ORDER_BY_LABEL";
export const PAYMENT_LABEL = "PAYMENT_LABEL";
export const CASH_LABEL = "CASH_LABEL";
export const FILTERS_LABEL = "FILTERS_LABEL";
export const BATHROOMS_LABEL = "BATHROOMS_LABEL";
export const HOME_TYPE_LABEL = "HOME_TYPE_LABEL";
export const CONDOMINIUM_LABEL = "CONDOMINIUM_LABEL";
export const SINGLE_FAMILY_LABEL = "SINGLE_FAMILY_LABEL";
export const MOBILE_HOME_LABEL = "MOBILE_HOME_LABEL";
export const TOWNHOUSE_LABEL = "TOWNHOUSE_LABEL";
export const SEARCH_HOMES_LABEL = "SEARCH_HOMES_LABEL";
export const RESET_LABEL = "RESET_LABEL";
export const SEARCH_LABEL = "SEARCH_LABEL";
export const MAX_LABEL = "MAX_LABEL";
export const MIN_LABEL = "MIN_LABEL";
export const LIVE_SEARCH_LABEL = "LIVE_SEARCH_LABEL";
export const NO_LOCATIONS_LABEL = "NO_LOCATIONS_LABEL";
export const ANY_LABEL = "ANY_LABEL";
export const NEXT_LABEL = "NEXT_LABEL";
export const SEARCH_RESULT_LABEL = "SEARCH_RESULT_LABEL";
//PROPERTY_CARD//
export const NEW_CONSTRUCTION_LABEL = "NEW_CONSTRUCTION_LABEL";
export const PENDING_LABEL = "PENDING_LABEL";
export const UNDER_CONTRACT_LABEL = "UNDER_CONTRACT_LABEL";
export const COMING_SOON_LABEL = "COMING_SOON_LABEL";
export const FOR_SALE_LABEL = "FOR_SALE_LABEL";
export const NO_INFO_LABEL = "NO_INFO_LABEL";
export const UNKNOWN_ADDRESS_LABEL = "UNKNOWN_ADDRESS_LABEL";
export const UNKNOWN_CITY_LABEL = "UNKNOWN_CITY_LABEL";
export const UNKNOWN_STATE_LABEL = "UNKNOWN_STATE_LABEL";
export const UNKNOWN_ZIP_CODE_LABEL = "UNKNOWN_ZIP_CODE_LABEL";
export const NOTE_LABEL = "NOTE_LABEL";
export const HOA_FEE_LABEL = "HOA_FEE_LABEL";
export const TAXES_LABEL = "TAXES_LABEL";
export const MONTHLY_PAYMENT_LABEL = "MONTHLY_PAYMENT_LABEL";
export const CASH_NEEDED_LABEL = "CASH_NEEDED_LABEL";
export const LEARN_MORE_LABEL = "LEARN_MORE_LABEL";
export const ASSOCIATION_FEE_LABEL = "ASSOCIATION_FEE_LABEL";
export const PAYMENT_NOT_INCLUDE_LABEL = "PAYMENT_NOT_INCLUDE";
export const INSURANCE_LABEL = "INSURANCE_LABEL";
export const PROPERTY_TAX_LABEL = "PROPERTY_TAX_LABEL";
export const MORTGAGE_LABEL = "MORTGAGE_LABEL";
//FAVORITES
export const VIEW_MORE_FAV_CARD_LABEL = "VIEW_MORE_FAV_CARD_LABEL";
export const COMPARE_LABEL = "COMPARE_LABEL";
export const CANCEL_LABEL = "CANCEL_LABEL";
export const SAVED_HOMES_LABEL = "SAVED_HOMES_LABEL";
export const TAP_HOMES_LABEL = "TAP_HOMES_LABEL";
export const SELECTED_LABEL = "SELECTED_LABEL";
export const SELECTED_TO_COMPARE_LABEL = "SELECTED_TO_COMPARE_LABEL";
//PROP COMPARISON
export const STREET_ADDRESS_LABEL = "STREET_ADDRESS_LABEL";
export const STATUS_LABEL = "STATUS_LABEL";
export const CITY_LABEL = "CITY_LABEL";
export const STATE_LABEL = "STATE_LABEL";
export const ZIPCODE_LABEL = "ZIPCODE_LABEL";
export const LISTING_PRICE_LABEL = "LISTING_PRICE_LABEL";
export const SAVED_OFFER_PRICE_LABEL = "SAVED_OFFER_PRICE_LABEL";
export const SELLER_REBATE_LABEL = "SELLER_REBATE_LABEL";
export const DOWN_PAYMENT_LABEL = "DOWN_PAYMENT_LABEL";
export const ANNUAL_TAX_AMOUNT_LABEL = "ANNUAL_TAX_AMOUNT_LABEL";
export const INVESTMENT_LABEL = "INVESTMENT_LABEL";
export const HALF_BATHROOMS_LABEL = "HALF_BATHROOMS_LABEL";
export const SQUARE_FEET_LABEL = "SQUARE_FEET_LABEL";
export const MONTHLY_LABEL = "MONTHLY_LABEL";
export const CLOSE_LABEL = "CLOSE_LABEL";
export const HOME_COMPARISON_LABEL = "HOME_COMPARISON_LABEL";
//IMPORT PROP
export const IMPORT_PROPERTY_LABEL = "IMPORT_PROPERTY_LABEL";
export const SEARCH_AND_IMPORT_PROP_LABEL = "SEARCH_AND_IMPORT_PROP_LABEL";
//SETTINGS
export const TERMS_OF_USE_LABEL = "TERMS_OF_USE_LABEL";
export const PRIVACY_POLICY_LABEL = "PRIVACY_POLICY_LABEL";
export const LOG_IN_LABEL = "LOG_IN_LABEL";
//PROFILE
export const PERSONAL_INFO_LABEL = "PERSONAL_INFO_LABEL";
export const NAME_LABEL = "NAME_LABEL";
export const EMAIL_LABEL = "EMAIL_LABEL";
export const SIGN_UP_LABEL = "SIGN_UP_LABEL";
export const COMPANY_LABEL = "COMPANY_LABEL";
export const PHONE_LABEL = "PHONE_LABEL";
export const LOAN_ORIGINATOR_PERSONAL_INFO_LABEL = "LOAN_ORIGINATOR_PERSONAL_INFO_LABEL";
export const ADD_LOAN_ORIGINATOR_LABEL = "ADD_LOAN_ORIGINATOR_LABEL";
export const CHANGE_MLO_LABEL = "CHANGE_MLO_LABEL";
export const REMOVE_MLO_LABEL = "REMOVE_MLO_LABEL";
export const INVESTMENT_PROFILE_LABEL = "INVESTMENT_PROFILE_LABEL";
export const PAYMENT_LIMIT_LABEL = "PAYMENT_LIMIT_LABEL";
export const FUND_LIMIT_LABEL = "FUND_LIMIT_LABEL";
export const SAVE_CHANGES_LABEL = "SAVE_CHANGES_LABEL";
export const GO_BACK_TO_SEARCH_LABEL = "GO_BACK_TO_SEARCH_LABEL";
export const MISSING_FIRST_NAME_LABEL = "MISSING_FIRST_NAME_LABEL";
export const MISSING_LAST_NAME_LABEL = "MISSING_LAST_NAME_LABEL";
export const MISSING_EMAIL_LABEL = "MISSING_EMAIL_LABEL";
export const LOAN_ORIGINATOR_LABEL = "LOAN_ORIGINATOR_LABEL";
export const CHANGE_LOAN_ORIGINATOR_LABEL = "CHANGE_LOAN_ORIGINATOR_LABEL";
export const CONFIRM_LOAN_ORIGINATOR_LABEL = "CONFIRM_LOAN_ORIGINATOR_LABEL";
export const LOAN_OFFICER_NAME_LABEL = "LOAN_OFFICER_NAME_LABEL";
export const LOAN_OFFICER_EMAIL_LABEL = "LOAN_OFFICER_EMAIL_LABEL";
export const LOAN_OFFICER_LABEL = "LOAN_OFFICER_LABEL";
export const CONFIRM_LABEL = "CONFIRM_LABEL";
export const CONFIRMATION_LABEL = "CONFIRMATION_LABEL";
export const CREATE_INVESTMENT_PROFILE_LABEL = "CREATE_INVESTMENT_PROFILE_LABEL";
export const LOAN_INFORMATION_LABEL = "LOAN_INFORMATION_LABEL";
export const LOAN_TYPE_LABEL = "LOAN_TYPE_LABEL";
export const TERM_LABEL = "TERM_LABEL";
export const YEARS_LABEL = "YEARS_LABEL";
//LOADING
export const LOADING_LABEL = "LOADING_LABEL";
//PROP DETAIL
export const SHARE_LABEL = "SHARE_LABEL";
export const SAVE_LABEL = "SAVE_LABEL";
export const QUALIFICATION_LABEL = "QUALIFICATION_LABEL";
export const OFFER_PRO_LABEL = "OFFER_PRO_LABEL";
export const REMOVE_LABEL = "REMOVE_LABEL";
export const VIEW_IMAGE_LABEL = "VIEW_IMAGE_LABEL";
export const VIEW_ALL_IMAGES_LABEL = "VIEW_ALL_IMAGES_LABEL";
export const PHOTOS_LABEL = "PHOTOS_LABEL";
export const NO_PHOTOS_LABEL = "NO_PHOTOS_LABEL";
export const BUILT_IN_LABEL = "BUILT_IN_LABEL";
export const MORTGAGE_INS_LABEL = "MORTGAGE_INS_LABEL";
export const ABOUT_THIS_HOME_LABEL = "ABOUT_THIS_HOME_LABEL";
export const LISTED_BY_LABEL = "LISTED_BY_LABEL";
//LOGIN
export const WELCOME_BACK_LABEL = "WELCOME_BACK_LABEL";
export const PASSWORD_LABEL = "PASSWORD_LABEL";
export const FORGOT_YOUR_PASSWORD_LABEL = "FORGOT_YOUR_PASSWORD_LABEL";
export const DO_NOT_HAVE_ACCOUNT_LABEL = "DO_NOT_HAVE_ACCOUNT_LABEL";
export const GO_BACK_LABEL = "GO_BACK_LABEL";
//FORGOT PASS
export const FORGOT_PASSWORD_LABEL = "FORGOT_PASSWORD_LABEL";
export const SEND_EMAIL_LABEL = "SEND_EMAIL_LABEL";
export const BACK_TO_LABEL = "BACK_TO_LABEL";
//CREATE ACCOUNT
export const CREATE_ACCOUNT_LABEL = "CREATE_ACCOUNT_LABEL";
export const FIRST_NAME_LABEL = "FIRST_NAME_LABEL";
export const LAST_NAME_LABEL = "LAST_NAME_LABEL";
export const ALREADY_HAVE_AN_ACCOUNT_LABEL = "ALREADY_HAVE_AN_ACCOUNT_LABEL";
export const NO_WHITE_SPACES_LABEL = "NO_WHITE_SPACES_LABEL";
export const EIGHT_CHARACTERS_LABEL = "EIGHT_CHARACTERS_LABEL";
export const MIX_PASS_CHARACTERS_LABEL = "MIX_PASS_CHARACTERS_LABEL";
export const FULL_NAME_LABEL = "FULL_NAME_LABEL";
//VALIDATION
export const EXPIRES_IN_LABEL = "EXPIRES_IN_LABEL";
export const VERIFY_LABEL = "VERIFY_LABEL";
export const RESEND_LABEL = "RESEND_LABEL";
//SELECT LOAN
export const PRE_APPROVED_LABEL = "PRE_APPROVED_LABEL";
export const NOT_APPROVED_LABEL = "NOT_APPROVED_LABEL";
//INV PROFILE
export const CHOOSE_LABEL = "CHOOSE_LABEL";
export const PADZILLY_CONNECTED_LENDER_LABEL = "PADZILLY_CONNECTED_LENDER_LABEL";
export const TELL_US_HOME_SEARCH_GOALS_LABEL = "TELL_US_HOME_SEARCH_GOALS_LABEL";
export const DESIRED_MORTGAGE_LABEL = "DESIRED_MORTGAGE_LABEL";
export const AVAILABLE_FUNDS_LABEL = "AVAILABLE_FUNDS_LABEL";
export const CONTINUE_LABEL = "CONTINUE_LABEL";
//SWIFTMATCH
export const SWIFTMATCH_READY_LABEL = "SWIFTMATCH_READY_LABEL";
export const MAXIMUM_PAYMENT_LIMIT_LABEL = "MAXIMUM_PAYMENT_LIMIT_LABEL";
export const SAVINGS_AND_FUNDS_LIMIT_LABEL = "SAVINGS_AND_FUNDS_LIMIT_LABEL";
export const PRE_APPROVED_LOAN_PROFILE_LABEL = "PRE_APPROVED_LOAN_PROFILE_LABEL";
export const TEMPORARY_LOAN_PROFILE_LABEL = "TEMPORARY_LOAN_PROFILE_LABEL";
export const CONVENTIONAL_LABEL = "CONVENTIONAL_LABEL";
export const ALTERNATE_LOAN_PROFILE = "ALTERNATE_LOAN_PROFILE";
export const INCREASE_DOWN_PAYMENT_LABEL = "INCREASE_DOWN_PAYMENT_LABEL";
export const LETS_GO_LABEL = "LETS_GO_LABEL";
export const START_HOME_MATCHING_LABEL = "START_HOME_MATCHING_LABEL";
//LOAN OFFICER
export const ENTER_LOAN_OFFICER_INFO_LABEL = "ENTER_LOAN_OFFICER_INFO_LABEL";
export const LOAN_OFFICER_PHONE_LABEL = "LOAN_OFFICER_PHONE_LABEL";
export const CONFIRM_YOUR_LENDER_LABEL = "CONFIRM_YOUR_LENDER_LABEL";
export const IS_MY_LENDER_LABEL = "IS_MY_LENDER_LABEL";
export const IS_NOT_MY_LENDER_LABEL = "IS_NOT_MY_LENDER_LABEL";
//RESET PASS
export const CHANGE_PASSWORD_LABEL = "CHANGE_PASSWORD_LABEL";
export const ENTER_NEW_PASSWORD_LABEL = "ENTER_NEW_PASSWORD_LABEL";
export const CONFIRM_PASSWORD_LABEL = "CONFIRM_PASSWORD_LABEL";
export const SUBMIT_LABEL = "SUBMIT_LABEL";
//404
export const PAGE_NOT_FOUND_LABEL = "PAGE_NOT_FOUND_LABEL";
//NO PROPERTIES
export const NO_PROPERTIES_FOUND_LABEL = "NO_PROPERTIES_FOUND_LABEL";
export const TRY_THE_FOLOWING_LABEL = "TRY_THE_FOLOWING_LABEL";
export const HERE_LABEL = "HERE_LABEL";
//HOME SEARCH GOALS
export const HOME_SEARCH_GOALS_LABEL = "HOME_SEARCH_GOALS_LABEL";
export const START_SEARCHING_LABEL = "START_SEARCHING_LABEL";
//FAV
export const ACCOUNT_REQUIRED_LABEL = "ACCOUNT_REQUIRED_LABEL";
export const NO_FAVORITE_PROPERTIES_YET_LABEL = "NO_FAVORITE_PROPERTIES_YET_LABEL";
//SWIFTMATCH PROFILE
export const SWIFTMATCH_PROFILE_LABEL = "SWIFTMATCH_PROFILE_LABEL";
//OFFER PRO
export const RESET_ALL_LABEL = "RESET_ALL_LABEL";
export const OFFER_PRICE_LABEL = "OFFER_PRICE_LABEL";
export const PRINT_OFFER_LETTERS_LABEL = "PRINT_OFFER_LETTERS_LABEL";
export const WITH_THE_ABOVE_TERMS_LABEL = "WITH_THE_ABOVE_TERMS_LABEL";
export const PADZILLY_OFFER_PRO_LABEL = "PADZILLY_OFFER_PRO_LABEL";
export const BACK_TO_PROP_DETAILS_LABEL = "BACK_TO_PROP_DETAILS_LABEL";
//SNACK BAR FAV
export const VIEW_LABEL = "VIEW_LABEL";
export const ADDED_TO_FAV_LABEL = "ADDED_TO_FAV_LABEL";
//ERROR LABELS
export const FUNDS_ERROR_LABEL = "FUNDS_ERROR_LABEL";
export const MORTGAGE_ERROR_LABEL = "MORTGAGE_ERROR_LABEL";
export const IS_REQUIRED_ERROR_LABEL = "IS_REQUIRED_ERROR_LABEL";
export const URL_LABEL = "URL_LABEL";
export const NAME_ERROR_LABEL = "NAME_ERROR_LABEL";
export const EMAIL_ERROR_LABEL = "EMAIL_ERROR_LABEL";
export const PASSWORD_ERROR_LABEL = "PASSWORD_ERROR_LABEL";
export const CONFIRM_PASSWORD_ERROR_LABEL = "CONFIRM_PASSWORD_ERROR_LABEL";
export const LAST_ERROR_LABEL = "LAST_ERROR_LABEL";
export const PHONE_NUMBER_ERROR_LABEL = "PHONE_NUMBER_ERROR_LABEL";
//LANDING PAGE
export const LENDERS_LABEL = "LENDERS_LABEL";
export const HOMEBUYING_EVOLVED_LABEL = "HOMEBUYING_EVOLVED_LABEL";
export const LOOKING_FOR_PROP_LABEL = "LOOKING_FOR_PROP_LABEL";
export const HOMES_FOR_YOU_LABEL = "HOMES_FOR_YOU_LABEL";
export const NO_PROPERTIES_FOR_YOU_LABEL = "NO_PROPERTIES_FOR_YOU_LABEL";
export const START_NEW_SEARCH_LABEL = "START_NEW_SEARCH_LABEL";
export const VIEW_MORE_PROP_IN_LABEL = "VIEW_MORE_PROP_IN_LABEL";
export const VIEW_FAVORITES_LABEL = "VIEW_FAVORITES_LABEL";
export const UNKNOW_PROPERTY_TYPE_LABEL = "UNKNOW_PROPERTY_TYPE_LABEL";
export const YOU_CAN_CONTINUE_SEARCHING_LABEL = "YOU_CAN_CONTINUE_SEARCHING_LABEL";
export const INFO_TITLE_ONE_LABEL = "INFO_TITLE_ONE_LABEL";
export const OFFER_LIKE_A_PRO_LABEL = "OFFER_LIKE_A_PRO_LABEL";
export const LENDER_APPROVAL_LABEL = "LENDER_APPROVAL_LABEL";
export const ALL_RIGHTS_RESERVED_LABEL = "ALL_RIGHTS_RESERVED_LABEL";
export const GAIN_THE_UPPER_HAND_LABEL = "GAIN_THE_UPPER_HAND_LABEL";
export const SEARCH_PRO_LANDING_LABEL = "SEARCH_PRO_LANDING_LABEL";
export const OFFER_PRO_LANDING_LABEL = "OFFER_PRO_LANDING_LABEL";
export const LOAN_LINK_LABEL = "LOAN_LINK_LABEL";
export const PRO_LABEL = "PRO_LABEL";
export const LINK_LABEL = "LINK_LABEL";
export const AND_LABEL = "AND_LABEL";
export const FOLLOW_US_LABEL = "FOLLOW_US_LABEL";
export const IMPORTANT_LINKS_LABEL = "IMPORTANT_LINKS_LABEL";
export const ABOUT_LABEL = "ABOUT_LABEL";
export const INVESTORS_LABEL = "INVESTORS_LABEL";
export const CONTACT_LABEL = "CONTACT_LABEL";
//SEARCH PRO
export const PADZILLY_SEARCH_PRO_LABEL = "PADZILLY_SEARCH_PRO_LABEL";
export const MIN_DOWN_PAYMENT_CONV_LABEL = "MIN_DOWN_PAYMENT_CONV_LABEL";
export const MIN_DOWN_PAYMENT_FHA_LABEL = "MIN_DOWN_PAYMENT_FHA_LABEL";
export const CONV_DOWN_PAYMENT_MESSAGE_LABEL = "CONV_DOWN_PAYMENT_MESSAGE_LABEL";
export const FHA_DOWN_PAYMENT_MESSAGE_LABEL = "FHA_DOWN_PAYMENT_MESSAGE_LABEL";
export const CONV_DOWN_PAYMENT_MAX_MESSAGE_LABEL = "CONV_DOWN_PAYMENT_MAX_MESSAGE_LABEL";
export const FHA_DOWN_PAYMENT_MAX_MESSAGE_LABEL = "FHA_DOWN_PAYMENT_MAX_MESSAGE_LABEL";
export const DOWN_PAYMENT_VALUES_SAVED_LABEL = "DOWN_PAYMENT_VALUES_SAVED_LABEL";
export const SEARCH_PRO_LABEL = "SEARCH_PRO_LABEL";
export const DONT_SHOW_VIDEO_LABEL = "DONT_SHOW_VIDEO_LABEL";
//MAP
export const MAP_LABEL = "MAP_LABEL";
export const LIST_LABEL = "LIST_LABEL";
//SCHOOLS
export const NEARBY_SCHOOLS_LABEL = "NEARBY_SCHOOLS_LABEL";
export const PRIVATE_LABEL = "PRIVATE_LABEL";
export const ELEMENTARY_LABEL = "ELEMENTARY_LABEL";
export const MIDDLE_LABEL = "MIDDLE_LABEL";
export const HIGH_LABEL = "HIGH_LABEL";
export const DISTANCE_LABEL = "DISTANCE_LABEL";
export const MORE_DETAILS_LABEL = "MORE_DETAILS_LABEL";
export const HIDE_LABEL = "HIDE_LABEL";
export const SCHOOL_WEBSITE_LABEL = "SCHOOL_WEBSITE_LABEL";
export const AWAY_LABEL = "AWAY_LABEL";