import BaseDialog from "../../BaseDialog/BaseDialog";
import BaseDialogBody from "../../BaseDialog/BaseDialogBody";
import BaseDialogFooter from "../../BaseDialog/BaseDialogFooter";
import BaseDialogHeader from "../../BaseDialog/BaseDialogHeader";
import BaseBtn from "../../BaseComponents/BaseBtn";
import { Box } from "@mui/material";
import { useTranslation } from 'react-i18next';
import {
    CONFIRM_LABEL,
    CONFIRMATION_LABEL
} from "../../../i18n/i18nLabel";

export default function ConfirmationDialog(props) {

    const { t } = useTranslation();

    return (
        <BaseDialog   {...props}>
            <BaseDialogHeader title={props.title ?? t(CONFIRMATION_LABEL)}
                close={() => { props.onClose(); }}
            />
            <BaseDialogBody>
                <Box sx={{ padding: "14px" }}>
                    {props.children}
                </Box>
            </BaseDialogBody>
            <BaseDialogFooter>
                <BaseBtn
                    close
                    onClick={() => { props.onClose(); }}
                ></BaseBtn>
                <BaseBtn
                    label={props.confirmationLabel ?? t(CONFIRM_LABEL)}
                    onClick={() => { props.confirmation(); }}
                />
            </BaseDialogFooter>
        </BaseDialog>
    );
}