import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
    useState,
    useEffect
} from "react";
import { useTranslation } from 'react-i18next';
import { GETTING_PROPS_TEXT, LOADING_PROPERTIES_EIGHT_TEXT, LOADING_PROPERTIES_FIVE_TEXT, LOADING_PROPERTIES_FOUR_TEXT, LOADING_PROPERTIES_NINE_TEXT, LOADING_PROPERTIES_ONE_TEXT, LOADING_PROPERTIES_SEVEN_TEXT, LOADING_PROPERTIES_SIX_TEXT, LOADING_PROPERTIES_THREE_TEXT, LOADING_PROPERTIES_TWO_TEXT } from '../../i18n/i18nText';
import { useStore } from '../../hooks/store/store';
import { GET_SEARCH_PREFERENCE } from '../../constants/store/getters';
import { LIST, MAP } from '../../constants/constants';

export default function LoadingProperties(props) {

    const { state } = useStore();

    const { t } = useTranslation();

    const [mapHeight, setMapHeight] = useState(window.innerHeight - (145));

    const theme = useTheme();

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down("sm"));

    const skeletons = [];

    const loadingTexts = [
        t(LOADING_PROPERTIES_ONE_TEXT),
        t(LOADING_PROPERTIES_TWO_TEXT),
        t(LOADING_PROPERTIES_THREE_TEXT),
        t(LOADING_PROPERTIES_FOUR_TEXT),
        t(LOADING_PROPERTIES_FIVE_TEXT),
        t(LOADING_PROPERTIES_SIX_TEXT),
        t(LOADING_PROPERTIES_SEVEN_TEXT),
        t(LOADING_PROPERTIES_EIGHT_TEXT),
        t(LOADING_PROPERTIES_NINE_TEXT)
    ]

    const randomNumber = Math.floor(Math.random() * 9);

    useEffect(() => {
        const handleResize = () => {
            setMapHeight(window.innerHeight - (145));
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [props.filters]);

    for (let i = 0; i < 15; i++) {
        skeletons.push(
            <Card key={i}>
                <CardMedia
                    sx={{ height: 140 }}
                >
                    <Skeleton variant="rectangular" style={{ height: "100%", width: "100%" }} />
                </CardMedia>
                <CardContent>
                    <Skeleton variant="rectangular" style={{ height: "40px", width: "100%" }} />
                    <Skeleton variant="rectangular" style={{ height: "80px", width: "100%", marginTop: "10px" }} />
                    <Skeleton variant="rounded" style={{ height: "40px", width: "100%", marginTop: "10px" }} />
                    <Skeleton variant="rounded" style={{ height: "40px", width: "100%", marginTop: "5px" }} />
                </CardContent>
            </Card>
        );
    }

    window.scrollTo(0, 0);

    return (
        <Box>
            <Box sx={{ height: "145px" }} />
            <Box sx={{
                width: "100%",
                display: "flex",
                flexDirection: isMdOrSmaller ? "column" : "row"
            }}>
                {/*state[GET_SEARCH_PREFERENCE] === MAP
                    ? <Box sx={{
                        width: "45%",
                        height: mapHeight + "px",
                        display: isMdOrSmaller ? "none" : ""
                    }}>
                        <Skeleton variant="rectangular" style={{ height: "100%", width: "100%" }} />
                    </Box>
                    : null
                */}
                <Box sx={{
                    paddingTop: "3px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    width: "100%",
                    //width: isMdOrSmaller || state[GET_SEARCH_PREFERENCE] !== MAP ? "100%" : "55%",
                    height: mapHeight + "px"
                }}
                >
                    <Box
                        sx={(theme) => (state[GET_SEARCH_PREFERENCE] === MAP
                            ? {
                                /*display: "grid",
                                gap: ".6rem",
                                gridAutoRows: "1fr",
                                [theme.breakpoints.between("1", "950")]: {
                                    gridTemplateColumns: "repeat(1, 1fr)",
                                },
                                "@media (min-width: 950px)": {
                                    gridTemplateColumns: "repeat(2, 1fr)",
                                },
                                "@media (min-width: 1600px)": {
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                },*/
                                display: "grid",
                                gap: ".6rem",
                                gridAutoRows: "1fr",
                                [theme.breakpoints.between("1", "570")]: {
                                    gridTemplateColumns: "repeat(1, 1fr)",
                                },
                                [theme.breakpoints.between("570", "950")]: {
                                    gridTemplateColumns: "repeat(2, 1fr)",
                                },
                                [theme.breakpoints.between("950", "1220")]: {
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                },
                                [theme.breakpoints.between("1220", "1520")]: {
                                    gridTemplateColumns: "repeat(4, 1fr)",
                                },
                                "@media (min-width: 1520px)": {
                                    gridTemplateColumns: "repeat(5, 1fr)",
                                },
                            }
                            : {
                                display: "grid",
                                gap: ".6rem",
                                gridAutoRows: "1fr",
                                [theme.breakpoints.between("1", "570")]: {
                                    gridTemplateColumns: "repeat(1, 1fr)",
                                },
                                [theme.breakpoints.between("570", "950")]: {
                                    gridTemplateColumns: "repeat(2, 1fr)",
                                },
                                [theme.breakpoints.between("950", "1220")]: {
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                },
                                [theme.breakpoints.between("1220", "1520")]: {
                                    gridTemplateColumns: "repeat(4, 1fr)",
                                },
                                "@media (min-width: 1520px)": {
                                    gridTemplateColumns: "repeat(5, 1fr)",
                                },
                            })}
                    >
                        <>
                            {skeletons}
                            <Backdrop
                                sx={{
                                    zIndex: (theme) => theme.zIndex.drawer + 1,
                                    background: "#D4D8DB4A",
                                }}
                                open={true}
                            >
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "10px" }}>
                                    <CircularProgress style={{ color: "#F1592A" }} size={40} />
                                    <p style={{ textAlign: "center" }}>{loadingTexts[randomNumber]}</p>
                                </Box>
                            </Backdrop>
                        </>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}