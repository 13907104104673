import { Box } from "@mui/material"
import BaseBtn from "../../BaseComponents/BaseBtn";
import {
    useHistory
} from "react-router-dom";
import { HOME_ROUTE } from "../../../constants/routes";
import { useTranslation } from 'react-i18next';
import {
    GO_BACK_TO_SEARCH_LABEL,
    NO_FAVORITE_PROPERTIES_YET_LABEL
} from "../../../i18n/i18nLabel";
import {
    EXPLORE_AND_SAVE_PROPERTIES_TEXT,
    NOT_SAVED_PROPERTIES_TEXT
} from "../../../i18n/i18nText";
import LandingPageFooter from "../../LandingPage/LandingPageFooter";

export default function NoProperties() {

    const { t } = useTranslation();

    const history = useHistory();

    const handleGoBack = () => {
        history.push(HOME_ROUTE)
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between"
            }}
        >
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "54px",
                marginBottom: "40px"
            }}>
                <p
                    style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: "1.5rem",
                        marginTop: "0px"
                    }}
                >
                    {t(NO_FAVORITE_PROPERTIES_YET_LABEL)}
                </p>
                <p style={{ margin: "0px" }}>
                    {t(NOT_SAVED_PROPERTIES_TEXT)}
                </p>
                <p style={{ marginTop: "0px" }}>
                    {t(EXPLORE_AND_SAVE_PROPERTIES_TEXT)}
                </p>
                <BaseBtn
                    label={t(GO_BACK_TO_SEARCH_LABEL)}
                    variant="outlined"
                    onClick={() => { handleGoBack(); }}
                />
            </Box>
            <Box>
                <LandingPageFooter />
            </Box>
        </Box>
    );
}