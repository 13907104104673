import { useEffect, useState } from "react";

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay ?? 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const Patterns = {
  YEAR: "{{YEAR}}",
  DATE_YEAR: "{{DATE_YEAR}}",
  DATE_TIME: "{{DATE-TIME}}",
  DATE: "{{Date}}",
  BROKERAGE: "{{BROKERAGE}}",
};

export function replacePatterns(text) {
  const currentYear = String(new Date().getFullYear());
  const currentDateTime = new Date().toLocaleString();
  const currentDate = new Date().toLocaleDateString();
  // const brokerage !TODO

  var result = text
    .replaceAll(Patterns.YEAR, currentYear)
    .replaceAll(Patterns.DATE_YEAR, currentYear)
    .replaceAll(Patterns.DATE, currentDate)
    .replaceAll(Patterns.DATE_TIME, currentDateTime);

  return result;
};
