import { Box, Link } from "@mui/material";
import BaseBtn from "../BaseComponents/BaseBtn";
import { useState, useRef } from "react";
import { HELP_URL, CONNECTED_LENDER } from "../../constants/urls";
import HelpIcon from '@mui/icons-material/Help';
import BaseCurrency from "../BaseComponents/BaseCurrency";
import { validations } from "../../utils/validations";
import { createInvestmentProfile } from "../../services/investmentProfileServices";
import BaseAlert from "../BaseComponents/BaseAlert";
import { useError } from "../../hooks/handleError";
import { handleError } from "../../utils/functions";
import { useStore } from "../../hooks/store/store";
import {
    GET_BORROWER_INVESTMENT_PROFILE,
    GET_FUNDS_LIMIT,
    GET_INVESTMENT_PROFILE,
    GET_LOAN_TYPE,
    GET_PAYMENT_LIMIT,
    GET_PROFILE_TOKEN
} from "../../constants/store/getters";
import { NOT_APPROVED } from "../../constants/constants";
import CheckMoney from "../Images/CheckMoney";
import Grow from '@mui/material/Grow';
import { useTranslation } from 'react-i18next';
import {
    CREATE_SWIFTMATCH_PROFILE_TEXT,
    FOR_BEST_RESULTS_TEXT,
    IN_THE_MEANTIME_TEXT,
    WE_WILL_REPLACE_TEXT
} from "../../i18n/i18nText";
import {
    AVAILABLE_FUNDS_LABEL,
    CHOOSE_LABEL,
    CONTINUE_LABEL,
    DESIRED_MORTGAGE_LABEL,
    FUNDS_ERROR_LABEL,
    MORTGAGE_ERROR_LABEL,
    PADZILLY_CONNECTED_LENDER_LABEL,
    TELL_US_HOME_SEARCH_GOALS_LABEL
} from "../../i18n/i18nLabel";

export default function InvProfileValues() {

    const { t } = useTranslation();

    const { state, dispatch } = useStore();

    const payment_limit = localStorage.getItem("payment_limit") ?? state[GET_PAYMENT_LIMIT];

    const funds_limit = localStorage.getItem("fund_limit") ?? state[GET_FUNDS_LIMIT];

    const PAYMENT_LIMIT = "payment_limit";

    const FUNDS_LIMIT = "funds_limit";

    const [paymentLimit, setPaymentLimit] = useState(payment_limit);

    const [fundsLimit, setFundsLimit] = useState(funds_limit);

    const [errors, setErrors] = useState({});

    const activeErrors = useRef(false);

    const [saving, setSaving] = useState(false);

    const { error, setErrorMessage, clearError } = useError();

    const validationValues = [
        { validation: ["required", "isGreaterThanCero"], value: paymentLimit, key: "payment_limit" },
        { validation: ["required", "isGreaterThanCero"], value: fundsLimit, key: "funds_limit" },
        { validation: ["paymentLimitAndFundLimit"], value: [paymentLimit, fundsLimit], key: "payment_limit" },
    ];

    const openHelp = () => {
        window.open(HELP_URL, '_blank');
    };

    const showError = (errorValue, field) => {
        if (field === FUNDS_LIMIT && Array.isArray(errorValue) && errorValue.length > 0) {
            return t(FUNDS_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        if (field === PAYMENT_LIMIT && Array.isArray(errorValue) && errorValue.length > 0) {
            return t(MORTGAGE_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        return "";
    };

    const createInvProfile = async () => {
        if (!activeErrors.current) {
            try {
                setSaving(true);
                const payLimit = parseFloat(paymentLimit.replace(/,/g, ''));
                const fundLimit = parseFloat(fundsLimit.replace(/,/g, ''));
                if (state[GET_PROFILE_TOKEN]) {
                    const newInvProfile = await createInvestmentProfile(payLimit, fundLimit, state[GET_PROFILE_TOKEN]);
                    if (newInvProfile) {
                        dispatch({ action: GET_INVESTMENT_PROFILE, value: newInvProfile });
                        dispatch({ action: GET_PAYMENT_LIMIT, value: payLimit });
                        dispatch({ action: GET_FUNDS_LIMIT, value: fundLimit });
                        localStorage.setItem("borrower_investment_profile", true);
                        dispatch({ action: GET_BORROWER_INVESTMENT_PROFILE, value: true });
                        localStorage.removeItem("payment_limit");
                        localStorage.removeItem("fund_limit");
                    }

                } else {
                    setErrorMessage(handleError());
                }
            } catch (e) {
                setErrorMessage(handleError(e));
            } finally {
                setSaving(false);
            }
        }
    }

    return (
        <Grow
            in={true}
            {...({ timeout: 500 })}
        >
            <Box sx={{
                display: "flex", flexDirection: "column"
            }}>
                <BaseAlert alert={error} onClose={() => { clearError(); }} />
                {state[GET_LOAN_TYPE] === NOT_APPROVED
                    ? <Box sx={{
                        display: "flex", flexDirection: "column"
                    }}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <CheckMoney maxwidth="30" maxheight="30" />
                            <p style={{ fontWeight: "bold", textAlign: "center", margin: "0px" }}>
                                {t(CREATE_SWIFTMATCH_PROFILE_TEXT)}
                            </p>
                        </Box>
                        <p style={{ marginTop: "0px", textAlign: "center" }}>
                            {t(FOR_BEST_RESULTS_TEXT)}
                        </p>
                        <p style={{ textAlign: "center" }}><strong>{t(CHOOSE_LABEL)} </strong>
                            <Link
                                color="secondary"
                                onClick={() => window.open(CONNECTED_LENDER, '_blank')}
                                className="cursor"
                            >
                                {t(PADZILLY_CONNECTED_LENDER_LABEL)}
                            </Link>
                        </p>
                        <h3 style={{ textAlign: "center" }}>{t(TELL_US_HOME_SEARCH_GOALS_LABEL)}</h3>
                    </Box>
                    :
                    <Box sx={{
                        display: "flex", flexDirection: "column"
                    }}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <CheckMoney maxwidth="30" maxheight="30" />

                            <p style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                fontSize: "1.1rem",
                                margin: "0px"
                            }}>
                                {t(IN_THE_MEANTIME_TEXT)}
                            </p>
                        </Box>
                        <p style={{ fontSize: "1.1rem", marginBottom: "0px", fontWeight: "bold" }}>
                            {t(TELL_US_HOME_SEARCH_GOALS_LABEL)}
                        </p>
                        <p style={{ marginTop: "0px" }}>
                            {t(WE_WILL_REPLACE_TEXT)}
                        </p>
                    </Box>
                }
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px"
                }}>
                    <label>{t(DESIRED_MORTGAGE_LABEL)}</label>
                    <BaseCurrency
                        value={paymentLimit}
                        error={showError(errors.payment_limit, PAYMENT_LIMIT)}
                        onInputChange={(value) => {
                            validationValues[0].value = value;
                            validationValues[2].value = [value, fundsLimit];
                            setPaymentLimit(value);
                            setErrors(validations(validationValues));
                        }}
                    />
                    <br />
                    <div className="displayFlexRow">
                        <label>{t(AVAILABLE_FUNDS_LABEL)}</label>
                        <HelpIcon
                            style={{ marginLeft: "14px" }}
                            className="cursor"
                            fontSize="small"
                            onClick={openHelp}
                        />
                    </div>
                    <BaseCurrency
                        value={fundsLimit}
                        error={showError(errors.funds_limit, FUNDS_LIMIT)}
                        onInputChange={(value) => {
                            validationValues[1].value = value;
                            validationValues[2].value = [paymentLimit, value];
                            setFundsLimit(value);
                            setErrors(validations(validationValues));
                        }}

                    />
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <BaseBtn
                            label={t(CONTINUE_LABEL)}
                            style={{ width: "300px", marginTop: "10px" }}
                            onClick={() => {
                                const eList = validations(validationValues);
                                setErrors(eList);
                                (Object.keys(eList).length === 0)
                                    ? activeErrors.current = false
                                    : activeErrors.current = true
                                createInvProfile();
                            }}
                            loading={saving}
                        />
                    </Box>
                </Box>
            </Box>
        </Grow>
    );
}