import { useStore } from "../../../hooks/store/store";
import { GET_FAVORITES } from "../../../constants/store/getters";
import SavedProperties from "./SavedProperties";
import NoProperties from "./NoProperties";

export default function FavoritesPage() {

    const { state } = useStore();

    return (
        state[GET_FAVORITES]?.length > 0
            ? <SavedProperties /> : <NoProperties />
    );
}