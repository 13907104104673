import * as GETTER_NAMES from "../../constants/store/getters";

export const GETTERS = {
    [GETTER_NAMES.GET_LANGUAGE]: null,
    [GETTER_NAMES.GET_PAGE]: null,
    [GETTER_NAMES.GET_LANDING_PAGE_ELEMENTS]: null,
    [GETTER_NAMES.GET_LOCATION]: null,
    [GETTER_NAMES.GET_LAST_SEARCH]: null,
    [GETTER_NAMES.GET_NEAR_PROPERTIES]: null,
    [GETTER_NAMES.GET_LAST_SEARCHED_PROPERTIES]: null,
    [GETTER_NAMES.GET_PROFILE_ID]: null,
    [GETTER_NAMES.GET_PROFILE]: null,
    [GETTER_NAMES.GET_PROFILE_TOKEN]: null,
    [GETTER_NAMES.GET_PROFILE_ROLE]: null,
    [GETTER_NAMES.GET_PROFILE_EMAIL]: null,
    [GETTER_NAMES.GET_PROFILE_VERIFIED]: null,
    [GETTER_NAMES.GET_PROFILE_FINISHED]: null,
    [GETTER_NAMES.GET_BORROWER_INVESTMENT_PROFILE]: null,
    [GETTER_NAMES.GET_INVESTMENT_PROFILE]: null,
    [GETTER_NAMES.GET_INVESTMENT_PROFILE_LOAN]: null,
    [GETTER_NAMES.GET_LOAN]: null,
    [GETTER_NAMES.GET_FUNDS_LIMIT]: null,
    [GETTER_NAMES.GET_PAYMENT_LIMIT]: null,
    [GETTER_NAMES.GET_LOAN_TYPE]: null,
    [GETTER_NAMES.GET_SEARCH_PRO_DOWN_PAYMENT]: null,
    [GETTER_NAMES.GET_CONVENTIONAL_DOWN_PAYMENT]: null,
    [GETTER_NAMES.GET_FHA_DOWN_PAYMENT]: null,
    [GETTER_NAMES.GET_OFFER_PRICE_CONVENTIONAL]: null,
    [GETTER_NAMES.GET_OFFER_PRICE_FHA]: null,
    [GETTER_NAMES.GET_SELLER_REBATE_CONVENTIONAL]: null,
    [GETTER_NAMES.GET_SELLER_REBATE_FHA]: null,
    [GETTER_NAMES.GET_PAGE_FAVORITES]: null,
    [GETTER_NAMES.GET_FAVORITES]: null,
    [GETTER_NAMES.GET_LAST_FAVORITE_RESULT]: null,
    [GETTER_NAMES.GET_LAST_INPUT_SEARCH]: null,
    [GETTER_NAMES.GET_LAST_SEARCH_RESULTS]: null,
    [GETTER_NAMES.GET_MLO_ID]: null,
    [GETTER_NAMES.GET_MLO_PROFILE]: null,
    [GETTER_NAMES.GET_NO_MLO]: null,
    [GETTER_NAMES.GET_MLO_PASSED]: null,
    [GETTER_NAMES.GET_EXISTENT_LOAN]: null,
    [GETTER_NAMES.GET_ORDER_BY]: null,
    [GETTER_NAMES.GET_OFFER_PRO_VALUES]: null,
    [GETTER_NAMES.GET_USER_ID_OTP]: null,
    [GETTER_NAMES.GET_SEARCH_PREFERENCE]: null,
    [GETTER_NAMES.GET_STORE_COMPLETE]: null,
}