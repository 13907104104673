import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import Background from '../Images/Background';
import { useTranslation } from 'react-i18next';
import { LOADING_LABEL } from '../../i18n/i18nLabel';

export default function BaseLoading(props) {

    const { t } = useTranslation();

    return (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            open={props.loading}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 0,
                    overflow: "hidden"
                }}
            >
                <Background />
            </Box>
            <Box
                sx={{
                    backgroundColor: '#98989845',
                    padding: '1rem',
                    borderRadius: '20px',
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <CircularProgress style={{ color: '#F1592A' }} size={60} />
                <p style={{ fontFamily: 'sans-serif' }}>{t(LOADING_LABEL)}</p>
            </Box>
        </Backdrop>
    );
}