import Verification from "./Verification";
import { useEffect, useState } from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useStore } from "../../hooks/store/store";
import {
    GET_PROFILE_ID,
    GET_PROFILE_VERIFIED,
    GET_USER_ID_OTP
} from "../../constants/store/getters";
import Padzilly from "../Images/Padzilly";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import BaseChangeLanguage from "../BaseComponents/BaseChangeLanguage";

export default function ForgotPasswordStepper() {

    const { state } = useStore();

    const theme = useTheme();

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const steps = ['1', '2', '3'];

    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        const checkStore = () => {
            //Verification Step
            if (state[GET_USER_ID_OTP] && state[GET_PROFILE_ID]) {
                setActiveStep(1);
                return;
            }
            //Change Password
            if (state[GET_PROFILE_VERIFIED]) {
                setActiveStep(2);
                return;
            }
        };
        checkStore();
    }, [state]);


    const StepContent = ({ activeStep }) => {
        switch (activeStep) {
            case 0:
                return <ForgotPassword />;
            case 1:
                return <Verification />;
            case 2:
                return <ResetPassword />;
            default:
                return null;
        }
    };

    return (
        <Box sx={{
            height: '100vh', display: 'flex'
        }}>
            <Padzilly
                style={{
                    flex: isMdOrSmaller ? 0 : 1,
                }}
            />
            <Box sx={{ width: isMdOrSmaller ? "100%" : "60%" }}>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: "100%",
                    alignItems: "center",
                    justifyContent: 'space-between',
                    padding: "2rem",
                    borderLeft: !isMdOrSmaller ? "solid #EB5A2D 5px" : "none",
                    height: "100%"
                }}>
                    {!isMdOrSmaller
                        ? <Stepper activeStep={activeStep} sx={{ width: "100%" }}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={index} completed={activeStep > index}>
                                        <StepLabel></StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        : null
                    }
                    <Box sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <StepContent
                            activeStep={activeStep}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
                        <BaseChangeLanguage />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}