import { Card, CardContent } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useStore } from "../../../hooks/store/store";
import {
    DOWN_PAYMENT_LABEL,
    LOAN_INFORMATION_LABEL,
    LOAN_TYPE_LABEL,
    TERM_LABEL,
    YEARS_LABEL
} from "../../../i18n/i18nLabel";
import { GET_INVESTMENT_PROFILE_LOAN } from "../../../constants/store/getters";

export default function LoanInformation() {

    const { t } = useTranslation();

    const { state } = useStore();

    return (
        <Card>
            <CardContent>
                <p style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    marginTop: "0px"
                }}>
                    {t(LOAN_INFORMATION_LABEL)}
                </p>
                <p>
                    <span style={{ fontWeight: "bold" }}>
                        {t(LOAN_TYPE_LABEL)}: </span>
                    {state[GET_INVESTMENT_PROFILE_LOAN]?.loanType}
                </p>
                <p>
                    <span style={{ fontWeight: "bold" }}>
                        {t(DOWN_PAYMENT_LABEL)}: </span>
                    {state[GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc}%
                </p>
                <p>
                    <span style={{ fontWeight: "bold" }}>
                        {t(TERM_LABEL)}: </span>
                    {(state[GET_INVESTMENT_PROFILE_LOAN]?.term / 12)} {t(YEARS_LABEL)}
                </p>
            </CardContent>
        </Card>
    );
}