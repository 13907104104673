import { Box } from "@mui/material";
import BaseBtn from "../BaseComponents/BaseBtn";
import HomeSearchGoalsDialog from "../Dialogs/HomeSearchGoalsDialog";
import { useState, useEffect } from "react";
import { LOGIN_ROUTE } from "../../constants/routes";
import { useHistory } from "react-router-dom";
import { useStore } from "../../hooks/store/store";
import {
    GET_PROFILE,
    GET_PROFILE_FINISHED
} from "../../constants/store/getters";
import { useTranslation } from 'react-i18next';
import {
    CREATE_INVESTMENT_PROFILE_LABEL,
    LOG_IN_LABEL,
} from "../../i18n/i18nLabel";
import {
    CONTINUE_AS_GUEST_TEXT,
    NO_INVESTMENT_PROFILE_TEXT
} from "../../i18n/i18nText";

export default function SingUpAgain() {

    const { t } = useTranslation();

    const history = useHistory();

    const { state, dispatch } = useStore();

    const [showSearchValuesDialog, setShowSearchValuesDialog] = useState(false);

    useEffect(() => {
        if (state[GET_PROFILE]) {
            window.location.reload();
        }
    }, [showSearchValuesDialog]);

    return (
        <Box>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "54px"
            }}>
                <h4 style={{ textAlign: "center" }}>
                    {t(NO_INVESTMENT_PROFILE_TEXT)}
                </h4>
                <BaseBtn
                    label={t(LOG_IN_LABEL)}
                    onClick={() => {
                        localStorage.removeItem('profile_finished');
                        dispatch({ action: GET_PROFILE_FINISHED, value: false });
                        history.push(LOGIN_ROUTE)
                    }}
                    variant="outlined"
                />
                <h4 style={{ textAlign: "center" }}>
                    {t(CONTINUE_AS_GUEST_TEXT)}
                </h4>
                <BaseBtn
                    label={t(CREATE_INVESTMENT_PROFILE_LABEL)}
                    onClick={() => setShowSearchValuesDialog(true)}
                    variant="outlined"
                />
            </Box>
            <HomeSearchGoalsDialog
                open={showSearchValuesDialog}
                onClose={() => setShowSearchValuesDialog(false)}
            />
        </Box >
    );
}