import { useState, useEffect } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function BaseToggle(props) {

    const [options, setOptions] = useState(props.options ?? []);

    const [clicked, setClicked] = useState(false);

    const getLabelOfTrueValue = () => {
        const item = options.find(item => item.value === true);
        return item ? item.label : null;
    };

    const [alignment, setAlignment] = useState(getLabelOfTrueValue);

    useEffect(() => {
        if (props.onInputChange && clicked) {
            props.onInputChange(options);
        }
    }, [options])

    const handleChange = async (label) => {
        if (alignment === label && options.length === 1) {
            setAlignment(null);
        } else {
            setAlignment(label);
        }
        setOptions(
            options.map(item => ({
                ...item,
                value: options.length > 1 ? item.label === label : !item.value
            }))
        );
    };

    return (
        <ToggleButtonGroup
            color="secondary"
            value={alignment}
            exclusive
            size='small'

        >
            {
                options.map((opt) =>
                    <ToggleButton
                        style={{ height: "30px", fontSize: ".7rem", fontWeight: "bold" }}
                        key={opt.label}
                        value={opt.label}
                        onClick={async () => {
                            setClicked(true);
                            await handleChange(opt.label);
                            setClicked(false);
                        }}
                    >
                        {opt.label}
                    </ToggleButton>
                )
            }
        </ToggleButtonGroup>
    );

}