import { Box } from "@mui/material";
import BaseAlert from "../BaseComponents/BaseAlert";
import { useError } from "../../hooks/handleError";
import { handleError } from "../../utils/functions";
import BaseInput from "../BaseComponents/BaseInput";
import BaseBtn from "../BaseComponents/BaseBtn";
import { useState, useRef } from "react";
import { validations } from "../../utils/validations";
import {
    EMAIL_ERROR,
    FIRST_NAME_ERROR
} from "../../constants/messages";
import { getMloByEmail } from "../../services/loanServices";
import { useStore } from "../../hooks/store/store";
import {
    GET_MLO_ID,
    GET_MLO_PROFILE,
    GET_PROFILE_TOKEN
} from "../../constants/store/getters";
import LoanOfficerInfo from "./LoanOfficerInfo";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import BaseInputMask from "../BaseComponents/BaseInputMask";
import { PHONE_MASK } from "../../constants/constants";
import Grow from '@mui/material/Grow';
import { useTranslation } from 'react-i18next';
import { WE_WILL_CHECK_LOAN_OFFICER_TEXT } from "../../i18n/i18nText";
import {
    CONTINUE_LABEL,
    EMAIL_ERROR_LABEL,
    ENTER_LOAN_OFFICER_INFO_LABEL,
    LOAN_OFFICER_EMAIL_LABEL,
    LOAN_OFFICER_NAME_LABEL,
    LOAN_OFFICER_PHONE_LABEL,
    NAME_ERROR_LABEL,
    PHONE_NUMBER_ERROR_LABEL
} from "../../i18n/i18nLabel";

export default function LoanOfficer() {

    const { t } = useTranslation();

    const { state, dispatch } = useStore();

    const [name, setName] = useState(null);

    const [phone, setPhone] = useState(null);

    const [email, setEmail] = useState(null);

    const [step, setStep] = useState(state[GET_MLO_ID]
        ? "mlo"
        : localStorage.getItem("no_mlo")
            ? "no_mlo"
            : false);

    const NAME = "name";

    const PHONE = "phone";

    const EMAIL = "email";

    const [errors, setErrors] = useState({});

    const { error, setErrorMessage, clearError } = useError();

    const activeErrors = useRef(false);

    const [saving, setSaving] = useState(false);

    const validationValues = [
        { validation: ["required", "onlyLetters"], value: name, key: NAME },
        { validation: ["required"], value: phone, key: PHONE },
        { validation: ["required", "isEmail"], value: email, key: EMAIL },
    ];

    const handleContinue = async () => {
        if (!activeErrors.current) {
            try {
                const mlo = await getMloByEmail(name, phone, email, state[GET_PROFILE_TOKEN]);
                if (mlo) {
                    localStorage.setItem("loan_originator_id", mlo?.id)
                    dispatch({ action: GET_MLO_ID, value: mlo?.id });
                    dispatch({ action: GET_MLO_PROFILE, value: mlo });
                    setStep("mlo");
                } else {
                    localStorage.setItem("no_mlo", true);
                    localStorage.setItem("no_mlo_name", name);
                    localStorage.setItem("no_mlo_phone", phone);
                    localStorage.setItem("no_mlo_email", email);
                    setStep("no_mlo");
                }
            } catch (e) {
                setErrorMessage(handleError(e));
            } finally {
                setErrors({});
                setSaving(false);
            }
        }
    }

    const showError = (errorValue, field) => {
        if (field === NAME && Array.isArray(errorValue) && errorValue.length > 0) {
            return errorValue[0] !== FIRST_NAME_ERROR ? t(NAME_ERROR_LABEL) + t(errorValue[0]) : t(errorValue[0]);
        }
        if (field === PHONE && Array.isArray(errorValue) && errorValue.length > 0) {
            return t(PHONE_NUMBER_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        if (field === EMAIL && Array.isArray(errorValue) && errorValue.length > 0) {
            return errorValue[0] === EMAIL_ERROR ? t(errorValue[0]) : t(EMAIL_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        return "";
    };

    return (
        !step
            ?
            <Grow
                in={true}
                {...({ timeout: 500 })}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "0px"
                }}>
                    <BaseAlert alert={error} onClose={() => { clearError(); }} />
                    <PersonSearchIcon fontSize="large" color="secondary" />
                    <p style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: "1.2rem"
                    }}>
                        {t(WE_WILL_CHECK_LOAN_OFFICER_TEXT)}
                    </p>
                    <p style={{
                        fontWeight: "bold",
                        textAlign: "center",
                    }}>
                        {t(ENTER_LOAN_OFFICER_INFO_LABEL)}
                    </p>

                    <BaseInput
                        style={{ width: "100%" }}
                        label={t(LOAN_OFFICER_NAME_LABEL)}
                        startIcon="person"
                        required
                        value={name}
                        error={showError(errors.name, NAME)}
                        onInputChange={(value) => {
                            validationValues[0].value = value;
                            setName(value);
                            setErrors(validations(validationValues));
                        }}
                    />
                    <BaseInputMask
                        mask={PHONE_MASK}
                        style={{ width: "100%" }}
                        label={t(LOAN_OFFICER_PHONE_LABEL)}
                        startIcon="phone"
                        required
                        value={phone}
                        error={showError(errors.phone, PHONE)}
                        onInputChange={(value) => {
                            validationValues[1].value = value;
                            setPhone(value);
                            setErrors(validations(validationValues));
                        }}
                    />
                    <BaseInput
                        style={{ width: "100%" }}
                        label={t(LOAN_OFFICER_EMAIL_LABEL)}
                        startIcon="email"
                        required
                        value={email}
                        error={showError(errors.email, EMAIL)}
                        onInputChange={(value) => {
                            validationValues[2].value = value;
                            setEmail(value);
                            setErrors(validations(validationValues));
                        }}
                    />
                    <BaseBtn
                        label={t(CONTINUE_LABEL)}
                        style={{ width: "300px", marginTop: "10px" }}
                        onClick={() => {
                            const eList = validations(validationValues);
                            setErrors(eList);
                            (Object.keys(eList).length === 0)
                                ? activeErrors.current = false
                                : activeErrors.current = true
                            handleContinue();
                        }}
                        loading={saving}
                    />
                </Box>
            </Grow>
            :
            <LoanOfficerInfo
                info={step}
                profile={state[GET_MLO_PROFILE]}
                name={name}
                phone={phone}
                email={email}
            />
    );
}