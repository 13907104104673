import { Box } from "@mui/material";
import Grow from '@mui/material/Grow';
import {
    useState,
    useRef,
    useEffect
} from "react";
import {
    validations,
    MORE_THAN_8_CHARACTERS,
    NO_SPACES,
    MIX_STRING_FOR_PASS
} from "../../utils/validations";
import BaseAlert from "../BaseComponents/BaseAlert";
import { handleError } from "../../utils/functions";
import { useError } from "../../hooks/handleError";
import BaseBtn from "../BaseComponents/BaseBtn";
import BaseInput from "../BaseComponents/BaseInput";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PASSWORDS_MUST_MATCH_MESSAGE } from "../../constants/messages";
import { useHistory } from "react-router-dom";
import { LOGIN_ROUTE } from "../../constants/routes";
import { changeResetPassword } from "../../services/authServices";
import { GET_INITIAL_STATE, GET_PROFILE_EMAIL } from "../../constants/store/getters";
import { useStore } from "../../hooks/store/store";
import { useTranslation } from 'react-i18next';
import {
    CHANGE_PASSWORD_LABEL,
    CONFIRM_PASSWORD_ERROR_LABEL,
    CONFIRM_PASSWORD_LABEL,
    EIGHT_CHARACTERS_LABEL,
    ENTER_NEW_PASSWORD_LABEL,
    MIX_PASS_CHARACTERS_LABEL,
    NO_WHITE_SPACES_LABEL,
    PASSWORD_ERROR_LABEL,
    PASSWORD_LABEL,
    SUBMIT_LABEL
} from "../../i18n/i18nLabel";

export default function ResetPassword() {

    const { t } = useTranslation();

    const { state, dispatch } = useStore();

    const history = useHistory();

    const PASSWORD = "password";

    const CONFIRM_PASSWORD = "confirm_password";

    const [password, setPassword] = useState(null);

    const [confirmPassword, setConfirmPassword] = useState(null);

    const [errors, setErrors] = useState({});

    const { error, setErrorMessage, clearError } = useError();

    const activeErrors = useRef(false);

    const [saving, setSaving] = useState(false);

    const [isWithoutSpaces, setIsWithoutSpaces] = useState(false);

    const [isEightCharacters, setIsEightCharacters] = useState(false);

    const [mixCharacters, setMixCharacters] = useState(false);

    const validationValues = [
        { validation: ["required"], value: password, key: PASSWORD },
        { validation: ["required", "isSamePassword"], value: [confirmPassword, password], key: CONFIRM_PASSWORD },
    ];

    const showError = (errorValue, field) => {
        if (field === PASSWORD && Array.isArray(errorValue) && errorValue.length > 0) {
            return t(PASSWORD_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        if (field === CONFIRM_PASSWORD && Array.isArray(errorValue) && errorValue.length > 0) {
            return errorValue[0] === PASSWORDS_MUST_MATCH_MESSAGE ? t(errorValue[0]) : t(CONFIRM_PASSWORD_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        return "";
    };

    useEffect(() => {
        if (MORE_THAN_8_CHARACTERS.test(password)) {
            setIsEightCharacters(true);
        } else {
            setIsEightCharacters(false);
        }
        if (NO_SPACES.test(password)) {
            setIsWithoutSpaces(true);
        } else {
            setIsWithoutSpaces(false);
        }
        if (MIX_STRING_FOR_PASS.test(password)) {
            setMixCharacters(true);
        } else {
            setMixCharacters(false);
        }
    }, [password]);

    const handleResetPassword = async () => {
        try {
            if (!activeErrors.current) {
                setSaving(true);
                await changeResetPassword(state[GET_PROFILE_EMAIL], password);
                localStorage.clear();
                dispatch({ action: GET_INITIAL_STATE, value: null });
                history.push(LOGIN_ROUTE);
            }
        } catch (e) {
            setErrorMessage(handleError(e));
        } finally {
            setSaving(false);
        }
    }

    return (
        <Grow
            in={true}
            {...({ timeout: 500 })}
        >
            <Box
                sx={{
                    width: "100% !important",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: 'center',
                }}
            >
                <BaseAlert alert={error} onClose={() => { clearError(); }} />
                <p
                    style={{
                        fontWeight: "bold",
                        fontSize: "1.8rem",
                        margin: "0px"
                    }}
                >
                    {t(CHANGE_PASSWORD_LABEL)}
                </p>
                <p
                    style={{
                        textAlign: "center",
                    }}
                >
                    {t(ENTER_NEW_PASSWORD_LABEL)}
                </p>
                <BaseInput style={{
                    width: "100%"
                }}
                    label={t(PASSWORD_LABEL)}
                    required
                    type="password"
                    value={password}
                    error={showError(errors.password, PASSWORD)}
                    onInputChange={(value) => {
                        validationValues[0].value = value;
                        validationValues[1].value[1] = value;
                        setPassword(value);
                        setErrors(validations(validationValues));
                    }}
                />
                <BaseInput style={{
                    width: "100%"
                }}
                    label={t(CONFIRM_PASSWORD_LABEL)}
                    required
                    value={confirmPassword}
                    type="password"
                    error={showError(errors.confirm_password, CONFIRM_PASSWORD)}
                    onInputChange={(value) => {
                        validationValues[1].value[0] = value;
                        setConfirmPassword(value);
                        setErrors(validations(validationValues));
                    }}
                />
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "100%",
                    marginTop: "8px"
                }}
                >
                    <Box sx={{ display: "flex", alignItems: "start", marginBottom: "4px" }}>
                        {isWithoutSpaces
                            ? <CheckCircleIcon fontSize="small" style={{ color: "green" }} />
                            : <CancelIcon fontSize="small" style={{ color: "red" }} />
                        }
                        <p style={{
                            margin: "0px",
                            fontSize: ".8rem"
                        }}>
                            {t(NO_WHITE_SPACES_LABEL)}
                        </p>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "start", marginBottom: "4px" }}>
                        {isEightCharacters
                            ? <CheckCircleIcon fontSize="small" style={{ color: "green" }} />
                            : <CancelIcon fontSize="small" style={{ color: "red" }} />
                        }
                        <p style={{
                            margin: "0px",
                            fontSize: ".8rem"
                        }}>
                            {t(EIGHT_CHARACTERS_LABEL)}
                        </p>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "start", marginBottom: "4px" }}>
                        {mixCharacters
                            ? <CheckCircleIcon fontSize="small" style={{ color: "green" }} />
                            : <CancelIcon fontSize="small" style={{ color: "red" }} />
                        }
                        <p style={{
                            margin: "0px",
                            fontSize: ".8rem"
                        }}>
                            {t(MIX_PASS_CHARACTERS_LABEL)}
                        </p>
                    </Box>
                </Box>
                <BaseBtn
                    label={t(SUBMIT_LABEL)}
                    style={{ marginTop: "20px", width: "300px" }}
                    loading={saving}
                    onClick={() => {
                        const eList = validations(validationValues);
                        setErrors(eList);
                        (Object.keys(eList).length === 0)
                            ? activeErrors.current = false
                            : activeErrors.current = true
                        handleResetPassword();
                    }}
                />
            </Box>
        </Grow>
    );
}