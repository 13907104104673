import {
    Card,
    CardContent,
    CardMedia
} from "@mui/material";
import { PROPERTY_SRC } from "../../../constants/constants";
import Box from "@mui/material/Box";
import { Link as LinkTo } from "react-router-dom";
import { HOME_DETAILS } from "../../../constants/routes";
import Tooltip from '@mui/material/Tooltip';
import { useState, useEffect } from "react";
import InfoCard from "../../properties/InfoCard";
import MediaCard from "../../properties/MediaCard";
import { useTranslation } from 'react-i18next';
import { VIEW_MORE_FAV_CARD_LABEL } from "../../../i18n/i18nLabel";

export default function PropertyCard({ property, comparison, handleComparison, isFull }) {

    const [select, setSelect] = useState(comparison);

    const [full, setFull] = useState(isFull);

    const [selected, setSelected] = useState(false);

    const { t } = useTranslation();

    useEffect(() => { setFull(isFull); }, [isFull]);

    useEffect(() => {
        if (!comparison) {
            setSelected(false);
        }
        setSelect(comparison);
    }, [comparison]);

    const cardContent = (
        <Card>
            <CardMedia
                sx={{ height: 180 }}
                image={property?.media?.[0]?.mediaUrl ?? PROPERTY_SRC}
            >
                <MediaCard
                    isFavorite={true}
                    status={property.status}
                    select={select}
                    selected={selected}
                    full={full}
                    property={property}
                />
            </CardMedia>
            <CardContent>
                <InfoCard
                    property={property}
                />
                <Box sx={{
                    display: "flex",
                    justifyContent: "end"
                }}>
                    <Tooltip
                        followCursor
                        title={
                            (property.lotSize ? "Lot Size: " + property.lotSize + " SQFT" : "") +
                            (property.lotSize && property.builtYear ? ",\n" : "") +
                            (property.builtYear ? "Year Built: " + property.builtYear : "")
                        }>
                        <span
                            style={{
                                fontSize: ".7rem",
                                padding: "5px",
                                borderRadius: "5px",
                                background: "whitesmoke",
                                color: "grey",
                                cursor: "pointer",
                            }}>{t(VIEW_MORE_FAV_CARD_LABEL)}</span>
                    </Tooltip>
                </Box>
            </CardContent>
        </Card>
    );

    const content = () => {
        return !select ? (
            <LinkTo to={HOME_DETAILS + property.propertyId} className="cardLink">
                {cardContent}
            </LinkTo>
        ) : (
            <Box
                style={{
                    borderRadius: "5px",
                    boxShadow: selected ? "0px 0px 10px rgba(255, 69, 0, 0.6)" : "none"
                }}
                className={(selected && full) || !full ? "cursor" : ""}
                onClick={() => {
                    if (selected) {
                        handleComparison(property, false);
                    } else {
                        handleComparison(property, true);
                    }
                    if (selected === false && !full) {
                        setSelected(true);
                    }
                    if (selected) {
                        setSelected(false);
                    }

                }}
            >
                {cardContent}
            </Box>
        );
    };

    return content();
}