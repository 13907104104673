import BaseDialog from "../BaseDialog/BaseDialog";
import BaseDialogBody from "../BaseDialog/BaseDialogBody";
import BaseDialogFooter from "../BaseDialog/BaseDialogFooter";
import BaseDialogHeader from "../BaseDialog/BaseDialogHeader";
import BaseBtn from "../BaseComponents/BaseBtn";
import { Box } from "@mui/material";
import { useError } from "../../hooks/handleError";
import { handleError } from "../../utils/functions";
import BaseAlert from "../BaseComponents/BaseAlert";
import { validations } from "../../utils/validations";
import { useState, useRef } from "react";
import { useStore } from "../../hooks/store/store";
import {
    GET_FUNDS_LIMIT,
    GET_INVESTMENT_PROFILE,
    GET_PAYMENT_LIMIT,
    GET_PROFILE_TOKEN
} from "../../constants/store/getters";
import { updateInvestmentProfile } from "../../services/investmentProfileServices";
import BaseCurrency from "../BaseComponents/BaseCurrency";
import CheckMoney from "../Images/CheckMoney";
import HelpIcon from '@mui/icons-material/Help';
import { HELP_URL } from "../../constants/urls";
import { useTranslation } from 'react-i18next';
import {
    AVAILABLE_FUNDS_LABEL,
    CONTINUE_LABEL,
    DESIRED_MORTGAGE_LABEL,
    FUNDS_ERROR_LABEL,
    MORTGAGE_ERROR_LABEL,
    SWIFTMATCH_PROFILE_LABEL,
    TELL_US_HOME_SEARCH_GOALS_LABEL
} from "../../i18n/i18nLabel";
import {
    IN_THE_MEANTIME_TEXT,
    WE_WILL_REPLACE_TEXT
} from "../../i18n/i18nText";

export default function SetInvProfileValues(props) {

    const { t } = useTranslation();

    const { state, dispatch } = useStore();

    const PAYMENT_LIMIT = "payment_limit";

    const FUNDS_LIMIT = "funds_limit";

    const [paymentLimit, setPaymentLimit] = useState(state[GET_PAYMENT_LIMIT]);

    const [fundsLimit, setFundsLimit] = useState(state[GET_FUNDS_LIMIT]);

    const [errors, setErrors] = useState({});

    const activeErrors = useRef(false);

    const [saving, setSaving] = useState(false);

    const { error, setErrorMessage, clearError } = useError();

    const validationValuesInvProfile = [
        { validation: ["required", "isGreaterThanCero"], value: paymentLimit, key: "payment_limit" },
        { validation: ["required", "isGreaterThanCero"], value: fundsLimit, key: "funds_limit" },
        { validation: ["paymentLimitAndFundLimit"], value: [paymentLimit, fundsLimit], key: "payment_limit" },
    ];

    const openHelp = () => {
        window.open(HELP_URL, '_blank');
    };

    const showErrorInvProfile = (errorValue, field) => {
        if (field === FUNDS_LIMIT && Array.isArray(errorValue) && errorValue.length > 0) {
            return t(FUNDS_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        if (field === PAYMENT_LIMIT && Array.isArray(errorValue) && errorValue.length > 0) {
            return t(MORTGAGE_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        return "";
    };

    const handleDialogBeforeHide = () => {
        setPaymentLimit(state[GET_PAYMENT_LIMIT]);
        setFundsLimit(state[GET_FUNDS_LIMIT]);
        setErrors({});
        clearError();
        setSaving(false);
        activeErrors.current = false;
        props.onClose();
    }

    const handleInvestmentProfile = async () => {
        if (!activeErrors.current) {
            try {
                setSaving(true);
                const payLimit = parseFloat(paymentLimit.replace(/,/g, ''));
                const fundLimit = parseFloat(fundsLimit.replace(/,/g, ''));

                if (state[GET_PROFILE_TOKEN] && state[GET_INVESTMENT_PROFILE]) {

                    const inv = state[GET_INVESTMENT_PROFILE];
                    inv.paymentLimit = payLimit;
                    inv.investmentAmount = fundLimit;

                    const profile = await updateInvestmentProfile(inv, state[GET_PROFILE_TOKEN]);
                    if (profile) {
                        dispatch({ action: GET_INVESTMENT_PROFILE, value: profile });
                        dispatch({ action: GET_PAYMENT_LIMIT, value: payLimit });
                        dispatch({ action: GET_FUNDS_LIMIT, value: fundLimit });
                        handleDialogBeforeHide();
                    } else {
                        setErrorMessage(handleError());
                    }

                } else {
                    setErrorMessage(handleError());
                }
            } catch (e) {
                setErrorMessage(handleError(e));
            } finally {
                setSaving(false);
            }
        }
    }

    return (
        <BaseDialog
            {...props}
        >
            <BaseDialogHeader
                title={t(SWIFTMATCH_PROFILE_LABEL)}
            />
            <BaseDialogBody>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingLeft: "14px",
                    paddingRight: "14px"
                }}>
                    <BaseAlert alert={error} onClose={() => { clearError(); }} />
                    <Box>
                        <Box sx={{
                            display: "flex", flexDirection: "column"
                        }}>
                            <Box sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <CheckMoney maxwidth="30" maxheight="30" />
                                <p style={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    fontSize: "1.1rem",
                                    margin: "0px"
                                }}>
                                    {t(IN_THE_MEANTIME_TEXT)}
                                </p>
                            </Box>
                            <p style={{ fontSize: "1.1rem", marginBottom: "0px", fontWeight: "bold" }}>
                                {t(TELL_US_HOME_SEARCH_GOALS_LABEL)}
                            </p>
                            <p style={{ marginTop: "0px" }}>
                                {t(WE_WILL_REPLACE_TEXT)}
                            </p>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "20px"
                        }}>
                            <label>{t(DESIRED_MORTGAGE_LABEL)}</label>
                            <BaseCurrency
                                value={paymentLimit}
                                error={showErrorInvProfile(errors.payment_limit, PAYMENT_LIMIT)}
                                onInputChange={(value) => {
                                    validationValuesInvProfile[0].value = value;
                                    validationValuesInvProfile[2].value = [value, fundsLimit];
                                    setPaymentLimit(value);
                                    setErrors(validations(validationValuesInvProfile));
                                }}
                            />
                            <br />
                            <div className="displayFlexRow">
                                <label>{t(AVAILABLE_FUNDS_LABEL)}</label>
                                <HelpIcon
                                    style={{ marginLeft: "14px" }}
                                    className="cursor"
                                    fontSize="small"
                                    onClick={openHelp}
                                />
                            </div>
                            <BaseCurrency
                                value={fundsLimit}
                                error={showErrorInvProfile(errors.funds_limit, FUNDS_LIMIT)}
                                onInputChange={(value) => {
                                    validationValuesInvProfile[1].value = value;
                                    validationValuesInvProfile[2].value = [paymentLimit, value];
                                    setFundsLimit(value);
                                    setErrors(validations(validationValuesInvProfile));
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </BaseDialogBody>
            <BaseDialogFooter className="setProperties">
                <Box sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <BaseBtn
                        label={t(CONTINUE_LABEL)}
                        onClick={() => {
                            const eList = validations(validationValuesInvProfile);
                            setErrors(eList);
                            (Object.keys(eList).length === 0)
                                ? activeErrors.current = false
                                : activeErrors.current = true
                            handleInvestmentProfile();
                        }}
                        loading={saving}
                    />
                </Box>
            </BaseDialogFooter>
        </BaseDialog>
    );
}