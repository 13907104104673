import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EXPIRES_IN_LABEL } from '../../i18n/i18nLabel';

export default function VerificationTimer({ storedTimestamp }) {

    const { t } = useTranslation();

    const [initialTime, setInitialTime] = useState(15 * 60);
    const [timeLeft, setTimeLeft] = useState(initialTime);

    useEffect(() => {
        if (storedTimestamp) {

            const now = new Date();
            const storedDate = new Date(storedTimestamp);

            const differenceInMilliseconds = now - storedDate;

            const totalSeconds = (differenceInMilliseconds / 1000) | 0;
            setInitialTime(Math.max(0, 15 * 60 - totalSeconds));
        }
    }, [storedTimestamp]);

    useEffect(() => {
        setTimeLeft(initialTime);
    }, [initialTime]);

    useEffect(() => {
        if (timeLeft <= 0) return;

        const intervalId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    return (
        <span>{t(EXPIRES_IN_LABEL)} {formattedTime}</span>
    );
}