import { Box } from "@mui/material";
import { ZERO_HOA_TAX } from "../../constants/urls";
import PaymentAndFoundValues from "./PaymentAndFoundValues";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { setCurrencyValue } from "../../utils/functions";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { RiHotelBedLine } from "react-icons/ri";
import { MdOutlineBathtub } from "react-icons/md";
import { LiaToiletSolid } from "react-icons/lia";
import { PiHouseBold } from "react-icons/pi";
import DisclaimerBtnDialog from "./DisclaimerBtnDialog";
import { useTranslation } from 'react-i18next';
import {
    BATHROOMS_LABEL,
    BEDROOMS_LABEL,
    CONDOMINIUM_LABEL,
    HOA_FEE_LABEL,
    MOBILE_HOME_LABEL,
    NOTE_LABEL,
    NO_INFO_LABEL,
    SINGLE_FAMILY_LABEL,
    TAXES_LABEL,
    TOWNHOUSE_LABEL,
} from "../../i18n/i18nLabel";
import {
    CONDOMINIUM,
    MOBILE_HOME,
    PRIMARY_COLOR,
    SINGLE_FAMILY,
    TOWNHOUSE
} from "../../constants/constants";

export default function InfoCard({ property, paymentValues }) {

    const { t } = useTranslation();

    const valuePercent = () => {
        const value = 100 - ((property?.price * 100) / property?.previousPrice);
        return Math.ceil(value * 100) / 100;
    }

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", minHeight: "40px" }}>
                    <Box sx={{ display: "flex", justifyContent: "start", fontWeight: "bold", alignItems: "center" }}>
                        ${setCurrencyValue(String(property?.price))}
                        {(property?.previousPrice && property?.price && property?.previousPrice > 10000) ? valuePercent() > 0
                            ? <Box sx={{ display: "flex", alignItems: "center" }}>
                                <ArrowDownwardIcon sx={{ fontSize: "medium", color: "red" }} />
                                <span style={{ fontSize: ".7rem" }}>{valuePercent()}%</span>
                            </Box>

                            : <Box sx={{ display: "flex", alignItems: "center" }}>
                                <ArrowUpwardIcon sx={{ fontSize: "medium", color: "green" }} />
                                <span style={{ fontSize: ".7rem" }}>{Math.abs(valuePercent())}%</span>
                            </Box>
                            : null
                        }
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                        <img
                            alt="MLS Logo"
                            style={{ maxHeight: "1.5rem", maxWidth: "5rem" }}
                            src={property?.mlsLogoURl}
                        />
                        {property?.disclaimer
                            ? <DisclaimerBtnDialog property={property} onClick={handleClick} />
                            : null
                        }
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    padding: "3px",
                    background: "#80808021",
                    color: "black",
                    fontWeight: "bold",
                    borderRadius: "3px",
                    width: "fit-content"
                }}>
                    {property?.propertyType === CONDOMINIUM
                        ? t(CONDOMINIUM_LABEL)
                        : property?.propertyType === SINGLE_FAMILY
                            ? t(SINGLE_FAMILY_LABEL)
                            : property?.propertyType === MOBILE_HOME
                                ? t(MOBILE_HOME_LABEL)
                                : property?.propertyType === TOWNHOUSE
                                    ? t(TOWNHOUSE_LABEL)
                                    : t(NO_INFO_LABEL)
                    }
                </Box>
                <Box sx={{ display: "flex", marginTop: "4px", minHeight: "40px" }}>
                    <FmdGoodOutlinedIcon fontSize="small" />
                    <p style={{ margin: "0px", fontSize: "0.80rem" }}>
                        {property?.streetAddress ? (property?.streetAddress.trim() + ", ") : ""}
                        {property?.city ? (property?.city + ", ") : ""}
                        {property?.state ?? ""}
                        {property?.zipCode ?? ""}
                    </p>
                </Box>
                <Box
                    sx={{
                        padding: "0.25rem 0.5rem",
                        display: "flex",
                        gap: "0.5rem",
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{ display: "flex", flexDirection: "column" }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "0.7rem",
                                fontWeight: "bold",
                            }}
                        >
                            <RiHotelBedLine size={"2em"} />
                            {property?.bedrooms !== null ? property?.bedrooms : "-"}
                        </Box>
                        <Box
                            sx={{ fontSize: "0.7rem", textAlign: "center" }}
                        >
                            {t(BEDROOMS_LABEL)}
                        </Box>
                    </Box>
                    <Box
                        sx={{ display: "flex", flexDirection: "column" }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "0.7rem",
                                fontWeight: "bold",
                            }}
                        >
                            <MdOutlineBathtub size={"2em"} />
                            {property?.bathroomsFull !== null ? property?.bathroomsFull : "-"}
                        </Box>
                        <Box
                            sx={{ fontSize: "0.7rem", textAlign: "center" }}
                        >
                            {t(BATHROOMS_LABEL)}
                        </Box>
                    </Box>
                    <Box
                        sx={{ display: "flex", flexDirection: "column" }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "0.7rem",
                                fontWeight: "bold",
                            }}
                        >
                            <LiaToiletSolid size={"2em"} />
                            {property?.bathroomsHalf !== null ? property?.bathroomsHalf : "-"}
                        </Box>
                        <Box
                            sx={{ fontSize: "0.7rem", textAlign: "center" }}
                        >
                            1/2 {t(BATHROOMS_LABEL)}
                        </Box>
                    </Box>
                    <Box
                        sx={{ display: "flex", flexDirection: "column" }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "0.7rem",
                                fontWeight: "bold",
                            }}
                        >
                            <PiHouseBold size={"2em"} />
                            {property?.livingArea !== null ? property?.livingArea : "-"}
                        </Box>
                        <Box
                            sx={{ fontSize: "0.7rem", textAlign: "center" }}
                        >
                            SQFT
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", height: "24px" }}>
                {property?.taxes === 0 || property?.hoa === 0
                    ? <Box sx={{ display: "flex", alignItems: "center" }}>
                        <InfoOutlinedIcon
                            onClick={(e) => {
                                handleClick(e);
                                window.open(ZERO_HOA_TAX, '_blank');
                            }}
                            className="cursor"
                            style={{ color: "black", fontSize: "1rem", marginRight: "4px" }}
                        />
                        <span
                            style={{ fontSize: ".8rem", color: PRIMARY_COLOR, fontWeight: "bold" }}
                        >
                            {t(NOTE_LABEL)}:{property?.hoa === 0 ? (" $0 " + t(HOA_FEE_LABEL)) : null}
                            {property?.taxes === 0 ? (" $0 " + t(TAXES_LABEL)) : null}
                        </span>
                    </Box>
                    : null
                }
            </Box>
            {paymentValues
                ? <Box>
                    <PaymentAndFoundValues
                        property={property}
                        onClick={handleClick}
                    />
                </Box>
                : null
            }
        </Box>
    );
}