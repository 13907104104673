import Backdrop from '@mui/material/Backdrop';
import { useState } from 'react';
import { Box } from '@mui/material';
import BaseBtn from '../BaseComponents/BaseBtn';
import { useTranslation } from 'react-i18next';
import { CLOSE_LABEL } from '../../i18n/i18nLabel';
import { OFFER_PRO_BACKDROP_TEXT } from '../../i18n/i18nText';

export default function OfferProBackdrop() {

    const { t } = useTranslation();

    const [open, setOpen] = useState(true);

    const handleClose = () => {
        localStorage.removeItem("open_offer_pro");
        setOpen(false);
    };

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <Box sx={{
                background: "white",
                color: "black",
                maxWidth: "280px",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <p style={{ textAlign: "center", fontSize: ".85rem" }}>
                    {t(OFFER_PRO_BACKDROP_TEXT)}
                </p>
                <BaseBtn
                    label={t(CLOSE_LABEL)}
                    variant="outlined"
                    onClick={handleClose}
                />
            </Box>
        </Backdrop>
    );
}