import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useState } from "react";
import BaseDialog from "../BaseDialog/BaseDialog";
import BaseDialogBody from "../BaseDialog/BaseDialogBody";
import BaseDialogHeader from "../BaseDialog/BaseDialogHeader";
import { replacePatterns } from "../../utils/utils";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";
import { LICENSING } from "../../constants/urls";
import { useTranslation } from 'react-i18next';
import {
  DISCLAIMER_TITLE_LINK_TEXT,
  DISCLAIMER_TITLE_TEXT
} from "../../i18n/i18nText";

export default function DisclaimerBtnDialog({ property, onClick }) {

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const handleDialog = () => setOpen(!open);

  const patternMatchDisclaimer = property?.disclaimer
    ? replacePatterns(property.disclaimer)
    : "";

  return (
    <Box onClick={onClick}>
      <IconButton color="primary" aria-label="Disclaimer" onClick={handleDialog}>
        <InfoOutlinedIcon style={{ color: "black" }} />
      </IconButton>
      <BaseDialog
        open={open}
        onClose={handleDialog}
        maxWidth="sm"
      >
        <BaseDialogHeader
          close={() => handleDialog()}
        >
          {t(DISCLAIMER_TITLE_TEXT)}{" "}
          <Link
            color="secondary"
            className="cursor"
            onClick={() => window.open(LICENSING, '_blank')}
          >
            {t(DISCLAIMER_TITLE_LINK_TEXT)}
          </Link>
        </BaseDialogHeader>
        <BaseDialogBody>
          <div style={{ padding: "8px" }}>
            {patternMatchDisclaimer}
          </div>
        </BaseDialogBody>
      </BaseDialog>
    </Box >
  );
}
