import {
  ANNUAL_TAX_AMOUNT_LABEL,
  BEDROOMS_LABEL,
  CITY_LABEL,
  DOWN_PAYMENT_LABEL,
  FULL_BATH_LABEL,
  HALF_BATHROOMS_LABEL,
  HOME_TYPE_LABEL,
  INVESTMENT_LABEL,
  LISTING_PRICE_LABEL,
  LOT_SIZE_LABEL,
  PAYMENT_LABEL,
  SAVED_OFFER_PRICE_LABEL,
  SELLER_REBATE_LABEL,
  SQUARE_FEET_LABEL,
  STATE_LABEL,
  STATUS_LABEL,
  STREET_ADDRESS_LABEL,
  YEAR_BUILT_LABEL,
  ZIPCODE_LABEL
} from "../i18n/i18nLabel";

export const GOOGLE_MAPS = "AIzaSyCnI1x008vUhzpJKhHyqdERg3EJXHlEEQ4";
//process.env.REACT_APP_GOOGLE_KEY

//FILTERS
export const CONVENTIONAL = "Conventional";
export const FHA = "FHA";
export const VA = "VA";
export const PAYMENT = "Payment";
export const CASH = "Cash";
export const CONV = "Conv";
export const PRIMARY_RESIDENCE = 'PrimaryResidence';
export const FARMERS_HOME_ADMINISTRATION = "FarmersHomeAdministration";

export const TOWNHOUSE = "Townhouse";
export const CONDOMINIUM = "Condominium";
export const SINGLE_FAMILY = "Single Family";
export const MOBILE_HOME = "Mobile Home";

//SEARCH
export const GET_NEW_VALUES_FOR_SEARCH = (loan) => {

  const lastSearch = localStorage.getItem("last_search")
    ? JSON.parse(localStorage.getItem("last_search"))
    : {};

  return {
    downpayment: (loan) ? loan.downPaymentPerc / 100 : 0.05,
    loanType: (loan) ? loan.loanType : lastSearch?.loanType ?? CONVENTIONAL,
    filterQualified: lastSearch?.filterQualified ?? 0,
    pageSize: 30,
    search: lastSearch?.search ?? null,
    searchBy: lastSearch?.searchBy ?? null,
    searchState: lastSearch.searchState ?? null,
    propertyType:
      lastSearch?.propertyType ??
      "Condominium,Townhouse,Single Family,Mobile Home",
    minPrice: lastSearch?.minPrice ?? null,
    maxPrice: lastSearch?.maxPrice ?? null,
    minBedrooms: lastSearch?.minBedrooms ?? null,
    maxBedrooms: lastSearch?.maxBedrooms ?? null,
    minBathrooms: lastSearch?.minBathrooms ?? null,
    maxBathrooms: lastSearch?.maxBathrooms ?? null,
    minLotSize: lastSearch?.minLotSize ?? null,
    maxLotSize: lastSearch?.maxLotSize ?? null,
    minHomeYear: lastSearch?.minHomeYear ?? null,
    maxHomeYear: lastSearch?.maxHomeYear ?? null,
    minHomeSize: lastSearch?.minHomeSize ?? null,
    maxHomeSize: lastSearch?.maxHomeSize ?? null,
    propertyId: null,
    lastPropertyId: null,
    lastModificationTimestamp: null,
    sellerRebate: (loan?.loanType === CONVENTIONAL || lastSearch?.loanType === CONVENTIONAL)
      ? Number(localStorage.getItem("seller_rebate_conventional"))
      : Number(localStorage.getItem("seller_rebate_fha")),
    offerPrice: (loan?.loanType === CONVENTIONAL || lastSearch?.loanType === CONVENTIONAL)
      ? Number(localStorage.getItem("offer_price_conventional"))
      : Number(localStorage.getItem("offer_price_fha")),
  };
};

//OTHERS//
export const NO_PROPERTY = "No Property";
export const NO_ACTIVE_SESSION = "No Active Session";
export const ASC = "ASC";
export const DESC = "DESC";
export const PROPERTY_SRC = "images/property-placeholder.jpg";
export const LOGO = "images/padzilly.png";
export const MARKER = "images/marker.svg";
export const INFO_EMAIL = "info@approvaliq.com";

//NAVIGATION
export const HOMES = "Homes";
export const FAVORITES = "Favorites";
export const PROFILE = "Profile";
export const SETTINGS = "Settings";

//ROLES
export const GUEST_ROLE = "Guest";
export const BORROWER_ROLE = "Borrower";

//LOAN
export const APPROVED = "Approved";
export const NOT_APPROVED = "Not Approved";

//MESSAGE TYPES
export const SUCCESS = "success";
export const ERROR = "error";
export const INFO = "info";

//MASK
export const PHONE_MASK = ['(', /\d/, /\d/, /\d/, ')', '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

//TABLE FIEDS
export const TABLE_FIELDS = [
  STREET_ADDRESS_LABEL,
  STATUS_LABEL,
  CITY_LABEL,
  STATE_LABEL,
  ZIPCODE_LABEL,
  LISTING_PRICE_LABEL,
  SAVED_OFFER_PRICE_LABEL,
  SELLER_REBATE_LABEL,
  DOWN_PAYMENT_LABEL,
  ANNUAL_TAX_AMOUNT_LABEL,
  'HOA',
  PAYMENT_LABEL,
  INVESTMENT_LABEL,
  BEDROOMS_LABEL,
  FULL_BATH_LABEL,
  HALF_BATHROOMS_LABEL,
  SQUARE_FEET_LABEL,
  HOME_TYPE_LABEL,
  YEAR_BUILT_LABEL,
  LOT_SIZE_LABEL,
];

//STATUS
export const STATUS_PENDING = "Pending";
export const STATUS_UNDER_CONTRACT = "Active Under Contract";
export const STATUS_COMING_SOON = "Coming Soon";
export const STATUS_ACTIVE = "Active";

//OFFER PRO
export const CALCULATION_OFFER_PRO_VALUES = {
  bipClosingFactor: 0.036,
  floodFactor: 0.0042,
  pmiFactor: 0.00075,
  ufmipFactor: 0.0175
};

//SHARE
export const SUBJECT = "Shared Padzilly Property";
export const VISITING = "You can access this property by visiting: ";

//IMPORT
export const REALTOR_URL_TO_IMPORT = "realtor.com/realestateandhomes-detail/";
export const ZILLOW_URL_TO_IMPORT = "zillow.com/homedetails/";
export const REALTOR = "realtor";
export const ZILLOW = "zillow";

//LOCATION
export const GEOLOCATION_NOT_SUPPORTED = "Geolocation is not supported by this browser";
export const GEO_PERMISSION_DENIED = "User denied the request for Geolocation";
export const GEO_POSITION_UNAVAILABLE = "Location information is unavailable";
export const GEO_TIMEOUT = "The request to get user location timed out";

//ERROR
export const UNKNOWN_ERROR = "Something went wrong, please try again later";

//DOWNPAYMENT
export const DEFAULT_CONVENTIONAL = 0.05;
export const MIN_CONVENTIONAL = 0.03;
export const DEFAULT_FHA = 0.035;
export const MIN_FHA = 0.035;
export const MAX_DOWN_PAYMENT = 0.8;

//PAGES
export const LANDING_PAGE = "landing_page";
export const HOMES_PAGE = "homes";
export const PROPERTY_DETAIL_PAGE = "property_detail";
export const LOGIN_ACCOUNT = "/login-account";
export const CREATE_ACCOUNT = "/create-account";

//LANDING SECTIONS
export const LANDING_TOP_BAR = "landing_top_bar";
export const LANDING_MAIN_SECTION = "landing_main_section";
export const LANDING_SEARCH_SECTION = "landing_search_section";
export const LANDING_NEAR_PROPERTIES = "landing_near_properties";
export const LANDING_LAST_SEARCH_PROPERTIES = "landing_last_search_properties";
export const LANDING_FAVORITE_PROPERTIES = "landing_favorite_properties";
export const LANDING_CARDS = "landing_cards";
export const LANDING_INFO = "landing_info";
export const LANDING_READY_GET_STARTED = "landing_ready_get_started";
export const LANDING_FOOTER = "landing_footer";
export const LANDING_SEARCH_BAR = "landing_search_bar";

//UTILS
export const PRIMARY_COLOR = "#EB5A2D";
export const SECONDARY_COLOR = "#233C90";
export const TITLES_COLOR = "#333333eb";
export const GOOGLE_PLAY = "images/GooglePlay.svg";
export const APP_STORE = "images/App_Store_Badge.svg";
export const EN_US = "en-US";
export const ES_US = "es-US";
export const LIST = "list";
export const MAP = "map";

//LANDING TYPES
export const NONE = "none";
export const IMAGE = "image";
export const TEXT = "text";
export const COLOR = "color";

//LANDING SUB_SECTIONS
//TOP BANNER
export const LANDING_SUB_SECTION_BANNER_LOGO = "banner_logo"
export const LANDING_SUB_SECTION_BANNER = "banner_background";
export const LANDING_SUB_SECTION_LENDERS = "banner_lenders";
export const LANDING_SUB_SECTION_SIGN_UP = "banner_sign_up";
export const LANDING_SUB_SECTION_BORDER_BANNER = "banner_border_banner";
//MAIN SECTION
export const LANDING_SUB_SECTION_SEARCH_TITLE = "search_section_title";
export const LANDING_SUB_SECTION_SEARCH_LOGIN = "search_section_login";
export const LANDING_SUB_SECTION_SEARCH_SIGN_UP = "search_section_sign_up";
//NEAR PROP
export const LANDING_SUB_SECTION_NEAR_PROP_PRIMARY_TITLE = "near_properties_primary_title";
export const LANDING_SUB_SECTION_NEAR_PROP_SECONDARY_TITLE = "near_properties_secondary_title";
export const LANDING_SUB_SECTION_NEAR_PROP_THIRD_TITLE = "near_properties_third_title";
export const LANDING_SUB_SECTION_NEAR_PROP_NO_TEXT = "near_properties_no_property";
export const LANDING_SUB_SECTION_NEAR_NO_PROP_BTN = "near_properties_no_property_btn";
export const LANDING_SUB_SECTION_NEAR_PROP_VIEW_MORE = "near_properties_view_more";
export const LANDING_SUB_SECTION_NEAR_PROP_BACKGROUND = "near_properties_background";
//FAVORITES
export const LANDING_SUB_SECTION_FAVORITES_TITLE = "favorites_title";
export const LANDING_SUB_SECTION_FAVORITES_BTN = "favorite_btn";
export const LANDING_SUBSECTION_FAVORITES_BACKGROUND = "favorite_background";
//LAST SEARCH
export const LANDING_SUB_SECTION_LAST_SEARCHED_PROPERTIES_TITLE = "last_searched_properties_title";
export const LANDING_SUB_SECTION_LAST_SEARCHED_PROPERTIES_BTN = "last_searched_properties_btn";
export const LANDING_SUB_SECTION_LAST_SEARCHED_PROPERTIES_BACKGROUND = "last_searched_properties_background";
//CARDS
export const LANDING_SUB_SECTION_INFO_CARD_ICON = "info_card_icon";
export const LANDING_SUB_SECTION_INFO_CARD_TITLE = "info_card_title";
export const LANDING_SUB_SECTION_INFO_CARD_TEXT = "info_card_text";
export const LANDING_SUB_SECTION_INFO_CARD_BACKGROUND = "info_card_background";
//INFO
export const LANDING_SUB_SECTION_INFO_IMAGE = "info_image";
export const LANDING_SUB_SECTION_INFO_TITLE = "info_title";
export const LANDING_SUB_SECTION_INFO_TEXT = "info_text";
export const LANDING_SUB_SECTION_INFO_BACKGROUND = "info_background";
//READY
export const LANDING_SUB_SECTION_READY_GET_STARTED_TITLE = "get_started_title";
export const LANDING_SUB_SECTION_READY_GET_STARTED_LINK_CREATE_ACCOUNT = "get_started_create_account_link";
export const LANDING_SUB_SECTION_READY_GET_STARTED_LINK_SEARCH_HOMES = "get_started_search_homes_link";
export const LANDING_SUB_SECTION_READY_GET_STARTED_BACKGROUND = "get_started_background";
//FOOTER
export const LANDING_SUB_SECTION_FOOTER_LOGO = "footer_logo";
export const LANDING_SUB_SECTION_FOOTER_TEXT = "footer_text";
export const LANDING_SUB_SECTION_FOOTER_APP_STORE = "footer_app_store";
export const LANDING_SUB_SECTION_FOOTER_PLAY_STORE = "footer_play_store";
export const LANDING_SUB_SECTION_FOOTER_TERMS_OF_USE = "footer_terms_of_use";
export const LANDING_SUB_SECTION_FOOTER_PRIVACY_POLICY = "footer_privacy_policy";
export const LANDING_SUB_SECTION_FOOTER_PADZILLY_DISCLAIMER = "footer_padzilly_disclaimer";
export const LANDING_SUB_SECTION_FOOTER_BACKGROUND = "footer_background";
//SEARCH BAR
export const LANDING_SUB_SECTION_SEARCH_BAR = "search_bar";

export const VIDEO_ONE = "//app.groove.cm/grooveembeds/video/260596/MmfgWC2UDqPS4bBWYdTF";
export const VIDEO_TWO = "//app.groove.cm/grooveembeds/video/258244/bSu1tLajmD5A4c8HIoRg";
export const VIDEO_THREE = "//app.groove.cm/grooveembeds/video/220213/lvyQt9OjPy580PYBN763";