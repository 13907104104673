import { Box, Card, CardContent } from "@mui/material"
import PolicyIcon from '@mui/icons-material/Policy';
import GavelIcon from '@mui/icons-material/Gavel';
import LogoutIcon from '@mui/icons-material/Logout';
import { useStore } from "../../hooks/store/store";
import {
    GET_LANDING_PAGE_ELEMENTS,
    GET_LOCATION,
    GET_NEAR_PROPERTIES,
    GET_PROFILE_ROLE,
    GET_PROFILE_TOKEN,
    GET_STORE_COMPLETE
} from "../../constants/store/getters";
import { useError } from "../../hooks/handleError";
import { handleError } from "../../utils/functions";
import BaseAlert from "../BaseComponents/BaseAlert";
import { signOut } from "../../services/authServices";
import LoginIcon from '@mui/icons-material/Login';
import {
    TERMS_CONDITIONS,
    PRIVACY_POLICY
} from "../../constants/urls";
import { BORROWER_ROLE } from "../../constants/constants";
import {
    LANDING_PAGE,
    LOGIN_ROUTE
} from "../../constants/routes";
import { useHistory } from "react-router-dom";
import { GETTERS } from "../../hooks/store/getters";
import { useTranslation } from 'react-i18next';
import {
    LOG_IN_LABEL,
    PRIVACY_POLICY_LABEL,
    SETTINGS_LABEL,
    SIGN_OUT_LABEL,
    TERMS_OF_USE_LABEL
} from "../../i18n/i18nLabel";

export default function Settings() {

    const { t } = useTranslation();

    const history = useHistory();

    const { state, dispatch } = useStore();

    const { error, setErrorMessage, clearError } = useError();

    const destroySession = async () => {
        try {
            await signOut(state[GET_PROFILE_TOKEN]);
            localStorage.clear();
            for (const key in GETTERS) {
                if (
                    key !== GET_NEAR_PROPERTIES
                    && key != GET_LOCATION
                    && key !== GET_STORE_COMPLETE
                    && key !== GET_LANDING_PAGE_ELEMENTS
                ) {
                    dispatch({ action: key, value: null });
                }
            }
            history.push(LANDING_PAGE);
        } catch (e) {
            setErrorMessage(handleError(e));
        }
    }

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "space-between",
            }}
        >
            <CardContent>
                <BaseAlert alert={error} onClose={() => { clearError(); }} />
                <p
                    style={{
                        fontWeight: "bold",
                        marginTop: "0px",
                        textTransform: "uppercase",
                        margin: "0px"
                    }}
                >
                    {t(SETTINGS_LABEL)}
                </p>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start"
                }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center"
                    }}
                        className="cursor optionHover"
                        onClick={() => {
                            window.open(TERMS_CONDITIONS, '_blank');
                        }}
                    >
                        <GavelIcon sx={{ marginRight: "5px" }} />
                        <p>{t(TERMS_OF_USE_LABEL)}</p>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center"
                    }}
                        className="cursor optionHover"
                        onClick={() => {
                            window.open(PRIVACY_POLICY, '_blank');
                        }}
                    >
                        <PolicyIcon sx={{ marginRight: "5px" }} />
                        <p>{t(PRIVACY_POLICY_LABEL)}</p>
                    </Box>
                    {state[GET_PROFILE_ROLE] === BORROWER_ROLE
                        ? <Box sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center"
                        }}
                            className="cursor optionHover"
                            onClick={() => { destroySession(); }}
                        >
                            <LogoutIcon sx={{ marginRight: "5px" }} />
                            <p>{t(SIGN_OUT_LABEL)}</p>
                        </Box>
                        : <Box sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center"
                        }}
                            className="cursor optionHover"
                            onClick={() => {
                                history.push(LOGIN_ROUTE)
                            }}
                        >
                            <LoginIcon sx={{ marginRight: "5px" }} />
                            <p>{t(LOG_IN_LABEL)}</p>
                        </Box>
                    }
                    {/*state[GET_PROFILE_ROLE] === BORROWER_ROLE
                    ? <Box sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center"
                    }}
                        className="cursor"
                    >
                        <PersonRemoveIcon sx={{ marginRight: "5px", color: "red" }} />
                        <p style={{
                            color: "red"
                        }}>Delete Your Account</p>
                    </Box>
                    : null*/}
                </Box>
            </CardContent>
        </Card>
    );
}