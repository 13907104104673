import { Box } from "@mui/material";
import BaseBtn from "../BaseComponents/BaseBtn";
import BaseInput from "../BaseComponents/BaseInput";
import Grow from '@mui/material/Grow';
import { useState, useRef } from "react";
import { EMAIL_ERROR } from "../../constants/messages";
import { validations } from "../../utils/validations";
import BaseAlert from "../BaseComponents/BaseAlert";
import { handleError } from "../../utils/functions";
import { useError } from "../../hooks/handleError";
import { sendPasswordOTP } from "../../services/authServices";
import { useStore } from "../../hooks/store/store";
import {
    GET_PROFILE_EMAIL,
    GET_PROFILE_ID,
    GET_USER_ID_OTP
} from "../../constants/store/getters";
import { LOGIN_ROUTE } from "../../constants/routes";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    BACK_TO_LABEL,
    EMAIL_ERROR_LABEL,
    EMAIL_LABEL,
    FORGOT_PASSWORD_LABEL,
    LOG_IN_LABEL,
    SEND_EMAIL_LABEL
} from "../../i18n/i18nLabel";
import { FORGOT_PASS_OTP_TEXT } from "../../i18n/i18nText";
import { PRIMARY_COLOR } from "../../constants/constants";

export default function ForgotPassword() {

    const { t } = useTranslation();

    const { dispatch } = useStore();

    const EMAIL = "email";

    const [email, setEmail] = useState();

    const [errors, setErrors] = useState({});

    const { error, setErrorMessage, clearError } = useError();

    const activeErrors = useRef(false);

    const [saving, setSaving] = useState(false);

    const validationValues = [
        { validation: ["required", "isEmail"], value: email, key: EMAIL },
    ];

    const showError = (errorValue, field) => {
        if (field === EMAIL && Array.isArray(errorValue) && errorValue.length > 0) {
            return errorValue[0] === EMAIL_ERROR ? t(errorValue[0]) : t(EMAIL_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        return "";
    };

    const handleResetPassword = async () => {
        try {
            setSaving(true);
            const otp = await sendPasswordOTP(email);
            localStorage.setItem("profile_id", otp?.userId);
            localStorage.setItem("user_id_otp", otp?.userId);
            dispatch({ action: GET_PROFILE_EMAIL, value: email });
            dispatch({ action: GET_PROFILE_ID, value: otp?.userId });
            dispatch({ action: GET_USER_ID_OTP, value: otp?.userId });
        } catch (e) {
            setErrorMessage(handleError(e));
        } finally {
            setSaving(false);
        }
    }

    return (
        <Grow
            in={true}
            {...({ timeout: 500 })}
        >
            <Box sx={{
                width: "100% !important",
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: 'center',
            }}>
                <BaseAlert alert={error} onClose={() => { clearError(); }} />
                <p
                    style={{
                        fontWeight: "bold",
                        fontSize: "1.8rem",
                        margin: "0px"
                    }}
                >
                    {t(FORGOT_PASSWORD_LABEL)}
                </p>
                <p
                    style={{
                        textAlign: "center",
                        width: "300px",

                    }}
                >
                    {t(FORGOT_PASS_OTP_TEXT)}
                </p>
                <BaseInput
                    label={t(EMAIL_LABEL)}
                    placeholder="joe@padzilly.com"
                    style={{
                        width: "100%"
                    }}
                    required
                    value={email}
                    error={showError(errors.email, EMAIL)}
                    onInputChange={(value) => {
                        validationValues[0].value = value;
                        setEmail(value);
                        setErrors(validations(validationValues));
                    }}
                />
                <BaseBtn
                    label={t(SEND_EMAIL_LABEL)}
                    style={{ marginTop: "20px", width: "300px" }}
                    loading={saving}
                    onClick={() => {
                        const eList = validations(validationValues);
                        setErrors(eList);
                        (Object.keys(eList).length === 0)
                            ? activeErrors.current = false
                            : activeErrors.current = true
                        handleResetPassword();
                    }}
                />
                <p style={{ textAlign: "center" }}>
                    {t(BACK_TO_LABEL)} <Link style={{ color: PRIMARY_COLOR }} to={LOGIN_ROUTE}>{t(LOG_IN_LABEL)}</Link>
                </p>
            </Box>
        </Grow>
    );
}