import { Box } from "@mui/material";
import { useStore } from "../../hooks/store/store";
import {
    GET_PROFILE_ROLE
} from "../../constants/store/getters";
import { BORROWER_ROLE } from "../../constants/constants";
import FavoritesPage from "./SavedProperties/FavoritesPage";
import GuestFavorites from "./SavedProperties/GuestFavorites";

export default function Favorites() {

    const { state } = useStore();

    return (
        <Box sx={{ height: "100vh" }}>
            <Box sx={{
                paddingTop: "54px",
                height: "100%",
                width: "100%",
            }}
            >
                {state[GET_PROFILE_ROLE] !== BORROWER_ROLE
                    ? <GuestFavorites />
                    : <FavoritesPage />
                }
            </Box>
        </Box>
    );
}