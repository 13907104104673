import { Box, Checkbox, Grow } from "@mui/material"
import BaseBtn from "../BaseComponents/BaseBtn"
import { useTranslation } from 'react-i18next';
import {
    PADZILLY_SEARCH_PRO_LABEL,
    CONVENTIONAL_LABEL,
    SEARCH_PRO_LABEL,
    DONT_SHOW_VIDEO_LABEL,
} from "../../i18n/i18nLabel";
import { useState } from "react";
import BaseDialog from "../BaseDialog/BaseDialog";
import BaseDialogHeader from "../BaseDialog/BaseDialogHeader";
import BaseDialogBody from "../BaseDialog/BaseDialogBody";
import { useStore } from "../../hooks/store/store";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    CONVENTIONAL,
    FHA,
    PRIMARY_COLOR,
} from "../../constants/constants";
import {
    GET_INVESTMENT_PROFILE_LOAN,
} from "../../constants/store/getters";
import { PADZILLY_SWIFTMATCH } from "../../constants/urls";
import HelpIcon from '@mui/icons-material/Help';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import Fha from "./SearchPro/Fha";
import Conventional from "./SearchPro/Conventional";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

export default function SearchPro({ onClose, bottomBar }) {

    const { state } = useStore();

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDialogBeforeHide = () => {
        setOpen(false);
    }

    const openHelp = () => {
        window.open(PADZILLY_SWIFTMATCH, '_blank');
    };

    const [checked, setChecked] = useState(localStorage.getItem("dont_show_search_pro_video") ? true : false);

    const handleChangeCheckbox = (event) => {
        setChecked(event.target.checked);
    };

    const handleDontShowVideo = () => {
        if (checked) {
            localStorage.setItem("dont_show_search_pro_video", true);
        } else {
            localStorage.removeItem("dont_show_search_pro_video");
        }
    }

    return (
        <Box>
            {
                !bottomBar
                    ? <BaseBtn
                        label={t(SEARCH_PRO_LABEL)}
                        onClick={() => { setOpen(true) }}
                    />
                    :
                    <Grow
                        in={true}
                        {...({ timeout: 500 })}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer"
                            }}
                            onClick={() => { setOpen(true) }}
                        >
                            <SavedSearchIcon fontSize="medium" sx={{ color: PRIMARY_COLOR }} />
                            <span style={{ color: PRIMARY_COLOR }}>{t(SEARCH_PRO_LABEL)}</span>
                        </Box>
                    </Grow>
            }
            <BaseDialog
                maxWidth="xs"
                open={open}
                onClose={handleDialogBeforeHide}
            >
                <BaseDialogHeader close={() => { handleDialogBeforeHide(); }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {
                            localStorage.getItem("dont_show_search_pro_video")
                                ? <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: "0px",
                                            margin: "0px"
                                        }}
                                    >
                                        {t(PADZILLY_SEARCH_PRO_LABEL)}
                                    </p>
                                    <HelpIcon
                                        style={{ marginLeft: "14px" }}
                                        className="cursor"
                                        fontSize="small"
                                        onClick={openHelp}
                                    />
                                </Box>
                                : null
                        }
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                                checked={checked}
                                onChange={handleChangeCheckbox}
                                size="small"
                                sx={{ width: "10px", height: "10px", marginRight: "5px" }}
                                color="secondary"
                            />
                            <span style={{ fontSize: ".7rem" }}>{t(DONT_SHOW_VIDEO_LABEL)}</span>
                        </Box>
                    </Box>
                </BaseDialogHeader>
                <BaseDialogBody>
                    <Box sx={{ width: '100%' }}>
                        {
                            !localStorage.getItem("dont_show_search_pro_video")
                                ? <img alt="search pro" src="images/search_pro.jpg" style={{ width: "100%" }} />
                                : null
                        }
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                {!state[GET_INVESTMENT_PROFILE_LOAN] || state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === CONVENTIONAL
                                    ? < Tab label={t(CONVENTIONAL_LABEL)} />
                                    : null
                                }
                                {!state[GET_INVESTMENT_PROFILE_LOAN] || state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === FHA
                                    ? <Tab label={t(FHA)} />
                                    : null
                                }
                            </Tabs>
                        </Box>
                        {!state[GET_INVESTMENT_PROFILE_LOAN] || state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === CONVENTIONAL
                            ? <CustomTabPanel value={value} index={0}>
                                <Conventional
                                    onClose={() => {
                                        onClose();
                                        handleDontShowVideo();
                                        handleDialogBeforeHide();
                                    }}
                                />
                            </CustomTabPanel>
                            : null
                        }
                        {!state[GET_INVESTMENT_PROFILE_LOAN] || state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === FHA
                            ? <CustomTabPanel value={value} index={state[GET_INVESTMENT_PROFILE_LOAN] ? 0 : 1}>
                                <Fha
                                    onClose={() => {
                                        onClose();
                                        handleDontShowVideo();
                                        handleDialogBeforeHide();
                                    }}
                                />
                            </CustomTabPanel>
                            : null
                        }
                    </Box>
                </BaseDialogBody>
            </BaseDialog>
        </Box>
    )
}