import DialogActions from '@mui/material/DialogActions';

export default function BaseDialogFooter(props) {
    return (
        <DialogActions
            style={{ padding: "14px", borderTop: "1px solid rgba(0, 0, 0, .05)" }}
            className={props.actions ? "dialogFooter" : props.className ?? ""}
        >
            {props.children}
        </DialogActions>
    );
}