import { PROPERTY_SRC } from "../../constants/constants";
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { Link as LinkTo } from "react-router-dom";
import { HOME_DETAILS } from "../../constants/routes";
import InfoCard from "./InfoCard";
import MediaCard from "./MediaCard";

export default function PropertyTile({ property }) {

  const card = (
    <LinkTo
      to={HOME_DETAILS + property.propertyId}
      className="cardLink"
      style={{ cursor: "pointer" }}
    >
      <Card sx={{ height: "100%" }}>
        <CardMedia
          sx={{ height: 180 }}
          image={property?.media?.[0]?.mediaUrl ?? PROPERTY_SRC}
        >
          <MediaCard
            status={property.status}
            isFavorite={property.isFavoriteProperty}
            property={property}
          />
        </CardMedia>
        <CardContent>
          <InfoCard
            property={property}
            paymentValues={true}
          />
        </CardContent>
      </Card >
    </LinkTo>
  );

  return card;
};
