import { Card, CardContent } from "@mui/material";
import { useTranslation } from 'react-i18next';
import {
    EMAIL_LABEL,
    MISSING_EMAIL_LABEL,
    MISSING_FIRST_NAME_LABEL,
    MISSING_LAST_NAME_LABEL,
    NAME_LABEL,
    PERSONAL_INFO_LABEL
} from "../../../i18n/i18nLabel";
import { useStore } from "../../../hooks/store/store";
import {
    GET_PROFILE,
    GET_PROFILE_EMAIL
} from "../../../constants/store/getters";

export default function BuyerRoleProfile() {

    const { t } = useTranslation();

    const { state } = useStore();

    return (
        <Card>
            <CardContent>
                <p style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    marginTop: "0px"
                }}>
                    {t(PERSONAL_INFO_LABEL)}
                </p>
                <p style={{
                    fontWeight: "bold",
                    marginBottom: "0px"
                }}>
                    {t(NAME_LABEL)}
                </p>
                <div
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <span>
                        {state[GET_PROFILE]?.firstName ?? t(MISSING_FIRST_NAME_LABEL)}
                    </span>
                    &nbsp;
                    <span>
                        {state[GET_PROFILE]?.lastName ?? t(MISSING_LAST_NAME_LABEL)}
                    </span>
                </div>
                <p style={{
                    fontWeight: "bold",
                    marginBottom: "0px"
                }}>
                    {t(EMAIL_LABEL)}
                </p>
                <span>{state[GET_PROFILE_EMAIL] ?? t(MISSING_EMAIL_LABEL)}</span>
            </CardContent>
        </Card>
    );
}