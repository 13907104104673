import { useTranslation } from 'react-i18next';
import {
    FUND_LIMIT_LABEL,
    FUNDS_ERROR_LABEL,
    GO_BACK_TO_SEARCH_LABEL,
    INVESTMENT_PROFILE_LABEL,
    MORTGAGE_ERROR_LABEL,
    PAYMENT_LIMIT_LABEL,
    SAVE_CHANGES_LABEL,
} from "../../../i18n/i18nLabel";
import { AMOUNTS_BELOW_TEXT } from "../../../i18n/i18nText";
import CardActions from "@mui/material/CardActions";
import { Box } from "@mui/material";
import BaseBtn from "../../BaseComponents/BaseBtn";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useState, useRef, useEffect } from "react";
import { validations } from "../../../utils/validations";
import { HELP_URL } from "../../../constants/urls";
import HelpIcon from '@mui/icons-material/Help';
import BaseCurrency from "../../BaseComponents/BaseCurrency";
import {
    getInvestmentProfile,
    updateInvestmentProfile
} from "../../../services/investmentProfileServices";
import { Link as LinkTo } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import BaseAlert from "../../BaseComponents/BaseAlert";
import { useError } from "../../../hooks/handleError";
import { handleError } from "../../../utils/functions";
import { HOME_ROUTE } from "../../../constants/routes";
import { useStore } from "../../../hooks/store/store";
import {
    GET_PAYMENT_LIMIT,
    GET_FUNDS_LIMIT,
    GET_PROFILE_TOKEN,
    GET_INVESTMENT_PROFILE,
    GET_INVESTMENT_PROFILE_LOAN,
    GET_LAST_SEARCH
} from "../../../constants/store/getters";

export default function InvestmentProfile() {

    const { t } = useTranslation();

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const { state, dispatch } = useStore();

    const PAYMENT_LIMIT = "payment_limit";

    const FUNDS_LIMIT = "funds_limit";

    const payment_limit = state[GET_INVESTMENT_PROFILE_LOAN]?.totalHousingExpenses
        ? Math.ceil(state[GET_INVESTMENT_PROFILE_LOAN]?.totalHousingExpenses)
        : state[GET_PAYMENT_LIMIT];

    const funds_limit = state[GET_INVESTMENT_PROFILE_LOAN]?.totalCashInBank
        ? Math.ceil(state[GET_INVESTMENT_PROFILE_LOAN]?.totalCashInBank)
        : state[GET_FUNDS_LIMIT];

    const [paymentLimit, setPaymentLimit] = useState(payment_limit);

    const [fundsLimit, setFundsLimit] = useState(funds_limit);

    const [errors, setErrors] = useState({});

    const activeErrors = useRef(false);

    const [saving, setSaving] = useState(false);

    const { error, setErrorMessage, clearError } = useError();

    const validationValues = [
        { validation: ["required", "isGreaterThanCero"], value: paymentLimit, key: "payment_limit" },
        { validation: ["required", "isGreaterThanCero"], value: fundsLimit, key: "funds_limit" },
        { validation: ["paymentLimitAndFundLimit"], value: [paymentLimit, fundsLimit], key: "payment_limit" },
    ];

    useEffect(() => {
        const getProfile = async () => {
            try {
                if (state[GET_PROFILE_TOKEN]) {
                    if (state[GET_INVESTMENT_PROFILE] === null
                        || state[GET_INVESTMENT_PROFILE] === undefined) {
                        const profile = await getInvestmentProfile(state[GET_PROFILE_TOKEN]);
                        if (profile) {
                            dispatch({ action: GET_INVESTMENT_PROFILE, value: profile });
                        }
                    }
                } else {
                    setErrorMessage(handleError());
                }
            } catch (e) {
                setErrorMessage(handleError(e));
            }
        };

        getProfile();
    }, []);

    const showError = (errorValue, field) => {
        if (field === FUNDS_LIMIT && Array.isArray(errorValue) && errorValue.length > 0) {
            return t(FUNDS_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        if (field === PAYMENT_LIMIT && Array.isArray(errorValue) && errorValue.length > 0) {
            return t(MORTGAGE_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        return "";
    };

    const openHelp = () => {
        window.open(HELP_URL, '_blank');
    };

    const updateInvProfile = async () => {
        if (!activeErrors.current) {
            try {
                setSaving(true);
                const payLimit = typeof paymentLimit !== "number" ? parseFloat(paymentLimit.replace(/,/g, '')) : parseFloat(paymentLimit);
                const fundLimit = typeof fundsLimit !== "number" ? parseFloat(fundsLimit.replace(/,/g, '')) : parseFloat(fundsLimit);

                let invProfile = state[GET_INVESTMENT_PROFILE];

                if (invProfile) {
                    invProfile.investmentAmount = fundLimit;
                    invProfile.paymentLimit = payLimit;
                }

                if (state[GET_PROFILE_TOKEN]) {
                    const updatedInvProfile = await updateInvestmentProfile(invProfile, state[GET_PROFILE_TOKEN]);
                    if (updatedInvProfile) {
                        // Reset filterQualified if payment or funds change
                        const search = state[GET_LAST_SEARCH];
                        if (search?.filterQualified) {
                            switch (search.filterQualified) {
                                case 3:
                                    if (state[GET_PAYMENT_LIMIT] !== payLimit) {
                                        search.filterQualified = 2;
                                    }
                                    if (state[GET_FUNDS_LIMIT] !== fundLimit) {
                                        search.filterQualified = 1;
                                    }
                                    break;
                                case 2:
                                    if (state[GET_FUNDS_LIMIT] !== fundLimit) {
                                        search.filterQualified = 0;
                                    }
                                    break;
                                case 1:
                                    if (state[GET_PAYMENT_LIMIT] !== payLimit) {
                                        search.filterQualified = 0;
                                    }
                                    break;
                                default: search.filterQualified = 0;
                            }

                        }
                        localStorage.setItem("last_search", JSON.stringify(search));
                        dispatch({ action: GET_LAST_SEARCH, value: search });
                        dispatch({ action: GET_INVESTMENT_PROFILE, value: updatedInvProfile });
                        dispatch({ action: GET_PAYMENT_LIMIT, value: payLimit });
                        dispatch({ action: GET_FUNDS_LIMIT, value: fundLimit });
                    }

                } else {
                    setErrorMessage(handleError());
                }
            } catch (e) {
                setErrorMessage(handleError(e));
            } finally {
                setSaving(false);
            }
        }
    };

    return (
        <Card sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
        }}>
            <CardContent>
                <BaseAlert alert={error} onClose={() => { clearError(); }} />
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <p style={{ fontWeight: "bold", marginTop: "0px" }}>{t(INVESTMENT_PROFILE_LABEL)}</p>
                    {state[GET_INVESTMENT_PROFILE_LOAN]
                        ? <span
                            style={{ marginBottom: "10px" }}
                        >{t(AMOUNTS_BELOW_TEXT)}</span>
                        : null}
                    <label>{t(PAYMENT_LIMIT_LABEL)}</label>
                    <BaseCurrency
                        value={paymentLimit}
                        error={showError(errors.payment_limit, PAYMENT_LIMIT)}
                        onInputChange={(value) => {
                            validationValues[0].value = value;
                            validationValues[2].value = [value, fundsLimit];
                            setPaymentLimit(value);
                            setErrors(validations(validationValues));
                        }}
                        style={{ maxWidth: "300px" }}
                        disabled={state[GET_INVESTMENT_PROFILE_LOAN] ? true : false}
                    />
                    <br />
                    <div className="displayFlexRow">
                        <label>{t(FUND_LIMIT_LABEL)}</label>
                        <HelpIcon
                            style={{ marginLeft: "14px" }}
                            className="cursor"
                            fontSize="small"
                            onClick={openHelp}
                        />
                    </div>
                    <BaseCurrency
                        value={fundsLimit}
                        error={showError(errors.funds_limit, FUNDS_LIMIT)}
                        onInputChange={(value) => {
                            validationValues[1].value = value;
                            validationValues[2].value = [paymentLimit, value];
                            setFundsLimit(value);
                            setErrors(validations(validationValues));
                        }}
                        style={{ maxWidth: "300px" }}
                        disabled={state[GET_INVESTMENT_PROFILE_LOAN] ? true : false}
                    />
                </Box>
            </CardContent>
            <CardActions>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: isSmOrSmaller ? "column" : "row",
                    width: "100%",
                }}>
                    {!state[GET_INVESTMENT_PROFILE_LOAN]
                        ? <BaseBtn
                            label={t(SAVE_CHANGES_LABEL)}
                            style={{
                                width: "174px",
                                borderRadius: "3px"
                            }}
                            onClick={() => {
                                const eList = validations(validationValues);
                                setErrors(eList);
                                (Object.keys(eList).length === 0)
                                    ? activeErrors.current = false
                                    : activeErrors.current = true
                                updateInvProfile();
                            }}
                            loading={saving}
                        />
                        : null
                    }
                    <LinkTo to={HOME_ROUTE}>
                        <BaseBtn
                            label={t(GO_BACK_TO_SEARCH_LABEL)}
                            style={{
                                width: "174px",
                                borderRadius: "3px",
                                marginLeft: !isSmOrSmaller ? "8px" : "",
                                marginTop: isSmOrSmaller ? "8px" : ""
                            }}
                            variant="outlined"
                            onClick={() => { return; }}
                        />
                    </LinkTo>
                </Box>
            </CardActions>
        </Card>
    );
}