import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Container } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { GETTING_DETAILS_TEXT } from "../../i18n/i18nText";

export default function PropertyDetailSkeleton() {

    const { t } = useTranslation();

    return (
        <> <Skeleton variant="rectangular" width="100%" height="56px" style={{ marginTop: "54px" }} />
            <Container>
                <Box sx={{ marginTop: "20px" }}>
                    <Skeleton variant="rectangular" width="100%" height="400px" style={{ borderRadius: "20px" }} />
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <Skeleton variant="rectangular" width="20%" height="50px" sx={{ marginTop: "10px" }} />
                        <Skeleton variant="rectangular" width="40%" height="50px" sx={{ marginTop: "10px" }} />
                        <Skeleton variant="rectangular" width="100%" height="70px" sx={{ marginTop: "10px" }} />
                        <Skeleton variant="rectangular" width="100%" height="400px" sx={{ marginTop: "10px" }} />
                        <Skeleton variant="rectangular" width="100%" height="400px" sx={{ marginTop: "10px", marginBottom: "20px" }} />
                        <Backdrop
                            sx={{
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                                background: "#D4D8DB4A",
                            }}
                            open={true}
                        >
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <CircularProgress style={{ color: "#F1592A" }} size={40} />
                                <p>{t(GETTING_DETAILS_TEXT)}</p>
                            </Box>
                        </Backdrop>
                    </Box>
                </Box>
            </Container>
        </>
    );
}