import { Box } from "@mui/material";
import {
    CONDOMINIUM,
    MOBILE_HOME,
    SINGLE_FAMILY,
    TOWNHOUSE,
} from "../../../constants/constants";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import PaymentAndFoundValues from "../../properties/PaymentAndFoundValues";
import PaymentChart from "../OtherComponents/PaymentChart";
import GoogleMapsEmbed from "../OtherComponents/GoogleMapsEmbed";
import {
    BUILT_IN_LABEL,
    CONDOMINIUM_LABEL,
    MOBILE_HOME_LABEL,
    MONTHLY_LABEL,
    NO_INFO_LABEL,
    SINGLE_FAMILY_LABEL,
    TAXES_LABEL,
    TOWNHOUSE_LABEL,
} from "../../../i18n/i18nLabel";
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { setCurrencyValue } from "../../../utils/functions";
import { useState } from "react";
import PaymentBreakdown from "../../Dialogs/PaymentBreakdown";

export default function DetailsInfo({ property }) {

    const { t } = useTranslation();

    const theme = useTheme();

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);

    const handleDialog = () => setOpen(!open);

    return (
        <Box>
            <Box sx={{
                display: "flex",
                paddingTop: "20px",
                minHeight: "40px",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "20px",
            }}>
                <FmdGoodOutlinedIcon fontSize="large" />
                <p style={{ margin: "0px", fontSize: "1.3rem" }}>
                    {property?.streetAddress ? (property?.streetAddress.trim() + ",") : ""}
                    {" "}
                    {property?.city ? (property?.city + ",") : ""}
                    {" "}
                    {property?.state ?? ""}
                    {" "}
                    {property?.zipCode ?? ""}
                </p>
            </Box>
            <Box sx={{
                display: "flex",
                width: "100%",
                flexDirection: isMdOrSmaller ? "column" : "row",
                borderBottom: "solid 1px grey",
                paddingBottom: "20px",
            }}>
                {<Box sx={{
                    width: isMdOrSmaller ? "100%" : "50%"
                }}>
                    <Box>
                        <GoogleMapsEmbed
                            onlyLocation={`${property?.latitude}, ${property?.longitude}`}
                        />
                    </Box>
                </Box>}
                <Box sx={{
                    width: isMdOrSmaller ? "100%" : "50%",
                    display: "flex",
                    flexDirection: isMdOrSmaller ? "column" : "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: isMdOrSmaller ? "20px" : "0px"
                }}>
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                    }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "10px"
                        }}>
                            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                <HomeWorkOutlinedIcon sx={{ marginRight: "5px" }} fontSize="large" />
                                {property?.propertyType === CONDOMINIUM ?
                                    t(CONDOMINIUM_LABEL) : property?.propertyType === MOBILE_HOME ?
                                        t(MOBILE_HOME_LABEL) : property?.propertyType === TOWNHOUSE ?
                                            t(TOWNHOUSE_LABEL) : property?.propertyType === SINGLE_FAMILY ?
                                                t(SINGLE_FAMILY_LABEL) : t(NO_INFO_LABEL)}
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <CalendarMonthOutlinedIcon sx={{ marginRight: "5px" }} fontSize="large" />
                                <span>{t(BUILT_IN_LABEL)} {property?.builtYear ?? "-"}</span>
                            </Box>
                        </Box>

                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "10px"
                        }}>
                            <Box sx={{ marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                <HandshakeOutlinedIcon sx={{ marginRight: "5px" }} fontSize="large" />
                                <span style={{ fontWeight: "bold" }}>HOA</span>
                                <span style={{ marginLeft: "5px" }}>${setCurrencyValue(Math.ceil(property?.hoa))}/{t(MONTHLY_LABEL)}</span>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <PaidOutlinedIcon sx={{ marginRight: "5px" }} fontSize="large" />
                                <span style={{ fontWeight: "bold" }}>{t(TAXES_LABEL)}</span>
                                <span style={{ marginLeft: "5px" }}>${setCurrencyValue(Math.ceil(property?.taxes))}</span>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <Box>
                            <PaymentChart
                                property={property}
                                width={480}
                            />
                        </Box>
                        <Box sx={{ width: "80%" }}>
                            <PaymentAndFoundValues
                                property={property}
                                onClick={() => { }}
                            />
                            <PaymentBreakdown
                                open={open}
                                onClose={() => { handleDialog(); }}
                                maxWidth="sm"
                                property={property}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}