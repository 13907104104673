import { Box } from "@mui/material";
import PropertyCard from "./PropertyCard";
import CircularProgress from '@mui/material/CircularProgress';
import Container from "@mui/material/Container";

export default function SavedPropertiesList(props) {

    const handlePropertiesToComparison = (property, action) => {
        if (action) {
            if (props.homesToCompare.length < 5) {
                if (props.homesToCompare.length === 4) {
                    props.handleIsFull(true);
                }
                return props.handleHomesToCompare([...props.homesToCompare, ...[property]]);
            } else {
                return props.handleHomesToCompare(props.homesToCompare);
            }
        } else {
            const index = props.homesToCompare.findIndex(item => item.propertyId === property.propertyId);
            if (index !== -1) {
                props.handleIsFull(false);
                return props.handleHomesToCompare([...props.homesToCompare.slice(0, index), ...props.homesToCompare.slice(index + 1)]);
            }
            return props.handleHomesToCompare(props.homesToCompare);
        }
    }

    const propertyList = props.propertyList.map((property) => (
        <PropertyCard
            key={property.propertyId}
            property={property}
            comparison={props.comparison}
            handleComparison={(value, operation) => {
                handlePropertiesToComparison(value, operation);
            }}
            isFull={props.isFull}
        />
    ));

    return (
        <Container sx={{ marginTop: "64px", paddingBottom: "20px" }}>
            <Box
                sx={(theme) => ({
                    display: "grid",
                    gap: "1rem",
                    gridAutoRows: "1fr",
                    [theme.breakpoints.down("xs")]: {
                        gridTemplateColumns: "1fr",
                    },
                    [theme.breakpoints.between("600", "950")]: {
                        gridTemplateColumns: "repeat(2, 1fr)",
                    },
                    [theme.breakpoints.between("950", "xl")]: {
                        gridTemplateColumns: "repeat(3, 1fr)",
                    },
                    "@media (min-width: 1360px)": {
                        gridTemplateColumns: "repeat(4, 1fr)",
                    },
                })}
            >
                {propertyList}
            </Box>
            <Box sx={{
                marginTop: "10px",
                display: props.isLoading && !props.comparison ? "flex" : "none",
                justifyContent: "center"
            }}>
                <CircularProgress style={{
                    color: "#F1592A",
                    marginBottom: "4px",
                }} size={30}
                />
            </Box>
        </Container>
    );
}