import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { Box } from "@mui/material";
import { useTranslation } from 'react-i18next';
import {
    CHECK_YOUR_CONNECTION_TEXT,
    PROBLEM_LOADING_PROPERTIES_TEXT
} from "../../i18n/i18nText";
import LandingPageFooter from "../LandingPage/LandingPageFooter";

export default function ErrorMessagePropertiesList() {

    const { t } = useTranslation();

    return (
        <Box
            sx={{ height: "100vh" }}
        >
            <Box
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}
            >
                <Box sx={{ marginTop: '164px' }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                        <ErrorOutlinedIcon
                            fontSize="large"
                        />
                    </Box>
                    <p style={{ textAlign: "center" }}>
                        {t(PROBLEM_LOADING_PROPERTIES_TEXT)}
                    </p>
                    <p style={{ textAlign: "center" }}>
                        {t(CHECK_YOUR_CONNECTION_TEXT)}
                    </p>
                </Box>
                <LandingPageFooter />
            </Box >
        </Box>
    );
}
