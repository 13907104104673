import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getProperties, getLocations } from "../../services/propertiesServices";
import HomeSearchGoalsDialog from "../Dialogs/HomeSearchGoalsDialog";
import LoadingProperties from "./LoadingProperties";
import PropertiesList from "./PropertiesList";
import NoDataProperties from "./NoDataProperties";
import ErrorMessagePropertiesList from "./ErrorMessagePropertiesList";
import {
  GET_NEW_VALUES_FOR_SEARCH,
  CONV,
  CONVENTIONAL,
  FHA,
  DESC,
} from "../../constants/constants";
import BaseAlert from "../BaseComponents/BaseAlert";
import { useError } from "../../hooks/handleError";
import { handleError } from "../../utils/functions";
import { useStore } from "../../hooks/store/store";
import {
  GET_CONVENTIONAL_DOWN_PAYMENT,
  GET_FHA_DOWN_PAYMENT,
  GET_INVESTMENT_PROFILE_LOAN,
  GET_LAST_INPUT_SEARCH,
  GET_LAST_SEARCH,
  GET_LAST_SEARCH_RESULTS,
  GET_LOCATION,
  GET_OFFER_PRICE_CONVENTIONAL,
  GET_OFFER_PRICE_FHA,
  GET_ORDER_BY,
  GET_PROFILE_TOKEN,
  GET_SELLER_REBATE_CONVENTIONAL,
  GET_SELLER_REBATE_FHA,
} from "../../constants/store/getters";
import FiltersDialog from "../Dialogs/FiltersDialog";
import LiveSearch from "./LiveSearch";
import { useTranslation } from 'react-i18next';
import {
  CASH_LABEL,
  CURRENT_LOCATION_LABEL,
  LIVE_SEARCH_LABEL,
  NO_LOCATIONS_LABEL,
  PAYMENT_LABEL
} from "../../i18n/i18nLabel";
import { Box } from "@mui/material";
import BottomNavBar from "../Pages/OtherComponents/BottomNavBar";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function SearchHomes() {

  const theme = useTheme();

  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();

  const { state, dispatch } = useStore();

  const lastSearchResults = state[GET_LAST_SEARCH_RESULTS];

  let lastSearch = state[GET_LAST_SEARCH];

  if (lastSearch) {
    if (state[GET_INVESTMENT_PROFILE_LOAN] && Object.keys(lastSearch).length !== 0) {
      lastSearch.loanType = state[GET_INVESTMENT_PROFILE_LOAN]?.loanType;
    }
  }

  const lastInputSearch = state[GET_LAST_INPUT_SEARCH];

  const [showSearchValuesDialog, setShowSearchValuesDialog] = useState(false);

  const [showFiltersDialog, setShowFiltersDialog] = useState(false);

  const [inputSearchValue, setInputSearchValue] = useState(
    lastInputSearch?.searchFor ?? ""
  );

  const [homesList, setHomesList] = useState(lastSearchResults);

  const [search, setSearch] = useState(GET_NEW_VALUES_FOR_SEARCH(state[GET_INVESTMENT_PROFILE_LOAN]));

  const [reachedBottom, setReachedBottom] = useState(false);

  const [noResults, setNoResults] = useState(false);

  const [lastProperty, setLastProperty] = useState(null);

  const [filters, setFilters] = useState(false);

  const { error, setErrorMessage, clearError } = useError();

  const [searchAction, setSearchAction] = useState(false);

  const [toggleClicked, setToggleClicked] = useState(false);

  const [searchLocationAction, setSearchLocationAction] = useState(false);

  const [orderDirection, setOrderDirection] = useState(state[GET_ORDER_BY]?.direction ?? DESC);

  const [orderBy, setOrderBy] = useState(state[GET_ORDER_BY]?.orderBy);

  const [options, setOptions] = useState({
    payment: {
      label: t(PAYMENT_LABEL),
      value:
        lastSearch?.filterQualified === 3 || lastSearch?.filterQualified === 1
          ? true
          : false,
    },
    cash: {
      label: t(CASH_LABEL),
      value:
        lastSearch?.filterQualified === 3 || lastSearch?.filterQualified === 2
          ? true
          : false,
    },
    conv: {
      label: CONV,
      value:
        lastSearch?.loanType === CONVENTIONAL
          ? true
          : lastSearch?.loanType !== CONVENTIONAL &&
            lastSearch?.loanType !== FHA
            ? true
            : false,
    },
    fha: { label: FHA, value: lastSearch?.loanType === FHA ? true : false },
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      "properties",
      search,
      state[GET_LAST_SEARCH_RESULTS],
      state[GET_FHA_DOWN_PAYMENT],
      state[GET_CONVENTIONAL_DOWN_PAYMENT],
      state[GET_SELLER_REBATE_CONVENTIONAL],
      state[GET_SELLER_REBATE_FHA],
      state[GET_OFFER_PRICE_CONVENTIONAL],
      state[GET_OFFER_PRICE_FHA]
    ],
    queryFn: async () => {
      try {
        localStorage.setItem("last_search", JSON.stringify(search));
        dispatch({ action: GET_LAST_SEARCH, value: search });
        setSearchAction(false);
        if (state[GET_PROFILE_TOKEN] && search.search && search.searchBy && search.searchState) {
          return await getProperties(
            search,
            state[GET_PROFILE_TOKEN],
            state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === FHA
              ? state[GET_FHA_DOWN_PAYMENT]
              : state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === CONVENTIONAL
                ? state[GET_CONVENTIONAL_DOWN_PAYMENT]
                : null,
            state[GET_CONVENTIONAL_DOWN_PAYMENT],
            state[GET_FHA_DOWN_PAYMENT]
          )
        }
        return [];
      } catch (e) {
        setErrorMessage(handleError(e));
      }
    },
    enabled: searchAction,
  });

  const { data: locations } = useQuery({
    queryKey: ["locations", inputSearchValue],
    queryFn: async () => {
      try {
        setSearchLocationAction(false);
        return await getLocations(inputSearchValue)
      } catch (e) {
        setErrorMessage(handleError(e));
        return null;
      }
    },
    enabled: searchLocationAction,
  });

  const handleOptions = (value) => {
    Object.entries(value).forEach((v) => {
      setOptions((prevState) => ({
        payment:
          v[1].label === t(PAYMENT_LABEL)
            ? { label: t(PAYMENT_LABEL), value: v[1].value }
            : prevState.payment,
        cash:
          v[1].label === t(CASH_LABEL)
            ? { label: t(CASH_LABEL), value: v[1].value }
            : prevState.cash,
        conv:
          v[1].label === CONV
            ? { label: CONV, value: v[1].value }
            : prevState.conv,
        fha:
          v[1].label === FHA
            ? { label: FHA, value: v[1].value }
            : prevState.fha,
      }));
    });
  };

  useEffect(() => {
    if (toggleClicked) {
      setSearch((prevState) => ({
        ...prevState,
        filterQualified:
          options.cash.value && options.payment.value
            ? 3
            : options.cash.value
              ? 2
              : options.payment.value
                ? 1
                : 0,
        loanType: state[GET_INVESTMENT_PROFILE_LOAN]
          ? state[GET_INVESTMENT_PROFILE_LOAN]?.loanType
          : options.conv.value ? CONVENTIONAL : FHA,
        lastPropertyId: null,
        lastModificationTimestamp: null,
        downpayment: options.conv.value ? state[GET_CONVENTIONAL_DOWN_PAYMENT] : state[GET_FHA_DOWN_PAYMENT],
      }));
      setHomesList([]);
      dispatch({ action: GET_LAST_SEARCH_RESULTS, value: [] });
      localStorage.setItem("last_search_results", []);
      setToggleClicked(false);
      setSearchAction(true);
    }
  }, [options])

  useEffect(() => {
    setHomesList(state[GET_LAST_SEARCH_RESULTS]);
  }, [state[GET_LAST_SEARCH_RESULTS]]);

  useEffect(() => {
    if (reachedBottom && homesList?.length && !isLoading) {
      setSearch((prevState) => ({
        ...prevState,
        lastPropertyId: homesList[homesList.length - 1]?.propertyId,
        lastModificationTimestamp:
          homesList[homesList.length - 1]?.modificationTimestamp,
      }));
      setNoResults(false);
      setSearchAction(true);
    }
  }, [reachedBottom]);

  useEffect(() => {
    if (data != undefined) {
      if (lastProperty !== search.lastPropertyId) {
        const elementsFiltered = data.filter(
          (item) =>
            !homesList.some((home) => home.propertyId === item.propertyId)
        );
        setHomesList((prevState) => [...prevState, ...elementsFiltered]);
        setNoResults(false);
        setLastProperty(search.lastPropertyId);
        if (localStorage.getItem("last_search_results") && data) {
          const properties = [...JSON.parse(localStorage.getItem("last_search_results")), ...data];
          localStorage.setItem("last_search_results", JSON.stringify(properties));
          dispatch({ action: GET_LAST_SEARCH_RESULTS, value: properties });
        }
      } else {
        if (Array.isArray(data) && data.length === 0) {
          setHomesList([]);
          localStorage.setItem("last_search_results", JSON.stringify(data));
          dispatch({ action: GET_LAST_SEARCH_RESULTS, value: data });
          setNoResults(true);
        } else {
          window.scrollTo(0, 0);
          setHomesList(data);
          localStorage.setItem("last_search_results", JSON.stringify(data));
          dispatch({ action: GET_LAST_SEARCH_RESULTS, value: data });
          setNoResults(false);
        }
      }
      setReachedBottom(false);
    }
  }, [data]);

  const verifyParameters = () => {
    if (!localStorage.getItem("profile_id")) {
      setShowSearchValuesDialog(true);
    } else {
      if (lastSearch?.search
        && lastSearch?.searchBy
        && lastSearch?.searchState
        && (lastSearchResults?.length === 0 || lastSearchResults[0]?.city !== lastSearch?.search)) {
        setSearchAction(true);
      }
    }
  };

  useEffect(() => {
    verifyParameters();
  }, []);

  const handleOrder = () => {
    if (orderDirection === DESC) {
      setHomesList(homesList.sort((a, b) => b[orderBy] - a[orderBy]));
    } else {
      setHomesList(homesList.sort((a, b) => a[orderBy] - b[orderBy]));
    }

    localStorage.setItem("last_search_results", JSON.stringify(homesList));
    dispatch({ action: GET_LAST_SEARCH_RESULTS, value: homesList });
    dispatch({ action: GET_ORDER_BY, value: { orderBy: orderBy, direction: orderDirection } });
  }

  return (
    <Box>
      <BaseAlert alert={error} onClose={() => { clearError(); }} />
      <LiveSearch
        fullWidth
        label={t(LIVE_SEARCH_LABEL)}
        noOptionsText={t(NO_LOCATIONS_LABEL)}
        options={state[GET_LOCATION]
          ? [{ description: t(CURRENT_LOCATION_LABEL), isCurrentLocation: true }, ...locations ?? []]
          : locations ?? []
        }
        getOptionLabel="description"
        search={search}
        value={search?.searchValue ?? lastInputSearch}
        onInputChange={(value) => {
          setInputSearchValue(value);
          setSearchLocationAction(true);
        }}
        inputValue={inputSearchValue}
        onChange={(value) => {
          if (value?.searchFor) {
            setSearch((prevState) => ({
              ...prevState,
              searchBy: value.searchBy,
              search: value.searchFor,
              searchState: value.state,
              lastPropertyId: null,
              lastModificationTimestamp: null,
            }));
            setLastProperty(null);
            if (value !== "") {
              localStorage.setItem("last_input_search", JSON.stringify(value));
              setSearchAction(true);
            }
          }
        }}
        onClick={() => { }}
        searchOptions={options}
        searchOptionsChange={(value) => {
          setToggleClicked(true);
          handleOptions(value);
        }}
        openFilters={() => setShowFiltersDialog(true)}
        filters={(value) => {
          setSearch((prevState) => ({
            ...prevState,
            [value]: null,
            lastModificationTimestamp: null,
            lastPropertyId: null,
          }));
          setHomesList([]);
          dispatch({ action: GET_LAST_SEARCH_RESULTS, value: [] });
          localStorage.setItem("last_search_results", []);
          setSearchAction(true);
        }}
        isFilters={(value) => setFilters(value)}
        handleSearch={(value) => {
          value.lastModificationTimestamp = null;
          value.lastPropertyId = null;
          setSearch(value);
          setHomesList([]);
          dispatch({ action: GET_LAST_SEARCH_RESULTS, value: [] });
          localStorage.setItem("last_search_results", []);
          if (search.search && search.searchBy && search.searchState) {
            setSearchAction(true);
          }
        }}
        handleOrder={() => { handleOrder(); }}
        setOrderBy={(value) => setOrderBy(value)}
        setOrderDirection={(value) => setOrderDirection(value)}
        orderBy={orderBy}
        orderDirection={orderDirection}
        searchPro={() => setSearchAction(true)}
      />
      {isError ? (
        <ErrorMessagePropertiesList />
      ) : Array.isArray(homesList) && homesList?.length > 0 ? (
        isLoading && lastProperty === search.lastPropertyId ? (
          <LoadingProperties filters={filters} loading={isLoading} />
        ) : (
          <PropertiesList
            filters={filters}
            list={homesList}
            noResults={noResults && (homesList?.length <= search.pageSize)}
            loading={isLoading}
            reachedBottom={() => {
              setReachedBottom(true)
            }}
          />
        )
      ) : isLoading ? (
        <LoadingProperties filters={filters} loading={isLoading} />
      ) : (
        <NoDataProperties noResults={noResults && state[GET_LAST_INPUT_SEARCH]} />
      )}

      {isSmOrSmaller
        ? <BottomNavBar searchPro={() => setSearchAction(true)} />
        : null
      }
      <HomeSearchGoalsDialog
        open={showSearchValuesDialog}
        onClose={() => {
          setShowSearchValuesDialog(false);
          setSearchAction(true);
        }}
      />
      <FiltersDialog
        open={showFiltersDialog}
        onClose={() => setShowFiltersDialog(false)}
        search={search}
        changeSearch={(value) => {
          value.lastModificationTimestamp = null;
          value.lastPropertyId = null;
          setSearch(value);
          setHomesList([]);
          dispatch({ action: GET_LAST_SEARCH_RESULTS, value: [] });
          localStorage.setItem("last_search_results", []);
          setSearchAction(true);
        }}
      />
    </Box>
  );
}
