import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import PercentIcon from '@mui/icons-material/Percent';

export default function BasePercent(props) {

    const [value, setValue] = useState(props.value ?? '0');

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const { label, required, error, disabled } = props;

    const handleChange = (event) => {
        let valueP = event.target.value;

        const regex = /^-?[0-9]*\.?[0-9]{0,2}$/;

        if (valueP === '-') {
            setValue(valueP);
        } else if (regex.test(valueP)) {
            setValue(valueP);
            if (props.onInputChange) {
                props.onInputChange(parseFloat(valueP) || 0);
            }
        }
    };

    const handleBlur = () => {
        if (value === '-' || value === "" || value === "-0") {
            setValue('0');
            if (props.onInputChange) {
                props.onInputChange(0);
            }
        }
        if (props.onBlur) {
            props.onBlur();
        }
    };

    return (
        <TextField
            label={label}
            type="text"
            color='secondary'
            error={error ? true : false}
            required={required}
            placeholder={props.placeholder ?? null}
            autoComplete='off'
            size="small"
            style={props.style ?? null}
            variant={props.variant ?? "standard"}
            helperText={error || ' '}
            value={value}
            onChange={handleChange}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton>
                        <PercentIcon fontSize='small' />
                    </IconButton>
                </InputAdornment>,
                readOnly: props.readOnly ?? false,
            }}
            onBlur={handleBlur}
            FormHelperTextProps={{
                style: { minHeight: '5px' },
            }}
            disabled={disabled}
        />
    );
}