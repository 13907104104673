export const HELP_URL = "https://help.padzilly.info/lap_profile";
export const CONNECTED_LENDER = "https://example.com/video";
export const TERMS_CONDITIONS = "https://padzilly.com/terms.html";
export const PRIVACY_POLICY = "https://padzilly.com/privacy.html";
export const PAYMENT_EXPLAIN = "https://help.padzilly.info/payment_explain";
export const ZERO_HOA_TAX = "https://help.padzilly.info/zero-hoa-tax";
export const LICENSING = "https://www.homeservices.com/licensing";
export const COVERAGE = "https://help.padzilly.info/coverage";
export const OFFER_PRO = "https://help.padzilly.info/offerpro";
export const ZILLOW = "https://www.zillow.com/";
export const REALTOR = "https://www.realtor.com/";
export const APP_STORE = "https://apps.apple.com/us/app/padzilly-homes/id6472548267";
export const GOOGLE_PLAY = "https://play.google.com/store/apps/details?id=com.padzilly.homes&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
export const PADZILLY_SWIFTMATCH = "https://help.padzilly.info/swiftmatch";
export const FACEBOOK_LINK = "https://www.facebook.com/profile.php?id=61554671340119";
export const INSTAGRAM_LINK = "https://www.instagram.com/padzillyhomes/";
export const LINKEDIN_LINK = "https://www.linkedin.com/company/padzilly-homes/?viewAsMember=true";
export const CONTACT = "https://help.padzilly.info/contact";
export const ABOUT = "https://help.padzilly.info/about";
export const INVESTORS = "https://help.padzilly.info/investors";
export const LENDERS = "https://help.padzilly.info/lenderlp";