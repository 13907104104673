import { Box, Container, Link, Skeleton } from "@mui/material"
import React, { useMemo, useRef } from 'react';
import { useStore } from "../../hooks/store/store";
import {
    GET_FAVORITES,
    GET_LANDING_PAGE_ELEMENTS,
    GET_LANGUAGE,
} from "../../constants/store/getters";
import PropertyCard from "./PropertyCard/PropertyCard";
import IconButton from '@mui/material/IconButton';
import PropertyCardSkeleton from "./PropertyCard/PropertyCardSkeleton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useHistory } from "react-router-dom";
import { FAVORITES_ROUTE } from "../../constants/routes";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { getLandingPageStyles } from "../../utils/functions";
import {
    EN_US,
    LANDING_FAVORITE_PROPERTIES,
    PRIMARY_COLOR,
    TITLES_COLOR,
} from "../../constants/constants";
import { useTranslation } from 'react-i18next';
import {
    NO_FAVORITE_PROPERTIES_YET_LABEL,
    SAVED_HOMES_LABEL,
    VIEW_FAVORITES_LABEL
} from "../../i18n/i18nLabel";
import {
    EXPLORE_AND_SAVE_PROPERTIES_TEXT,
    NOT_SAVED_PROPERTIES_TEXT
} from "../../i18n/i18nText";
import SearchLocation from "./Search/SearchLocation";
import SearchPro from "../Dialogs/SearchPro";

export default function LandingPageFavoriteProperties() {

    const { t } = useTranslation();

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

    const history = useHistory();

    const { state } = useStore();

    const scrollContainerRef = useRef(null);

    const handleScroll = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = isSmOrSmaller ? 310 : 620;
            if (direction === 'left') {
                scrollContainerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
            } else {
                scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            }
        }
    };

    const goToLocationSearch = () => {
        history.push(FAVORITES_ROUTE);
    }

    const favoriteStyles = state[GET_LANDING_PAGE_ELEMENTS]?.length >= 0
        ? getLandingPageStyles(state[GET_LANDING_PAGE_ELEMENTS].filter(element => element.section === LANDING_FAVORITE_PROPERTIES), LANDING_FAVORITE_PROPERTIES)
        : {};

    const landingProperties = useMemo(() => {
        return (
            <Box sx={{
                width: "100%",
                background: favoriteStyles?.background ?? "white",
                display: "flex"
            }}>
                <Container>
                    <Box
                        sx={{
                            width: "100%",
                            padding: "20px"
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                paddingLeft: "10px",
                            }}
                        >
                            <p
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "1.4rem",
                                    margin: 0,
                                    color: favoriteStyles?.title?.color ?? TITLES_COLOR
                                }}
                            >
                                {
                                    favoriteStyles?.title?.text
                                        ? state[GET_LANGUAGE] === EN_US
                                            ? favoriteStyles?.title?.text
                                            : favoriteStyles?.title?.translation
                                        : t(SAVED_HOMES_LABEL)
                                }
                            </p>
                            {state[GET_FAVORITES]?.length > 0
                                ? <Box sx={{ display: "flex" }}>
                                    <IconButton
                                        onClick={() => handleScroll('left')}
                                        style={{ borderRadius: "20px", marginRight: "5px" }}
                                    >
                                        <ArrowBackIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleScroll('right')}
                                        style={{ borderRadius: "20px", marginLeft: "5px" }}
                                    >
                                        <ArrowForwardIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                                : null
                            }
                        </Box>
                        <Box sx={{ position: 'relative', width: '100%' }}>
                            <Box
                                ref={scrollContainerRef}
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: "center",
                                    overflowX: 'auto',
                                    gap: 2,
                                    paddingTop: "20px",
                                    paddingBottom: "20px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    scrollBehavior: 'smooth',
                                    '&::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                }}
                            >
                                {
                                    !state[GET_FAVORITES]
                                        ? Array.from({ length: 10 }).map((_, index) => (
                                            <PropertyCardSkeleton key={index} />
                                        ))
                                        : state[GET_FAVORITES]?.length > 0
                                            ? state[GET_FAVORITES].map((property, index) => (
                                                <React.Fragment key={index}>
                                                    <PropertyCard property={property} />
                                                </React.Fragment>
                                            ))
                                            : <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    width: "100%",
                                                    alignItems: "center",
                                                    flexDirection: "column"
                                                }}>
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    marginTop: "0px",
                                                    marginBottom: "10px"
                                                }}>
                                                    <p
                                                        style={{
                                                            fontWeight: "bold",
                                                            textAlign: "center",
                                                            fontSize: "1.5rem",
                                                            marginTop: "0px"
                                                        }}
                                                    >
                                                        {t(NO_FAVORITE_PROPERTIES_YET_LABEL)}
                                                    </p>
                                                    <p style={{ margin: "0px" }}>
                                                        {t(NOT_SAVED_PROPERTIES_TEXT)}
                                                    </p>
                                                    <p style={{ marginTop: "0px" }}>
                                                        {t(EXPLORE_AND_SAVE_PROPERTIES_TEXT)}
                                                    </p>
                                                </Box>
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexDirection: isSmOrSmaller ? "column" : "row"
                                                }}>
                                                    <SearchLocation />
                                                    &nbsp;
                                                    <SearchPro onClose={() => { }} />
                                                </Box>
                                            </Box>
                                }
                            </Box>
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                {state[GET_FAVORITES]?.length > 0
                                    ? <Link
                                        onClick={() => goToLocationSearch()}
                                        sx={{
                                            color: favoriteStyles?.btn?.color ?? PRIMARY_COLOR,
                                            cursor: "pointer",
                                            textDecoration: "none",
                                            fontSize: ".9rem",
                                            transition: "opacity 0.3s",
                                            '&:hover': {
                                                opacity: 0.8,
                                            },
                                        }}
                                    >
                                        {favoriteStyles?.btn?.text
                                            ? state[GET_LANGUAGE] === EN_US
                                                ? favoriteStyles?.btn?.text
                                                : favoriteStyles?.btn?.transition
                                            : t(VIEW_FAVORITES_LABEL)}
                                    </Link>
                                    : !state[GET_FAVORITES]
                                        ? <Skeleton width={"220px"} />
                                        : null
                                }
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        );
    }, [
        state[GET_FAVORITES],
        isSmOrSmaller,
        isMdOrSmaller
    ]);

    return landingProperties;
}