import { Box } from "@mui/material";
import { GUEST_ROLE } from "../../constants/constants";
import SingUpAgain from "./SingUpAgain";
import { useStore } from "../../hooks/store/store";
import {
    GET_INVESTMENT_PROFILE_LOAN,
    GET_PROFILE,
    GET_PROFILE_ROLE,
} from "../../constants/store/getters";
import Container from "@mui/material/Container";
import Settings from "./Settings";
import GuestRoleProfile from "./ProfileSections/GuestRoleProfile";
import BuyerRoleProfile from "./ProfileSections/BuyerRoleProfile";
import LoanOriginator from "./ProfileSections/LoanOriginator";
import InvestmentProfile from "./ProfileSections/InvestmentProfile";
import EditDownPaymentProfile from "./ProfileSections/EditDownPaymentProfile";
import LandingPageFooter from "../LandingPage/LandingPageFooter";
import LoanInformation from "./ProfileSections/LoanInformation";

export default function Profile() {

    const { state } = useStore();

    const role = state[GET_PROFILE]?.role;

    return (
        <Box sx={{ height: "100vh" }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "space-between"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <Container sx={{ marginBottom: "40px" }}>
                        {role !== undefined
                            ? <Box sx={{ marginTop: "70px" }}>
                                {role === GUEST_ROLE
                                    ? <GuestRoleProfile />
                                    : null
                                }
                                <Box sx={(theme) => ({
                                    marginTop: "10px",
                                    display: "grid",
                                    gap: ".6rem",
                                    marginBottom: "10px",
                                    [theme.breakpoints.between("1", "850")]: {
                                        gridTemplateColumns: "repeat(1, 1fr)",
                                    },
                                    "@media (min-width: 850px)": {
                                        gridTemplateColumns: "repeat(2, 1fr)",
                                    },
                                    "@media (min-width: 1600px)": {
                                        gridTemplateColumns: "repeat(3, 1fr)",
                                    },
                                })}>
                                    {state[GET_PROFILE_ROLE] !== GUEST_ROLE
                                        ? <BuyerRoleProfile />
                                        : null
                                    }
                                    {state[GET_PROFILE_ROLE] !== GUEST_ROLE
                                        ? <LoanOriginator />
                                        : null
                                    }
                                    {state[GET_INVESTMENT_PROFILE_LOAN]
                                        ? <LoanInformation />
                                        : null
                                    }
                                    <InvestmentProfile />
                                    <EditDownPaymentProfile />
                                    <Settings />
                                </Box>
                            </Box>
                            : <SingUpAgain />
                        }
                    </Container>
                </Box>
                <Box
                    sx={{
                        marginBottom: "70px"
                    }}
                >
                    <LandingPageFooter />
                </Box>
            </Box>
        </Box>
    );
}