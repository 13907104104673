import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import "./Layout.css";
import ButtonBase from "@mui/material/ButtonBase";
import { Link } from 'react-router-dom';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { NAVIGATION } from '../constants/navigation';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import SessionActions from './Login/SessionActions';
import { useStore } from '../hooks/store/store';
import {
  GET_PROFILE_ROLE,
} from '../constants/store/getters';
import {
  GUEST_ROLE,
  PRIMARY_COLOR,
  SECONDARY_COLOR
} from '../constants/constants';
import LogoNoWhite from './Images/LogoNoWhite';
import BaseChangeLanguage from './BaseComponents/BaseChangeLanguage';
import { useTranslation } from 'react-i18next';
import BottomNavBar from './Pages/OtherComponents/BottomNavBar';

export default function Layout({ children }) {

  const { state } = useStore();

  const theme = useTheme();

  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  const [mobileOpen, setMobileOpen] = useState(false);

  const [isClosing, setIsClosing] = useState(false);

  const { t } = useTranslation();

  const getPage = () => {
    return window.location.pathname;
  }

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const drawerMenu = (
    <Box sx={{ backgroundColor: "white", }}>
      <Divider
        sx={{
          marginTop: "15px",
          '&:before': {
            background: 'white',
          },
          '&:after': {
            background: 'white',
          },
        }}
      >
        <LogoNoWhite width="90px" height="20px" />
      </Divider>
      <List>
        {NAVIGATION.map((item) => (
          <ListItem
            key={item.path}
            onClick={handleDrawerClose}
            sx={{ justifyContent: "flex-end" }}
          >
            <Link to={item.path}
              style={{
                textDecoration: "none",
                color: SECONDARY_COLOR,
                textTransform: "uppercase",
              }}
            >
              <Box sx={{
                color: item.path === getPage() ? PRIMARY_COLOR : "inherit",
                '&:hover': {
                  color: PRIMARY_COLOR,
                },
                '&:active': {
                  color: PRIMARY_COLOR,
                },
              }}>
                <span>{t(item.section)}</span>
              </Box>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const drawerWidth = 240;

  const container = window.document.body;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        color="inherit"
        position="fixed"
        style={{
          justifyContent: isSmOrSmaller ? "center" : "",
          boxShadow: "none",
          borderBottom: "solid 2px #1D285B"
        }}
      >
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          height: "54px !important",
          alignItems: "center"
        }}>
          <Box sx={{ marginLeft: ".5rem" }}>
            <ButtonBase component={Link} to="/">
              <LogoNoWhite width="135px" height="30px" />
            </ButtonBase>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: !isSmOrSmaller ? "flex" : "none", alignItems: "center" }}>

              {NAVIGATION.map((item) => (
                <Link key={item.path} to={item.path}
                  style={{
                    textDecoration: "none",
                    color: SECONDARY_COLOR,
                    textTransform: "uppercase",
                    marginRight: "20px",
                    fontSize: ".87rem"
                  }}>
                  <Box sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '54px',
                    color: item.path === getPage() ? PRIMARY_COLOR : SECONDARY_COLOR,
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: item.path === getPage() ? "100%" : 0,
                      height: '2px',
                      backgroundColor: PRIMARY_COLOR,
                      transition: 'width 0.2s ease-out',
                    },
                    '&:hover::after': {
                      width: '100%',
                    },
                    '&:hover': {
                      color: PRIMARY_COLOR,
                    },
                    '&:active': {
                      color: PRIMARY_COLOR,
                      '&::after': {
                        width: '100%',
                      },
                    },
                  }}>
                    <span>{t(item.section)}</span>
                  </Box>
                </Link>
              ))}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <BaseChangeLanguage />
              {state[GET_PROFILE_ROLE] && state[GET_PROFILE_ROLE] !== GUEST_ROLE
                ? <SessionActions />
                : null
              }
              <IconButton
                size="large"
                color="inherit"
                sx={{ display: { sm: "none" } }}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </div>
      </AppBar>
      {isSmOrSmaller ? <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: 'white' },
          }}
        >
          {drawerMenu}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },

            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawerMenu}
        </Drawer>
      </Box>
        : null
      }
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}>
        {children}
        <Box>
          {isSmOrSmaller
            ? <BottomNavBar />
            : null
          }
        </Box>
      </Box >
    </Box >
  );
}
