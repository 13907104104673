import BaseDialog from "../BaseDialog/BaseDialog";
import BaseDialogBody from "../BaseDialog/BaseDialogBody";
import BaseDialogFooter from "../BaseDialog/BaseDialogFooter";
import BaseDialogHeader from "../BaseDialog/BaseDialogHeader";
import BaseBtn from "../BaseComponents/BaseBtn";
import BaseInput from "../BaseComponents/BaseInput";
import { Box } from "@mui/material";
import { useError } from "../../hooks/handleError";
import { handleError } from "../../utils/functions";
import BaseAlert from "../BaseComponents/BaseAlert";
import { validations } from "../../utils/validations";
import { useState, useRef } from "react";
import { useStore } from "../../hooks/store/store";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
    EMAIL_ERROR,
    FIRST_NAME_ERROR,
    NO_MLO_ERROR
} from "../../constants/messages";
import { getMloByEmail } from "../../services/loanServices";
import {
    GET_FUNDS_LIMIT,
    GET_INVESTMENT_PROFILE,
    GET_INVESTMENT_PROFILE_LOAN,
    GET_MLO_ID,
    GET_MLO_PROFILE,
    GET_PAYMENT_LIMIT,
    GET_PROFILE_TOKEN
} from "../../constants/store/getters";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { updateInvestmentProfile } from "../../services/investmentProfileServices";
import { useTranslation } from 'react-i18next';
import {
    ADD_LOAN_ORIGINATOR_LABEL,
    CANCEL_LABEL,
    CHANGE_LOAN_ORIGINATOR_LABEL,
    CONFIRM_LABEL,
    CONFIRM_LOAN_ORIGINATOR_LABEL,
    EMAIL_ERROR_LABEL,
    EMAIL_LABEL,
    LOAN_OFFICER_EMAIL_LABEL,
    LOAN_OFFICER_LABEL,
    LOAN_OFFICER_NAME_LABEL,
    LOAN_ORIGINATOR_LABEL,
    NAME_ERROR_LABEL,
} from "../../i18n/i18nLabel";
import {
    DO_NOT_HAVE_LOAN_ORIGINATOR_TEXT,
    LOAN_OFFICER_CONNECTED_TEXT,
    WE_ARE_CONTACTING_TEXT
} from "../../i18n/i18nText";
import { PRIMARY_COLOR } from "../../constants/constants";

export default function AddLoanOriginator(props) {

    const { t } = useTranslation();

    const { state, dispatch } = useStore();

    const NAME = "name";

    const EMAIL = "email";

    const [name, setName] = useState(null);

    const [email, setEmail] = useState(null);

    const [errors, setErrors] = useState({});

    const activeErrors = useRef(false);

    const [saving, setSaving] = useState(false);

    const confirmLoan = useRef(1);

    const { error, setErrorMessage, clearError } = useError();

    const [mlo, setMlo] = useState(null);

    const validationValues = [
        { validation: ["required", "onlyLetters"], value: name, key: NAME },
        { validation: ["required", "isEmail", "isSameEmail"], value: [email, state[GET_MLO_PROFILE]?.email], key: EMAIL },
    ];

    const showError = (errorValue, field) => {
        if (field === NAME && Array.isArray(errorValue) && errorValue.length > 0) {
            return errorValue[0] === FIRST_NAME_ERROR ? (errorValue[0]) : t(NAME_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        if (field === EMAIL && Array.isArray(errorValue) && errorValue.length > 0) {
            return errorValue[0] === EMAIL_ERROR ? t(errorValue[0]) : t(EMAIL_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        return "";
    };

    const handleDialogBeforeHide = () => {
        setEmail(null);
        setName(null);
        setErrors({});
        clearError();
        setSaving(false);
        setMlo(null);
        confirmLoan.current = 1;
        activeErrors.current = false;
        props.onClose();
    }

    const handleAddLoan = async () => {
        if (!activeErrors.current) {
            try {
                setSaving(true);
                const loan = await getMloByEmail(name, null, email, state[GET_PROFILE_TOKEN]);
                if (loan) {
                    setMlo(loan);
                    confirmLoan.current = 2
                } else {
                    setErrorMessage(t(NO_MLO_ERROR));
                }
            } catch (e) {
                setErrorMessage(handleError(e));
            } finally {
                setErrors({});
                setSaving(false);
            }
        }
    }

    const handleConfirmLoan = async () => {
        try {
            setSaving(true);

            let invPro = state[GET_INVESTMENT_PROFILE];
            invPro.loanOriginatorId = mlo?.id;
            if (invPro.loan !== null) {
                invPro.loan = null;
                invPro.paymentLimit = null;
                invPro.investmentAmount = null;
            }

            const invProfile = await updateInvestmentProfile(invPro, state[GET_PROFILE_TOKEN]);
            if (invProfile) {
                dispatch({ action: GET_INVESTMENT_PROFILE, value: invProfile });
                dispatch({ action: GET_PAYMENT_LIMIT, value: invProfile.paymentLimit });
                dispatch({ action: GET_FUNDS_LIMIT, value: invProfile.investmentAmount });
                dispatch({ action: GET_MLO_ID, value: mlo?.id });
                dispatch({ action: GET_MLO_PROFILE, value: mlo });
                dispatch({ action: GET_INVESTMENT_PROFILE_LOAN, value: null });
                handleDialogBeforeHide();
            } else {
                setErrorMessage(handleError());
            }
        } catch (e) {
            setErrorMessage(handleError(e));
        } finally {
            setSaving(false);
        }
    }

    const handleCancel = () => {
        handleDialogBeforeHide();
    }

    const titleOptions = {
        1: LOAN_ORIGINATOR_LABEL,
        2: CONFIRM_LOAN_ORIGINATOR_LABEL,
    };

    const options = {
        1: (<BaseBtn
            style={{
                borderRadius: "3px",
                background: "rgb(241 89 42 / 30%)",
                color: PRIMARY_COLOR,
                width: "222px"
            }}
            loading={saving}
            onClick={() => {
                const eList = validations(validationValues);
                setErrors(eList);
                (Object.keys(eList).length === 0)
                    ? activeErrors.current = false
                    : activeErrors.current = true
                handleAddLoan();
            }}
        >
            {state[GET_MLO_PROFILE] || state[GET_INVESTMENT_PROFILE_LOAN]
                ?
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ManageAccountsIcon fontSize="small" />
                    &nbsp;
                    {t(CHANGE_LOAN_ORIGINATOR_LABEL)}
                </Box>
                : <Box sx={{ display: "flex", alignItems: "center" }}>
                    <PersonAddIcon fontSize="small" />
                    &nbsp;
                    {t(ADD_LOAN_ORIGINATOR_LABEL)}
                </Box>}
        </BaseBtn>),
        2: (<Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <BaseBtn
                label={t(CANCEL_LABEL)}
                onClick={() => { handleCancel(); }}
                variant="outlined"
            />
            <BaseBtn
                label={t(CONFIRM_LABEL)}
                onClick={() => { handleConfirmLoan(); }}
                style={{ marginLeft: "10px" }}
            />
        </Box>)
    }

    const content = {
        1: (<Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <p style={{ fontWeight: "bold", marginTop: "0px", textAlign: "center" }}>
                {state[GET_MLO_PROFILE] || state[GET_INVESTMENT_PROFILE_LOAN]
                    ? t(CHANGE_LOAN_ORIGINATOR_LABEL)
                    : t(DO_NOT_HAVE_LOAN_ORIGINATOR_TEXT)}
            </p>
            <BaseInput
                style={{ width: "100%" }}
                label={t(LOAN_OFFICER_NAME_LABEL)}
                startIcon="person"
                required
                value={name}
                error={showError(errors.name, NAME)}
                onInputChange={(value) => {
                    validationValues[0].value = value;
                    setName(value);
                    setErrors(validations(validationValues));
                }}
            />
            <BaseInput
                style={{ width: "100%" }}
                label={t(LOAN_OFFICER_EMAIL_LABEL)}
                startIcon="email"
                required
                value={email}
                error={showError(errors.email, EMAIL)}
                onInputChange={(value) => {
                    validationValues[1].value = [value, state[GET_MLO_PROFILE]?.email];
                    setEmail(value);
                    setErrors(validations(validationValues));
                }}
            />
        </Box>),
        2: (<Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <p style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "1.2rem",
                marginTop: "0px"
            }}>
                {t(LOAN_OFFICER_CONNECTED_TEXT)}.
            </p>
            <p
                style={{ textAlign: "center" }}
            >
                {t(WE_ARE_CONTACTING_TEXT)}.
            </p>
            <Box
                sx={{
                    width: "100%"
                }}
            >
                <p
                    style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        fontSize: "1.2rem",
                    }}
                >
                    {t(LOAN_OFFICER_LABEL)}
                </p>
                <p>{mlo?.name}</p>
                <p>{mlo?.companyName}</p>
                <p
                    style={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                    }}
                ><strong>{t(EMAIL_LABEL)}: </strong><a style={{ color: "black" }} href={`mailto:${mlo?.email}`}>{mlo?.email}</a></p>
            </Box>
        </Box>)
    }

    return (
        <BaseDialog
            {...props}
        >
            <BaseDialogHeader
                title={t(titleOptions[confirmLoan.current]) || ""}
                close={() => { handleDialogBeforeHide(); }}
            />
            <BaseDialogBody>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingLeft: "14px",
                    paddingRight: "14px"
                }}>
                    <BaseAlert alert={error} onClose={() => { clearError(); }} />
                    {content[confirmLoan.current] || null}
                </Box>
            </BaseDialogBody>
            <BaseDialogFooter className="setProperties">
                {options[confirmLoan.current] || null}
            </BaseDialogFooter>
        </BaseDialog>
    );
}