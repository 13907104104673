import Slider from '@mui/material/Slider';
import { useEffect, useState } from 'react';

export default function BaseSlider(props) {

    const [value, setValue] = useState(props.value ?? 0);

    useEffect(() => {
        if (props.value || props.value === 0) {
            setValue(props.value);
        }
    }, [props.value]);

    function valuetext(value) {
        return value;
    }

    return (
        <Slider
            max={props.max ?? props.marks?.length - 1 ?? 100}
            min={props.min ?? 0}
            size="small"
            color='secondary'
            step={props.step ?? 1}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            marks={props.marks}
            value={value}
            defaultValue={0}
            onChange={(event) => {
                setValue(event.target.value);
                if (event.target.value || event.target.value === 0) {
                    setValue(event.target.value);
                }
                if (props.onInputChange) {
                    props.onInputChange(event.target.value);
                }
            }}
            disabled={props.disabled}
        />
    );
}