import TextField from '@mui/material/TextField';
import MaskedInput from 'react-text-mask';
import { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';

export default function BaseInputMask(props) {
    const dateMask = props?.mask;
    const label = props?.label;
    const required = props?.required;
    const errors = props?.error;
    const startIcon = props?.startIcon;

    const [value, setValue] = useState(props.value ?? "");

    return (
        <MaskedInput
            mask={dateMask}
            guide={false}
            value={value}
            style={props.style ?? null}
            title={errors}
            onChange={(event) => {
                setValue(event.target.value);
                if (props.onInputChange) {
                    props.onInputChange(value);
                }
            }}
            render={(ref, props) => (
                <TextField
                    {...props}
                    inputRef={ref}
                    variant="standard"
                    size='small'
                    color='secondary'
                    autoComplete='off'
                    label={label}
                    helperText={errors || ' '}
                    error={errors ? true : false}
                    required={required}
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">
                            {startIcon === "phone"
                                ? <StayCurrentPortraitIcon />
                                : null
                            }
                        </InputAdornment>)
                    }}
                    FormHelperTextProps={{
                        style: { minHeight: '5px' },
                    }}
                    onBlur={() => { if (props.onBlur) { props.onBlur() } }}
                />
            )}

        />
    );
};