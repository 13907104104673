import React, { useState, useEffect, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Route,
  useLocation,
  useHistory,
  Switch
} from "react-router-dom";
import "./custom.css";
import Layout from "./components/Layout";
import { ThemeProvider } from "@mui/material";
import "./i18n";
import {
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query";
import {
  ERROR_404,
  HOME_ROUTE,
  FAVORITES_ROUTE,
  PROFILE_ROUTE,
  // SETTINGS_ROUTE,
  HOME_DETAILS,
  LOGIN_ROUTE,
  CREATE_ACCOUNT_ROUTE,
  FORGOT_PASSWORD,
  LANDING_PAGE,
} from "./constants/routes";
import { getProperty } from "./services/propertiesServices";
import Error404 from "./components/Pages/Error404";
import ViewDetails from "./components/Pages/ViewDetails";
import {
  NO_ACTIVE_SESSION,
  NO_PROPERTY,
  LANDING_PAGE as LANDING_PAGE_CONSTANT,
  GUEST_ROLE,
  BORROWER_ROLE
} from "./constants/constants";
import CreateAccountStepper from "./components/Login/CreateAccountStepper";
import Profile from "./components/Pages/Profile";
//import Settings from "./components/Pages/Settings";
import Favorites from "./components/Pages/Favorites";
import SearchHomes from "./components/properties/SearchHomes";
import CacheBuster from 'react-cache-buster';
import packageFile from "../package.json";
import BaseLoading from "./components/BaseComponents/BaseLoading";
import { useStore } from "./hooks/store/store";
import {
  GET_FAVORITES,
  GET_INVESTMENT_PROFILE,
  GET_INVESTMENT_PROFILE_LOAN,
  GET_LANDING_PAGE_ELEMENTS,
  GET_LAST_FAVORITE_RESULT,
  GET_ORDER_BY,
  GET_PAGE,
  GET_PROFILE_FINISHED,
  GET_PROFILE_TOKEN,
  GET_STORE_COMPLETE
} from "./constants/store/getters";
import LoginStepper from "./components/Login/LoginStepper";
import { useError } from "./hooks/handleError";
import { handleError } from "./utils/functions";
import BaseAlert from "./components/BaseComponents/BaseAlert";
import ForgotPasswordStepper from "./components/Login/ForgotPasswordStepper";
import { lightTheme } from "./components/themes/themes";
import LandingPage from "./components/LandingPage/LandingPage";

export default function App() {

  const { error, setErrorMessage, clearError } = useError();

  const queryClient = new QueryClient();

  const location = useLocation();

  const history = useHistory();

  const [property, setProperty] = useState(null);

  const { state, dispatch } = useStore();

  const handlePropertyUrl = async (match) => {
    if (match) {
      const homeID = match[1];
      const validID = /^\d+$/.test(homeID);
      const validIDRealtor = /^realtor-[a-zA-Z0-9]+$/.test(homeID);
      const validIDZillow = /^zillow-[a-zA-Z0-9]+$/.test(homeID);

      if (validID || validIDZillow || validIDRealtor) {
        try {
          if (state[GET_PROFILE_TOKEN]) {
            const property = await getProperty(String(homeID), state[GET_PROFILE_TOKEN], state[GET_INVESTMENT_PROFILE_LOAN]);
            if (property.propertyId) {
              setProperty(property);
              localStorage.setItem("page", location.pathname);
              dispatch({ action: GET_PAGE, value: location.pathname });
              return;
            } else {
              setProperty(NO_PROPERTY);
              return;
            }
          }
        } catch (e) {
          const err = handleError(e);
          if (err === "Property not found.") {
            setProperty(NO_PROPERTY);
          } else {
            setErrorMessage(err);
            localStorage.setItem("page", HOME_ROUTE);
            dispatch({ action: GET_PAGE, value: HOME_ROUTE });
            history.push(HOME_ROUTE);
          }
          return;
        }
      } else {
        history.push(ERROR_404);
      }
    }
  }

  useEffect(() => {
    const routing = async () => {
      const DETAIL_REGEX = /\/details\/(.+)$/;
      const match = location.pathname.match(DETAIL_REGEX);

      if (location.pathname === LANDING_PAGE) {
        localStorage.setItem("page", LANDING_PAGE_CONSTANT);
        dispatch({ action: GET_PAGE, value: LANDING_PAGE_CONSTANT });
        history.push(LANDING_PAGE);
        return;
      }

      if (match) {
        if (!localStorage.getItem("profile_id")) {
          localStorage.setItem("property_details", location.pathname);
          dispatch({ action: GET_PAGE, value: LANDING_PAGE });
          history.push(LANDING_PAGE);
          setProperty(NO_ACTIVE_SESSION);
          return;
        } else {
          await handlePropertyUrl(match);
        }
      } else {
        if (!localStorage.getItem("profile_finished") || localStorage.getItem("profile_role") === GUEST_ROLE) {
          if (location.pathname === CREATE_ACCOUNT_ROUTE) {
            localStorage.setItem("page", CREATE_ACCOUNT_ROUTE);
            dispatch({ action: GET_PAGE, value: CREATE_ACCOUNT_ROUTE });
            history.push(CREATE_ACCOUNT_ROUTE);
            return;
          }
          if (location.pathname === LOGIN_ROUTE) {
            localStorage.setItem("page", LOGIN_ROUTE);
            dispatch({ action: GET_PAGE, value: LOGIN_ROUTE });
            history.push(LOGIN_ROUTE);
            return;
          }
          if (location.pathname === FORGOT_PASSWORD) {
            localStorage.setItem("page", FORGOT_PASSWORD);
            dispatch({ action: GET_PAGE, value: FORGOT_PASSWORD });
            history.push(FORGOT_PASSWORD);
            return;
          }
          if (state[GET_INVESTMENT_PROFILE]) {
            localStorage.setItem('profile_finished', true);
            localStorage.setItem("page", location.pathname);
            dispatch({ action: GET_PAGE, value: location.pathname });
            dispatch({ action: GET_PROFILE_FINISHED, value: true });
            history.push(location.pathname);
            return;
          }
          history.push(LANDING_PAGE);
          return;
        } else {
          if ((location.pathname === LOGIN_ROUTE
            || location.pathname === CREATE_ACCOUNT_ROUTE)
            && localStorage.getItem("profile_role") === BORROWER_ROLE) {
            dispatch({ action: GET_PAGE, value: HOME_ROUTE });
            history.push(HOME_ROUTE);
            return;
          }
          localStorage.setItem("page", location.pathname);
          dispatch({ action: GET_PAGE, value: location.pathname });
          history.push(location.pathname);
          return;
        }
      }
    };

    routing();
  }, [location.pathname]);

  const isProduction = process.env.NODE_ENV === 'production';

  const { version } = packageFile;

  const loginRoutes = useMemo(() => {
    return (
      <Switch>
        <Route exact path={CREATE_ACCOUNT_ROUTE} component={() => <CreateAccountStepper />} />
        <Route exact path={LOGIN_ROUTE} component={() => <LoginStepper />} />
        <Route exact path={FORGOT_PASSWORD} component={() => <ForgotPasswordStepper />} />
      </Switch>
    )
  }, [state[GET_STORE_COMPLETE]]);

  const landingRoute = useMemo(() => {
    return (
      <Switch>
        <Route exact path={LANDING_PAGE} component={() => <LandingPage />} />
      </Switch>
    )
  }, [state[GET_LANDING_PAGE_ELEMENTS]]);

  const favRoute = useMemo(() => {
    return (
      <Route exact path={FAVORITES_ROUTE} component={() => <Layout><Favorites /></Layout>} />
    )
  }, [
    state[GET_STORE_COMPLETE],
    state[GET_FAVORITES],
    state[GET_LAST_FAVORITE_RESULT]
  ]);

  const homeRoute = useMemo(() => {
    return (
      <Route exact path={HOME_ROUTE} component={() => <Layout><SearchHomes /></Layout>} />
    )
  }, [
    state[GET_STORE_COMPLETE],
    state[GET_ORDER_BY]
  ]);

  const detailRoute = useMemo(() => {
    return (
      <Route exact path={`${HOME_DETAILS}:id`} component={() => <Layout><ViewDetails property={property} /></Layout>} />
    );
  }, [
    state[GET_STORE_COMPLETE],
    property,
  ]);

  const render = (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<BaseLoading loading={true} />}
      metaFileDirectory={'.'}
    >
      <ThemeProvider theme={createTheme(lightTheme)}>
        <QueryClientProvider client={queryClient}>
          <BaseAlert alert={error} onClose={() => { clearError(); }} />
          <CssBaseline />
          {location.pathname === LANDING_PAGE
            ? landingRoute
            : localStorage.getItem("profile_role") === GUEST_ROLE
              ? <Switch>
                <Route exact path={CREATE_ACCOUNT_ROUTE} component={() => <CreateAccountStepper />} />
                <Route exact path={LOGIN_ROUTE} component={() => <LoginStepper />} />
                <Route exact path={FORGOT_PASSWORD} component={() => <ForgotPasswordStepper />} />
                {homeRoute}
                {favRoute}
                <Route exact path={PROFILE_ROUTE} component={() => <Layout><Profile /></Layout>} />
                {/*<Route exact path={SETTINGS_ROUTE} component={() => <Layout><Settings /></Layout>} />*/}
                {detailRoute}
                <Route exact path={ERROR_404} component={() => <Layout><Error404 /></Layout>} />
                <Route path="*" component={() => <Layout><Error404 /></Layout>} />
              </Switch>
              : !state[GET_PROFILE_FINISHED]
                ? loginRoutes
                : <Switch>
                  {homeRoute}
                  {favRoute}
                  <Route exact path={PROFILE_ROUTE} component={() => <Layout><Profile /></Layout>} />
                  {/*<Route exact path={SETTINGS_ROUTE} component={() => <Layout><Settings /></Layout>} />*/}
                  {detailRoute}
                  <Route exact path={ERROR_404} component={() => <Layout><Error404 /></Layout>} />
                  <Route path="*" component={() => <Layout><Error404 /></Layout>} />
                </Switch>
          }
        </QueryClientProvider>
      </ThemeProvider>
    </CacheBuster>
  );

  return render;
}
