import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function BaseFilterOption(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if (props.handleOpen) {
            props.handleOpen();
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (props.isOpen === false) {
            handleClose();
        }
    }, [props.isOpen]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={{ marginLeft: "2px", marginRight: "2px" }}>
            <Button
                style={{
                    color: props.disabled ? "#8080807d" : "grey",
                    borderColor: props.disabled ? "#80808033" : "grey",
                    fontSize: ".7rem",
                    background: props.disabled ? "#80808012" : "transparent",
                    fontWeight: "bold"
                }}
                aria-describedby={id}
                variant="outlined"
                onClick={handleClick}
                disabled={props.disabled}
            >
                {props.label} {!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{ marginTop: "10px" }}
            >
                <Box sx={{ p: 2 }}>
                    {props.children}
                </Box>
            </Popover>
        </div>
    );
}