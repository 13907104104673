import { Box } from "@mui/material";
import BaseBtn from "../BaseComponents/BaseBtn";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { useStore } from "../../hooks/store/store";
import {
    GET_EXISTENT_LOAN,
    GET_INVESTMENT_PROFILE,
    GET_INVESTMENT_PROFILE_LOAN,
    GET_LOAN_TYPE,
    GET_PROFILE_TOKEN
} from "../../constants/store/getters";
import { useError } from "../../hooks/handleError";
import { handleError } from "../../utils/functions";
import BaseAlert from "../BaseComponents/BaseAlert";
import { createInvestmentProfileByLoanId } from "../../services/investmentProfileServices";
import { useState } from "react";
import { APPROVED } from "../../constants/constants";
import Grow from '@mui/material/Grow';
import { useTranslation } from 'react-i18next';
import {
    CONFIRM_YOUR_LENDER_LABEL,
    EMAIL_LABEL,
    IS_MY_LENDER_LABEL,
    IS_NOT_MY_LENDER_LABEL
} from "../../i18n/i18nLabel";
import { GREAT_NEWS_TWO_TEXT } from "../../i18n/i18nText";

export default function LoanApproved() {

    const { t } = useTranslation();

    const { state, dispatch } = useStore();

    const [saving, setSaving] = useState(false);

    const { error, setErrorMessage, clearError } = useError();

    const handleNotMyLender = () => {
        localStorage.removeItem("loan_exist");
        dispatch({ action: GET_EXISTENT_LOAN, value: null });
        localStorage.setItem("loan_type", APPROVED);
        dispatch({ action: GET_LOAN_TYPE, value: APPROVED });
    }

    const handleYesMyLender = async () => {
        try {
            setSaving(true);
            let invObj = {
                monthlyIncome: null,
                monthlyDebts: null,
                investmentAmount: null,
                paymentLimit: null,
                loanOriginatorId: state[GET_EXISTENT_LOAN]?.loanOriginatorId,
                loanId: state[GET_EXISTENT_LOAN]?.loanId
            }

            const invPro = await createInvestmentProfileByLoanId(invObj, state[GET_PROFILE_TOKEN]);

            if (invPro) {
                localStorage.removeItem("loan_exist");
                dispatch({ action: GET_EXISTENT_LOAN, value: null });
                localStorage.setItem("loan_type", APPROVED);
                dispatch({ action: GET_LOAN_TYPE, value: APPROVED });
                dispatch({ action: GET_INVESTMENT_PROFILE, value: invPro });
                dispatch({ action: GET_INVESTMENT_PROFILE_LOAN, value: invPro?.loan });
                localStorage.setItem("loan_info", invPro?.loan ? true : false);
                dispatch({ action: GET_PAYMENT_LIMIT, value: invPro.paymentLimit });
                dispatch({ action: GET_FUNDS_LIMIT, value: invPro.investmentAmount });
            } else {
                setErrorMessage(handleError());
            }
        } catch (e) {
            setErrorMessage(handleError(e));
        } finally {
            setSaving(false);
        }
    }

    return (
        <Grow
            in={true}
            {...({ timeout: 500 })}
        >
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <BaseAlert alert={error} onClose={() => { clearError(); }} />
                <PersonSearchIcon fontSize="large" color="secondary" />
                <p style={{
                    fontWeight: "bold",
                    marginTop: "0px",
                    fontSize: "1.1rem"
                }}>
                    {t(GREAT_NEWS_TWO_TEXT)}
                </p>

                <Box sx={{ width: "100%" }}>
                    <p style={{ fontWeight: "bold" }}>
                        {t(CONFIRM_YOUR_LENDER_LABEL)}
                    </p>
                    {state[GET_EXISTENT_LOAN]
                        ? <>
                            <p>{state[GET_EXISTENT_LOAN]?.loanOriginatorName}</p>
                            <p>{state[GET_EXISTENT_LOAN]?.loanOriginatorCompanyName}</p>
                            <p
                                style={{
                                    whiteSpace: 'normal',
                                    wordBreak: 'break-word'
                                }}
                            ><strong>{t(EMAIL_LABEL)}: </strong><a style={{ color: "black" }} href={`mailto:${state[GET_EXISTENT_LOAN]?.loanOriginatorEmail}`}>{state[GET_EXISTENT_LOAN]?.loanOriginatorEmail}</a></p>
                        </>
                        : null
                    }
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <BaseBtn
                        label={t(IS_MY_LENDER_LABEL)}
                        onClick={() => { handleYesMyLender(); }}
                        style={{
                            width: "300px"
                        }}
                        loading={saving}
                    />
                    <BaseBtn
                        variant="outlined"
                        label={t(IS_NOT_MY_LENDER_LABEL)}
                        onClick={() => { handleNotMyLender(); }}
                        style={{
                            width: "300px",
                            marginTop: "10px"
                        }}
                    />
                </Box>
            </Box>
        </Grow>
    );
}