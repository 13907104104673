import {
    Card,
    CardActions,
    CardContent,
    Box
} from "@mui/material";
import BaseBtn from "../../BaseComponents/BaseBtn";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonRemove from "@mui/icons-material/PersonRemove";
import {
    ADD_LOAN_ORIGINATOR_LABEL,
    CHANGE_MLO_LABEL,
    COMPANY_LABEL, EMAIL_LABEL,
    LOAN_ORIGINATOR_PERSONAL_INFO_LABEL,
    NAME_LABEL,
    PHONE_LABEL,
    REMOVE_MLO_LABEL,
} from "../../../i18n/i18nLabel";
import {
    THERE_IS_NO_LOAN_O_TEXT,
    REMOVE_LOAN_ORIGINATOR_TEXT
} from "../../../i18n/i18nText";
import { useTranslation } from 'react-i18next';
import { useStore } from "../../../hooks/store/store";
import {
    GET_INVESTMENT_PROFILE,
    GET_INVESTMENT_PROFILE_LOAN,
    GET_LOAN,
    GET_MLO_PROFILE,
    GET_PROFILE,
    GET_MLO_ID,
    GET_PROFILE_TOKEN,
    GET_PAYMENT_LIMIT,
    GET_FUNDS_LIMIT,
    GET_SELLER_REBATE_CONVENTIONAL,
    GET_SELLER_REBATE_FHA,
} from "../../../constants/store/getters";
import { GUEST_ROLE, PRIMARY_COLOR, SUCCESS } from "../../../constants/constants";
import { useState, useEffect } from "react";
import AddLoanOriginator from "../../Dialogs/AddLoanOriginator";
import ConfirmationDialog from "../../Dialogs/ConfirmationDialog/ConfirmationDialog";
import { MLO_REMOVED_MESSAGE } from "../../../constants/messages";
import BaseAlert from "../../BaseComponents/BaseAlert";
import { useError } from "../../../hooks/handleError";
import { handleError } from "../../../utils/functions";
import { updateInvestmentProfile } from "../../../services/investmentProfileServices";
import SetInvProfileValues from "../../Dialogs/SetInvProfileValues";

export default function LoanOriginator() {

    const { t } = useTranslation();

    const { state, dispatch } = useStore();

    const { error, setErrorMessage, clearError } = useError();

    const [showAddLoanOriginator, setShowAddLoanOriginator] = useState(false);

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const role = state[GET_PROFILE]?.role;

    const [showInvProfileValues, setShowInvProfileValues] = useState(false);

    useEffect(() => {
        if (!state[GET_INVESTMENT_PROFILE]?.paymentLimit
            && !state[GET_INVESTMENT_PROFILE]?.investmentAmount
            && !state[GET_INVESTMENT_PROFILE]?.loan) {
            setShowInvProfileValues(true);
        }
    }, [state]);

    const removeLoan = async () => {
        try {
            let invPro = state[GET_INVESTMENT_PROFILE];

            invPro.loanOriginatorId = null;
            invPro.loanOriginator = null;
            if (invPro.loan !== null) {
                invPro.loan = null;
                invPro.paymentLimit = null;
                invPro.investmentAmount = null;
            }

            if (state[GET_PROFILE_TOKEN]) {
                const invProfile = await updateInvestmentProfile(invPro, state[GET_PROFILE_TOKEN]);
                if (invProfile) {
                    dispatch({ action: GET_INVESTMENT_PROFILE, value: invProfile });
                    dispatch({ action: GET_PAYMENT_LIMIT, value: invProfile.paymentLimit });
                    dispatch({ action: GET_FUNDS_LIMIT, value: invProfile.investmentAmount });
                    dispatch({ action: GET_MLO_PROFILE, value: null });
                    dispatch({ action: GET_MLO_ID, value: null });
                    dispatch({ action: GET_INVESTMENT_PROFILE_LOAN, value: null });
                    localStorage.setItem("search_pro_down_payment", true);
                    localStorage.setItem("loan_info", false);
                    localStorage.setItem("seller_rebate_conventional", 0);
                    localStorage.setItem("seller_rebate_fha", 0);
                    dispatch({ action: GET_SELLER_REBATE_CONVENTIONAL, value: 0 });
                    dispatch({ action: GET_SELLER_REBATE_FHA, value: 0 });
                    setErrorMessage(t(MLO_REMOVED_MESSAGE), SUCCESS);
                }
            } else {
                setErrorMessage(handleError());
            }
        } catch (e) {
            setErrorMessage(handleError(e));
        }
    }

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "space-between",
            }}
        >
            <CardContent >
                <BaseAlert alert={error} onClose={() => { clearError(); }} />
                <Box sx={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <p style={{ fontWeight: "bold", marginTop: "0px" }}>{t(LOAN_ORIGINATOR_PERSONAL_INFO_LABEL)}</p>
                    {state[GET_INVESTMENT_PROFILE]?.loanOriginatorId || state[GET_INVESTMENT_PROFILE_LOAN]
                        ? <>
                            <p style={{ margin: "0px", fontWeight: "bold" }}>{t(NAME_LABEL)}</p>
                            <p style={{ margin: "0px" }}>{state[GET_MLO_PROFILE]?.name ?? state[GET_LOAN]?.loanOriginatorName}</p>
                            <p style={{ margin: "0px", fontWeight: "bold" }}>{t(COMPANY_LABEL)}</p>
                            <p style={{ margin: "0px" }}>{state[GET_MLO_PROFILE]?.companyName ?? state[GET_LOAN]?.loanOriginatorCompanyName}</p>
                            <p style={{ margin: "0px", fontWeight: "bold" }}>{t(EMAIL_LABEL)}</p>
                            <p style={{ margin: "0px" }}>{state[GET_MLO_PROFILE]?.email ?? state[GET_LOAN]?.loanOriginatorEmail}</p>
                            <p style={{ margin: "0px", fontWeight: "bold" }}>{t(PHONE_LABEL)}</p>
                            <p style={{ margin: "0px" }}>{state[GET_MLO_PROFILE]?.phone ?? state[GET_LOAN]?.loanOriginatorPhone ?? "-"}</p>
                        </>
                        :
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                            <p style={{
                                textAlign: "center",
                                color: "grey"
                            }}>
                                {t(THERE_IS_NO_LOAN_O_TEXT)}
                            </p>
                            <BaseBtn
                                style={{
                                    borderRadius: "3px",
                                    marginTop: "10px",
                                    background: role !== GUEST_ROLE ? "rgb(241 89 42 / 30%)" : "",
                                    color: role !== GUEST_ROLE ? PRIMARY_COLOR : ""
                                }}
                                disabled={role === GUEST_ROLE}
                                onClick={() => setShowAddLoanOriginator(true)}
                            >
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <PersonAddIcon fontSize="small" />
                                    &nbsp;
                                    {t(ADD_LOAN_ORIGINATOR_LABEL)}
                                </Box>
                            </BaseBtn>
                        </Box>
                    }

                </Box>
            </CardContent>
            <CardActions>
                {state[GET_INVESTMENT_PROFILE]?.loanOriginatorId || state[GET_INVESTMENT_PROFILE_LOAN]
                    ? <Box sx={{ display: "flex", alignItems: "center" }}>
                        <BaseBtn
                            style={{
                                borderRadius: "3px",
                            }}
                            variant="outlined"
                            color="warning"
                            onClick={() => {
                                setShowAddLoanOriginator(true);
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <ManageAccountsIcon fontSize="small" />
                                &nbsp;
                                {t(CHANGE_MLO_LABEL)}
                            </Box>
                        </BaseBtn>
                        <BaseBtn
                            variant="outlined"
                            style={{
                                borderRadius: "3px",
                                marginLeft: "10px"
                            }}
                            color="error"
                            onClick={() => setShowConfirmationDialog(true)}
                        >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <PersonRemove fontSize="small" />
                                &nbsp;
                                {t(REMOVE_MLO_LABEL)}
                            </Box>
                        </BaseBtn>
                    </Box>
                    : null
                }
            </CardActions>
            <AddLoanOriginator
                open={showAddLoanOriginator}
                onClose={() => {
                    setShowAddLoanOriginator(false);
                }}
            />
            <ConfirmationDialog
                confirmation={() => { removeLoan(); }}
                open={showConfirmationDialog}
                onClose={() => {
                    setShowConfirmationDialog(false);
                }}
                title={t(REMOVE_MLO_LABEL)}
            >
                <p
                    style={{ margin: "0px" }}
                >{t(REMOVE_LOAN_ORIGINATOR_TEXT)}</p>
            </ConfirmationDialog>
            <SetInvProfileValues
                open={showInvProfileValues}
                onClose={() => {
                    setShowInvProfileValues(false);
                }}
            />
        </Card>
    );
}