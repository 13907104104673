import { Card, CardContent } from "@mui/material";
import { Box } from "@mui/material";
import {
    APPROVED,
    NOT_APPROVED,
    PRIMARY_COLOR
} from "../../constants/constants";
import Approved from "../Images/Approved";
import NotApproved from "../Images/NotApproved";
import Grow from '@mui/material/Grow';
import { useTranslation } from 'react-i18next';
import {
    CHOOSE_NOT_APPROVED_TEXT,
    CHOOSE_PRE_APPROVED_TEXT,
    LETS_CREATE_PADZILLY_SWIFTMATCH_TEXT
} from "../../i18n/i18nText";
import {
    NOT_APPROVED_LABEL,
    PRE_APPROVED_LABEL
} from "../../i18n/i18nLabel";

export default function SelectLoan({ option }) {

    const { t } = useTranslation();

    return (
        <Grow
            in={true}
            {...({ timeout: 500 })}
        >
            <Box>
                <p style={{ fontWeight: "bold", fontSize: "1rem", textAlign: "center" }}>
                    {t(LETS_CREATE_PADZILLY_SWIFTMATCH_TEXT)}
                </p>
                <Box className="cardLink" style={{ marginBottom: "10px", maxHeight: "220px" }} onClick={() => option(APPROVED)}>
                    <Card>
                        <CardContent>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column"
                            }}>
                                <Approved maxwidth="60" maxheight="60" />
                                <p style={{ fontWeight: "bold", fontSize: "1rem", marginBottom: "0px" }}>
                                    {t(PRE_APPROVED_LABEL)}
                                </p>
                                <p style={{ color: PRIMARY_COLOR, textAlign: "center", marginTop: "0px" }}>
                                    {t(CHOOSE_PRE_APPROVED_TEXT)}
                                </p>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Box className="cardLink" style={{ maxHeight: "220px" }} onClick={() => option(NOT_APPROVED)}>
                    <Card>
                        <CardContent>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column"
                            }}>
                                <NotApproved maxwidth="60" maxheight="60" />
                                <p style={{ fontWeight: "bold", fontSize: "1rem", marginBottom: "0px" }}>
                                    {t(NOT_APPROVED_LABEL)}
                                </p>
                                <p style={{ color: PRIMARY_COLOR, textAlign: "center", marginTop: "0px" }}>
                                    {t(CHOOSE_NOT_APPROVED_TEXT)}
                                </p>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Grow>
    );
}