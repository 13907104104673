import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useState, useEffect } from 'react';
import { setCurrencyValue } from '../../utils/functions';

export default function BaseCurrency(props) {

    const [value, setValue] = useState(props.value != null ? setCurrencyValue(props.value) : "");

    useEffect(() => {
        setValue(props.value != null ? setCurrencyValue(props.value) : "")
    }, [props.value]);

    const { label, required, error } = props;

    return (
        <TextField
            className={props.className ?? ""}
            style={props.style ?? null}
            label={label}
            color='secondary'
            error={error ? true : false}
            required={required}
            autoComplete='off'
            size="small"
            helperText={error || ' '}
            variant={props.variant ?? "standard"}
            InputProps={{
                startAdornment:
                    <InputAdornment
                        position="start">
                        {<AttachMoneyIcon fontSize='small' />}
                    </InputAdornment>,
                readOnly: props.readOnly ?? false,
                style: { color: props.color }
            }}
            value={value !== null ? value : ""}
            onChange={(event) => {
                const currencyValue = setCurrencyValue(event.target.value, props.nullable ? 1 : 0);
                if (currencyValue !== null && currencyValue !== "") {
                    setValue(currencyValue)
                    if (props.onInputChange) {
                        props.onInputChange(currencyValue);
                    }
                } else {
                    if (currencyValue === "0") {
                        setValue(currencyValue);
                        if (props.onInputChange) {
                            props.onInputChange(currencyValue);
                        }
                    } else {
                        setValue(null);
                        if (props.onInputChange) {
                            props.onInputChange(null);
                        }
                    }
                }
            }}
            onBlur={() => { if (props.onBlur) { props.onBlur() } }}
            FormHelperTextProps={{
                style: { height: '12px' },
            }}
            disabled={props?.disabled}
        />
    );
}