import Radio from "@mui/material/Radio";
import { Box } from "@mui/material";
import { useState, useEffect } from 'react';

export default function BaseRadio(props) {

    const [selectedValue, setSelectedValue] = useState(props.selectedValue);

    useEffect(() => { setSelectedValue(props.selectedValue) }, [props.selectedValue])

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        if (props?.onInputChange) {
            props.onInputChange(event.target.value);
        }
    };

    return (
        <Box sx={{
            display: "flex",
            alignItems: "center"
        }}>
            <Radio
                checked={selectedValue === props.value}
                color='secondary'
                size='small'
                onChange={handleChange}
                value={props.value}
            />
            <span>{props.label ?? ""}</span>
        </Box>

    );
}