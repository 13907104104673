import { Box } from "@mui/material";
import BaseBtn from "../BaseComponents/BaseBtn";
import { useState, useEffect } from 'react';
import BaseComplete from "../BaseComponents/BaseComplete";
import VerificationTimer from "./VerificationTimer";
import { verifyUser, resendOtp } from "../../services/authServices";
import BaseAlert from "../BaseComponents/BaseAlert";
import { handleError } from "../../utils/functions";
import { useError } from "../../hooks/handleError";
import { useStore } from "../../hooks/store/store";
import {
    GET_PROFILE_ID,
    GET_PROFILE,
    GET_PROFILE_TOKEN,
    GET_PROFILE_VERIFIED,
    GET_EXISTENT_LOAN,
    GET_PROFILE_EMAIL,
    GET_LOAN,
    GET_USER_ID_OTP,
} from "../../constants/store/getters";
import { getLoanByUserEmail } from "../../services/loanServices";
import Grow from '@mui/material/Grow';
import { useTranslation } from 'react-i18next';
import {
    RESEND_LABEL,
    VERIFY_LABEL
} from "../../i18n/i18nLabel";
import {
    CHECK_SPAM_TEXT,
    VERIFICATION_TEXT
} from "../../i18n/i18nText";
import { PRIMARY_COLOR } from "../../constants/constants";

export default function Verification() {

    const { t } = useTranslation();

    const { state, dispatch } = useStore();

    const [loading, setLoading] = useState(false);

    const { error, setErrorMessage, clearError } = useError();

    let now = null;

    if (state[GET_PROFILE]?.otpExpirationDate) {
        const otpExpirationDateInMilliseconds = state[GET_PROFILE]?.otpExpirationDate * 1000;
        // must be 15 min but otpExpirationDate for some reason returns the result with 2 more minutes
        const fifteenMinutesInMilliseconds = 17 * 60 * 1000;
        const newDateInMilliseconds = otpExpirationDateInMilliseconds - fifteenMinutesInMilliseconds;
        now = new Date(newDateInMilliseconds);
    } else {

        now = new Date();
    }

    const formattedDate = now.toLocaleString();

    const [storedTimestamp, setStoredTimestamp] = useState(null);

    useEffect(() => {
        if (localStorage.getItem("expiration_time") === null) {
            localStorage.setItem('expiration_time', formattedDate);
        } else {
            setStoredTimestamp(localStorage.getItem("expiration_time"));
        }
    }, []);

    useEffect(() => {
        const expiration = localStorage.getItem("expiration_time");
        if (expiration) {
            setStoredTimestamp(expiration);
        }
    }, []);


    const resendTime = () => {
        if (localStorage.getItem("resend_expiration_time") != null) {
            const now = new Date();
            const storedDate = new Date(localStorage.getItem("resend_expiration_time"));

            const differenceInMilliseconds = now - storedDate;

            const totalSeconds = (differenceInMilliseconds / 1000) | 0;
            return Math.max(0, 3 * 60 - totalSeconds);
        }
        return 0;
    }

    let initialTime = localStorage.getItem("resend_expiration_time") !== null ? resendTime() : 0 * 60;

    const [timeLeft, setTimeLeft] = useState(initialTime);

    useEffect(() => {
        if (timeLeft <= 0) return;

        const intervalId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    const [verificationCode, setVerificationCode] = useState(null);

    const handleVerifyUser = async () => {

        try {
            if (state[GET_PROFILE_ID] && verificationCode) {
                setLoading(true);
                const id = state[GET_PROFILE_ID];
                const verification = await verifyUser(id, verificationCode);
                if (!state[GET_USER_ID_OTP]) {
                    const loan = await getLoanByUserEmail(verification.token);
                    if (loan?.loanId) {
                        localStorage.setItem("loan_exist", JSON.stringify(loan));
                        dispatch({ action: GET_EXISTENT_LOAN, value: loan });
                        dispatch({ action: GET_LOAN, value: loan });
                    }
                }
                dispatch({ action: GET_PROFILE, value: verification });
                dispatch({ action: GET_PROFILE_TOKEN, value: verification.token });
                dispatch({ action: GET_PROFILE_VERIFIED, value: true });
                dispatch({ action: GET_PROFILE_EMAIL, value: verification.email });
                dispatch({ action: GET_USER_ID_OTP, value: null });
                localStorage.removeItem('user_id_otp');
                localStorage.removeItem('expiration_time');
                localStorage.removeItem('resend_expiration_time');
            } else {
                setErrorMessage(handleError());
            }
        } catch (e) {
            setErrorMessage(handleError(e));
        } finally {
            setLoading(false);
        }
    };

    const handleResendOtp = async () => {
        try {
            if (state[GET_PROFILE_ID] !== null) {
                const id = state[GET_PROFILE_ID];
                await resendOtp(id);
                setTimeLeft(3 * 60);
                const nowR = new Date();
                const formattedDateR = nowR.toLocaleString();
                localStorage.setItem('expiration_time', formattedDateR);
                localStorage.setItem('resend_expiration_time', formattedDateR);
                setStoredTimestamp(formattedDateR);
            } else {
                setErrorMessage(handleError());
            }
        } catch (e) {
            setErrorMessage(handleError(e));
        }
    };

    return (
        <Grow
            in={true}
            {...({ timeout: 500 })}
        >
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "410px"
            }}
            >
                <BaseAlert alert={error} onClose={() => { clearError(); }} />
                <p
                    style={{ fontWeight: "bold", textAlign: "center" }}
                >
                    {t(VERIFICATION_TEXT)}
                </p>
                <p style={{ color: PRIMARY_COLOR, textAlign: "center" }}>
                    {t(CHECK_SPAM_TEXT)}
                </p>
                <Box sx={{
                    color: PRIMARY_COLOR,
                    background: "#8080802b",
                    padding: "8px",
                    borderRadius: "3px",
                    fontWeight: "bold",
                    fontSize: "1.3rem",
                    marginBottom: "20px"
                }}>
                    <VerificationTimer storedTimestamp={storedTimestamp} />
                </Box>
                <BaseComplete length={6} onComplete={(e) => { setVerificationCode(e); }} />
                <Box sx={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center"
                }}>
                    <BaseBtn
                        label={t(VERIFY_LABEL)}
                        onClick={() => { handleVerifyUser(); }}
                        disabled={verificationCode === null}
                        loading={loading}
                        style={{
                            width: "300px"
                        }}
                    />
                    <BaseBtn
                        label={timeLeft !== 0 ? t(RESEND_LABEL) + " " + "(" + formattedTime + ")" : t(RESEND_LABEL)}
                        variant="outlined"
                        disabled={timeLeft !== 0}
                        style={{
                            color: "black",
                            borderColor: "grey",
                            marginTop: "8px",
                            width: "300px"
                        }}
                        onClick={() => {
                            handleResendOtp();
                        }}
                    />
                </Box>
            </Box>
        </Grow>
    );
}