import { Box, Container } from "@mui/material";
import BaseBtn from "../BaseComponents/BaseBtn";
import { useHistory } from "react-router-dom";
import {
    CREATE_ACCOUNT_ROUTE,
    LOGIN_ROUTE
} from "../../constants/routes";
import SearchLocation from "./Search/SearchLocation";
import { useStore } from "../../hooks/store/store";
import {
    GET_LANDING_PAGE_ELEMENTS,
    GET_LANGUAGE,
} from "../../constants/store/getters";
import {
    EN_US,
    GUEST_ROLE,
    LANDING_SEARCH_SECTION,
} from "../../constants/constants";
import { getLandingPageStyles } from "../../utils/functions";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
    HOMEBUYING_EVOLVED_LABEL,
    LOG_IN_LABEL,
    SIGN_UP_LABEL
} from "../../i18n/i18nLabel";
import SearchPro from "../Dialogs/SearchPro";

export default function MainSection() {

    const { t } = useTranslation();

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const isXsOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const history = useHistory();

    const { state } = useStore();

    const searchSectionStyles = state[GET_LANDING_PAGE_ELEMENTS]?.length >= 0
        ? getLandingPageStyles(state[GET_LANDING_PAGE_ELEMENTS].filter(element => element.section === LANDING_SEARCH_SECTION), LANDING_SEARCH_SECTION)
        : {};

    return (
        <Container>
            <Box sx={{
                minHeight: "45vh",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: isSmOrSmaller ? "center" : "start",
                justifyContent: "center",
                zIndex: 1,
                color: "white",
                paddingLeft: isSmOrSmaller ? "0px" : "80px",
                paddingBottom: "20px"
            }}>
                <h1 style={{
                    marginTop: "0px",
                    fontSize: "3rem",
                    fontWeight: "bold",
                    marginBottom: "10px",
                    textAlign: isXsOrSmaller ? "center" : "start",
                    color: searchSectionStyles?.title?.color ?? "white"
                }}>{
                        searchSectionStyles?.title?.text
                            ? state[GET_LANGUAGE] == EN_US
                                ? searchSectionStyles?.title?.text
                                : searchSectionStyles?.title?.translation
                            : t(HOMEBUYING_EVOLVED_LABEL)
                    }
                </h1>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: isSmOrSmaller ? "column" : "row"
                }}>
                    <SearchLocation />
                    &nbsp;
                    <SearchPro onClose={() => { }} />
                </Box>
                {localStorage.getItem("profile_role") === GUEST_ROLE || !localStorage.getItem("profile_role")
                    ? <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px"
                    }}>
                        <BaseBtn
                            style={{ marginRight: "10px", background: searchSectionStyles?.signUp?.color ?? "" }}
                            label={searchSectionStyles?.signUp?.text
                                ? state[GET_LANGUAGE] === EN_US
                                    ? searchSectionStyles?.signUp?.text
                                    : searchSectionStyles?.signUp?.translation
                                : t(SIGN_UP_LABEL)}
                            onClick={() => history.push(CREATE_ACCOUNT_ROUTE)}
                        />
                        <BaseBtn
                            style={{ marginLeft: "10px", color: searchSectionStyles?.login?.color ?? "white", borderColor: searchSectionStyles?.login?.color ?? "white" }}
                            variant="outlined"
                            label={searchSectionStyles?.login?.text
                                ? state[GET_LANGUAGE] === EN_US
                                    ? searchSectionStyles?.login?.text
                                    : searchSectionStyles?.login?.translation
                                : t(LOG_IN_LABEL)}
                            onClick={() => history.push(LOGIN_ROUTE)}
                        />
                    </Box>
                    : null
                }
            </Box>
        </Container>
    );
}