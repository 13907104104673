import { Box } from "@mui/material";
import BaseBtn from "../../BaseComponents/BaseBtn";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import {
    CREATE_ACCOUNT_ROUTE,
    HOME_ROUTE,
    LOGIN_ROUTE
} from "../../../constants/routes";
import { useTranslation } from 'react-i18next';
import {
    ACCOUNT_REQUIRED_LABEL,
    GO_BACK_TO_SEARCH_LABEL,
    LOG_IN_LABEL,
    SIGN_UP_LABEL
} from "../../../i18n/i18nLabel";
import { FAVORITES_GUEST_TEXT } from "../../../i18n/i18nText";
import LandingPageFooter from "../../LandingPage/LandingPageFooter";

export default function GuestFavorites() {

    const { t } = useTranslation();

    const theme = useTheme();

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

    const history = useHistory();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between"
            }}
        >
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center",
                marginTop: "54px"
            }}>
                <h3>{t(ACCOUNT_REQUIRED_LABEL)}</h3>
                <p style={{ textAlign: "center", marginTop: "0px", width: "50%" }}>
                    {t(FAVORITES_GUEST_TEXT)}
                </p>
                <Box sx={{
                    display: "flex",
                    flexDirection: isMdOrSmaller ? "column" : "row",
                    marginTop: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <BaseBtn
                        label={t(GO_BACK_TO_SEARCH_LABEL)}
                        variant="standard"
                        onClick={() => {
                            history.push(HOME_ROUTE);
                        }}
                        style={{ width: isMdOrSmaller ? "100%" : "auto" }}
                    />
                    <BaseBtn
                        label={t(SIGN_UP_LABEL)}
                        style={{
                            marginLeft: !isMdOrSmaller ? "10px" : "0px",
                            width: isMdOrSmaller ? "100%" : "auto",
                            marginTop: isMdOrSmaller ? "10px" : "0px"
                        }}
                        onClick={() => {
                            history.push(CREATE_ACCOUNT_ROUTE);
                        }}
                    />
                    <BaseBtn
                        label={t(LOG_IN_LABEL)}
                        style={{
                            marginLeft: !isMdOrSmaller ? "10px" : "0px",
                            width: isMdOrSmaller ? "100%" : "auto",
                            marginTop: isMdOrSmaller ? "10px" : "0px"
                        }}
                        variant="outlined"
                        onClick={() => {
                            history.push(LOGIN_ROUTE);
                        }}
                    />
                </Box>
            </Box>
            <Box>
                <LandingPageFooter />
            </Box>
        </Box>
    );
}