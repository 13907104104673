import BaseDialog from "../BaseDialog/BaseDialog";
import BaseDialogBody from "../BaseDialog/BaseDialogBody";
import BaseDialogHeader from "../BaseDialog/BaseDialogHeader";
import BaseDialogFooter from "../BaseDialog/BaseDialogFooter";
import BaseBtn from "../BaseComponents/BaseBtn";
import BaseSlider from "../BaseComponents/BaseSlider";
import BaseCurrency from "../BaseComponents/BaseCurrency";
import { Box } from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useState, useEffect, useMemo } from "react";
import PaymentAndFoundValues from "../properties/PaymentAndFoundValues";
import ReplayIcon from '@mui/icons-material/Replay';
import BasePercent from "../BaseComponents/BasePercent";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useStore } from "../../hooks/store/store";
import {
    GET_CONVENTIONAL_DOWN_PAYMENT,
    GET_FAVORITES,
    GET_FHA_DOWN_PAYMENT,
    GET_INVESTMENT_PROFILE_LOAN,
    GET_LAST_SEARCH_RESULTS,
    GET_OFFER_PRICE_CONVENTIONAL,
    GET_OFFER_PRICE_FHA,
    GET_OFFER_PRO_VALUES,
    GET_PROFILE_ID,
    GET_PROFILE_TOKEN,
    GET_SELLER_REBATE_CONVENTIONAL,
    GET_SELLER_REBATE_FHA
} from "../../constants/store/getters";
import { getSellerRebateMax } from "../../utils/functions";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { OFFER_PRO } from "../../constants/urls";
import { propertyCalculations } from "../../utils/offerPro";
import { offerProLetter } from "../../services/buyerService";
import { handleError } from "../../utils/functions";
import { useError } from "../../hooks/handleError";
import BaseAlert from "../BaseComponents/BaseAlert";
import SnackBarSaveProperty from "../Pages/SavedProperties/SnackbarSaveProperty";
import {
    CONVENTIONAL,
    FHA,
    MIN_CONVENTIONAL,
    PRIMARY_COLOR,
    SUCCESS
} from "../../constants/constants";
import { SAVE_AS_FAVORITE_MESSAGE } from "../../constants/messages";
import { saveFavoriteProperty } from "../../services/propertiesServices";
import { useTranslation } from 'react-i18next';
import {
    CASH_NEEDED_TEXT,
    PAYMENT_AND_CASH_NEEDED_TEXT,
    PAYMENT_NEEDED_TEXT,
    VERIFY_MONTHLY_ASSOCIATION_TEXT,
    YOU_APPEAR_QUALIFIED_TEXT
} from "../../i18n/i18nText";
import {
    BACK_TO_PROP_DETAILS_LABEL,
    DOWN_PAYMENT_LABEL,
    OFFER_PRICE_LABEL,
    PADZILLY_OFFER_PRO_LABEL,
    PRINT_OFFER_LETTERS_LABEL,
    RESET_ALL_LABEL,
    SELLER_REBATE_LABEL,
    TAXES_LABEL,
    WITH_THE_ABOVE_TERMS_LABEL
} from "../../i18n/i18nLabel";

export default function OfferProDialog(props) {

    const { t } = useTranslation();

    const { state, dispatch } = useStore();

    const { error, setErrorMessage, clearError } = useError();

    const getStoredProperty = () => {
        const properties = state[GET_FAVORITES];
        if (properties) {
            const property = properties.find(item => item.propertyId === props.property?.propertyId);
            return property ?? null;
        } else {
            return null;
        }
    }

    const property = getStoredProperty();

    const [hoa, setHoa] = useState(
        property?.hoa
            ? Math.ceil(property.hoa)
            : props.property?.hoa
                ? Math.ceil(props.property.hoa)
                : 0
    );

    const [tax, setTax] = useState(
        property?.taxes
            ? Math.ceil(property.taxes)
            : props.property?.taxes
                ? Math.ceil(props.property.taxes)
                : 0
    );

    const [price, setPrice] = useState(
        property?.offerPrice
            ? property?.offerPrice
            : (props.property?.price + (props.property?.price * (state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === CONVENTIONAL
                ? (state[GET_OFFER_PRICE_CONVENTIONAL])
                : state[GET_OFFER_PRICE_FHA])))
    );

    const [originalPrice] = useState(props.property?.price ?? 0);

    const [sellerValue, setSellerValue] = useState(
        property?.sellerRebate ??
            state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === CONVENTIONAL
            ? Number((state[GET_SELLER_REBATE_CONVENTIONAL] * 100).toFixed(2))
            : Number((state[GET_SELLER_REBATE_FHA] * 100).toFixed(2))
    );

    const [seller, setSeller] = useState(sellerValue * price / 100);

    const currentDownPayment = state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === CONVENTIONAL
        ? Number((state[GET_CONVENTIONAL_DOWN_PAYMENT] * 100).toFixed(2))
        : state[GET_INVESTMENT_PROFILE_LOAN]?.loanType === FHA
            ? Number((state[GET_FHA_DOWN_PAYMENT] * 100).toFixed(2))
            : Number((MIN_CONVENTIONAL * 100).toFixed(2));

    const [downPayment, setDownPayment] = useState(
        property?.downPayment
            ? property?.downPayment
            : currentDownPayment
    );

    const [sellerRange, setSellerRange] = useState(getSellerRebateMax(
        state[GET_INVESTMENT_PROFILE_LOAN], Number(state[GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc.toFixed(2)))
    );

    const [paymentQualify, setPaymentQualify] = useState(false);

    const [fundQualify, setFundQualify] = useState(false);

    const [payment, setPayment] = useState(props.property ? Math.ceil(props.property.calculationOfPayment) : 0);

    const [fund, setFund] = useState(props.property ? Math.ceil(props.property.investmentTotal) : 0);

    const [saving, setSaving] = useState(false);

    const minPrice = originalPrice - (originalPrice * 0.3);

    const maxPrice = originalPrice + (originalPrice * 0.3);

    const [favorite, setFavorite] = useState(props.favorite);

    useEffect(() => { setFavorite(props.favorite) }, [props.favorite])

    useEffect(() => {
        if (props.property) {
            let hoaStored = hoa;
            hoaStored = hoaStored.toString();
            hoaStored = hoaStored.replace(/[^\d]/g, '');
            hoaStored = parseInt(hoaStored);

            let taxesStored = tax;
            taxesStored = taxesStored.toString();
            taxesStored = taxesStored.replace(/[^\d]/g, '');
            taxesStored = parseInt(taxesStored);

            let priceStored = price;
            priceStored = priceStored.toString();
            priceStored = priceStored.replace(/[^\d]/g, '');
            priceStored = parseInt(priceStored);

            let sellerStored = sellerValue * priceStored / 100;

            const values = propertyCalculations(
                props.property,
                state[GET_INVESTMENT_PROFILE_LOAN],
                downPayment,
                priceStored,
                sellerStored,
                "X",
                hoaStored,
                taxesStored,
                state[GET_OFFER_PRO_VALUES]
            );

            setHoa(Math.ceil(hoaStored));
            setTax(Math.ceil(taxesStored));
            setPrice(priceStored);
            setPayment(values.payment);
            setFund(values.investmentTotal);
            setPaymentQualify(values.isPaymentQualified);
            setFundQualify(values.isInvestmentQualified);
            setSeller(Math.ceil(sellerStored));
        }
    }, [
        price,
        hoa,
        tax,
        sellerValue,
        downPayment
    ]);

    const sellerRangeLabels = () => {
        const labels = [];
        for (let i = 0; i <= sellerRange; i++) {
            labels.push({
                value: i,
                label: `${i}%`
            });
        }
        return labels;
    }

    const handleDialogBeforeHide = () => {
        resetAll();
        clearError();
        props.onClose();
    }

    const resetAll = () => {
        setHoa(props.property?.hoa ? Math.ceil(props.property?.hoa) : 0);
        setTax(props.property?.taxes ? Math.ceil(props.property?.taxes) : 0);
        setPrice(props.property?.price ? Math.ceil(props.property?.price) : 0);
        setSeller(props.property?.sellerRebate ? Math.ceil(props.property?.sellerRebate) : 0);
        setSellerValue(0);
        setDownPayment(currentDownPayment);
    }

    const savePDF = async (base64Data, fileName) => {
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);
    }

    const handleClick = async () => {
        try {
            setSaving(true);
            if (props.property) {
                const result = await offerProLetter(
                    state[GET_PROFILE_ID],
                    props.property.propertyId,
                    downPayment,
                    price,
                    sellerValue,
                    tax,
                    hoa,
                    state[GET_PROFILE_TOKEN]
                );

                const base64Data = result.split('base64,')[1];
                const fileName = `${(props.property.fullAddress ?? 'property').replaceAll('#', '')}_offer_letter.pdf`

                await savePDF(base64Data, fileName);

                if (result && !favorite) {

                    await saveFavoriteProperty(
                        state[GET_PROFILE_ID],
                        props.property.propertyId,
                        downPayment,
                        price,
                        sellerValue,
                        tax,
                        hoa,
                        state[GET_PROFILE_TOKEN]
                    );

                    const favoriteArray = state[GET_FAVORITES] ?? [];
                    favoriteArray.push(props.property);
                    dispatch({ action: GET_FAVORITES, value: favoriteArray });
                    setErrorMessage(SAVE_AS_FAVORITE_MESSAGE, SUCCESS);

                    const propsArray = JSON.parse(localStorage.getItem("last_search_results"));
                    const index = propsArray.findIndex(item => item.propertyId === props.property.propertyId);

                    if (index !== -1) {
                        propsArray[index].isFavoriteProperty = true;
                    }

                    localStorage.setItem("last_search_results", JSON.stringify(propsArray));
                    dispatch({ action: GET_LAST_SEARCH_RESULTS, value: propsArray });
                    if (props.setFavorite) {
                        setFavorite(true);
                        props.setFavorite();
                    }
                }
            }
        } catch (e) {
            setErrorMessage(handleError(e));
        } finally {
            setSaving(false);
        }
    }

    const messageBar = useMemo(() => {
        return (
            error?.type === SUCCESS && favorite
                ? <SnackBarSaveProperty alert={error} onClose={() => { clearError(); }} />
                : <BaseAlert alert={error} onClose={() => { clearError(); }} />
        );
    }, [error]);

    const valuesSection = useMemo(() => {
        return (
            <BaseDialogBody>
                {
                    messageBar
                }
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <NotificationsIcon
                        style={{ color: PRIMARY_COLOR }}
                    />
                    <p style={{ marginLeft: "10px" }}>
                        {t(VERIFY_MONTHLY_ASSOCIATION_TEXT)}
                    </p>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                }}>
                    <Box sx={{ width: "120px" }}>
                        <BaseCurrency
                            label="HOA"
                            value={hoa}
                            onInputChange={(value) => setHoa(value)}
                        />
                    </Box>
                    <Box sx={{ width: "120px", textTransform: "uppercase" }}>
                        <BaseCurrency
                            label={t(TAXES_LABEL)}
                            value={tax}
                            onInputChange={(value) => setTax(value)}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <p>{props.property?.streetAddress}, {props.property?.city}, {props.property?.state} {props.property?.zipCode}</p>
                </Box>
                <Box>
                    <p
                        style={{
                            color: paymentQualify && fundQualify ? "green" : PRIMARY_COLOR,
                            textAlign: "center",
                            fontWeight: "bold"
                        }}
                    >
                        {
                            paymentQualify && fundQualify
                                ? t(YOU_APPEAR_QUALIFIED_TEXT)
                                : paymentQualify && !fundQualify
                                    ? t(CASH_NEEDED_TEXT)
                                    : !paymentQualify && fundQualify
                                        ? t(PAYMENT_NEEDED_TEXT)
                                        : !paymentQualify && !fundQualify
                                            ? t(PAYMENT_AND_CASH_NEEDED_TEXT)
                                            : ""
                        }
                    </p>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                }}>
                    <Box sx={{ width: "80%" }}>
                        <PaymentAndFoundValues
                            property={{
                                investmentTotal: fund,
                                calculationOfPayment: payment
                            }}
                            onClick={() => { }}
                            noBreakdown={true}
                        />
                    </Box>
                </Box>
                <Box>
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end"
                    }}
                    >
                        <BaseBtn
                            variant="outlined"
                            onClick={() => { resetAll(); }}
                        ><Box sx={{
                            display: "flex",
                            alignItems: "center"
                        }}>
                                <span>{t(RESET_ALL_LABEL)}</span>
                                <ReplayIcon fontSize="small" />
                            </Box>
                        </BaseBtn>
                    </Box>
                </Box>
                <Box sx={{
                    marginTop: "20px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <Box sx={{
                        width: "80%",
                    }}>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <label>{t(OFFER_PRICE_LABEL)}:</label>
                            <Box sx={{ width: "120px" }}>
                                <BaseCurrency
                                    value={price}
                                    onInputChange={(value) => {
                                        value = value.replace(/[^\d]/g, '');
                                        value = parseInt(value);
                                        setPrice(value);
                                    }}
                                    onBlur={() => {
                                        if (price > maxPrice) {
                                            setPrice(maxPrice);
                                        }
                                        if (price < minPrice) {
                                            setPrice(minPrice);
                                        }
                                    }}
                                    color={originalPrice > price ? "green" : originalPrice < price ? "firebrick" : "inherit"}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <BaseSlider
                                min={minPrice}
                                max={maxPrice}
                                step={500}
                                value={price}
                                onInputChange={(value) => setPrice(value)}
                            />
                        </Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <label>{t(SELLER_REBATE_LABEL)}:</label>
                            <Box sx={{ width: "120px" }}>
                                <BaseCurrency
                                    value={seller}
                                    onInputChange={(value) => {
                                        let sellerStored = value;
                                        sellerStored = sellerStored.toString();
                                        sellerStored = sellerStored.replace(/[^\d]/g, '');
                                        sellerStored = parseInt(sellerStored);
                                        setSeller(sellerStored);
                                    }}
                                    onBlur={() => {
                                        const sv = seller * 100 / price;
                                        if (sv > sellerRangeLabels().length - 1) {
                                            setSellerValue(sellerRangeLabels().length - 1);
                                        } else {
                                            setSellerValue(sv);
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <BaseSlider
                                step={0.5}
                                value={sellerValue}
                                marks={sellerRangeLabels()}
                                onInputChange={(value) => setSellerValue(value)}
                            />
                        </Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <label>{t(DOWN_PAYMENT_LABEL)}:</label>
                            <Box sx={{ width: "120px" }}>
                                <BasePercent
                                    value={downPayment}
                                    onInputChange={(value) => {
                                        setDownPayment(parseFloat(value));
                                        setSellerRange(getSellerRebateMax(state[GET_INVESTMENT_PROFILE_LOAN], parseFloat(value)))
                                    }}
                                    onBlur={() => {
                                        if (downPayment > 80) {
                                            setDownPayment(80);
                                        }
                                        if (downPayment < Number(state[GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc.toFixed(2))) {
                                            setDownPayment(Number(state[GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc.toFixed(2)));
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <BaseSlider
                                value={downPayment}
                                min={Number(state[GET_INVESTMENT_PROFILE_LOAN]?.downPaymentPerc.toFixed(2))}
                                max={80}
                                step={5}
                                onInputChange={(value) => {
                                    setDownPayment(value);
                                    setSellerRange(getSellerRebateMax(state[GET_INVESTMENT_PROFILE_LOAN], value))
                                }}
                            />
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginTop: "10px"
                        }}>
                            <BaseBtn
                                variant="outlined"
                                onClick={() => { handleClick(); }}
                                loading={saving}
                                style={{ width: "176px" }}
                            >
                                <Box sx={{
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <DescriptionOutlinedIcon fontSize="small" />
                                    <span>{t(PRINT_OFFER_LETTERS_LABEL)}</span>
                                </Box>
                            </BaseBtn>
                            <span style={{
                                color: "grey",
                                fontSize: ".7rem"
                            }}
                            >*{t(WITH_THE_ABOVE_TERMS_LABEL)}
                            </span>
                        </Box>
                    </Box>
                </Box>
            </BaseDialogBody>
        );
    }, [
        price,
        hoa,
        tax,
        sellerValue,
        downPayment,
        seller,
        error,
        fund,
        payment
    ]);

    return (
        <BaseDialog {...props} maxWidth="xs">
            <BaseDialogHeader close={() => { props.onClose() }} >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {t(PADZILLY_OFFER_PRO_LABEL)}
                    <InfoOutlinedIcon
                        fontSize="small"
                        style={{ marginLeft: "10px" }}
                        className="cursor"
                        onClick={() => { window.open(OFFER_PRO, '_blank'); }}
                    />
                </Box>
            </BaseDialogHeader>
            {valuesSection}
            <BaseDialogFooter className="setProperties">
                <BaseBtn
                    label={t(BACK_TO_PROP_DETAILS_LABEL)}
                    onClick={() => { handleDialogBeforeHide(); }}
                />
            </BaseDialogFooter>
        </BaseDialog>
    );
}