import { Box } from "@mui/material";
import { useStore } from "../../../hooks/store/store";
import {
    GET_FAVORITES,
    GET_LAST_FAVORITE_RESULT,
    GET_ORDER_BY,
    GET_PAGE_FAVORITES,
    GET_PROFILE_ID,
    GET_PROFILE_TOKEN
} from "../../../constants/store/getters";
import {
    useState,
    useEffect,
} from "react";
import { DESC } from "../../../constants/constants";
import PropertyComparison from "../../Dialogs/PropertyComparison";
import { getFavorites } from "../../../services/propertiesServices";
import { useError } from "../../../hooks/handleError";
import { handleError } from "../../../utils/functions";
import BaseAlert from "../../BaseComponents/BaseAlert";
import SavedPropertiesList from "./SavedPropertiesList";
import { useQuery } from "@tanstack/react-query";
import SavedTopBar from "./SavedTopBar";
import SavedBottomBar from "./SavedBottomBar";
import LandingPageFooter from "../../LandingPage/LandingPageFooter";

export default function SavedProperties() {

    const { state, dispatch } = useStore();

    const { error, setErrorMessage, clearError } = useError();

    const [isLastResults, setIsLastResults] = useState(state[GET_LAST_FAVORITE_RESULT]);

    const [reachedBottom, setReachedBottom] = useState(false);

    const [orderDirection, setOrderDirection] = useState(state[GET_ORDER_BY]?.direction ?? DESC);

    const [orderBy, setOrderBy] = useState(state[GET_ORDER_BY]?.orderBy);

    const [comparison, setComparison] = useState(false);

    const [isFull, setIsFull] = useState(false);

    const [showComparison, setShowComparison] = useState(false);

    const [page, setPage] = useState(state[GET_PAGE_FAVORITES] ?? 0);

    const [homesToCompare, setHomesToCompare] = useState([]);

    const [propertyList, setPropertyList] = useState(state[GET_FAVORITES]);

    const { data } = useQuery({
        queryKey: [
            "favorites",
            state[GET_FAVORITES]
        ],
        queryFn: async () => {
            try {
                if (state[GET_PROFILE_TOKEN] && state[GET_PROFILE_ID]) {
                    return await getFavorites(page, 20, state[GET_PROFILE_ID], state[GET_PROFILE_TOKEN])
                }
            } catch (e) {
                setErrorMessage(handleError(e));
            }
        },
        enabled: reachedBottom && !isLastResults,
    });

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            const bottomThreshold = scrollHeight - clientHeight;
            if (scrollTop + 1 >= bottomThreshold) {
                if (!comparison) {
                    if (isLastResults) {
                        dispatch({ action: GET_LAST_FAVORITE_RESULT, value: true });
                    } else {
                        setPage((prevState) => prevState + 1);
                    }
                    setReachedBottom(true);
                }
            } else {
                setReachedBottom(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (data != undefined) {
            const elementsFiltered = data.filter(
                (item) =>
                    !propertyList.some((home) => home.propertyId === item.propertyId)
            );
            if (data?.length === 0) {
                setIsLastResults(true);
            }
            setPropertyList((prevState) => [...prevState, ...elementsFiltered]);
            dispatch({ action: GET_PAGE_FAVORITES, value: page });
            dispatch({ action: GET_FAVORITES, value: propertyList });
        }
    }, [data]);

    const handleOrder = () => {
        if (orderDirection === DESC) {
            setPropertyList(propertyList.sort((a, b) => b[orderBy] - a[orderBy]));
        } else {
            setPropertyList(propertyList.sort((a, b) => a[orderBy] - b[orderBy]));
        }
        dispatch({ action: GET_ORDER_BY, value: { orderBy: orderBy, direction: orderDirection } });
    }

    const handleCompare = () => {
        if (comparison) {
            setHomesToCompare([]);
        }
        setComparison(!comparison);
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between"
            }}
        >
            <Box>
                <BaseAlert alert={error} onClose={() => { clearError(); }} />
                <SavedTopBar
                    comparison={comparison}
                    handleOrder={() => { handleOrder(); }}
                    handleCompare={() => { handleCompare(); }}
                    setOrderBy={(value) => setOrderBy(value)}
                    setOrderDirection={(value) => setOrderDirection(value)}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                />
                <SavedPropertiesList
                    propertyList={propertyList}
                    isLoading={!isLastResults || data?.length >= 20 || (propertyList?.length !== state[GET_FAVORITES].length)}
                    comparison={comparison}
                    isFull={isFull}
                    homesToCompare={homesToCompare}
                    handleIsFull={(value) => { setIsFull(value) }}
                    handleHomesToCompare={(value) => { setHomesToCompare(value) }}
                />
            </Box>
            {comparison
                ? <SavedBottomBar
                    setShowComparison={(value) => setShowComparison(value)}
                    homesToCompare={homesToCompare}
                />
                : null
            }
            <Box
                sx={{
                    paddingBottom: comparison ? "60px" : "0px",
                }}
            >
                <LandingPageFooter />
            </Box>
            <PropertyComparison
                open={showComparison}
                onClose={() => setShowComparison(false)}
                properties={homesToCompare}
            />
        </Box>
    );
}