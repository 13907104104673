import {
  CONDOMINIUM,
  MOBILE_HOME,
  REALTOR,
  SINGLE_FAMILY,
  TOWNHOUSE,
  ZILLOW
} from "../constants/constants";

function extractJsonObject(html, key) {
  const keyIndex = html.indexOf(key);
  if (keyIndex === -1) return null;

  let startIndex = html.indexOf('{', keyIndex);
  if (startIndex === -1) return null;

  let openBraces = 1;
  let endIndex = startIndex + 1;

  while (openBraces > 0 && endIndex < html.length) {
    if (html[endIndex] === '{') {
      openBraces++;
    } else if (html[endIndex] === '}') {
      openBraces--;
    }
    endIndex++;
  }

  if (openBraces === 0) {
    const jsonString = html.slice(startIndex, endIndex);
    return jsonString;
  } else {
    return null;
  }
}

export async function setSharedProperty(html, page, url) {

  let propertyDetailsJsonString = null;

  if (page === REALTOR) {
    propertyDetailsJsonString = extractJsonObject(html, '"propertyDetails":');

    if (propertyDetailsJsonString) {
      try {
        const propertyDetails = JSON.parse(propertyDetailsJsonString);

        let parser = new DOMParser();
        let doc = parser.parseFromString(html, 'text/html');

        let metaTag = doc.querySelector('meta[name="twitter:image"]');

        if (metaTag) {
          let img = metaTag.getAttribute('content');
          propertyDetails.principalPhoto = img;
        }

        return await formatProperty(propertyDetails, REALTOR, url);
      } catch (e) {
        throw e;
      }
    }
  }
  if (page === ZILLOW) {
    const regex = /<script id="__NEXT_DATA__" type="application\/json">(.*?)<\/script>/s;

    const match = html.match(regex);
    if (match && match[1]) {
      try {
        const props = JSON.parse(match[1]);
        propertyDetailsJsonString = props?.props?.pageProps?.componentProps?.gdpClientCache;
        propertyDetailsJsonString = JSON.parse(propertyDetailsJsonString);

        const keys = Object.keys(propertyDetailsJsonString);

        if (keys.length > 0) {
          const firstKey = keys[0];
          const property = propertyDetailsJsonString[firstKey]?.property;
          return await formatProperty(property, ZILLOW, url);
        }
      } catch (e) {
        throw e;
      }
    }
  }
  return null;
}

async function formatProperty(property, page, url) {
  try {
    const prop = {
      propertyId: null,
      state: null,
      listingKey: null,
      associationName: null,
      fullAddress: null,
      streetAddress: null,
      city: null,
      county: null,
      zipCode: null,
      propertyType: null,
      price: null,
      previousPrice: null,
      bedrooms: null,
      bathroomsFull: null,
      bathroomsHalf: null,
      livingArea: null,
      lotSize: null,
      builtYear: null,
      remarks: null,
      floodZone: null,
      status: null,
      taxAnnualAmount: null,
      associationFeeFrequency: null,
      associationFee: null,
      latitude: null,
      longitude: null,
      listAgentPhone: null,
      listAgentEmail: null,
      listAgentFullName: null,
      listingId: null,
      modificationTimestamp: null,
      dataSourceId: null,
      media: [],
      mlsLogoURl: null,
      disclaimer: null,
      brokerageAtribution: null,
      loanAmount: null,
      taxes: null,
      principalAndInt: null,
      monthlyPMI: null,
      homeIns: null,
      floodIns: null,
      hoa: null,
      investmentTotal: null,
      investmentQualified: false,
      calculationOfPayment: null,
      paymentQualified: false,
      interestRate: 0,
      isFavoriteProperty: false,
      externalUrl: null,
      downPayment: 5,
      offerPrice: null,
      sellerRebate: null,
      mipFactor: null
    };
    if (page === REALTOR) {

      if (property?.photos && property?.photos?.length > 0) {
        prop.media = [
          {
            description: '',
            mediaCategory: '',
            mediaId: property?.source?.listing_id ? "media-" + property?.source?.listing_id : '',
            mediaKey: '',
            mimeType: '',
            order: 0,
            propertyId: REALTOR + "-" + property?.source?.listing_id ?? '',
            mediaUrl: property?.principalPhoto ?? property?.photos[0].href,
          }
        ];
      }

      if (property?.consumer_advertisers) {
        property?.consumer_advertisers.forEach(item => {
          if (item?.photo?.href) {
            prop.mlsLogoURl = item?.photo?.href;
          }
        });
      }

      if (property?.mortgage?.estimate?.monthly_payment_details) {
        property?.mortgage?.estimate?.monthly_payment_details.forEach(item => {
          if (item?.type === "home_insurance") {
            prop.homeIns = item?.amount;
          }
          if (item?.type === "property_tax") {
            prop.taxes = item?.amount;
            prop.taxAnnualAmount = item?.amount ? item?.amount * 12 : null;

          }
        });
      }

      prop.state = property?.location?.address?.state_code ?? null;
      prop.streetAddress = property?.location?.address?.line ?? null;
      prop.city = property?.location?.address?.city ?? null;
      prop.county = property?.location?.county?.name ?? null;
      prop.zipCode = property?.location?.address?.postal_code ?? null;
      prop.fullAddress = prop.streetAddress + ", "
        + prop.city + ", "
        + prop.state + " "
        + prop.zipCode;
      prop.propertyType = property?.description?.sub_type === "condo"
        ? CONDOMINIUM
        : property?.description?.sub_type === "townhouse"
          ? TOWNHOUSE
          : property?.description?.sub_type === "single_family"
            ? SINGLE_FAMILY
            : property?.description?.sub_type === "mobile"
              ? MOBILE_HOME
              : "Other";
      prop.price = property?.list_price;
      prop.bathroomsFull = property?.description?.baths_full ?? 0;
      prop.bedrooms = property?.description?.beds ?? 0;
      prop.bathroomsHalf = property?.description?.baths_half ?? 0;
      prop.livingArea = property?.description?.sqft ?? null;
      prop.lotSize = property?.description?.lot_sqft ?? null;
      prop.builtYear = property?.description?.year_built ?? null;
      prop.remarks = property?.description?.text ?? null;
      prop.status = property?.status === "for_sale" ? "Active" : "Closed";
      prop.latitude = property?.location?.address?.coordinate?.lat ?? 0;
      prop.longitude = property?.location?.address?.coordinate?.lon ?? 0;
      prop.listAgentPhone = property?.source?.agents[0]?.office_phone ?? null;
      prop.listAgentFullName = property?.source?.agents[0]?.agent_name ?? null;
      prop.disclaimer = property?.source?.disclaimer?.text ?? null;
      prop.hoa = property?.hoa?.fee ?? null;
      prop.externalUrl = url ?? null;
      prop.associationFee = property?.hoa?.fee ?? null;
      prop.associationFeeFrequency = property?.hoa?.fee ? "Monthly" : null;
      prop.propertyId = property?.source?.listing_id ? REALTOR + "-" + property?.source?.listing_id : REALTOR;
      prop.listingId = property?.source?.listing_id ?? "";
      prop.dataSourceId = property?.source?.listing_id ? REALTOR + "-" + property?.source?.listing_id : REALTOR;
    }
    if (page === ZILLOW) {

      let media = [];

      if (property?.originalPhotos && property?.originalPhotos?.length > 0) {
        media.push({
          description: '',
          mediaCategory: '',
          mediaId: property?.mlsid ? "media-" + property?.mlsid : '',
          mediaKey: '',
          mimeType: '',
          order: 0,
          propertyId: ZILLOW + "-" + property?.mlsid ?? '',
          mediaUrl: property?.originalPhotos[0].mixedSources?.jpeg[0]?.url,
        });
      }

      prop.state = property?.address?.state ?? null;
      prop.streetAddress = property?.address?.streetAddress ?? null;
      prop.city = property?.address?.city ?? null;
      prop.county = property?.county ?? null;
      prop.zipCode = property?.address?.zipcode ?? null;
      prop.fullAddress = property?.address?.streetAddress + ", "
        + property?.address?.city + ", "
        + property?.address?.state + " "
        + property?.address?.zipcode;
      prop.propertyType = property?.homeType === "SINGLE_FAMILY"
        ? SINGLE_FAMILY
        : property?.homeType === "CONDO"
          ? CONDOMINIUM
          : property?.homeType === "TOWNHOUSE"
            ? TOWNHOUSE
            : property?.homeType === "MANUFACTURED"
              ? MOBILE_HOME
              : "Other";
      prop.price = property?.price;
      prop.bathroomsFull = property?.bathrooms ?? 0;
      prop.bedrooms = property?.bedrooms ?? 0;
      prop.bathroomsHalf = 0;
      prop.livingArea = property?.livingArea ?? null;
      prop.lotSize = property?.lotSize ?? null;
      prop.builtYear = property?.yearBuilt ?? null;
      prop.remarks = property?.description ?? null;
      prop.status = property?.homeStatus === "FOR_SALE" ? "Active" : "Closed";
      prop.latitude = property?.latitude ?? 0;
      prop.longitude = property?.longitude ?? 0;
      prop.listAgentPhone = property?.attributionInfo?.agentPhoneNumber ?? null;
      prop.listAgentFullName = property?.attributionInfo?.agentName ?? null;
      prop.mlsLogoURl = property?.attributionInfo?.providerLogo ?? null;
      prop.disclaimer = property?.attributionInfo?.mlsDisclaimer ?? null;
      prop.hoa = property?.monthlyHoaFee ?? null;
      prop.homeIns = property?.annualHomeownersInsurance ?? null;
      prop.externalUrl = url ?? null;
      prop.media = media;
      prop.associationFee = property?.monthlyHoaFee ?? null;
      prop.associationFeeFrequency = property?.monthlyHoaFee ? "Monthly" : null;
      prop.propertyId = property?.mlsid ? ZILLOW + "-" + property?.mlsid : ZILLOW;
      prop.listingId = property?.mlsid ?? "";
      prop.dataSourceId = property?.mlsid ? ZILLOW + "-" + property?.mlsid : ZILLOW;
    }
    return prop;
  } catch (e) {
    throw e;
  }
}
