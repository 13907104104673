import { Box, Card, CardContent } from "@mui/material";
import BaseBtn from "../../BaseComponents/BaseBtn";
import {
    LOG_IN_LABEL,
    SIGN_UP_LABEL
} from "../../../i18n/i18nLabel";
import {
    CURRENTLY_AS_GUEST_TEXT,
    PLEASE_AUTHENTICATE_TEXT,
} from "../../../i18n/i18nText";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import {
    CREATE_ACCOUNT_ROUTE,
    LOGIN_ROUTE
} from "../../../constants/routes";

export default function GuestRoleProfile() {

    const history = useHistory();

    const { t } = useTranslation();

    return (
        <Card>
            <CardContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: ".5rem"
                }}
            >
                <p
                    style={{
                        fontWeight: "bold",
                        fontSize: "1.3rem",
                        textAlign: "center",
                        marginBottom: "0px"
                    }}
                >
                    {t(CURRENTLY_AS_GUEST_TEXT)}
                </p>
                <p
                    style={{ textAlign: "center" }}
                >
                    {t(PLEASE_AUTHENTICATE_TEXT)}
                </p>
                <Box>
                    <BaseBtn
                        label={t(SIGN_UP_LABEL)}
                        onClick={() => {
                            history.push(CREATE_ACCOUNT_ROUTE);
                        }}

                    />
                    <BaseBtn
                        label={t(LOG_IN_LABEL)}
                        variant="outlined"
                        style={{
                            marginLeft: "10px",
                        }}
                        onClick={() => {
                            history.push(LOGIN_ROUTE);
                        }}
                    />
                </Box>
            </CardContent>
        </Card>
    );
}