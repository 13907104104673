import { Box, Container, Link, Skeleton } from "@mui/material"
import React, { useMemo, useRef, useEffect } from 'react';
import { useStore } from "../../hooks/store/store";
import {
    GET_LANDING_PAGE_ELEMENTS,
    GET_LANGUAGE,
    GET_LAST_INPUT_SEARCH,
    GET_LAST_SEARCH,
    GET_LOCATION,
    GET_NEAR_PROPERTIES,
    GET_PROFILE_FINISHED,
} from "../../constants/store/getters";
import PropertyCard from "./PropertyCard/PropertyCard";
import IconButton from '@mui/material/IconButton';
import PropertyCardSkeleton from "./PropertyCard/PropertyCardSkeleton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BaseBtn from "../BaseComponents/BaseBtn";
import { useHistory } from "react-router-dom";
import { HOME_ROUTE } from "../../constants/routes";
import { getNearProperties } from "../../services/landingServices";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
    EN_US,
    GET_NEW_VALUES_FOR_SEARCH,
    LANDING_NEAR_PROPERTIES,
    PRIMARY_COLOR,
    TITLES_COLOR,
} from "../../constants/constants";
import {
    getLandingPageStyles,
    getStateCode
} from "../../utils/functions";
import { useTranslation } from 'react-i18next';
import {
    HOMES_FOR_YOU_LABEL,
    LOOKING_FOR_PROP_LABEL,
    NO_PROPERTIES_FOR_YOU_LABEL,
    START_NEW_SEARCH_LABEL,
    VIEW_MORE_PROP_IN_LABEL
} from "../../i18n/i18nLabel";
import { WE_DIDNT_FIND_PROPS_TEXT } from "../../i18n/i18nText";
import { useQuery } from "@tanstack/react-query";

export default function LandingPageProperties() {

    const { t } = useTranslation();

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

    const history = useHistory();

    const { state, dispatch } = useStore();

    const scrollContainerRef = useRef(null);

    const { data, isLoading, isError } = useQuery({
        queryKey: [
            "properties",
            state[GET_LOCATION]
        ],
        queryFn: async () => {
            try {
                if ((!state[GET_NEAR_PROPERTIES] || state[GET_NEAR_PROPERTIES]?.length === 0)
                    && state[GET_LOCATION]
                    && Object.keys(state[GET_LOCATION]).length > 0) {
                    return await getNearProperties(state[GET_LOCATION]?.city, getStateCode(state[GET_LOCATION]?.regionName));
                } else {
                    return [];
                }
            } catch (e) {
                dispatch({ action: GET_NEAR_PROPERTIES, value: [] });
            }
        },
        enabled: !state[GET_NEAR_PROPERTIES],
    });

    useEffect(() => {
        if (!isLoading && data !== undefined && state[GET_LOCATION]) {
            dispatch({ action: GET_NEAR_PROPERTIES, value: data });
        }
    }, [data]);

    const handleScroll = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = isSmOrSmaller ? 310 : 620;
            if (direction === 'left') {
                scrollContainerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
            } else {
                scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            }
        }
    };

    const goToLocationSearch = () => {
        let lastSearch = localStorage.getItem("last_search")
            ? JSON.parse(localStorage.getItem("last_search"))
            : GET_NEW_VALUES_FOR_SEARCH(null);

        if (lastSearch) {
            const location = {
                searchBy: "City",
                description: state[GET_LOCATION]?.city + ", " + getStateCode(state[GET_LOCATION]?.regionName),
                searchFor: state[GET_LOCATION]?.city,
                state: getStateCode(state[GET_LOCATION]?.regionName)
            }
            localStorage.setItem("last_input_search", JSON.stringify(location));
            dispatch({ action: GET_LAST_INPUT_SEARCH, value: location });
            lastSearch.search = state[GET_LOCATION]?.city;
            lastSearch.searchBy = "City";
            lastSearch.searchState = getStateCode(state[GET_LOCATION]?.regionName);
            localStorage.setItem("last_search", JSON.stringify(lastSearch));
            dispatch({ action: GET_LAST_SEARCH, value: lastSearch });
            localStorage.setItem("profile_finished", true);
            dispatch({ action: GET_PROFILE_FINISHED, value: true });
            history.push(HOME_ROUTE);
        }
    }

    const nearPropertiesStyles = state[GET_LANDING_PAGE_ELEMENTS]?.length >= 0
        ? getLandingPageStyles(state[GET_LANDING_PAGE_ELEMENTS].filter(element => element.section === LANDING_NEAR_PROPERTIES), LANDING_NEAR_PROPERTIES)
        : {};

    const landingProperties = useMemo(() => {
        if (!Array.isArray(state[GET_NEAR_PROPERTIES])) {
            return (
                <Box sx={{
                    width: "100%",
                    background: nearPropertiesStyles?.background ?? "white",
                    display: "flex"
                }}>
                    <Container>
                        <Box
                            sx={{
                                width: "100%",
                                padding: "20px",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingLeft: "10px",
                                }}
                            >
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "1.4rem",
                                        margin: 0,
                                        color: !state[GET_NEAR_PROPERTIES]
                                            ? (nearPropertiesStyles?.thirdTitle?.color ?? TITLES_COLOR)
                                            : state[GET_NEAR_PROPERTIES]?.length > 0
                                                ? (nearPropertiesStyles?.primaryTitle?.color ?? TITLES_COLOR)
                                                : (nearPropertiesStyles?.secondaryTitle?.color ?? TITLES_COLOR)
                                    }}
                                >
                                    {
                                        nearPropertiesStyles?.thirdTitle?.text
                                            ? state[GET_LANGUAGE] === EN_US
                                                ? nearPropertiesStyles?.thirdTitle?.text
                                                : nearPropertiesStyles?.thirdTitle?.translation
                                            : t(LOOKING_FOR_PROP_LABEL)
                                    }
                                </p>
                            </Box>
                            <Box sx={{ position: 'relative', width: '100%' }}>
                                <Box
                                    ref={scrollContainerRef}
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        alignItems: "center",
                                        overflowX: 'auto',
                                        gap: 2,
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                        scrollBehavior: 'smooth',
                                        '&::-webkit-scrollbar': {
                                            display: 'none',
                                        },
                                    }}
                                >
                                    {
                                        Array.from({ length: 10 }).map((_, index) => (
                                            <PropertyCardSkeleton key={index} />
                                        ))
                                    }
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%"
                                }}>
                                    <Skeleton width={"220px"} />
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            )
        } else {
            return (
                <Box sx={{
                    width: "100%",
                    background: nearPropertiesStyles?.background ?? "white",
                    display: "flex"
                }}>
                    <Container>
                        <Box
                            sx={{
                                width: "100%",
                                padding: "20px",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingLeft: "10px",
                                }}
                            >
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "1.4rem",
                                        margin: 0,
                                        color: !state[GET_NEAR_PROPERTIES]
                                            ? (nearPropertiesStyles?.thirdTitle?.color ?? TITLES_COLOR)
                                            : state[GET_NEAR_PROPERTIES]?.length > 0
                                                ? (nearPropertiesStyles?.primaryTitle?.color ?? TITLES_COLOR)
                                                : (nearPropertiesStyles?.secondaryTitle?.color ?? TITLES_COLOR)
                                    }}
                                >
                                    {
                                        state[GET_NEAR_PROPERTIES]?.length > 0
                                            ? (nearPropertiesStyles?.primaryTitle?.text
                                                ? state[GET_LANGUAGE] === EN_US
                                                    ? nearPropertiesStyles?.primaryTitle?.text
                                                    : nearPropertiesStyles?.primaryTitle?.transition
                                                : t(HOMES_FOR_YOU_LABEL)) + " " + state[GET_LOCATION]?.city
                                            : (nearPropertiesStyles?.secondaryTitle?.text
                                                ? state[GET_LANGUAGE] === EN_US
                                                    ? nearPropertiesStyles?.secondaryTitle?.text
                                                    : nearPropertiesStyles?.secondaryTitle?.transition
                                                : t(NO_PROPERTIES_FOR_YOU_LABEL)) + " " + state[GET_LOCATION]?.city
                                    }
                                </p>
                                {
                                    state[GET_NEAR_PROPERTIES]?.length > 0
                                        ? <Box sx={{ display: "flex" }}>
                                            <IconButton
                                                onClick={() => handleScroll('left')}
                                                style={{ borderRadius: "20px", marginRight: "5px" }}
                                            >
                                                <ArrowBackIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => handleScroll('right')}
                                                style={{ borderRadius: "20px", marginLeft: "5px" }}
                                            >
                                                <ArrowForwardIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                        : null
                                }
                            </Box>
                            <Box sx={{ position: 'relative', width: '100%' }}>
                                <Box
                                    ref={scrollContainerRef}
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        alignItems: "center",
                                        overflowX: 'auto',
                                        gap: 2,
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                        paddingLeft: "5px",
                                        paddingRight: "5px",
                                        scrollBehavior: 'smooth',
                                        '&::-webkit-scrollbar': {
                                            display: 'none',
                                        },
                                    }}
                                >
                                    {
                                        state[GET_NEAR_PROPERTIES]?.length > 0
                                            ? state[GET_NEAR_PROPERTIES].map((property, index) => (
                                                <React.Fragment key={index}>
                                                    <PropertyCard property={property} />
                                                </React.Fragment>
                                            ))
                                            : <Box sx={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                                color: nearPropertiesStyles?.noPropText?.color ?? "black"
                                            }}>
                                                <p style={{ textAlign: "center" }}>
                                                    {nearPropertiesStyles?.noPropText?.text
                                                        ? state[GET_LANGUAGE] === EN_US
                                                            ? nearPropertiesStyles?.noPropText?.text
                                                            : nearPropertiesStyles?.noPropText?.transition
                                                        : t(WE_DIDNT_FIND_PROPS_TEXT)}
                                                </p>
                                                <BaseBtn
                                                    label={nearPropertiesStyles?.noPropBtn?.text
                                                        ? state[GET_LANGUAGE] === EN_US
                                                            ? nearPropertiesStyles?.noPropBtn?.text
                                                            : nearPropertiesStyles?.noPropBtn?.transition
                                                        : t(START_NEW_SEARCH_LABEL)}
                                                    style={{ background: nearPropertiesStyles?.noPropBtn?.color ?? "" }}
                                                    onClick={() => {
                                                        localStorage.setItem("profile_finished", true);
                                                        dispatch({ action: GET_PROFILE_FINISHED, value: true });
                                                        history.push(HOME_ROUTE)
                                                    }}
                                                />
                                            </Box>
                                    }
                                </Box>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    {state[GET_NEAR_PROPERTIES]?.length
                                        ? <Link
                                            onClick={() => goToLocationSearch()}
                                            sx={{
                                                color: nearPropertiesStyles?.viewMoreBtn?.color ?? PRIMARY_COLOR,
                                                cursor: "pointer",
                                                textDecoration: "none",
                                                fontSize: ".9rem",
                                                transition: "opacity 0.3s",
                                                '&:hover': {
                                                    opacity: 0.8,
                                                },
                                            }}
                                        >
                                            {nearPropertiesStyles?.viewMoreBtn?.text
                                                ? state[GET_LANGUAGE] === EN_US
                                                    ? nearPropertiesStyles?.viewMoreBtn?.text
                                                    : nearPropertiesStyles?.viewMoreBtn?.translation
                                                : t(VIEW_MORE_PROP_IN_LABEL)} {" " + state[GET_LOCATION]?.city}
                                        </Link>
                                        : null
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            );
        }
    }, [
        state[GET_NEAR_PROPERTIES],
        isSmOrSmaller,
        isMdOrSmaller,
    ]);

    return landingProperties;
}