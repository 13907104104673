
import { Box } from "@mui/material";
import BaseBtn from "../../BaseComponents/BaseBtn";
import { useTranslation } from 'react-i18next';
import {
    COMPARE_LABEL,
    HOMES_LABEL,
    SELECTED_LABEL,
    SELECTED_TO_COMPARE_LABEL
} from "../../../i18n/i18nLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function SavedBottomBar(props) {

    const theme = useTheme();

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const { t } = useTranslation();

    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "fixed",
            width: "100vw",
            bottom: isSmOrSmaller ? "70px" : "0px",
            paddingLeft: "24px",
            paddingRight: "24px",
            left: 0,
            marginLeft: 'calc(-50vw + 50%)',
            background: "whitesmoke",
            zIndex: "1000",
            paddingBottom: "10px",
            paddingTop: "10px",
            boxShadow: "0 -4px 6px -2px rgba(0, 0, 0, 0.1)"
        }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start"
                }}
            >
                <span>{t(SELECTED_LABEL)} {props.homesToCompare.length} {t(HOMES_LABEL)}</span>
                <span style={{
                    fontSize: ".8rem"
                }}>{t(SELECTED_TO_COMPARE_LABEL)}</span>
            </Box>
            <BaseBtn
                label={t(COMPARE_LABEL)}
                disabled={props.homesToCompare.length <= 1}
                onClick={() => { props.setShowComparison(true); }}
            />
        </Box>
    );
}