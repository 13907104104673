import { Box } from "@mui/material";
import TopBar from "./TopBar/TopBar";
import LandingMainSection from "./LandingMainSection";
import MainSection from "./MainSection";
import LandingPageInfo from "./LandingPageInfo";
import LandingPageFooter from "./LandingPageFooter";
import SearchTopBar from "./TopBar/SearchTopBar";
import { useState, useEffect, useMemo } from 'react';
import LandingPageProperties from "./LandingPageProperties";
import { useStore } from "../../hooks/store/store";
import {
    GET_CONVENTIONAL_DOWN_PAYMENT,
    GET_FHA_DOWN_PAYMENT,
    GET_LANDING_PAGE_ELEMENTS,
    GET_LAST_SEARCH_RESULTS,
    GET_LOCATION,
    GET_STORE_COMPLETE
} from "../../constants/store/getters";
import LandingPageSearchedProperties from "./LandingPageSearchedProperties";
import LandingPageFavoriteProperties from "./LandingPageFavoriteProperties";
//import { getLandingPageElements } from "../../services/landingServices";
import { BORROWER_ROLE, MIN_CONVENTIONAL, MIN_FHA } from "../../constants/constants";
import { getLocationByIP } from "../../services/landingServices";

export default function LandingPage() {

    const { state, dispatch } = useStore();

    const [showSearchTopBar, setShowSearchTopBar] = useState(false);

    const [opacity, setOpacity] = useState(0);

    const [translateY, setTranslateY] = useState(-50);

    useEffect(() => {

        const handleScroll = () => {
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            const triggerPoint = windowHeight / 2.2;

            if (scrollY > triggerPoint) {
                setShowSearchTopBar(true);
                const relativeScrollY = scrollY - triggerPoint;
                const newOpacity = Math.min(1, relativeScrollY / 100);
                const newTranslateY = Math.min(0, (relativeScrollY - 100));
                setOpacity(newOpacity);
                setTranslateY(newTranslateY);
            } else {
                setShowSearchTopBar(false);
                setOpacity(0);
                setTranslateY(-50);
            }
        };

        const loadLandingPageElements = async () => {
            if ((state[GET_LANDING_PAGE_ELEMENTS]?.length == 0 || !state[GET_LANDING_PAGE_ELEMENTS]) && state[GET_STORE_COMPLETE]) {
                //NO LANDING PAGE ELEMENTS UNTIL HAVE THE FINAL DESIGN
                //  const page = await getLandingPageElements();
                //  dispatch({ action: GET_LANDING_PAGE_ELEMENTS, value: page });
            }
        }

        const getClientLocation = async () => {
            if (!state[GET_LOCATION]) {
                const location = await getLocationByIP() ?? null;
                dispatch({ action: GET_LOCATION, value: location });
            }
        }

        const setDownPayment = () => {
            if (!localStorage.getItem("down_payment_conventional")) {
                localStorage.setItem("down_payment_conventional", MIN_CONVENTIONAL);
                dispatch({ action: GET_CONVENTIONAL_DOWN_PAYMENT, value: MIN_CONVENTIONAL });
            }
            if (!localStorage.getItem("down_payment_fha")) {
                localStorage.setItem("down_payment_fha", MIN_FHA);
                dispatch({ action: GET_FHA_DOWN_PAYMENT, value: MIN_FHA });
            }
        }

        window.addEventListener('scroll', handleScroll);
        getClientLocation();
        loadLandingPageElements();
        setDownPayment();
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const lastSearch = localStorage.getItem("last_search")
        ? JSON.parse(localStorage.getItem("last_search"))
        : {};

    const landingElements = useMemo(() => {
        //state[GET_LANDING_PAGE_ELEMENTS]?.length >= 0
        //NO LANDING PAGE ELEMENTS UNTIL HAVE THE FINAL DESIGN
        return (
            <Box>
                <TopBar />
                {showSearchTopBar
                    ? <Box
                        sx={{
                            opacity: opacity,
                            transform: `translateY(${translateY}px)`,
                            transition: 'opacity 0.3s, transform 0.3s',
                            boxShadow: '0 4px 2px -2px gray',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            zIndex: 1000,
                        }}
                    >
                        <SearchTopBar />
                    </Box>
                    : null
                }
                <LandingMainSection />
                <Box sx={{ position: 'relative', zIndex: 0 }}>
                    <MainSection />
                    <LandingPageInfo />
                    <LandingPageProperties />
                    {(lastSearch?.search && lastSearch?.searchState && state[GET_LAST_SEARCH_RESULTS]?.length > 0)
                        ? <LandingPageSearchedProperties />
                        : null
                    }
                    {
                        localStorage.getItem("profile_role") === BORROWER_ROLE
                            ? <LandingPageFavoriteProperties />
                            : null
                    }
                    <LandingPageFooter />
                </Box>
            </Box>
        );
    },
        [
            state[GET_LANDING_PAGE_ELEMENTS],
            showSearchTopBar,
            opacity,
            translateY
        ]);

    return landingElements;
}