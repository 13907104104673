import TextField from '@mui/material/TextField';
import { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import EmailIcon from '@mui/icons-material/Email';

export default function BaseInput(props) {

    const [value, setValue] = useState(props.value ?? "");

    const { label, required, error, type } = props;

    const [showPassword, setShowPassword] = useState(type === "password" ? false : true);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <TextField
            label={label}
            type={showPassword ? 'text' : 'password'}
            color='secondary'
            error={error ? true : false}
            required={required}
            placeholder={props.placeholder ?? null}
            autoComplete='off'
            size="small"
            style={props.style ?? null}
            variant={props.variant ?? "standard"}
            helperText={error || ' '}
            value={value}
            onChange={(event) => {
                setValue(event.target.value);
                if (props.onInputChange) {
                    props.onInputChange(event.target.value);
                }
            }}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    {props.type === "password"
                        ? <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        : null
                    }
                </InputAdornment>,
                startAdornment: props.startIcon
                    ?
                    (<InputAdornment position="start">
                        {props.startIcon === "person"
                            ? <PersonIcon />
                            : props.startIcon === "phone"
                                ? <StayCurrentPortraitIcon />
                                : props.startIcon === "email"
                                    ? <EmailIcon />
                                    : null
                        }
                    </InputAdornment>)
                    : null
            }}
            onBlur={() => { if (props.onBlur) { props.onBlur() } }}
            FormHelperTextProps={{
                style: { minHeight: '5px' },
            }}

        />
    );
}