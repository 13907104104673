import * as TEXTS from "../i18nText"

export default {
    [TEXTS.DISCLAIMER_TITLE_TEXT]: "MLS Data provided under licensing by the",
    [TEXTS.DISCLAIMER_TITLE_LINK_TEXT]: "National Brokerage Network",
    [TEXTS.PAYMENT_BREAKDOWN_TITLE_TEXT]: "This is not an advertisement for a mortage loan or an offer to extend credit.",
    [TEXTS.PAYMENT_BREAKDOWN_FIRST_TEXT]: "The estimated payment is based on assumptions of insurance, taxes and a sample interest rate of 7.250% we used at the time we qualified you. These factors may be different at time of closing.",
    [TEXTS.PAYMENT_BREAKDOWN_SECOND_TEXT]: "The estimated payment is based on assumptions of insurance, taxes and the ",
    [TEXTS.PAYMENT_BREAKDOWN_SECOND_STRONG_TEXT]: "loan program you are qualified for. These factors may be different at time of closing.",
    [TEXTS.IMPORT_PROP_ONE_TEXT]: "You can import a property from",
    [TEXTS.IMPORT_PROP_TWO_TEXT]: "by providing the property URL",
    [TEXTS.IMPORT_PROP_O_TEXT]: "or",
    [TEXTS.THERE_IS_NO_LOAN_O_TEXT]: "There is no loan originator, please add one",
    [TEXTS.PLEASE_AUTHENTICATE_TEXT]: "To mark properties as favorites and add loan originators, please authenticate",
    [TEXTS.CURRENTLY_AS_GUEST_TEXT]: "You are currently browsing as a guest",
    [TEXTS.AMOUNTS_BELOW_TEXT]: "Amounts below are from your loan approval",
    [TEXTS.REMOVE_LOAN_ORIGINATOR_TEXT]: "Are you sure you want to remove associated Loan Originator?",
    [TEXTS.DO_NOT_HAVE_LOAN_ORIGINATOR_TEXT]: "You do not have a loan originator linked to your profile",
    [TEXTS.LOAN_OFFICER_CONNECTED_TEXT]: "Your loan officer is connected to Padzilly",
    [TEXTS.WE_ARE_CONTACTING_TEXT]: "We are contacting them to upload your loan approval profile right away",
    [TEXTS.GETTING_PROPS_TEXT]: "Getting the properties, please hold on",
    [TEXTS.GETTING_DETAILS_TEXT]: "Getting the property, please hold on",
    [TEXTS.FORGOT_PASS_OTP_TEXT]: "We will send you an email with a One-Time Password (OTP) to reset your password.",
    [TEXTS.VERIFICATION_TEXT]: "Please enter the verification code we sent to your email address",
    [TEXTS.CHECK_SPAM_TEXT]: "Check your spam ok junk folder if it is not in your inbox. This can take 2-3 minutes",
    [TEXTS.LETS_CREATE_PADZILLY_SWIFTMATCH_TEXT]: "Let's create your home search profile on Padzilly SearchPro!",
    [TEXTS.CHOOSE_PRE_APPROVED_TEXT]: "Choose this if you have a current qualification or approval from a lender",
    [TEXTS.CHOOSE_NOT_APPROVED_TEXT]: "Choose this if you don't have an approval, but want to start smart searching now",
    [TEXTS.CREATE_SWIFTMATCH_PROFILE_TEXT]: "No problem! Let's create your SearchPro profile",
    [TEXTS.FOR_BEST_RESULTS_TEXT]: "For the best results, a lender pre-qualification connected to Padzilly is recommended",
    [TEXTS.IN_THE_MEANTIME_TEXT]: "In the meantime, let's try SearchPro!",
    [TEXTS.WE_WILL_REPLACE_TEXT]: "We will replace this with your loan data once it is uploaded",
    [TEXTS.WE_WILL_CHECK_LOAN_OFFICER_TEXT]: "We will check to see if your loan officer is already connected to Padzilly",
    [TEXTS.GREAT_NEWS_TEXT]: "Great news! Your loan officer is connected to Padzilly",
    [TEXTS.LOAN_OFFICER_NOT_CONNECTED_TEXT]: "It looks like your loan officer is not connected to Padzilly yet",
    [TEXTS.WE_ARE_REACHING_OUT_TEXT]: "We are reaching out to them so they can sign up and make your Home shopping journey easier and faster",
    [TEXTS.GREAT_NEWS_TWO_TEXT]: "Great news! Your Lender is connected to Padzilly and we found your loan approval",
    [TEXTS.CONTINUE_SEARCH_TEXT]: "You can go to Homes and continue your search",
    [TEXTS.ENTER_INFO_FOR_SEARCH_TEXT]: "Please, enter a city, zip code or address to search for",
    [TEXTS.NO_MATCHING_PROPERTIES_TEXT]: "We could not find any properties matching your search criteria",
    [TEXTS.CHANGE_PROPERTY_TYPE_TEXT]: "Change the property type, rooms, and feature filters above",
    [TEXTS.FIND_IN_ZILLOW_OR_REALTOR_TEXT]: "If you can not find a specific property you can search on Zillow or Realtor.com and share with",
    [TEXTS.CHECK_MLS_TEXT]: "Check for MLS coverage in your search area",
    [TEXTS.PROBLEM_LOADING_PROPERTIES_TEXT]: "There was a problem loading the properties",
    [TEXTS.CHECK_YOUR_CONNECTION_TEXT]: "Please check your connection and try again",
    [TEXTS.WE_COULDNT_FIND_PROP_TEXT]: "Sorry, we couldn't find the property",
    [TEXTS.FAVORITES_GUEST_TEXT]: "An account is required to access your favorite property listing. Please sign up to create an account, or log in if you have an account",
    [TEXTS.OFFER_PRO_BACKDROP_TEXT]: "Use OfferPro to qualify or secure the best deal by adjusting price and other factors",
    [TEXTS.VERIFY_MONTHLY_ASSOCIATION_TEXT]: "Please verify the monthly association fee and annual property tax amount to ensurance an accurate approval",
    [TEXTS.NOT_SAVED_PROPERTIES_TEXT]: "You have not saved any property as favorite",
    [TEXTS.EXPLORE_AND_SAVE_PROPERTIES_TEXT]: "Explore and save your preferred properties",
    [TEXTS.YOU_APPEAR_QUALIFIED_TEXT]: "You appear qualified for this home!",
    [TEXTS.CASH_NEEDED_TEXT]: "You may qualify it the Cash Needed is lowered. Try adjusting below",
    [TEXTS.PAYMENT_NEEDED_TEXT]: "You may qualify it the Payment is lowered. Try adjusting below",
    [TEXTS.PAYMENT_AND_CASH_NEEDED_TEXT]: "You may qualify it the Payment and Cash Needed are lowered. Try adjusting below",
    [TEXTS.MUST_BE_GREATER_THAN_TEXT]: "must be greater than 0",
    [TEXTS.MORTGAGE_PAYMENT_ERROR_TEXT]: "limit must be lower than Funds limit",
    [TEXTS.VALID_URL_TO_IMPORT_ERROR_TEXT]: "must be a valid Realtor or Zillow Link",
    [TEXTS.INVALID_EMAIL_ERROR_TEXT]: "Invalid email, ex: joe@padzilly.com",
    [TEXTS.ONLY_LETTERS_ERROR_TEXT]: "must have only letters",
    [TEXTS.NO_MLO_FOUND_ERROR_TEXT]: "No MLO was found with the provided email",
    [TEXTS.MLO_SAME_EMAIL_ERROR_TEXT]: "should not be the same as the current associated MLO email",
    [TEXTS.PASSWORDS_MUST_MATCH_ERROR_TEXT]: "Passwords must match",
    [TEXTS.FIRST_NAME_ERROR_TEXT]: "Invalid First Name, ex: Joe",
    [TEXTS.LAST_NAME_ERROR_TEXT]: "Invalid Last Name, ex: Smith",
    [TEXTS.FULL_NAME_ERROR_TEXT]: "Invalid Full Name, ex: Joe Smith",
    [TEXTS.MLO_WAS_REMOVED_ERROR_TEXT]: "The MLO was removed",
    [TEXTS.NO_INVESTMENT_PROFILE_TEXT]: "There is no investment profile, if you have an account you can log in here",
    [TEXTS.CONTINUE_AS_GUEST_TEXT]: "If you want to continue as a guest you can create an investment profile here",
    //LANDING PAGE
    [TEXTS.WE_DIDNT_FIND_PROPS_TEXT]: "We didn't find any properties near you, you can start a new search",
    [TEXTS.INFO_TEXT_ONE_TEXT]: "Only Padzilly quickly zeros in on your home by using your desired monthly payment and cash available to filter all homes and find those that fit your budget.",
    [TEXTS.INFO_ONE_POINT_ONE_TEXT]: "Set your own payment and investment limit, or upload your lender loan qualification",
    [TEXTS.INFO_ONE_POINT_TWO_TEXT]: "View only homes that fit your requirements or lender pre-approval",
    [TEXTS.INFO_ONE_POINT_THREE_TEXT]: "Highly accurate payment and cash figures",
    [TEXTS.OFFER_LIKE_A_PRO_TEXT]: "Padzilly is the only platform that helps you structure your offer to get the best deal and fit a property to your loan approval.",
    [TEXTS.INFO_TWO_POINT_ONE_TEXT]: "Adjust price, seller concession and down payment in seconds ",
    [TEXTS.INFO_TWO_POINT_TWO_TEXT]: "Try infinite financial scenarios with ease",
    [TEXTS.INFO_TWO_POINT_THREE_TEXT]: "Structure your best deal and make offers with confidence, just like the pros do",
    [TEXTS.LOAN_APPROVAL_TEXT]: "Ask your lender to upload your loan approval and Padzilly will ensure every listing matches your pre-approved loan",
    [TEXTS.INFO_THREE_POINT_ONE_TEXT]: "Eliminate the guesswork",
    [TEXTS.INFO_THREE_POINT_TWO_TEXT]: "View only homes you truly qualify for",
    [TEXTS.INFO_THREE_POINT_THREE_TEXT]: "Lender level payment and cash figures",
    [TEXTS.INFO_THREE_POINT_FOUR_TEXT]: "Get instant property approval letters and offer terms",
    [TEXTS.FOOTER_TEXT]: "For a better experience you can download the app from Google Play or AppStore",
    //SCHOOLS
    [TEXTS.SCHOOLS_DISCLAIMER_TEXT]: "The GreatSchools Rating helps parents compare schools within a state based on a variety of school quality indicators and provides a helpful picture of how effectively each school serves all of its students. Ratings are on a scale of 1 (below average) to 10 (above average) and can include test scores, college readiness, academic progress, advanced courses, equity, discipline and attendance data. We also advise parents to visit schools, consider other information on school performance and programs, and consider family needs as part of the school selection process",
    [TEXTS.SELLER_REBATE_ONLY_LAP_TEXT]: "Seller Rebate is only available to users with approved loans associated to their account",
    //LOADING PROPERTIES
    [TEXTS.LOADING_PROPERTIES_ONE_TEXT]: "Crunching numbers like a chef dicing veggies... Your recipe for the perfect home is almost ready! Please hold on",
    [TEXTS.LOADING_PROPERTIES_TWO_TEXT]: "Crunching numbers like a DJ mixing beats... The perfect home track is about to drop! Please hold on",
    [TEXTS.LOADING_PROPERTIES_THREE_TEXT]: "Crunching numbers like a squirrel stashing acorns... Gathering just the right homes for you! Please hold on",
    [TEXTS.LOADING_PROPERTIES_FOUR_TEXT]: "Crunching numbers like a barista frothing milk... Your custom home brew is almost done! Please hold on",
    [TEXTS.LOADING_PROPERTIES_FIVE_TEXT]: "Crunching numbers like a puzzle master... Piecing together your perfect home match! Please hold on",
    [TEXTS.LOADING_PROPERTIES_SIX_TEXT]: "Crunching numbers like a writer finding the perfect word... Your home story is nearly written! Please hold on",
    [TEXTS.LOADING_PROPERTIES_SEVEN_TEXT]: "Crunching numbers like a sculptor chiseling marble... Your masterpiece home is taking shape! Please hold on",
    [TEXTS.LOADING_PROPERTIES_EIGHT_TEXT]: "Crunching numbers like a baker kneading dough... The perfect home is about to rise! Please hold on",
    [TEXTS.LOADING_PROPERTIES_NINE_TEXT]: "Crunching numbers like a gamer leveling up... Your home victory is on the horizon! Please hold on",
}