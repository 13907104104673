import Checkbox from '@mui/material/Checkbox';
import { useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function BaseCheckbox(props) {

    const [value, setValue] = useState(props.value);

    useEffect(() => { setValue(props.value) }, [props.value])

    const handleEvent = () => {
        setValue(!value);
        if (props.onInputChange) {
            const val = !value;
            props.onInputChange(val);
        }
    };

    return (
        <FormControlLabel
            required={props.required}
            disabled={props.disabled}
            title={props.title}
            control={
                <Checkbox
                    title={props.title}
                    id={props.id}
                    value={props.value}
                    checked={props.value}
                    onChange={handleEvent}
                    color='secondary'
                />
            }
            label={props.label}
        />
    );
}