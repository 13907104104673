import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import BaseToggle from "../BaseComponents/BaseToggle";
import BaseBtn from "../BaseComponents/BaseBtn";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import BaseChip from "../BaseComponents/BaseChip";
import { useState, useEffect, useRef } from "react";
import BaseFilterOption from "../BaseComponents/BaseFilterOption";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { getStateCode, setCurrencyValue } from "../../utils/functions";
import {
    HOME_SIZE_MAX,
    HOME_SIZE_MIN,
    LOT_SIZE_MIN,
    LOT_SIZE_MAX,
    BATH_BED_ROOMS_MIN,
    BATH_BED_ROOMS_MAX
} from "../../constants/selectOptions";
import BaseCurrency from "../BaseComponents/BaseCurrency";
import BaseSelect from "../BaseComponents/BaseSelect";
import { useStore } from "../../hooks/store/store";
import {
    GET_INVESTMENT_PROFILE_LOAN,
    GET_LANGUAGE,
    GET_LAST_SEARCH_RESULTS,
    GET_LOCATION,
    GET_SEARCH_PREFERENCE
} from "../../constants/store/getters";
import BaseRadio from "../BaseComponents/BaseRadio";
import { IconButton } from "@mui/material";
import {
    DESC,
    ASC,
    ES_US,
    LIST,
    MAP
} from "../../constants/constants";
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { Paper } from "@mui/material";
import { useTranslation } from 'react-i18next';
import {
    BATHROOMS_LABEL,
    BEDROOMS_LABEL,
    CURRENT_LOCATION_LABEL,
    FILTERS_LABEL,
    FULL_BATH_LABEL,
    HOME_SIZE_LABEL,
    LIST_LABEL,
    LOT_SIZE_LABEL,
    MAP_LABEL,
    MAX_LABEL,
    MIN_LABEL,
    ORDER_BY_LABEL,
    PRICE_LABEL,
    RESET_LABEL,
    SEARCH_LABEL,
    SIZE_LABEL,
    YEAR_BUILT_LABEL
} from "../../i18n/i18nLabel";
import SortIcon from '@mui/icons-material/Sort';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchPro from "../Dialogs/SearchPro";

export default function LiveSearch(props) {

    const { t } = useTranslation();

    const theme = useTheme();

    const { state, dispatch } = useStore();

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

    const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const [searchValue, setSearchValue] = useState(props.value ?? null);

    const [filterChips, setFilterChips] = useState([]);

    const [search, setSearch] = useState(props.search);

    const [isFilterOptionOpen, setIsFilterOptionOpen] = useState(false);

    const [isSortingOpen, setIsSortingOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const popperRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popperRef.current && !popperRef.current.contains(event.target) && !anchorEl.contains(event.target)) {
            setIsSortingOpen(false);
        }
    };

    useEffect(() => {
        if (isSortingOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSortingOpen]);

    useEffect(() => {
        let filterArray = [];
        if (props.search.minPrice !== null) {
            filterArray.push({ label: (state[GET_LANGUAGE] !== ES_US ? (t(MIN_LABEL) + " " + t(PRICE_LABEL)) : (t(PRICE_LABEL) + " " + t(MIN_LABEL))) + ": $" + setCurrencyValue(props.search.minPrice), value: "minPrice" });
        }
        if (props.search.maxPrice !== null) {
            filterArray.push({ label: (state[GET_LANGUAGE] !== ES_US ? (t(MAX_LABEL) + " " + t(PRICE_LABEL)) : (t(PRICE_LABEL) + " " + t(MAX_LABEL))) + ": $" + setCurrencyValue(props.search.maxPrice), value: "maxPrice" });
        }
        if (props.search.minBedrooms !== null) {
            filterArray.push({ label: (state[GET_LANGUAGE] !== ES_US ? (t(MIN_LABEL) + " " + t(BEDROOMS_LABEL)) : (t(BEDROOMS_LABEL) + " " + t(MIN_LABEL))) + ": " + props.search.minBedrooms, value: "minBedrooms" });
        }
        if (props.search.maxBedrooms !== null) {
            filterArray.push({ label: (state[GET_LANGUAGE] !== ES_US ? (t(MAX_LABEL) + " " + t(BEDROOMS_LABEL)) : (t(BEDROOMS_LABEL) + " " + t(MAX_LABEL))) + ": " + props.search.maxBedrooms, value: "maxBedrooms" });
        }
        if (props.search.minBathrooms !== null) {
            filterArray.push({ label: (state[GET_LANGUAGE] !== ES_US ? (t(MIN_LABEL) + " " + t(BATHROOMS_LABEL)) : (t(BATHROOMS_LABEL) + " " + t(MIN_LABEL))) + ": " + props.search.minBathrooms, value: "minBathrooms" });
        }
        if (props.search.maxBathrooms !== null) {
            filterArray.push({ label: (state[GET_LANGUAGE] !== ES_US ? (t(MAX_LABEL) + " " + t(BATHROOMS_LABEL)) : (t(BATHROOMS_LABEL) + " " + t(MAX_LABEL))) + ": " + props.search.maxBathrooms, value: "maxBathrooms" });
        }
        if (props.search.minHomeSize !== null) {
            filterArray.push({ label: (state[GET_LANGUAGE] !== ES_US ? (t(MIN_LABEL) + " " + t(HOME_SIZE_LABEL)) : (t(HOME_SIZE_LABEL) + " " + t(MIN_LABEL))) + ": " + HOME_SIZE_MIN.find(item => item.value === props.search.minHomeSize)?.label, value: "minHomeSize" });
        }
        if (props.search.maxHomeSize !== null) {
            filterArray.push({ label: (state[GET_LANGUAGE] !== ES_US ? (t(MAX_LABEL) + " " + t(HOME_SIZE_LABEL)) : (t(HOME_SIZE_LABEL) + " " + t(MAX_LABEL))) + ": " + HOME_SIZE_MAX.find(item => item.value === props.search.maxHomeSize)?.label, value: "maxHomeSize" });
        }
        if (props.search.minLotSize !== null) {
            filterArray.push({ label: (state[GET_LANGUAGE] !== ES_US ? (t(MIN_LABEL) + " " + t(LOT_SIZE_LABEL)) : (t(LOT_SIZE_LABEL) + " " + t(MIN_LABEL))) + ": " + LOT_SIZE_MIN.find(item => item.value === props.search.minLotSize)?.label, value: "minLotSize" });
        }
        if (props.search.maxLotSize !== null) {
            filterArray.push({ label: (state[GET_LANGUAGE] !== ES_US ? (t(MAX_LABEL) + " " + t(LOT_SIZE_LABEL)) : (t(LOT_SIZE_LABEL) + " " + t(MAX_LABEL))) + ": " + LOT_SIZE_MAX.find(item => item.value === props.search.maxLotSize)?.label, value: "maxLotSize" });
        }
        if (props.search.minHomeYear !== null) {
            filterArray.push({ label: (state[GET_LANGUAGE] !== ES_US ? (t(MIN_LABEL) + " " + t(YEAR_BUILT_LABEL)) : (t(YEAR_BUILT_LABEL) + " " + t(MIN_LABEL))) + ": " + props.search.minHomeYear, value: "minHomeYear" });
        }
        if (props.search.maxHomeYear !== null) {
            filterArray.push({ label: (state[GET_LANGUAGE] !== ES_US ? (t(MAX_LABEL) + " " + t(YEAR_BUILT_LABEL)) : (t(YEAR_BUILT_LABEL) + " " + t(MAX_LABEL))) + ": " + props.search.maxHomeYear, value: "maxHomeYear" });
        }
        setFilterChips(filterArray);

        if (props.isFilters) {
            if (filterArray?.length > 0) {
                props.isFilters(true);
            } else {
                props.isFilters(false);
            }
        }

        setSearch(props.search);

    }, [props.search]);

    const handleChips = (value) => {
        if (props.filters) {
            props.filters(value);
        }
    }

    const handleOptions = () => {
        //
    };

    const [anchorElFilters, setAnchorElFilters] = useState(null);
    const [open, setOpen] = useState(false);
    const popperRefFilters = useRef(null);

    const handleClick = (event) => {
        setAnchorElFilters(event.currentTarget);
        setOpen((prev) => !prev);
    };

    const handleClickOutsideFilters = (event) => {
        if (popperRefFilters.current && !popperRefFilters.current.contains(event.target) && !anchorElFilters.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutsideFilters);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideFilters);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideFilters);
        };
    }, [open]);

    const [values, setValues] = useState([
        { label: t(LIST_LABEL), value: state[GET_SEARCH_PREFERENCE] !== MAP },
        { label: t(MAP_LABEL), value: state[GET_SEARCH_PREFERENCE] === MAP },
    ]);

    const setMapList = (val) => {
        setValues(val);
        val[0]?.value ? localStorage.setItem("search_preference", LIST) : localStorage.setItem("search_preference", MAP);
        dispatch({ action: GET_SEARCH_PREFERENCE, value: val[0]?.value ? LIST : MAP })
    }

    return (
        <Box sx={{
            position: "fixed",
            width: "100%",
            top: "54px",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "10px",
            left: "0px",
            background: "#fff",
            zIndex: "1000",
            paddingBottom: "5px"
        }}>
            <Box sx={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
            }}
            >
                <Box sx={{
                    display: "flex",
                    width: !isMdOrSmaller ? "90%" : "80%",
                    flexDirection: "column",
                    paddingRight: "14px"
                }}>
                    <Box style={{ width: "100%", display: "flex", alignItems: "center" }}>
                        <Box style={{ width: "100%" }}>
                            <Autocomplete
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px',
                                    },
                                }}
                                isOptionEqualToValue={() => handleOptions}
                                size="small"
                                filterOptions={(x) => x}
                                noOptionsText={props.noOptionsText}
                                options={props.options ?? []}
                                autoHighlight
                                includeInputInList
                                getOptionLabel={(option) => {
                                    return (
                                        option !== null
                                        && option !== undefined
                                        && option !== "")
                                        ? option[props.getOptionLabel]
                                        : props.search
                                            ? props.search[props.getOptionLabel]
                                                ? props.search[props.getOptionLabel]
                                                : ""
                                            : ""
                                }
                                }
                                PaperComponent={(props) => (
                                    <Paper {...props} style={{ marginTop: '8px' }} />
                                )}
                                inputValue={props.inputValue}
                                onInputChange={(event, newInputValue) => {
                                    if (newInputValue !== t(CURRENT_LOCATION_LABEL)) {
                                        props.onInputChange(newInputValue);
                                    }
                                }}
                                value={searchValue}
                                onChange={async (event, newValue) => {
                                    if (newValue?.isCurrentLocation) {
                                        const location = {
                                            searchBy: "City",
                                            description: state[GET_LOCATION]?.city + ", " + getStateCode(state[GET_LOCATION]?.regionName),
                                            searchFor: state[GET_LOCATION]?.city,
                                            state: getStateCode(state[GET_LOCATION]?.regionName)
                                        }
                                        props.onChange(location);
                                        setSearchValue(location);
                                    } else {
                                        props.onChange(newValue);
                                        setSearchValue(newValue);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        autoComplete="off"
                                        value={searchValue}
                                        onClick={() => props.onClick()}
                                        placeholder={props.label ?? ""}
                                        fullWidth={props.fullWidth ?? false}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: <InputAdornment
                                                position="end">
                                                <SearchIcon
                                                    fontSize='medium'
                                                />
                                            </InputAdornment>,
                                        }}
                                        color="secondary"
                                    />
                                )}
                                renderOption={(prop, option) => (
                                    <Box
                                        sx={{ height: "40px" }}
                                        {...prop}
                                    >
                                        {option?.isCurrentLocation
                                            ? <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <FmdGoodOutlinedIcon fontSize="small" />
                                                {option.description}

                                            </Box>
                                            : option.description
                                        }
                                    </Box>
                                )}
                            />
                        </Box>
                        <Box style={{ display: !isSmOrSmaller ? "flex" : "none", alignItems: "center" }}>
                            <BaseFilterOption
                                label={t(PRICE_LABEL)}
                                isOpen={isFilterOptionOpen}
                                handleOpen={() => setIsFilterOptionOpen(true)}
                            >
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    width: "100%",
                                    marginBottom: "8px"
                                }}>
                                    <BaseCurrency
                                        nullable
                                        label={t(MIN_LABEL)}
                                        variant="outlined"
                                        className="inputFilters"
                                        value={search.minPrice}
                                        onInputChange={(value) => {
                                            if (value) {
                                                value = value.toString();
                                            }
                                            setSearch(prevState => ({
                                                ...prevState,
                                                minPrice: (value) ? parseFloat(value.replace(/,/g, '')) : null,
                                                lastPropertyId: null,
                                                lastModificationTimestamp: null,
                                            }));
                                        }}
                                        onBlur={() => {
                                            if (search.maxPrice < search.minPrice && search.maxPrice !== null && search.minPrice !== null) {
                                                const minP = search.minPrice;
                                                const maxP = search.maxPrice;
                                                setSearch(prevState => ({
                                                    ...prevState,
                                                    minPrice: maxP,
                                                    maxPrice: minP,
                                                    lastPropertyId: null,
                                                    lastModificationTimestamp: null,
                                                }));
                                            }
                                        }}
                                    />
                                    <BaseCurrency
                                        nullable
                                        label={t(MAX_LABEL)}
                                        variant="outlined"
                                        className="inputFilters"
                                        value={search.maxPrice}
                                        onInputChange={(value) => {
                                            if (value) {
                                                value = value.toString();
                                            }
                                            setSearch(prevState => ({
                                                ...prevState,
                                                maxPrice: (value) ? parseFloat(value.replace(/,/g, '')) : null,
                                                lastPropertyId: null,
                                                lastModificationTimestamp: null,
                                            }));

                                        }}
                                        onBlur={() => {
                                            if (search.maxPrice < search.minPrice && search.maxPrice !== null && search.minPrice !== null) {
                                                const minP = search.minPrice;
                                                const maxP = search.maxPrice;
                                                setSearch(prevState => ({
                                                    ...prevState,
                                                    minPrice: maxP,
                                                    maxPrice: minP,
                                                    lastPropertyId: null,
                                                    lastModificationTimestamp: null,
                                                }));
                                            }
                                        }}
                                    />
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    marginRight: "8px"
                                }}>
                                    <BaseBtn
                                        close
                                        label={t(RESET_LABEL)}
                                        onClick={() =>
                                            setSearch(prevState => ({
                                                ...prevState,
                                                minPrice: null,
                                                maxPrice: null,
                                                lastPropertyId: null,
                                                lastModificationTimestamp: null,
                                            }))
                                        }
                                    />
                                    &nbsp;
                                    <BaseBtn
                                        label={t(SEARCH_LABEL)}
                                        onClick={() => {
                                            if (props.handleSearch) {
                                                props.handleSearch(search);
                                            }
                                            setIsFilterOptionOpen(false);
                                        }}
                                    />
                                </Box>
                            </BaseFilterOption>
                            <BaseFilterOption
                                label={t(BEDROOMS_LABEL)}
                                isOpen={isFilterOptionOpen}
                                handleOpen={() => setIsFilterOptionOpen(true)}
                            >
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    width: "100%",
                                    marginBottom: "8px"
                                }}>
                                    <BaseSelect
                                        className="inputFilters"
                                        optionValue="value"
                                        optionLabel="label"
                                        optionKey="value"
                                        label={t(MIN_LABEL)}
                                        variant="outlined"
                                        options={BATH_BED_ROOMS_MIN}
                                        value={search.minBedrooms}
                                        onInputChange={(value) => {
                                            const i = BATH_BED_ROOMS_MAX.findIndex(item => item.value === value);
                                            setSearch(prevState => ({
                                                ...prevState,
                                                lastPropertyId: null,
                                                lastModificationTimestamp: null,
                                                minBedrooms: value !== "" ? value : null,
                                                maxBedrooms: prevState.maxBedrooms < value && prevState.maxBedrooms !== null && value !== null
                                                    ? BATH_BED_ROOMS_MAX[i + 1].value === "Any"
                                                        ? null
                                                        : BATH_BED_ROOMS_MAX[i + 1].value
                                                    : prevState.maxBedrooms
                                            }));
                                        }}
                                    />
                                    <BaseSelect
                                        className="inputFilters"
                                        optionValue="value"
                                        optionLabel="label"
                                        optionKey="value"
                                        label={t(MAX_LABEL)}
                                        variant="outlined"
                                        options={BATH_BED_ROOMS_MAX}
                                        value={search.maxBedrooms}
                                        onInputChange={(value) => {
                                            const i = BATH_BED_ROOMS_MIN.findIndex(item => item.value === value);
                                            setSearch(prevState => ({
                                                ...prevState,
                                                lastPropertyId: null,
                                                lastModificationTimestamp: null,
                                                maxBedrooms: value,
                                                minBedrooms: prevState.minBedrooms > value && prevState.minBedrooms !== null && value !== null
                                                    ? BATH_BED_ROOMS_MIN[i - 1].value === "Any"
                                                        ? null
                                                        : BATH_BED_ROOMS_MIN[i - 1].value
                                                    : prevState.minBedrooms
                                            }));
                                        }}
                                    />
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    marginRight: "8px"
                                }}>
                                    <BaseBtn
                                        close
                                        label={t(RESET_LABEL)}
                                        onClick={() =>
                                            setSearch(prevState => ({
                                                ...prevState,
                                                lastPropertyId: null,
                                                lastModificationTimestamp: null,
                                                maxBedrooms: null,
                                                minBedrooms: null
                                            }))
                                        }
                                    />
                                    &nbsp;
                                    <BaseBtn
                                        label={t(SEARCH_LABEL)}
                                        onClick={() => {
                                            if (props.handleSearch) {
                                                props.handleSearch(search);
                                            }
                                            setIsFilterOptionOpen(false);
                                        }}
                                    />
                                </Box>
                            </BaseFilterOption>
                            <BaseFilterOption
                                label={t(SIZE_LABEL)}
                                isOpen={isFilterOptionOpen}
                                handleOpen={() => setIsFilterOptionOpen(true)}
                            >
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    width: "100%",
                                    marginBottom: "8px"
                                }}>
                                    <BaseSelect
                                        className="inputFilters"
                                        optionValue="value"
                                        optionLabel="label"
                                        optionKey="value"
                                        label={t(MIN_LABEL)}
                                        variant="outlined"
                                        options={HOME_SIZE_MIN}
                                        value={search.minHomeSize}
                                        onInputChange={(value) => {
                                            const i = HOME_SIZE_MAX.findIndex(item => item.value === value);
                                            setSearch(prevState => ({
                                                ...prevState,
                                                lastPropertyId: null,
                                                lastModificationTimestamp: null,
                                                minHomeSize: value !== "" ? value : null,
                                                maxHomeSize: prevState.maxHomeSize < value && prevState.maxHomeSize !== null && value !== null
                                                    ? HOME_SIZE_MAX[i + 1].value === "Any"
                                                        ? null
                                                        : HOME_SIZE_MAX[i + 1].value
                                                    : prevState.maxHomeSize
                                            }));
                                        }}
                                    />
                                    <BaseSelect
                                        className="inputFilters"
                                        optionValue="value"
                                        optionLabel="label"
                                        optionKey="value"
                                        label={t(MAX_LABEL)}
                                        variant="outlined"
                                        options={HOME_SIZE_MAX}
                                        value={search.maxHomeSize}
                                        onInputChange={(value) => {
                                            const i = HOME_SIZE_MIN.findIndex(item => item.value === value);
                                            setSearch(prevState => ({
                                                ...prevState,
                                                lastPropertyId: null,
                                                lastModificationTimestamp: null,
                                                maxHomeSize: value !== "" ? value : null,
                                                minHomeSize: prevState.minHomeSize > value && prevState.minHomeSize !== null && value !== null
                                                    ? HOME_SIZE_MIN[i - 1].value === "Any"
                                                        ? null
                                                        : HOME_SIZE_MIN[i - 1].value
                                                    : prevState.minHomeSize
                                            }));
                                        }}
                                    />
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    marginRight: "8px"
                                }}>
                                    <BaseBtn
                                        close
                                        label={t(RESET_LABEL)}
                                        onClick={() =>
                                            setSearch(prevState => ({
                                                ...prevState,
                                                lastPropertyId: null,
                                                lastModificationTimestamp: null,
                                                maxHomeSize: null,
                                                minHomeSize: null
                                            }))
                                        }
                                    />
                                    &nbsp;
                                    <BaseBtn
                                        label={t(SEARCH_LABEL)}
                                        onClick={() => {
                                            if (props.handleSearch) {
                                                props.handleSearch(search);
                                            }
                                            setIsFilterOptionOpen(false);
                                        }}
                                    />
                                </Box>
                            </BaseFilterOption>
                            <Box>
                                <Popper
                                    sx={{ zIndex: 9999 }}
                                    open={isSortingOpen}
                                    anchorEl={anchorEl}
                                    placement={"bottom"}
                                    transition
                                    ref={popperRef}
                                >
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: "8px"
                                            }}>
                                                <Box sx={{
                                                    display: "flex",
                                                    justifyContent: "start"
                                                }}>
                                                    <Box sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "start"
                                                    }}>
                                                        <BaseRadio
                                                            label={t(PRICE_LABEL)}
                                                            selectedValue={props.orderBy}
                                                            value="price"
                                                            onInputChange={(value) => {
                                                                props.setOrderBy(value);
                                                            }}
                                                        />
                                                        <BaseRadio
                                                            label={t(BEDROOMS_LABEL)}
                                                            selectedValue={props.orderBy}
                                                            value="bedrooms"
                                                            onInputChange={(value) => {
                                                                props.setOrderBy(value);
                                                            }}
                                                        />
                                                        <BaseRadio
                                                            label={t(FULL_BATH_LABEL)}
                                                            selectedValue={props.orderBy}
                                                            value="bathroomsFull"
                                                            onInputChange={(value) => {
                                                                props.setOrderBy(value);
                                                            }}
                                                        />
                                                        <BaseRadio
                                                            label={t(HOME_SIZE_LABEL)}
                                                            selectedValue={props.orderBy}
                                                            value="livingArea"
                                                            onInputChange={(value) => {
                                                                props.setOrderBy(value);
                                                            }}
                                                        />
                                                        <BaseRadio
                                                            label={t(LOT_SIZE_LABEL)}
                                                            selectedValue={props.orderBy}
                                                            value="lotSize"
                                                            onInputChange={(value) => {
                                                                props.setOrderBy(value);
                                                            }}
                                                        />
                                                        <BaseRadio
                                                            label={t(YEAR_BUILT_LABEL)}
                                                            selectedValue={props.orderBy}
                                                            value="builtYear"
                                                            onInputChange={(value) => {
                                                                props.setOrderBy(value);
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                if (props.orderDirection === ASC) {
                                                                    props.setOrderDirection(DESC);
                                                                } else {
                                                                    props.setOrderDirection(ASC);
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                props.orderDirection === DESC
                                                                    ? <VerticalAlignBottomIcon fontSize="small" sx={{ margin: "8px", color: "black" }} />
                                                                    : <VerticalAlignTopIcon fontSize="small" sx={{ margin: "8px", color: "black" }} />
                                                            }
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                                <Box sx={{
                                                    display: "flex",
                                                    justifyContent: "end"
                                                }}>
                                                    <BaseBtn
                                                        label={t(ORDER_BY_LABEL)}
                                                        variant="outlined"
                                                        onClick={() => {
                                                            setIsSortingOpen(false);
                                                            props.handleOrder();
                                                        }}
                                                    />
                                                </Box>
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                                <IconButton
                                    size="small"
                                    onClick={(event) => {
                                        setIsSortingOpen(true);
                                        setAnchorEl(event.currentTarget);
                                    }}
                                    disabled={!state[GET_LAST_SEARCH_RESULTS]?.length > 0}
                                >
                                    <SortIcon fontSize="small" sx={{ color: !state[GET_LAST_SEARCH_RESULTS]?.length > 0 ? "grey" : "black" }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="paymentAndCash">
                        <BaseToggle
                            options={[props.searchOptions.payment]}
                            onInputChange={(value) => props.searchOptionsChange(value)}
                        />
                        <BaseToggle
                            onInputChange={(value) => props.searchOptionsChange(value)}
                            options={[props.searchOptions.cash]}
                        />
                        {!state[GET_INVESTMENT_PROFILE_LOAN]
                            ? <BaseToggle
                                options={[props.searchOptions.conv, props.searchOptions.fha]}
                                onInputChange={(value) => props.searchOptionsChange(value)}
                            /> : null
                        }
                        <Box>
                            {
                                filterChips?.length > 0 ? (
                                    <Box>
                                        <Popper
                                            sx={{ zIndex: 9999 }}
                                            open={open}
                                            anchorEl={anchorElFilters}
                                            placement={"bottom"}
                                            transition
                                            ref={popperRefFilters}
                                        >
                                            {({ TransitionProps }) => (
                                                <Fade {...TransitionProps} timeout={350}>
                                                    <Paper sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        padding: "10px"
                                                    }}>
                                                        <p
                                                            style={{
                                                                fontWeight: "bold",
                                                                margin: "0px",
                                                                padding: "5px",
                                                            }}
                                                        >
                                                            {t(FILTERS_LABEL)}
                                                        </p>
                                                        <Box className="filterChips">
                                                            {
                                                                filterChips.map((chip) => (
                                                                    <BaseChip
                                                                        key={chip.value}
                                                                        label={chip.label}
                                                                        value={chip.value}
                                                                        closeChip={(value) => { handleChips(value) }}
                                                                    />
                                                                ))

                                                            }
                                                        </Box>
                                                    </Paper>
                                                </Fade>
                                            )}
                                        </Popper>
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                        >
                                            <FilterAltIcon fontSize="small" sx={{ color: "black" }} />
                                        </IconButton>
                                    </Box>
                                ) : null
                            }
                        </Box>
                        {!isSmOrSmaller
                            ? <SearchPro onClose={() => { props.searchPro(); }} />
                            : null
                        }
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    width: !isMdOrSmaller ? "10%" : "20%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "end",
                }}>
                    <Box style={{ display: "flex", marginTop: "5px" }}>
                        <BaseBtn
                            onClick={() => props.openFilters()}
                            label={t(FILTERS_LABEL)}
                            variant="outlined"
                        />
                    </Box>
                    <Box sx={{
                        display: isSmOrSmaller ? "none" : "flex",
                        justifyContent: "start",
                    }}
                    >
                        {
                            /*<BaseToggle
                            options={values}
                            onInputChange={(value) => setMapList(value)}
                        />*/
                        }
                    </Box>
                </Box>
            </Box>
        </Box >
    );
};