import { Box } from "@mui/material";
import BaseInput from "../BaseComponents/BaseInput";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BaseBtn from "../BaseComponents/BaseBtn";
import { Link } from 'react-router-dom';
import { LANDING_PAGE, LOGIN_ROUTE } from "../../constants/routes";
import { useState, useRef, useEffect } from "react";
import {
    validations,
    MORE_THAN_8_CHARACTERS,
    NO_SPACES,
    MIX_STRING_FOR_PASS
} from "../../utils/validations";
import {
    EMAIL_ERROR,
    FIRST_NAME_ERROR,
    FULL_NAME_ERROR,
    LAST_NAME_ERROR
} from "../../constants/messages";
import { signUpMobile, signUpDesktop } from "../../services/authServices";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import BaseAlert from "../BaseComponents/BaseAlert";
import { handleError } from "../../utils/functions";
import { useError } from "../../hooks/handleError";
import { useStore } from "../../hooks/store/store";
import {
    GET_FUNDS_LIMIT,
    GET_INVESTMENT_PROFILE,
    GET_LANDING_PAGE_ELEMENTS,
    GET_LOCATION,
    GET_NEAR_PROPERTIES,
    GET_PAYMENT_LIMIT,
    GET_PROFILE_ID,
    GET_PROFILE_ROLE,
    GET_PROFILE_TOKEN,
    GET_STORE_COMPLETE
} from "../../constants/store/getters";
import Grow from '@mui/material/Grow';
import { GETTERS } from "../../hooks/store/getters";
import {
    BORROWER_ROLE,
    GUEST_ROLE,
    PRIMARY_COLOR
} from "../../constants/constants";
import { useTranslation } from 'react-i18next';
import {
    ALREADY_HAVE_AN_ACCOUNT_LABEL,
    CREATE_ACCOUNT_LABEL,
    EIGHT_CHARACTERS_LABEL,
    EMAIL_ERROR_LABEL,
    EMAIL_LABEL,
    FIRST_NAME_LABEL,
    FULL_NAME_LABEL,
    GO_BACK_LABEL,
    LAST_ERROR_LABEL,
    LAST_NAME_LABEL,
    LOG_IN_LABEL,
    MIX_PASS_CHARACTERS_LABEL,
    NAME_ERROR_LABEL,
    NO_WHITE_SPACES_LABEL,
    PASSWORD_ERROR_LABEL,
    PASSWORD_LABEL,
    SIGN_UP_LABEL
} from "../../i18n/i18nLabel";

export default function CreateAccount() {

    const { t } = useTranslation();

    const theme = useTheme();

    const { state, dispatch } = useStore();

    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    const FULL_NAME = "full_name";

    const FIRST_NAME = "first_name";

    const LAST_NAME = "last_name";

    const EMAIL = "email";

    const PASSWORD = "password";

    const [errors, setErrors] = useState({});

    const { error, setErrorMessage, clearError } = useError();

    const activeErrors = useRef(false);

    const [saving, setSaving] = useState(false);

    const [fullName, setFullName] = useState(null);

    const [firstName, setFirstName] = useState(null);

    const [lastName, setLastName] = useState(null);

    const [email, setEmail] = useState(null);

    const [password, setPassword] = useState(null);

    const [isWithoutSpaces, setIsWithoutSpaces] = useState(false);

    const [isEightCharacters, setIsEightCharacters] = useState(false);

    const [mixCharacters, setMixCharacters] = useState(false);

    useEffect(() => {
        if (MORE_THAN_8_CHARACTERS.test(password)) {
            setIsEightCharacters(true);
        } else {
            setIsEightCharacters(false);
        }
        if (NO_SPACES.test(password)) {
            setIsWithoutSpaces(true);
        } else {
            setIsWithoutSpaces(false);
        }
        if (MIX_STRING_FOR_PASS.test(password)) {
            setMixCharacters(true);
        } else {
            setMixCharacters(false);
        }
    }, [password]);

    const validationValues = isMdOrSmaller
        ? [
            { validation: ["required", "isFullName"], value: fullName, key: FULL_NAME },
            { validation: ["required", "isEmail"], value: email, key: EMAIL },
            { validation: ["required"], value: password, key: PASSWORD },
        ]
        : [
            { validation: ["required", "onlyLetters"], value: firstName, key: FIRST_NAME },
            { validation: ["required", "onlyLetters"], value: lastName, key: LAST_NAME },
            { validation: ["required", "isEmail"], value: email, key: EMAIL },
            { validation: ["required"], value: password, key: PASSWORD },
        ];

    const signUp = async () => {
        if (!activeErrors.current) {
            try {
                setSaving(true);
                let user;
                if (isMdOrSmaller) {
                    user = await signUpMobile(
                        fullName,
                        email,
                        password,
                        state[GET_PROFILE_ROLE],
                        state[GET_PROFILE_ID],
                        state[GET_PROFILE_TOKEN]
                    );
                } else {
                    user = await signUpDesktop(
                        firstName,
                        lastName,
                        email,
                        password,
                        state[GET_PROFILE_ROLE],
                        state[GET_PROFILE_ID],
                        state[GET_PROFILE_TOKEN]
                    );
                }
                if (user.userId) {
                    if (state[GET_PROFILE_ROLE] === GUEST_ROLE) {
                        localStorage.clear();
                        /*Only if a Guest exist*/
                        if (state[GET_PAYMENT_LIMIT] && state[GET_FUNDS_LIMIT]) {
                            localStorage.setItem('guest_profile', true);
                            localStorage.setItem('payment_limit', state[GET_PAYMENT_LIMIT]);
                            localStorage.setItem('fund_limit', state[GET_FUNDS_LIMIT]);
                        }
                        /* */
                        for (const key in GETTERS) {
                            if (
                                key !== GET_NEAR_PROPERTIES
                                && key != GET_LOCATION
                                && key !== GET_STORE_COMPLETE
                                && key !== GET_LANDING_PAGE_ELEMENTS) {
                                dispatch({ action: key, value: null });
                            }
                        }
                    }
                    localStorage.setItem('profile_id', user.userId);
                    localStorage.setItem("profile_role", BORROWER_ROLE);
                    dispatch({ action: GET_PROFILE_ID, value: user.userId });
                    dispatch({ action: GET_PROFILE_ROLE, value: BORROWER_ROLE });
                    dispatch({ action: GET_INVESTMENT_PROFILE, value: null });
                }
            } catch (e) {
                setErrorMessage(handleError(e));
            } finally {
                setErrors({});
                setSaving(false);
            }
        }
    };

    const showError = (errorValue, field) => {
        if (field === FULL_NAME && Array.isArray(errorValue) && errorValue.length > 0 && isMdOrSmaller) {
            return errorValue[0] === FULL_NAME_ERROR ? t(errorValue[0]) : t(NAME_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        if (field === FIRST_NAME && Array.isArray(errorValue) && errorValue.length > 0 && !isMdOrSmaller) {
            return errorValue[0] === FIRST_NAME_ERROR ? errorValue[0] : t(NAME_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        if (field === LAST_NAME && Array.isArray(errorValue) && errorValue.length > 0 && !isMdOrSmaller) {
            return errorValue[0] === LAST_NAME_ERROR ? errorValue[0] : t(LAST_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        if (field === EMAIL && Array.isArray(errorValue) && errorValue.length > 0) {
            return errorValue[0] === EMAIL_ERROR ? t(errorValue[0]) : t(EMAIL_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        if (field === PASSWORD && Array.isArray(errorValue) && errorValue.length > 0) {
            return t(PASSWORD_ERROR_LABEL) + " " + t(errorValue[0]);
        }
        return "";
    };

    return (
        <Grow
            in={true}
            {...({ timeout: 500 })}
        >
            <Box sx={{
                width: "100%",
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: 'center',
            }}>
                <BaseAlert alert={error} onClose={() => { clearError(); }} />
                <h2 style={{
                    marginTop: "0px",
                    marginBottom: "10px",
                    fontSize: isMdOrSmaller ? "" : "2rem"
                }}>
                    {t(CREATE_ACCOUNT_LABEL)}
                </h2>
                {isMdOrSmaller
                    ? <BaseInput
                        label={t(FULL_NAME_LABEL)}
                        required
                        placeholder="ex. Joe Smith"
                        style={{
                            width: "100%"
                        }}
                        value={fullName}
                        error={showError(errors.full_name, FULL_NAME)}
                        onInputChange={(value) => {
                            if (isMdOrSmaller) {
                                validationValues[0].value = value;
                                setFullName(value);
                                setErrors(validations(validationValues));
                            }
                        }}
                    />
                    : <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <BaseInput
                            label={t(FIRST_NAME_LABEL)}
                            required
                            placeholder="Joe"
                            value={firstName}
                            error={!isMdOrSmaller ? showError(errors.first_name, FIRST_NAME) : null}
                            onInputChange={(value) => {
                                if (!isMdOrSmaller) {
                                    validationValues[0].value = value;
                                    setFirstName(value);
                                    setErrors(validations(validationValues));
                                }
                            }}
                            style={{
                                width: "100%",
                                marginRight: "3px",
                            }}
                        />
                        <BaseInput
                            label={t(LAST_NAME_LABEL)}
                            required
                            placeholder="Smith"
                            value={lastName}
                            error={!isMdOrSmaller ? showError(errors.last_name, LAST_NAME) : null}
                            onInputChange={(value) => {
                                if (!isMdOrSmaller) {
                                    validationValues[1].value = value;
                                    setLastName(value);
                                    setErrors(validations(validationValues));
                                }
                            }}
                            style={{
                                width: "100%",
                                marginLeft: "3px",
                            }}
                        />
                    </Box>
                }

                <BaseInput
                    label={t(EMAIL_LABEL)}
                    required
                    placeholder="ex. joe@padzilly.com"
                    style={{
                        width: "100%"
                    }}
                    value={email}
                    error={showError(errors.email, EMAIL)}
                    onInputChange={(value) => {
                        if (!isMdOrSmaller) {
                            validationValues[2].value = value;
                            setEmail(value);
                            setErrors(validations(validationValues));
                        } else {
                            validationValues[1].value = value;
                            setEmail(value);
                            setErrors(validations(validationValues));
                        }

                    }}
                />
                <BaseInput style={{
                    width: "100%"
                }}
                    label={t(PASSWORD_LABEL)}
                    required
                    type="password"
                    value={password}
                    error={showError(errors.password, PASSWORD)}
                    onInputChange={(value) => {
                        if (!isMdOrSmaller) {
                            validationValues[3].value = value;
                            setPassword(value);
                            setErrors(validations(validationValues));
                        } else {
                            validationValues[2].value = value;
                            setPassword(value);
                            setErrors(validations(validationValues));
                        }

                    }}
                />
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    width: "100%",
                    marginTop: "8px"
                }}
                >
                    <Box sx={{ display: "flex", alignItems: "start", marginBottom: "4px" }}>
                        {isWithoutSpaces
                            ? <CheckCircleIcon fontSize="small" style={{ color: "green" }} />
                            : <CancelIcon fontSize="small" style={{ color: "red" }} />
                        }
                        <p style={{
                            margin: "0px",
                            fontSize: ".8rem"
                        }}>
                            {t(NO_WHITE_SPACES_LABEL)}
                        </p>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "start", marginBottom: "4px" }}>
                        {isEightCharacters
                            ? <CheckCircleIcon fontSize="small" style={{ color: "green" }} />
                            : <CancelIcon fontSize="small" style={{ color: "red" }} />
                        }
                        <p style={{
                            margin: "0px",
                            fontSize: ".8rem"
                        }}>
                            {t(EIGHT_CHARACTERS_LABEL)}
                        </p>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "start", marginBottom: "4px" }}>
                        {mixCharacters
                            ? <CheckCircleIcon fontSize="small" style={{ color: "green" }} />
                            : <CancelIcon fontSize="small" style={{ color: "red" }} />
                        }
                        <p style={{
                            margin: "0px",
                            fontSize: ".8rem"
                        }}>
                            {t(MIX_PASS_CHARACTERS_LABEL)}
                        </p>
                    </Box>
                </Box>
                <BaseBtn
                    label={t(SIGN_UP_LABEL)}
                    style={{ width: "300px" }}
                    onClick={() => {
                        const eList = validations(validationValues);
                        setErrors(eList);
                        (Object.keys(eList).length === 0 && isEightCharacters && mixCharacters && isWithoutSpaces)
                            ? activeErrors.current = false
                            : activeErrors.current = true
                        signUp();
                    }}
                    loading={saving}
                />
                <p style={{ textAlign: "center" }}>
                    {t(ALREADY_HAVE_AN_ACCOUNT_LABEL)} <Link style={{ color: PRIMARY_COLOR }} to={LOGIN_ROUTE}>{t(LOG_IN_LABEL)}</Link>
                </p>
                <Link
                    className="cursor"
                    style={{
                        color: PRIMARY_COLOR,
                        textDecorationColor: PRIMARY_COLOR,
                        fontSize: ".9rem",
                        marginTop: "10px",
                        textDecoration: "none"
                    }}
                    to={LANDING_PAGE}
                >
                    {t(GO_BACK_LABEL)}
                </Link>
            </Box>
        </Grow>
    );
}